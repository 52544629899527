import { ActionIcon, Flex, Group, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { SetStateAction, useCallback } from "react";

type AsideSectionHeaderType = {
  show: boolean;
  toggle: (value?: SetStateAction<boolean> | undefined) => void;
  label: string;
  buttonEl?: JSX.Element;
  rightIconEl?: JSX.Element;
};

const AsideSectionHeader: React.FC<AsideSectionHeaderType> = ({
  show,
  toggle,
  label,
  buttonEl,
  rightIconEl,
}) => {
  const onClickActionIcon = useCallback(() => toggle(), [toggle]);
  return (
    <Flex align="center" justify="space-between" px={10} py={0} h={40}>
      <Group spacing={0}>
        <ActionIcon size={"sm"} onClick={onClickActionIcon}>
          <IconChevronDown
            size="19px"
            stroke={1.0}
            style={{
              transform: `rotate(${show ? 0 : 180}deg)`,
              transition: "transform 0.2s",
            }}
          />
        </ActionIcon>
        <Text fz="sm">{label}</Text>
        {rightIconEl}
      </Group>
      {buttonEl}
    </Flex>
  );
};

export default AsideSectionHeader;
