import { ModalEndOfWork } from "@/components/ModalEndOfWork";
import { useDevice } from "@/hooks/features/useDevice";
import { useDialog } from "@/hooks/useDialog";
import { Button } from "@mantine/core";
import { useCallback } from "react";

export const FinishButton = (): JSX.Element => {
  const { openDialog, closeDialog, isDialogMode } = useDialog<"endOfWork">();
  const { isTabletOrSmartphone } = useDevice();

  const onClickFinishButton = useCallback(() => {
    openDialog("endOfWork");
  }, [openDialog]);

  return (
    <>
      <Button
        size={isTabletOrSmartphone ? "xs" : "sm"}
        color={"red"}
        onClick={onClickFinishButton}
      >
        作業終了
      </Button>
      {isDialogMode("endOfWork") && (
        <ModalEndOfWork
          opened={isDialogMode("endOfWork")}
          onClose={closeDialog}
        />
      )}
    </>
  );
};
