import { GetClientOptionsRole, getClient } from "@/libs/api/client";
import { UnauthorizedError } from "@/libs/api/errors";
import { UserLoginType } from "@/libs/api/generated/enum";
import { LoginResult } from "@/libs/api/generated/types";
import { newLogger } from "@/libs/utils/logger";
import { AuthAtom, authAtom, getAuthFromLocalStorage } from "@/stores";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import dayjs from "dayjs";
import { createClient } from "graphql-ws";
import * as jwt from "jsonwebtoken";
import { useCallback } from "react";
import { useRecoilState } from "recoil";

const logger = newLogger({ prefix: "useSession" });

// アクセストークンリフレッシュ操作の重複実行を防ぐための変数
// Reactコンポーネント内だとライフサイクルの影響を受けるのでグローバル変数で定義
let tokenReloading = false;

/**
 * ログインセッションに関する処理
 * useSWR内でstateの状態（アクセストークン）が更新されない問題があるため、
 * 各APIクライアントのリクエストに利用するアクストークンはローカルストレージから直接取得する
 */
export const useSession = () => {
  const [session, setSession] = useRecoilState(authAtom);

  // ログイントークンの保存
  const setCredentials = useCallback(
    (params: { teamId?: string | null; credentials: LoginResult }) => {
      const decodedToken = jwt.decode(params.credentials.accessToken) as {
        sub: string;
        teamMemberId: string;
        loginType: UserLoginType;
        twoFAEnabled: boolean;
        twoFACertified: boolean;
      };
      logger.debug("decodedToken", decodedToken);
      const newSession: AuthAtom = {
        ...params.credentials,
        userId: decodedToken.sub,
        teamId: params.teamId || "",
        teamMemberId: decodedToken.teamMemberId,
        loginType: decodedToken.loginType,
        twoFAEnabled: decodedToken.twoFAEnabled,
        twoFACertified: decodedToken.twoFACertified,
      };
      setSession(newSession);

      return newSession;
    },
    [setSession]
  );

  // トークンリフレッシュ
  const reloadCredentials = useCallback(
    async (params: { teamId?: string }) => {
      const localStorageSession = getAuthFromLocalStorage();
      if (!localStorageSession?.accessToken) {
        throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
      }

      if (tokenReloading) {
        logger.debug("アクセストークンリフレッシュ操作の重複実行制御");
        while (tokenReloading) {
          await new Promise((resolve) => setTimeout(resolve, 50));
        }
        return getAuthFromLocalStorage() as AuthAtom;
      }

      try {
        tokenReloading = true;

        const client = getClient({
          accessToken: localStorageSession?.accessToken as string,
          role: "anonymous",
        });
        const { loginByRefreshToken: refreshTokenResponse } =
          await client.loginByRefreshToken({
            input: {
              refreshToken: localStorageSession?.refreshToken as string,
              teamId: params.teamId,
            },
          });

        return setCredentials({
          teamId: params.teamId,
          credentials: refreshTokenResponse,
        });
      } finally {
        tokenReloading = false;
      }
    },
    [setCredentials]
  );

  const refreshTokenIfExpired = useCallback(
    async (accessToken: string) => {
      // 有効期限が5分後に切れる場合はトークンリフレッシュ
      const decodedToken = jwt.decode(accessToken) as {
        exp: number;
        teamId: string;
      };
      const teamId = decodedToken.teamId || undefined;
      const tokenExpires = decodedToken.exp * 1000;
      const now = Date.now() + 5 * 60 * 1000; // 5分先だが便宜上nowの変数名を利用

      if (tokenExpires < now) {
        logger.info(
          "アクセストークンの有効期限切れのためトークンリフレッシュ",
          {
            tokenExpires: new Date(tokenExpires).toISOString(),
            now: new Date(now).toISOString(),
          }
        );
        const _newSession = await reloadCredentials({ teamId });

        return _newSession.accessToken;
      }

      return accessToken;
    },
    [reloadCredentials]
  );

  const reloadCurrentAWSCredentials = useCallback(async () => {
    const localStorageSession = getAuthFromLocalStorage();
    if (!localStorageSession?.accessToken) {
      throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
    }

    // AWS Credentialsが1分後に有効期限切れする場合はリフレッシュ
    if (
      dayjs(localStorageSession.credentials.expiration).diff(
        dayjs().add(1, "minutes"),
        "seconds"
      ) < 0
    ) {
      logger.info("AWS Credentialsの有効期限切れのためリフレッシュ");
      return await reloadCredentials({ teamId: localStorageSession.teamId });
    }
    return localStorageSession;
  }, [reloadCredentials]);

  const resetSession = useCallback(() => {
    setSession(null);
  }, [setSession]);

  const getClientWithSession = useCallback(
    async (options: { role?: GetClientOptionsRole } = {}) => {
      const localStorageSession = getAuthFromLocalStorage();
      if (!localStorageSession?.accessToken) {
        throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
      }

      const accessToken = await refreshTokenIfExpired(
        localStorageSession.accessToken
      );

      return getClient({ accessToken, role: options.role });
    },
    [refreshTokenIfExpired]
  );

  const getClientAsGuest = useCallback(
    () => getClient({ accessToken: null }),
    []
  );

  const getApolloClientWithSession = useCallback(async () => {
    const link = new GraphQLWsLink(
      createClient({
        url: `${process.env.NEXT_PUBLIC_WS_BASE_URL as string}/v1/graphql`,
        // 無限にリトライする
        // リトライはデフォルトでRandom Exponential Backoffアルゴリズムが有効になっている
        shouldRetry() {
          return true;
        },
        retryAttempts: Infinity,
        connectionParams: async () => {
          const localStorageSession = getAuthFromLocalStorage();
          if (!localStorageSession?.accessToken) {
            throw new UnauthorizedError(
              "401",
              "",
              "アクセストークンが存在しない"
            );
          }

          const accessToken = await refreshTokenIfExpired(
            localStorageSession.accessToken
          );
          logger.info("getApolloClientWithSession connectionParams", {
            accessToken,
          });
          return {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
        },
      })
    );

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
        },
      },
    });

    return client;
  }, [refreshTokenIfExpired]);

  return {
    session,
    refreshTokenIfExpired,
    getClientAsGuest,
    getClientWithSession,
    getApolloClientWithSession,
    setCredentials,
    reloadCredentials,
    reloadCurrentAWSCredentials,
    resetSession,
  };
};
