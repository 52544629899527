import TaskStatusIcon from "@/components/new/navbar/TaskStatusIcon";
import UserAttendanceStatusIndicator from "@/components/new/navbar/UserAttendanceStatusIndicator";
import { Box, Flex, Text, useMantineTheme } from "@mantine/core";
import { MouseEventHandler } from "react";
import { TaskConstantType } from "../userTasks/constant";
import TaskTimeLabel from "./TaskTimeLabel";

type Props = {
  id?: string;
  name: string;
  projectName: string;
  projectTagName: string;
  workTime?: string | null;
  status: TaskConstantType;
  active?: boolean;
  fullWidth?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const TaskCardSmall: React.FC<Props> = ({
  id,
  name,
  projectName,
  projectTagName,
  workTime,
  status,
  active,
  fullWidth,
  onClick,
}) => {
  const theme = useMantineTheme();
  return (
    <Flex
      id={id}
      w={fullWidth ? "100%" : 240}
      h={64}
      py={4}
      px={4}
      gap={0}
      direction={"column"}
      justify={"center"}
      sx={{
        background: theme.colors.dark[7],
        border: `1px solid ${theme.colors.dark[5]}`,
        borderRadius: "4px",
        cursor: "default",
        "&:hover": {
          border: onClick ? `1px solid ${theme.colors.dark[4]}` : "",
          background: onClick ? theme.colors.dark[6] : "",
        },
      }}
      onClick={onClick}
    >
      <Flex justify={"space-between"} gap={4}>
        <Flex gap={4} sx={{ flex: 1 }}>
          <TaskStatusIcon type={status} />
          <Box sx={{ flex: 1 }}>
            <Text
              w={fullWidth ? 440 : 144}
              fz={10}
              color="dimmed"
              truncate
            >{`${projectName} | ${projectTagName}`}</Text>
          </Box>
        </Flex>
        {workTime && (
          <Box>
            <TaskTimeLabel time={workTime} size="sm" />
          </Box>
        )}
      </Flex>
      <Flex gap={4}>
        <Flex mt={1} w={16} h={16} justify={"center"} align={"center"}>
          {active && <UserAttendanceStatusIndicator type={"ACTIVE"} />}
        </Flex>
        <Text
          fz={12}
          truncate
          lineClamp={2}
          sx={{ whiteSpace: "pre-wrap", flex: 1 }}
        >
          {name}
        </Text>
      </Flex>
    </Flex>
  );
};
export default TaskCardSmall;
