import { projectDisplayMenuAtom } from "@/components/pages/tasks/tasks.store";
import { Box, Flex, Menu, Paper, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import React, { useCallback } from "react";
import { useRecoilState } from "recoil";
import TaskStatusIcon from "../navbar/TaskStatusIcon";
import { TaskStatusTitleMap } from "../userTasks/TaskBoxHeader";
import _ from "lodash";
import {
  TaskConstant,
  TaskConstantObjectType,
  TaskConstantType,
} from "../userTasks/constant";

const TaskStatusSelect: React.FC<{
  size?: "sm";
  status: TaskConstantType;
  statusOptions: Partial<TaskConstantObjectType>;
  onClick: (status: TaskConstantType) => void;
  disabled?: boolean;
}> = ({ size, status, statusOptions, onClick, disabled }) => {
  const [projectDisplayMenu, setprojectDisplayMenu] = useRecoilState(
    projectDisplayMenuAtom
  );
  const onClickStatusChange = useCallback(
    (newStatus: TaskConstantType) => () => {
      onClick(newStatus);
    },
    [onClick]
  );

  return (
    <Menu position="bottom-start" withinPortal disabled={disabled}>
      <Menu.Target>
        <Paper
          withBorder={!disabled}
          w={size === "sm" ? 22 : 36}
          h={size === "sm" ? 22 : 36}
          c={"dark.2"}
          sx={{ cursor: disabled ? "default" : "pointer" }}
        >
          <Flex justify={"center"} align={"center"} h={"100%"}>
            <TaskStatusIcon
              type={status as TaskConstantType}
              size={size === "sm" ? 14 : 20}
            />
          </Flex>
        </Paper>
      </Menu.Target>
      <Menu.Dropdown>
        {Object.values(statusOptions).map(
          (statusOption) =>
            (statusOption === TaskConstant.SUSPEND_TASK &&
              !projectDisplayMenu.displaySuspendTask) || (
              <Menu.Item
                key={statusOption}
                py={5}
                px={6}
                onClick={onClickStatusChange(statusOption)}
              >
                <Flex align={"center"} gap={4}>
                  <Box w={14} h={14} c={"dark.2"}>
                    {status === statusOption && <IconCheck size={14} />}
                  </Box>
                  <TaskStatusIcon type={statusOption} size={14} />
                  <Text>{TaskStatusTitleMap[statusOption]}</Text>
                </Flex>
              </Menu.Item>
            )
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default TaskStatusSelect;
