import { useDevice } from "@/hooks/features/useDevice";
import { minuteToHour } from "@/utils/date";
import { Box, Card, Flex, Text } from "@mantine/core";

export const AttendanceTimeResult = ({
  totalWorkTime,
  totalTaskTime,
  timeDifference,
}: {
  totalWorkTime: number;
  totalTaskTime: number;
  timeDifference: number;
}): JSX.Element => {
  const { isTabletOrSmartphone } = useDevice();
  return (
    <Box
      sx={
        isTabletOrSmartphone
          ? { width: "100%" }
          : {
              position: "fixed",
              top: 64,
              right: 20,
              zIndex: 999,
            }
      }
    >
      <Card withBorder shadow={"xs"} p={8}>
        <Flex justify={"center"} align={"center"} gap={8}>
          <div>
            <Text size={"xs"} color="dimmed">
              作業時間
            </Text>
            <Text size={"xs"}>
              {totalWorkTime
                ? minuteToHour(totalWorkTime, { isJapanese: true })
                : "--"}
            </Text>
          </div>
          <Text size={"xs"} color="dimmed">
            ー
          </Text>
          <div>
            <Text size={"xs"} color="dimmed">
              タスク合計時間
            </Text>
            <Text size={"xs"}>
              {totalTaskTime
                ? minuteToHour(totalTaskTime, { isJapanese: true })
                : "--"}
            </Text>
          </div>
        </Flex>
        {timeDifference !== 0 ? (
          <>
            <Text size={"xs"} ta="center" color="dimmed" mt={8}>
              合計作業時間に対して
              {!isTabletOrSmartphone && <br />}
              合計タスク時間が
            </Text>
            <Flex mt={4} justify={"center"}>
              <Text size={"xs"}>
                {minuteToHour(timeDifference, {
                  isJapanese: true,
                  isAbs: true,
                })}
              </Text>
              <Text size={"xs"} ml={4}>
                {timeDifference < 0 ? "多いです" : "少ないです"}
              </Text>
            </Flex>
          </>
        ) : (
          <></>
        )}
      </Card>
    </Box>
  );
};
