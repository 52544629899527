import { logger } from "@/libs/utils/logger";
import { PageMetaAtom, pageMetaAtom } from "@/stores";
import { isMobile } from "@/utils/device";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { useSession } from "./features/useSession";

export const usePage = () => {
  const router = useRouter();
  const { session } = useSession();
  const [pageMeta, setPageMeta] = useRecoilState(pageMetaAtom);

  // ページのメタ情報を初期化
  const initializePageMeta = useCallback(() => {
    const paths = router.route.split("/");
    const isTeamPage = paths[1] === "t";
    const existsTeamId = paths[2] !== undefined;
    const teamId =
      (router.query.teamId as string | undefined) ?? session?.teamId;

    if (isTeamPage && existsTeamId && teamId === undefined) {
      logger.debug("routerの初期化待ち");
      return false;
    }

    const _pageMeta: PageMetaAtom = {
      teamId,
      isTeamPage,
      isMobile: isMobile(),
    };
    setPageMeta(_pageMeta);
    logger.info("ページのメタ情報を初期化", _pageMeta);
    return true;
  }, [router.query.teamId, router.route, session?.teamId, setPageMeta]);

  // チームページ内のパスを作成
  const createTeamPagePath = useCallback(
    (path: string, rewriteTeamId?: string) => {
      return `/t/${rewriteTeamId ?? pageMeta?.teamId}/${path}`;
    },
    [pageMeta]
  );

  return {
    initializePageMeta,
    createTeamPagePath,
    // システム上は必ずメタデータせってミドルウェアを通るため
    // 必ず初期化されている前提で扱う
    pageMeta: pageMeta as PageMetaAtom,
  };
};
