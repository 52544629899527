export enum DateRangePresetType {
  CUSTOM = "custom",
  THIS_WEEK = "thisWeek",
  LAST_WEEK = "lastWeek",
  THIS_MONTH = "thisMonth",
  LAST_MONTH = "lastMonth",
  LAST_30_DAYS = "last30days",
  LAST_90_DAYS = "last90days",
  LAST_12_MONTHS = "last12months",
  THIS_YEAR = "thisYear",
  LAST_YEAR = "lastYear",
  ALL = "all",
}

export const dateRangePresetLabels = {
  [DateRangePresetType.CUSTOM]: "カスタム",
  [DateRangePresetType.THIS_WEEK]: "今週",
  [DateRangePresetType.LAST_WEEK]: "先週",
  [DateRangePresetType.THIS_MONTH]: "今月",
  [DateRangePresetType.LAST_MONTH]: "先月",
  [DateRangePresetType.LAST_30_DAYS]: "過去30日",
  [DateRangePresetType.LAST_90_DAYS]: "過去90日",
  [DateRangePresetType.LAST_12_MONTHS]: "過去12ヶ月",
  [DateRangePresetType.THIS_YEAR]: "今年",
  [DateRangePresetType.LAST_YEAR]: "前年",
  [DateRangePresetType.ALL]: "全期間",
};

export const dateRangePresetList = Object.values(DateRangePresetType).map(
  (type) => ({
    value: type,
    label: dateRangePresetLabels[type],
  })
);
