import { Box, useMantineTheme } from "@mantine/core";
import { useCallback } from "react";

export const AttendanceStatus = {
  ACTIVE: "ACTIVE",
  BREAK: "BREAK",
  FINISHED: "FINISHED",
} as const;

export type AttendanceStatusType =
  (typeof AttendanceStatus)[keyof typeof AttendanceStatus];

type UserAttendanceStatusIndicatorProps = {
  type: AttendanceStatusType;
};

const UserAttendanceStatusIndicator = ({
  type,
}: UserAttendanceStatusIndicatorProps) => {
  const theme = useMantineTheme();
  const statusColorMap: Record<AttendanceStatusType, string> = {
    ACTIVE: theme.colors.teal[5],
    BREAK: theme.colors.yellow[5],
    FINISHED: theme.colors.dark[3],
  };
  const backgroundColor = statusColorMap[type];
  const sx = useCallback(
    () => ({
      background: backgroundColor,
      borderRadius: 99,
    }),
    []
  );
  return <Box w={8} h={8} sx={sx}></Box>;
};

export default UserAttendanceStatusIndicator;
