import { TeamRole, WorkStatus } from "@/libs/api/generated/enum";
import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import _ from "lodash";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";
import { toTeamMemberDataTableColumnView } from "./views/TeamMemberDataTableColumnView";
import { toTeamMemberView } from "./views/TeamMemberView";

export const useTeamMembersQuery = (projectIds?: string[]) => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();

  // チームメンバー情報を取得
  const {
    data: teamMembers,
    isLoading: teamMembersLoading,
    error: teamMembersError,
    mutate: mutateTeamMembers,
  } = useSWR([SWR_KEYS.QUERY_TEAM_MEMBERS, projectIds], async () => {
    const client = await getClientWithSession();
    const { teamMembers } = await client.teamMembers({
      where: {
        deletedAt: { _isNull: true },
        user: { deletedAt: { _isNull: true } },
        // projectIdsが指定された場合はそのプロジェクトに所属するメンバーに絞り込む
        projectMembers: projectIds
          ? {
              _and: [
                {
                  projectId: {
                    _in: projectIds,
                  },
                },
                {
                  deletedAt: {
                    _isNull: true,
                  },
                },
              ],
            }
          : undefined,
      },
    });
    return _.orderBy(
      teamMembers,
      ["role", (item) => item.user.lastName],
      ["asc", "asc"]
    ).sort((a, b) => {
      if (a.userId === session?.userId) {
        return -1;
      }
      if (b.userId === session?.userId) {
        return 1;
      }
      return 0;
    });
  });

  // 自身の情報を取得
  const currentTeamMember = useMemo(() => {
    if (!teamMembers) return null;

    return teamMembers.find((teamMember) => {
      return teamMember.userId === session?.userId;
    });
  }, [session?.userId, teamMembers]);

  // 自身の権限を判定
  const isTeamAdmin = useMemo(() => {
    if (!teamMembers) return false;

    return teamMembers.some((teamMember) => {
      return (
        teamMember.userId === session?.userId &&
        teamMember.role === TeamRole.ADMIN
      );
    });
  }, [session?.userId, teamMembers]);

  const isNotWorking = useMemo(
    () => currentTeamMember?.workStatus !== WorkStatus.WORKING,
    [currentTeamMember?.workStatus]
  );

  return {
    teamMembers: teamMembers || [],
    mutateTeamMembers,
    currentTeamMember,
    isTeamAdmin,
    teamMemberList: teamMembers?.map(toTeamMemberView) || [],
    teamMemberDataTableList:
      teamMembers?.map(toTeamMemberDataTableColumnView) || [],
    isNotWorking,
  };
};
