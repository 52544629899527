import { logger } from "@/libs/utils/logger";
import { isTabletOrSmartphoneAtom } from "@/stores";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

export const useDevice = () => {
  const [isTabletOrSmartphone, setIsTabletOrSmartphone] = useRecoilState(
    isTabletOrSmartphoneAtom
  );
  const [isSafari, setIsSafari] = useState(false);
  const [isPWA, setIsPWA] = useState(false);

  const checkIsTabletOrSmartphone = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(
        userAgent
      );
    const isTablet =
      /ipad|android(?!.*mobile)|windows.*touch|blackberry.*tablet/i.test(
        userAgent
      );
    const screenWidth = window.innerWidth <= 1024;

    return (isMobile || isTablet) && screenWidth;
  };

  useEffect(() => {
    const checkIsPWA = () => {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
      const isIOSStandalone =
        window.navigator &&
        (window.navigator as Navigator & { standalone?: boolean }).standalone; // 型を明示的に指定

      return isStandalone || isIOSStandalone;
    };

    setIsTabletOrSmartphone(checkIsTabletOrSmartphone() || false); // undefinedをfalseに変換
    setIsPWA(checkIsPWA() || false); // undefinedをfalseに変換
    logger.info(
      isPWA ? "PWAとして検出しています" : "PWAとして検出していません"
    );
  }, [isPWA, setIsTabletOrSmartphone]);

  useEffect(() => {
    if (typeof navigator !== "undefined") {
      const userAgent = navigator.userAgent;
      // iOSデバイス、かつSafariブラウザを判定
      setIsSafari(
        /^((?!chrome|android).)*safari/i.test(userAgent) &&
          /(iPhone|iPod|iPad)/.test(userAgent)
      );
    }
  }, []);

  return {
    isTabletOrSmartphone,
    isSafari,
    isPWA,
  };
};
