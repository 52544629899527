import { CustomError } from "ts-custom-error";

export abstract class ApplicationError extends CustomError {
  public type: string;

  constructor(
    public code: string,
    public userMessage: string,
    public debugMessage: string
  ) {
    super(userMessage);

    this.type = this.constructor.name;
  }
}

export class BadRequestError extends ApplicationError {}
export class UnauthorizedError extends ApplicationError {}
export class ForbiddenError extends ApplicationError {}
export class NotFoundError extends ApplicationError {}
export class ConflictError extends ApplicationError {}
export class SystemError extends ApplicationError {}
export class TooManyRequestError extends ApplicationError {}
export class ServiceUnavailableError extends ApplicationError {}
