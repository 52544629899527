import React, { ReactElement } from "react";

type Props = {
  children: ReactElement;
};

export const LoggedOutMobile = React.memo((props: Props): JSX.Element => {
  return <>{props.children}</>;
});
LoggedOutMobile.displayName = "LoggedOutMobile";
