export enum ErrorCode {
  // Status: 400
  // パラメーター不正
  InvalidParams = '400-001',
  // 後続の管理者が不在
  NoOtherAdmin = '400-002',
  // 想定外の契約更新
  UnexpectedUpdateContract = '400-003',
  // 2段階認証の有効化が必要
  TwoFAActivationRequired = '400-004',
  // リフレッシュトークンの有効期限切れ
  RefreshTokenExpired = '400-005',

  // Status: 401
  // メールアドレスかパスワードが誤っている
  InvalidEmailOrPassword = '401-001',
  // 不正なアクセストークン
  InvalidToken = '401-002',
  // 不正なリフレッシュトークン
  InvalidRefreshToken = '401-003',
  // パスワードが誤っている
  InvalidPassword = '401-004',
  // ユーザーが存在しない
  UserDeleted = '401-005',
  // 2段階認証が未完了
  TwoFAUncertified = '401-007',
  // 2段階認証コードが不正
  InvalidTwoFACode = '401-008',

  // Status: 403
  // 操作権限がない
  Forbidden = '403-001',
  // 該当チームへの権限がない
  NoAccessToTeam = '403-002',
  // ユーザーがロックされています
  UserLockedOut = '403-003',
  // いずれのチームにも所属していない
  NoJoiningTeam = '403-004',
  // 未検証のメールアドレス
  UnverifiedEmail = '403-005',
  // 許容されたIPアドレスではない
  NotAllowedIpAddress = '403-006',

  // Status: 404
  // 指定したリソースが存在しない
  ResourceNotFound = '404-001',

  // Status: 409
  // リクエストがサーバーの現在の状態と矛盾する
  UniquenessError = '409-001',
  // 実行中のタスクが残っている
  RemainingDoingTask = '409-002',
  // 要求した処理が既に行われている
  AlreadyDone = '409-003',
  // 追加しようとしたものが既に存在している
  AlreadyExists = '409-004',

  // Status: 415
  // 許可されていないContent-Type
  UnsupportedMediaType = '415-001',

  // Status: 429
  // 過剰なリクエスト数
  TooManyRequests = '429-001',

  // Status: 500
  // 不明なエラー
  Unknown = '500-001',
  // 不正な引数
  InvalidArgs = '500-002',
  // APIコール時のエラー
  APICall = '500-003',
}
