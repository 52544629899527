import { NotificationAlert } from "@/components/NotificationAlert";
import { BadRequestError, NotFoundError } from "@/libs/api/errors";
import { useCallback } from "react";
import { useApi } from "../useApi";

export const useGithubIssuesQuery = () => {
  const { getClientWithSession } = useApi();

  const getGithubIssues = useCallback(
    async (params: {
      organizationName: string;
      repositoryName: string;
      target?: string;
      page: number;
    }) => {
      const client = await getClientWithSession();
      const { organizationName, repositoryName, target, page } = params;
      try {
        const { githubIssues } = await client.githubIssues({
          input: {
            organizationName,
            repositoryName,
            target,
            page,
          },
        });
        return githubIssues;
      } catch (err) {
        if (err instanceof NotFoundError || err instanceof BadRequestError) {
          NotificationAlert({
            message: err.message,
          });
          return [];
        }
        throw err;
      }
    },
    [getClientWithSession]
  );

  return {
    getGithubIssues,
  };
};
