import { NotificationAlert } from "@/components/NotificationAlert";
import {
  TaskConstant,
  TaskConstantToTaskStatus,
} from "@/components/new/userTasks/constant";
import { TaskStatus, WorkStatus } from "@/libs/api/generated/enum";
import {
  TasksConstraint,
  TasksInsertInput,
  TasksUpdates,
} from "@/libs/api/generated/types";
import { logger, newLogger } from "@/libs/utils/logger";
import { arrayMove } from "@/utils/dnd";
import { DragEndEvent, DragOverEvent, DragStartEvent } from "@dnd-kit/core";
import { useCallback, useState } from "react";
import { ulid } from "ulid";
import {
  DoneContainerId,
  SuspendContainerId,
  TaskContainer,
  TodoContainerId,
  useTasksQuery,
} from "../queries/useTasksQuery";
import { useTeamMembersQuery } from "../queries/useTeamMembersQuery";
import { useWorkTrackingQuery } from "../queries/useWorkTrackingQuery";
import { TaskView } from "../queries/views/TasksView";
import { useApi } from "../useApi";
import { Coordinates } from "@dnd-kit/utilities";

export const useTasks = () => {
  const [draggingTask, setDraggingTask] = useState<TaskView | undefined>(
    undefined
  );
  const [overDelta, setOverDelta] = useState<Coordinates | undefined>(
    undefined
  );

  const { getClientWithSession } = useApi();
  const { currentTeamMember, mutateTeamMembers } = useTeamMembersQuery();
  const {
    tasks,
    mutateTasks,
    taskContainers,
    projectTaskContainers,
    todoTaskContainer,
    doneTaskContainer,
    suspendTaskContainer,
  } = useTasksQuery(currentTeamMember?.id || "");
  const { mutateWorkTracking } = useWorkTrackingQuery(
    currentTeamMember?.id || ""
  );

  const insertTasks = useCallback(
    async (
      tasks: Omit<
        TasksInsertInput,
        "id" | "teamId" | "projectMemberId" | "createdAt" | "updatedAt"
      >[]
    ) => {
      const now = new Date();
      // 所属するプロジェクトを取得
      const client = await getClientWithSession();
      const { projectMembers } = await client.projectMembers({
        where: {
          teamMemberId: {
            _eq: currentTeamMember?.id,
          },
          projectId: {
            _in: tasks.map((task) => task.projectId!),
          },
        },
      });

      const objects = tasks.map((task, i) => {
        const projectMemberId = projectMembers.find(
          (member) => member.projectId === task.projectId
        )?.id;
        if (!projectMemberId) {
          logger.info("projectMemberから削除されている", {
            projectId: task.projectId,
          });
          return null;
        }

        // 順序とステータスを決める
        const taskOptions: { order: number; status: TaskStatus } = (() => {
          if (
            task.status === "TODAY" ||
            task.status === "SUSPEND" ||
            task.status === "DONE"
          ) {
            // Today's Task・Suspended Task・Done Taskのいずれかの場合、リスト内の末尾の順序を設定
            const container =
              task.status === "SUSPEND"
                ? suspendTaskContainer
                : task.status === "DONE"
                ? doneTaskContainer
                : todoTaskContainer;
            const order = container.tasks.length + i;

            return {
              order,
              status:
                // Today's Taskかつ0番目のタスクだった場合、ステータスをDOINGに設定
                task.status === "TODAY" && order === 0
                  ? TaskStatus.DOING
                  : (task.status as TaskStatus),
            };
          } else {
            // プロジェクトに属するタスクの場合、リスト内の末尾の順序を設定
            const container = projectTaskContainers.find(
              (container) => container.containerId === task.projectId
            );
            return {
              order: (container?.tasks.length || 0) + i,
              status: task.status as TaskStatus,
            };
          }
        })();
        logger.debug("projectMemberId", {
          projectMemberId: projectMembers.find(
            (member) => member.projectId === task.projectId
          )?.id,
        });

        return {
          ...task,
          id: ulid(),
          teamId: currentTeamMember?.teamId,
          teamMemberId: currentTeamMember?.id,
          projectMemberId: projectMembers.find(
            (member) => member.projectId === task.projectId
          )?.id,
          ...taskOptions,
          createdAt: now,
          updatedAt: now,
        };
      });

      const notNullObjects = objects.filter((val) => val !== null);
      if (notNullObjects.length > 0) {
        logger.info("タスクを登録", notNullObjects);
        await client.insertTasks({
          objects: notNullObjects as TasksInsertInput[],
          onConflict: {
            constraint: TasksConstraint.Pk_8d12ff38fcc62aaba2cab748772,
          },
        });
      }

      await mutateTasks();
      await mutateTeamMembers();
      await mutateWorkTracking();
    },
    [
      currentTeamMember?.id,
      currentTeamMember?.teamId,
      doneTaskContainer,
      getClientWithSession,
      mutateTasks,
      mutateTeamMembers,
      mutateWorkTracking,
      projectTaskContainers,
      suspendTaskContainer,
      todoTaskContainer,
    ]
  );

  const updateTask = useCallback(
    async (
      task: TaskView,
      updateValue: Partial<
        Pick<TaskView, "projectId" | "tagId" | "name" | "lock" | "status">
      >
    ) => {
      const client = await getClientWithSession();

      // 選択したタスクが所属しているプロジェクトを取得
      const projectContainer = taskContainers.find(
        (container) => container.containerId === task.projectId
      )!;

      // 現在のタスクが所属するcontainer情報
      const currentContainer: TaskContainer =
        task.status === TaskStatus.DONE
          ? doneTaskContainer
          : task.status === TaskStatus.SUSPEND
          ? suspendTaskContainer
          : task.status === TaskStatus.TODAY || task.status === TaskStatus.DOING
          ? todoTaskContainer
          : projectContainer;

      // 移動先のtaskContainer情報
      const newContainer: TaskContainer =
        updateValue.status === TaskConstant.DONE_TASK
          ? doneTaskContainer
          : updateValue.status === TaskConstant.SUSPEND_TASK
          ? suspendTaskContainer
          : updateValue.status === TaskConstant.TODAYS_TASK
          ? todoTaskContainer
          : projectContainer;

      // 更新するステータスを設定
      const newStatus =
        TaskConstantToTaskStatus(updateValue.status) === TaskStatus.TODAY &&
        newContainer.tasks.length === 0
          ? TaskStatus.DOING
          : TaskConstantToTaskStatus(updateValue.status);

      if (currentContainer.containerId === newContainer.containerId) {
        // ステータスが変わらない場合は他の項目のみを更新
        await client.updateTasksByPk({
          pkColumns: {
            id: task.id,
          },
          _set: {
            projectTaskTagId: updateValue.tagId,
            name: updateValue.name,
            updatedAt: new Date(),
            lock: updateValue.lock,
            status: task.status,
          },
        });
      } else {
        // タスク移動後の順序を設定
        const updates: TasksUpdates[] = currentContainer.tasks
          .filter((containerTask) => containerTask.id !== task.id)
          .map((containerTask, i) => {
            return {
              where: {
                id: {
                  _eq: containerTask.id,
                },
              },
              _set: {
                status:
                  containerTask.status === TaskStatus.TODAY && i === 0
                    ? TaskStatus.DOING
                    : containerTask.status,
                order: i,
              },
            };
          });

        // 選択したタスクの更新情報を追加
        updates.push({
          where: {
            id: {
              _eq: task.id,
            },
          },
          _set: {
            projectTaskTagId: updateValue.tagId,
            name: updateValue.name,
            updatedAt: new Date(),
            lock: updateValue.lock,
            status: newStatus,
            order: newContainer.tasks.length,
          },
        });

        await client.updateTasksMany({
          // DOINGにユニークキーが設定されているため逆順に更新
          updates: updates.reverse(),
        });
      }
      await mutateTasks();
      await mutateTeamMembers();
      await mutateWorkTracking();
    },
    [
      doneTaskContainer,
      getClientWithSession,
      mutateTasks,
      mutateTeamMembers,
      mutateWorkTracking,
      suspendTaskContainer,
      taskContainers,
      todoTaskContainer,
    ]
  );

  const updateTasks = useCallback(
    async (
      _taskContainers: TaskContainer[],
      options: { enableApiExecution: boolean } = { enableApiExecution: true }
    ) => {
      const updates = _taskContainers.reduce((acc, container) => {
        const orderedTasks = container.tasks.map((task, i) => {
          const status = (() => {
            if (container.containerId === TodoContainerId) {
              return i === 0 ? TaskStatus.DOING : TaskStatus.TODAY;
            }

            if (container.containerId === DoneContainerId) {
              return TaskStatus.DONE;
            }

            if (container.containerId === SuspendContainerId) {
              return TaskStatus.SUSPEND;
            }

            return TaskStatus.TODO;
          })();
          const updateParams: TasksUpdates = {
            where: {
              id: {
                _eq: task.id,
              },
            },
            _set: {
              status,
              order: i,
            },
          };
          return updateParams;
        });
        acc.push(...orderedTasks);

        return acc;
      }, [] as TasksUpdates[]);

      logger.debug("タスクの順序を入れ替える", updates);
      const client = await getClientWithSession();

      if (options.enableApiExecution) {
        const doingUpdates = updates.filter(
          (update) => update._set?.status === TaskStatus.DOING
        );
        const otherUpdates = updates.filter(
          (update) => update._set?.status !== TaskStatus.DOING
        );

        await client.updateTasksMany({
          // DOINGにユニークキーが設定されているため単純に更新するとえ一意性制約が発生する可能性がある
          // 既存のDOINGのタスクをその他のステータスに変更して、最後にDOINGのタスクを更新することで回避
          updates: [...otherUpdates, ...doingUpdates],
        });
      }

      const updatedTasks = tasks
        .map((task) => {
          const update = updates.find((val) => val.where.id?._eq === task.id);
          return {
            ...task,
            order: update!._set!.order!,
            status: update!._set!.status!,
          };
        })
        .sort((a, b) => a.order - b.order);
      await mutateTasks(updatedTasks, {
        optimisticData: updatedTasks,
        // タスクはログインユーザー自身の操作しかなくmutateで常に同期されているため
        // mutate後のAPIデータ取得は無効化する
        revalidate: false,
      });
    },
    [getClientWithSession, mutateTasks, tasks]
  );

  // 選択したタスクを削除
  const deleteTask = useCallback(
    async (task: TaskView) => {
      logger.info("タスクを削除", task);
      const currentContainer = (() => {
        switch (task.status) {
          case TaskStatus.DONE:
            return doneTaskContainer;
          case TaskStatus.TODO:
            return projectTaskContainers.filter(
              (container) => container.containerId === task.projectId
            )[0];
          default:
            return todoTaskContainer;
        }
      })();

      // タスク削除後の順序・ステータスを設定
      const updates: TasksUpdates[] = currentContainer.tasks
        .filter((containerTask) => containerTask.id !== task.id)
        .map((containerTask, i) => {
          return {
            where: {
              id: {
                _eq: containerTask.id,
              },
            },
            _set: {
              order: i,
              status:
                // DOINGのタスクを削除した場合に2番目のタスクをDOINGに上書き
                task.status === TaskStatus.DOING && i === 0
                  ? TaskStatus.DOING
                  : containerTask.status,
            },
          };
        });

      // 削除するタスクの情報を追加
      updates.push({
        where: {
          id: {
            _eq: task.id,
          },
        },
        _set: {
          status: TaskStatus.TODO,
          deletedAt: new Date(),
        },
      });

      const client = await getClientWithSession();
      await client.updateTasksMany({
        // DOINGにユニークキーが設定されているため逆順に更新
        updates: updates.reverse(),
      });
      await mutateTasks();
      await mutateTeamMembers();
      await mutateWorkTracking();
    },
    [
      doneTaskContainer,
      getClientWithSession,
      mutateTasks,
      mutateTeamMembers,
      mutateWorkTracking,
      projectTaskContainers,
      todoTaskContainer,
    ]
  );

  // handleDragStart: ドラッグ開始時に発火するイベント
  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      logger.debug("handleDragStart===========================");
      if (currentTeamMember?.workStatus !== WorkStatus.WORKING) {
        logger.debug("作業開始前・休憩中のToday's Task・Done Taskの移動は不可");
        return;
      }

      const { active } = event;
      const activeId = active.id;

      const activeContainerId = active.data.current?.sortable.containerId;
      const activeContainer = taskContainers.find(
        (container) => container.containerId === activeContainerId
      );
      if (!activeContainer) return;
      const activeTask = activeContainer.tasks.find(
        (task) => task.id === activeId
      );
      setDraggingTask(activeTask);
    },
    [currentTeamMember?.workStatus, taskContainers]
  );

  // ドラッグ中に発生するイベント
  // 同リスト（例: TodayからToday等）はDnDKitで制御されるが、
  // 別リスト（例: TodayからDone等）はDnDKitで制御されないため、独自実装が必要
  // ドラッグ中はStateの操作のみとして、handleDragEndでDBの更新を行う
  const handleDragOver = useCallback(
    async (event: DragOverEvent) => {
      const _logger = newLogger({ prefix: "useTasks:handleDragOver" });
      _logger.debug({ event });
      const { active, over, delta } = event;

      // 境界付近を移動させた際に無限ループが起こらないようにする
      if (delta.x === overDelta?.x && delta.y === overDelta.y) return;
      setOverDelta(delta);

      // タスクID
      const activeTaskId = active.id;
      const overTaskId = over?.id;
      // 移動元・移動先のタスクのindex
      const activeTaskIndex = active.data.current?.sortable.index ?? 0;
      const overTaskIndex = over?.data.current?.sortable.index;
      // 移動元・移動先のリスト（例: Today, Doneなど）
      const activeContainerId = active.data.current?.sortable.containerId;
      const overContainerId =
        over?.data.current?.sortable.containerId ?? overTaskId;
      _logger.debug({
        activeContainerId,
        overContainerId,
        activeTaskIndex,
        overTaskIndex,
        activeTaskId,
        overTaskId,
      });
      if (!overTaskId) {
        _logger.debug("overTaskIdが存在しませんでした");
        return;
      }

      if (
        currentTeamMember?.workStatus !== WorkStatus.WORKING &&
        ([TodoContainerId, SuspendContainerId, DoneContainerId].includes(
          activeContainerId
        ) ||
          [TodoContainerId, SuspendContainerId, DoneContainerId].includes(
            overContainerId
          ))
      ) {
        _logger.debug(
          "作業開始前・休憩中のToday's Task・Done Taskの移動は不可"
        );
        return;
      }

      const overContainerIndex = over.data.current?.sortable.index ?? 0;
      if (!activeContainerId || !overContainerId) {
        _logger.debug("activeContainerIdまたはoverContainerIdがない場合return");
        return;
      }
      // 空のoverContainerにactiveTaskを追加
      if (activeContainerId === overContainerId) {
        _logger.debug("Containerの移動はreturn");
        return;
      }
      const activeTaskContainer = taskContainers.find(
        (container) => container.containerId === activeContainerId
      )?.tasks;
      if (!activeTaskContainer) {
        _logger.debug("activeTaskContainerが存在しませんでした");
        return;
      }
      const activeTask = activeTaskContainer.find(
        (task) => task.id === activeTaskId
      );
      if (!activeTask) {
        _logger.debug("activeTaskが存在しませんでした");
        return;
      }

      const newContainers = taskContainers.map((container, i) => {
        if (container.containerId === activeContainerId) {
          return {
            containerId: container.containerId,
            tasks: container.tasks.filter((task) => task.id !== activeTaskId),
          };
        }
        if (container.containerId === overContainerId) {
          return {
            containerId: container.containerId,
            tasks: arrayMove(
              [activeTask, ...container.tasks],
              0,
              overContainerIndex + 1
            ),
          };
        }
        return container;
      });
      await updateTasks(newContainers, { enableApiExecution: false });
      _logger.debug("移動が完了しました", newContainers);
    },
    [currentTeamMember?.workStatus, overDelta, taskContainers, updateTasks]
  );

  // handleDragEnd: ドラッグ終了時に発火するイベント
  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const _logger = newLogger({ prefix: "useTasks:handleDragEnd" });
      _logger.debug({ event });
      const { active, over } = event;
      // タスクID
      const activeTaskId = active.id;
      const overTaskId = over?.id;
      // 移動元・移動先のタスクのindex
      const activeTaskIndex = active.data.current?.sortable.index ?? 0;
      const overTaskIndex = over?.data.current?.sortable.index;
      // 移動元・移動先のリスト（例: Today, Doneなど）
      const activeContainerId = active.data.current?.sortable.containerId;
      const overContainerId =
        over?.data.current?.sortable.containerId ?? overTaskId;
      _logger.debug({
        activeContainerId,
        overContainerId,
        activeTaskIndex,
        overTaskIndex,
        activeTaskId,
        overTaskId,
      });
      if (!overTaskId) {
        _logger.debug("overTaskIdが存在しませんでした");
        return;
      }

      if (
        currentTeamMember?.workStatus !== WorkStatus.WORKING &&
        ([TodoContainerId, SuspendContainerId, DoneContainerId].includes(
          activeContainerId
        ) ||
          [TodoContainerId, SuspendContainerId, DoneContainerId].includes(
            overContainerId
          ))
      ) {
        _logger.debug(
          "作業開始前・休憩中のToday's Task・Done Taskの移動は不可"
        );
        NotificationAlert({
          message: `作業${
            currentTeamMember?.workStatus === WorkStatus.NOT_WORKING
              ? "開始"
              : "再開"
          }後にタスクを移動してください`,
        });
        return;
      }

      const activeContainerIndex = active.data.current?.sortable.index;
      const overContainerIndex = over.data.current?.sortable.index ?? 0;

      const newTaskContainers = (() => {
        if (activeContainerIndex === overContainerIndex) {
          return taskContainers;
        } else {
          // 同Containerの移動の場合、順列を入れ替える
          return taskContainers.map((container, i) => {
            if (container.containerId === activeContainerId) {
              return {
                containerId: container.containerId,
                tasks: arrayMove(
                  container.tasks,
                  activeContainerIndex,
                  overContainerIndex
                ),
              };
            }
            return container;
          });
        }
      })();

      await updateTasks(newTaskContainers);
      await mutateTeamMembers();
      await mutateWorkTracking();
      _logger.debug("ドラッグ終了", newTaskContainers);
      setDraggingTask(undefined);
      setOverDelta(undefined);
    },
    [
      currentTeamMember?.workStatus,
      mutateTeamMembers,
      mutateWorkTracking,
      taskContainers,
      updateTasks,
    ]
  );

  return {
    draggingTask,
    insertTasks,
    updateTask,
    deleteTask,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
};
