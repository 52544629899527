import { AppShell, Footer, Header, Image, MantineTheme } from "@mantine/core";
import { ReactElement, useCallback } from "react";
import { FooterInner } from "./FooterInner";

type Props = {
  children: ReactElement;
};

export const AppShellCustomGuest = (props: Props): JSX.Element => {
  const sx = useCallback(
    (theme: MantineTheme) => ({ background: theme.colors.black[0] }),
    []
  );

  const header = (
    <Header height={{ base: 50, md: 70 }} p="md" sx={sx}>
      <Image src="/logo-factog-header.png" alt="Factog" width={120} />
    </Header>
  );
  return (
    <>
      <AppShell
        styles={{
          main: {
            background: "#191326",
          },
        }}
        asideOffsetBreakpoint="sm"
        aside={<></>}
        header={header}
        footer={
          <Footer height={60} p="md" sx={{ background: "black" }}>
            <FooterInner />
          </Footer>
        }
      >
        {props.children}
      </AppShell>
    </>
  );
};
