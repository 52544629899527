import { NavLink } from "@mantine/core";
import Link from "next/link";
import React from "react";
import TooltipWrapper from "../common/TooltipWrapper";

// Navbar
export type NavLinkItemProps = {
  id: string;
  label: string;
  icon: React.ReactNode;
  path: string;
  active: boolean;
};

const NavLinkItem: React.FC<
  NavLinkItemProps & { tooltipDisabled: boolean }
> = ({ label, icon, path, tooltipDisabled, active }) => {
  return (
    <TooltipWrapper label={label} disabled={tooltipDisabled}>
      <NavLink
        component={Link}
        href={path}
        label={label}
        icon={icon}
        active={active}
        noWrap
      />
    </TooltipWrapper>
  );
};

export default NavLinkItem;
