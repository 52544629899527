import { ProviderName } from "@/components/pages/tasks/FormSelectLinkedTasks";
import { useProjectTagQuery } from "@/hooks/queries/useProjectTagQuery";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { TaskServiceType } from "@/libs/api/generated/enum";
import { localizedYup } from "@/utils/localized-yup";
import { validationRules } from "@/utils/yup-rules";
import {
  Checkbox,
  Flex,
  Select,
  SelectItem,
  Text,
  TextInput,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconArrowsExchange2 } from "@tabler/icons";
import _ from "lodash";
import React, { forwardRef, useCallback } from "react";
import TooltipWrapper from "../common/TooltipWrapper";
import { TaskConstant, TaskConstantType } from "../userTasks/constant";
import { TaskServiceProviderName } from "./AddRelationTaskBody";
import TaskStatusSelect from "./TaskStatusSelect";
import { addTaskAtom } from "@/stores";
import { useRecoilState } from "recoil";
import { AddTaskPage } from "@/libs/api/constants";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
}

export type FormRowAddTaskProps = {
  tasks: {
    projectId: string;
    projectTagId: string;
    name: string;
    lock: boolean;
    status: string;
    serviceType?: TaskServiceType;
    serviceId?: string;
  }[];
};

// バリデーションルール
export const FormRowAddTaskSchema = localizedYup.object().shape({
  tasks: localizedYup.array().of(
    localizedYup.object({
      projectId: validationRules.task.projectId.required(),
      projectTagId: validationRules.task.projectTagId.required(),
      name: validationRules.task.name.required(),
      lock: validationRules.task.lock.required(),
    })
  ),
});

type Props = {
  form: UseFormReturnType<
    FormRowAddTaskProps,
    (values: FormRowAddTaskProps) => FormRowAddTaskProps
  >;
  index: number;
  focusOnProjectSelect?: boolean;
  isEdit?: boolean;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>
    </div>
  )
);
SelectItem.displayName = "SelectItem";

export const CreateTaskRow = (props: Props): JSX.Element => {
  const { form, isEdit, focusOnProjectSelect } = props;
  const { activeProjects } = useProjectsQuery();
  const selectedTask = props.form.values["tasks"];
  const selectedProjectId = selectedTask[props.index]["projectId"];
  const selectedTaskStatus = selectedTask[props.index]["status"];
  const selectedTaskServiceType = selectedTask[props.index]["serviceType"];
  const { projectTags } = useProjectTagQuery({
    projectId: selectedProjectId,
  });
  const [addTaskState, setAddTaskState] = useRecoilState(addTaskAtom);

  const service: ProviderName | undefined =
    TaskServiceProviderName[
      form.values["tasks"][props.index]["serviceType"] as TaskServiceType
    ];

  const filter = useCallback(
    (value: string, item: SelectItem) =>
      item.label!.toLowerCase().includes(value.toLowerCase().trim()),
    []
  );

  const onClickStatusSelect = useCallback(
    (status: TaskConstantType) => {
      form.setValues({
        tasks: props.form.values.tasks.map((task, i) => {
          if (i === props.index) {
            return {
              ...props.form.values["tasks"][props.index],
              status,
            };
          } else {
            return task;
          }
        }),
      });
    },
    [form, props.form.values, props.index]
  );

  return (
    <Flex gap={16} align={"flex-start"}>
      <TaskStatusSelect
        status={selectedTaskStatus as TaskConstantType}
        statusOptions={_.pick(TaskConstant, ["TASK_BOX", "TODAYS_TASK"])}
        onClick={onClickStatusSelect}
      />
      <Select
        id="project"
        w={200}
        placeholder="プロジェクト名"
        itemComponent={SelectItem}
        data={activeProjects.map((project) => ({
          label: project.name,
          value: project.id,
        }))}
        maxDropdownHeight={160}
        nothingFound="プロジェクトが見つかりません"
        searchable
        dropdownPosition="bottom"
        filter={filter}
        disabled={
          isEdit ||
          selectedTaskServiceType === TaskServiceType.BACKLOG ||
          selectedTaskServiceType === TaskServiceType.GITHUB
        }
        data-autofocus={focusOnProjectSelect ? true : undefined}
        withinPortal
        {...form.getInputProps(`tasks.${props.index}.projectId`)}
      />
      <Select
        id="tag"
        w={160}
        placeholder="タグ名"
        itemComponent={SelectItem}
        data={projectTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        }))}
        maxDropdownHeight={460}
        nothingFound="タグが見つかりません"
        searchable
        dropdownPosition="bottom"
        filter={filter}
        disabled={!selectedProjectId}
        data-autofocus={!focusOnProjectSelect ? true : undefined}
        withinPortal
        {...form.getInputProps(`tasks.${props.index}.projectTagId`)}
      />
      <TextInput
        id="taskName"
        w={320}
        placeholder="タスク名"
        autoComplete="off"
        {...form.getInputProps(`tasks.${props.index}.name`)}
      />
      <Flex justify={"center"} align={"center"} w={36} h={36}>
        <Checkbox
          id="lock"
          checked={form.values.tasks[props.index].lock}
          {...form.getInputProps(`tasks.${props.index}.lock`)}
        />
      </Flex>
      {service && (
        <TooltipWrapper label={`${service}からの連携`}>
          <Flex justify={"center"} align={"center"} h={36}>
            <IconArrowsExchange2 size="19px" />
          </Flex>
        </TooltipWrapper>
      )}
    </Flex>
  );
};
