import { OrderBy } from "@/libs/api/generated/types";
import { SWR_KEYS } from "@/stores/swr";
import useSWR from "swr";
import { useApi } from "../useApi";
import { toProjectTagView } from "./views/ProjectTagView";

export const useProjectTagQuery = (params: { projectId: string }) => {
  const { getClientWithSession } = useApi();
  // プロジェクト情報を取得
  const {
    data: projectTags,
    isLoading: projectTagsLoading,
    error: projectTagsError,
    mutate: mutateProjectTags,
  } = useSWR([SWR_KEYS.QUERY_PROJECT_TAGS, params.projectId], async () => {
    if (!params.projectId) return [];

    const client = await getClientWithSession();
    const { projectTaskTags } = await client.projectTaskTags({
      where: {
        projectId: {
          _eq: params.projectId,
        },
        deletedAt: {
          _isNull: true,
        },
      },
      orderBy: {
        order: OrderBy.Asc,
      },
    });
    return projectTaskTags;
  });

  return {
    projectTags: projectTags || [],
    mutateProjectTags,
    projectTagsList: projectTags
      ? projectTags.map(toProjectTagView).sort((a, b) => a.id - b.id)
      : [],
  };
};
