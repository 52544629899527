import Error from "@/components/Error";
import { useAuth } from "@/hooks/features/useAuth";
import { ErrorCode } from "@/libs/api/generated/codes";
import { logger } from "@/libs/utils/logger";
import { beforePathAtom, switchingTeamAtom } from "@/stores";
import { useRouter } from "next/router";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { NotificationAlert } from "../NotificationAlert";
import { messages } from "@/utils/messages";

// 認証しない
const ignorePaths = [
  "/",
  "/sign-in",
  "/sign-up",
  "/sign-up/confirm",
  "/sign-out",
  "/password-reset",
  "/password-setting",
  "/cookie-policy",
  "/terms-of-use",
  "/privacy-policy",
  "/contact",
  "/team-delete-success",
  "/accept-invitation",
  "/remove-team-allowed-ip-address",
  "/faq",
  "/withdrawal",
  "/callback/google-login",
  "/callback/microsoft-login",
  "/add-to-home",
];

type Props = {
  children: ReactElement;
};

export const AuthMiddleware = (props: Props): JSX.Element => {
  const router = useRouter();
  const { session } = useAuth();
  const [beforePath, setBeforePath] = useRecoilState(beforePathAtom);

  const [isInvalidAccessToken, setInvalidAccessToken] = useState<
    null | boolean
  >(null);

  const [isInvalidTeamIdPath, setInvalidTeamIdPath] = useState<boolean>(false);
  const [switchingTeam, setSwitchingTeam] = useRecoilState(switchingTeamAtom);

  const redirectSignIn = useCallback(() => {
    // アクセストークンが無効であることを示す
    setInvalidAccessToken(true);

    // ログイン成功したらログイン前にアクセスしたページに戻るためにパスを保存しておく
    setBeforePath(router.asPath);

    // アラートにより再ログインを促す
    NotificationAlert({ message: messages.common.sessionExpired });

    // ログイン画面に遷移
    location.href = "/sign-in";
  }, [router, setBeforePath]);

  // ログイン状態を確認する
  useEffect(() => {
    // 認証しないパスの場合は何もしない
    if (ignorePaths.includes(router.pathname)) {
      logger.info("認証しないパス", router.pathname);
      return;
    }

    // sessionが存在しない場合はログイン画面に遷移
    if (!session) {
      logger.info(
        "アクセストークンが存在しないためログイン画面にリダイレクト",
        router.pathname
      );
      return redirectSignIn();
    }

    // パスパラメータのチームIDが不正な場合は404画面に遷移
    if (
      !!router.query.teamId &&
      router.query.teamId !== session.teamId &&
      !switchingTeam
    ) {
      logger.info("パスのチームIDが不正");
      setInvalidTeamIdPath(true);
    } else {
      setInvalidTeamIdPath(false);
    }
  }, [session, router, redirectSignIn, switchingTeam, setSwitchingTeam]);

  if (
    (isInvalidAccessToken == null && isInvalidAccessToken) ||
    (!ignorePaths.includes(router.pathname) && !session)
  ) {
    return <></>;
  }

  if (isInvalidTeamIdPath) {
    return <Error errorCode={ErrorCode.ResourceNotFound} />;
  }

  return <>{props.children}</>;
};
