import { SWR_KEYS } from "@/stores/swr";
import useSWR from "swr";
import { useApi } from "../useApi";
import { toWorkTrackingView } from "./views/WorkTrackingView";

export const useWorkTrackingQuery = (teamMemberId: string) => {
  const { getClientWithSession } = useApi();

  // 勤怠記録情報を取得
  const {
    data: workTracking,
    isLoading: workTrackingLoading,
    mutate: mutateWorkTracking,
  } = useSWR([SWR_KEYS.QUERY_WORK_TRACKING, teamMemberId], async () => {
    if (!teamMemberId) return null;

    const client = await getClientWithSession();
    const { workTrackings } = await client.workTrackings({
      where: {
        teamMemberId: {
          _eq: teamMemberId,
        },
        finishedAt: {
          _isNull: true,
        },
      },
    });

    if (workTrackings.length === 0) {
      return null;
    }

    // 毎日必ず作業終了するため、finishedAtがnullのレコードは1件のみ
    return workTrackings.map(toWorkTrackingView)[0];
  });

  return {
    workTracking,
    workTrackingLoading,
    mutateWorkTracking,
  };
};
