import { Box, Space, Text } from "@mantine/core";

const ProjectTagTaskLabel: React.FC<{
  username?: string;
  project?: string;
  tag?: string;
  task?: string;
  w?: number | string;
  truncate?: true | "end" | "start";
}> = ({ username, project, tag, task, w, truncate }) => {
  return (
    <Box w={w}>
      {username && (
        <>
          <Text
            lh={1.2}
            fz={11}
            color="dimmed"
            truncate={truncate}
            sx={{ whiteSpace: truncate ? "nowrap" : "pre-wrap" }}
          >
            {username}
          </Text>
        </>
      )}
      {project && tag && (
        <>
          <Space h={4} />
          <Text
            lh={1.2}
            fz={11}
            color="dimmed"
            truncate={truncate}
            sx={{ whiteSpace: truncate ? "nowrap" : "pre-wrap" }}
          >
            {`${project} | ${tag}`}
          </Text>
        </>
      )}
      {task && (
        <>
          <Space h={4} />
          <Text
            lh={1.2}
            fz={13}
            truncate={truncate}
            sx={{ whiteSpace: truncate ? "nowrap" : "pre-wrap" }}
          >
            {task}
          </Text>
        </>
      )}
    </Box>
  );
};

export default ProjectTagTaskLabel;
