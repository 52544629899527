import { useCallback, useState } from "react";

export const useDialog = <T>(defaultDialog: T | "" = "") => {
  const [dialog, setDialog] = useState<T | "">(defaultDialog);

  const openDialog = useCallback((mode: T) => {
    setDialog(mode);
  }, []);

  const closeDialog = useCallback(() => {
    setDialog("");
  }, []);

  const isDialogMode = useCallback(
    (mode: T) => {
      return dialog === mode;
    },
    [dialog]
  );

  return { dialog, setDialog, openDialog, closeDialog, isDialogMode };
};
