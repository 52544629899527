import { ModalInnerActivity } from "@/components/pages/activities/ModalInnerActivity";
import { useDialog } from "@/hooks/useDialog";
import { Routes, usePath } from "@/hooks/usePath";
import { defaultReportsParams, reportsParamsAtom } from "@/stores/reports";
import { Menu, Modal } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";

const TeamMemberMenuItems: React.FC<{ id: string; name: string }> = ({
  id,
  name,
}) => {
  const router = useRouter();
  const teamId = useMemo(
    () => (router.query.teamId as string) || "",
    [router.query.teamId]
  );
  const { generatePath } = usePath();
  const { openDialog, closeDialog, isDialogMode } =
    useDialog<"activityDialog">("");
  const [reportsParams, setReportsParams] = useRecoilState(reportsParamsAtom);

  const onClickSummary = useCallback(() => {
    if (`${router.pathname}/` === Routes.REPORT_SUMMARY.path) {
      // 分析画面からクリックされた場合、パラメータを直接更新する
      setReportsParams({
        ...defaultReportsParams,
        teamMemberIds: [id],
      });
    } else {
      const reportSummaryLink = generatePath({
        routePath: Routes.REPORT_SUMMARY.path,
        dynamicParams: { teamId },
        queryParams: {
          s: JSON.stringify({
            ...defaultReportsParams,
            teamMemberIds: [id],
          }),
        },
      });

      void router.push(reportSummaryLink);
    }
  }, [generatePath, id, router, setReportsParams, teamId]);

  const onClickActivity = useCallback(
    () => openDialog("activityDialog"),
    [openDialog]
  );
  return (
    <>
      <Menu.Label>{name}</Menu.Label>
      <Menu.Item
        component={Link}
        href={generatePath({
          routePath: Routes.TEAM_MEMBER_TASKS.path,
          dynamicParams: { teamId, teamMemberId: id },
        })}
      >
        {Routes.TEAM_MEMBER_TASKS.name}
      </Menu.Item>
      <Menu.Item
        component={Link}
        href={generatePath({
          routePath: Routes.TEAM_MEMBER_WORKS.path,
          dynamicParams: { teamId, teamMemberId: id },
        })}
      >
        {Routes.TEAM_MEMBER_WORKS.name}
      </Menu.Item>
      <Menu.Item
        component={Link}
        href={generatePath({
          routePath: Routes.TEAM_MEMBER_PROJECTS.path,
          dynamicParams: { teamId, teamMemberId: id },
        })}
      >
        {Routes.TEAM_MEMBER_PROJECTS.name}
      </Menu.Item>
      <Menu.Item
        component={Link}
        href={generatePath({
          routePath: Routes.TEAM_MEMBER_PROFILE.path,
          dynamicParams: { teamId, teamMemberId: id },
        })}
      >
        {Routes.TEAM_MEMBER_PROFILE.name}
      </Menu.Item>
      <Menu.Item onClick={onClickSummary}>分析</Menu.Item>
      <Menu.Item closeMenuOnClick={false} onClick={onClickActivity}>
        アクティビティ
      </Menu.Item>
      {isDialogMode("activityDialog") && (
        <Modal
          centered
          opened={isDialogMode("activityDialog")}
          onClose={closeDialog}
          title="アクティビティ"
          size={640}
          zIndex={999}
        >
          <ModalInnerActivity teamMemberId={id} />
        </Modal>
      )}
    </>
  );
};

export default TeamMemberMenuItems;
