import { NotFoundError } from "@/libs/api/errors";
import { useCallback } from "react";
import { useApi } from "../useApi";
import { GoogleCalendarEventQuery } from "@/libs/api/generated/types";

export const useGoogleCalendarEventQuery = () => {
  const { getClientWithSession } = useApi();

  const getGoogleCalendarEvent = useCallback(
    async (pageToken?: string | null) => {
      const client = await getClientWithSession();

      try {
        const { googleCalendarEvent }: GoogleCalendarEventQuery =
          await client.googleCalendarEvent({
            input: {
              pageToken,
              maxResults: 50,
            },
          });

        return googleCalendarEvent;
      } catch (err) {
        if (err instanceof NotFoundError) {
          return {
            events: [],
          };
        }
        throw err;
      }
    },
    [getClientWithSession]
  );

  return {
    getGoogleCalendarEvent,
  };
};
