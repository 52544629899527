import { useDevice } from "@/hooks/features/useDevice";
import { useWorks } from "@/hooks/features/useWorks";
import { BadRequestError } from "@/libs/api/errors";
import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useCallback, useState } from "react";

export const ResumeButton = (): JSX.Element => {
  const { resumeWork } = useWorks();
  const [loading, setLoading] = useState(false);
  const { isTabletOrSmartphone } = useDevice();

  const onClick = useCallback(async () => {
    try {
      setLoading(true);
      await resumeWork();
      notifications.show({
        title: "🔥 作業再開しました",
        message: "",
      });
    } catch (error) {
      if (error instanceof BadRequestError) {
        // 無効なステータス変更操作はリロードする
        location.reload();
      }
    } finally {
      setLoading(false);
    }
  }, [resumeWork]);

  return (
    <Button
      size={isTabletOrSmartphone ? "xs" : "sm"}
      color={"green"}
      onClick={onClick}
      loading={loading}
      disabled={loading}
    >
      再開
    </Button>
  );
};
