import { Box } from "@mantine/core";
import { ActivityDateType, ActivityType } from "./constant";
import Activity from "./Activity";
import ActivityDate from "./ActivityDate";
import ActivityTooltipLabel from "./ActivityTooltipLabel";
import TooltipWrapper from "../../common/TooltipWrapper";

const ActivityDates: React.FC<ActivityDateType> = ({ date, activities }) => {
  return (
    <Box>
      <ActivityDate date={date} />
      {activities.map((activity: ActivityType, j: number) => (
        <Box key={j}>
          <TooltipWrapper label={<ActivityTooltipLabel {...activity} />}>
            <Box>
              <Activity {...activity} />
            </Box>
          </TooltipWrapper>
        </Box>
      ))}
    </Box>
  );
};
export default ActivityDates;
