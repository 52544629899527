import { NotificationAlert } from "@/components/NotificationAlert";
import { BadRequestError, NotFoundError } from "@/libs/api/errors";
import { useCallback } from "react";
import { useApi } from "../useApi";

export const useGithubRepositoriesQuery = () => {
  const { getClientWithSession } = useApi();

  const getGithubRepositories = useCallback(
    async (params: { organizationName: string }) => {
      const client = await getClientWithSession();

      let result: Array<{ id: number; name: string }> = [];
      let page = 1;
      while (true) {
        try {
          const { githubRepositories } = await client.githubRepositories({
            input: {
              organizationName: params.organizationName,
              page,
            },
          });
          result = [...result, ...githubRepositories];

          if (githubRepositories.length < 100) break;
          page++;
        } catch (err) {
          if (err instanceof NotFoundError || err instanceof BadRequestError) {
            NotificationAlert({
              message: err.message,
            });
            return result;
          }
          throw err;
        }
      }

      return result;
    },
    [getClientWithSession]
  );

  return {
    getGithubRepositories,
  };
};
