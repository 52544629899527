import { ProjectRole, TeamRole } from "./generated/enum";

export enum AddTaskPage {
  ADD_TASK = "1",
  SELECT_GOOGLE_TASK = "2",
  ADD_GOOGLE_TASK = "3",
}

export const TeamRoleNames: Record<TeamRole, string> = {
  [TeamRole.ADMIN]: "管理者",
  [TeamRole.MEMBER]: "メンバー",
};

export const TeamRoleOptions = Object.values(TeamRole).map((role) => ({
  value: role,
  label: TeamRoleNames[role],
}));

export const ProjectRoleNames: Record<ProjectRole, string> = {
  [ProjectRole.ADMIN]: "管理者",
  [ProjectRole.MEMBER]: "メンバー",
};

export const ProjectRoleOptions = Object.values(ProjectRole).map((role) => ({
  value: role,
  label: ProjectRoleNames[role],
}));
