import { TaskStatus } from "@/libs/api/generated/enum";
import { WorkTrackingsAttributeFragment } from "@/libs/api/generated/types";
import {
  formatDateWithWeek,
  formatTime,
  minuteToHour,
  timeStringToMinutes,
} from "@/utils/date";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import _ from "lodash";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

export type WorkTrackingView = {
  raw: WorkTrackingsAttributeFragment;
  // 作業開始日
  startedDate: string;
  // 作業開始時刻
  startedTime: string;
  // 作業終了時刻
  finishedTime: string;
  // 合計作業時間(チームボード画面で表示)
  totalWorkTime: string;
  // 合計作業時間秒数(ヘッダーの合計作業時間部分で使用)
  totalWorkTimeSecond: number;
  // 休憩時間の配列
  breakTimes: {
    // 休憩開始時刻
    startedTime: string;
    // 休憩終了時刻
    finishedTime: string;
  }[];
  // タスクの配列
  tasks: {
    id: string;
    name: string;
    projectName: string;
    projectTagName: string;
    workTime: string;
    workTimeSecond: number;
    status: TaskStatus;
  }[];
};

const convertToFinishedTime = (startedAt: string, finishedAt?: string) => {
  if (!finishedAt) return "";

  const startedAtDate = dayjs(startedAt);
  const finishedAtDate = dayjs(finishedAt);

  const startOfStartedAtDate = startedAtDate.startOf("day");
  const startOfFinishedAtDate = finishedAtDate.startOf("day");

  if (startOfStartedAtDate.isSame(startOfFinishedAtDate, "day")) {
    return formatTime(finishedAt);
  }

  // 24:00を超える表記に変更
  const diffDays = startOfFinishedAtDate.diff(startOfStartedAtDate, "days");
  const finishedAtHours = finishedAtDate.hour();
  return minuteToHour(
    // (終了日と開始日の差分日数 * 24時間（日を時間換算) + 終了時刻の時間) * 60分(時間を分換算) + 終了時刻の分
    (diffDays * 24 + finishedAtHours) * 60 + finishedAtDate.minute()
  );
};

const statusOrder = {
  [TaskStatus.DONE]: 0,
  [TaskStatus.SUSPEND]: 1,
  [TaskStatus.DOING]: 2,
  [TaskStatus.TODAY]: 3,
  [TaskStatus.TODO]: 4,
};

export const toWorkTrackingView = (
  entity: WorkTrackingsAttributeFragment
): WorkTrackingView => {
  // 休憩中の場合は該当するレコードのfinished_atがnullになる
  const isBreaking = entity.workTrackingBreaks.some(
    (breakTime) => breakTime.finishedAt == null
  );
  const breakTimes = entity.workTrackingBreaks.map((breakTime) => {
    return {
      startedTime: formatTime(breakTime.startedAt),
      finishedTime: convertToFinishedTime(
        breakTime.startedAt,
        breakTime.finishedAt || dayjs().toISOString()
      ),
    };
  });

  let totalBreakMinutes = 0;
  breakTimes.map((breakTime) => {
    totalBreakMinutes +=
      timeStringToMinutes(breakTime.finishedTime) -
      timeStringToMinutes(breakTime.startedTime);
  });

  // 休憩時には合計作業時間の秒数を0に丸め込む
  const totalWorkTimeSecond =
    (isBreaking ? dayjs().startOf("minute") : dayjs()).diff(
      dayjs(entity.startedAt).startOf("minute"),
      "second"
    ) -
    totalBreakMinutes * 60;

  return {
    raw: entity,
    startedDate: formatDateWithWeek(entity.startedAt),
    startedTime: formatTime(entity.startedAt),
    finishedTime: convertToFinishedTime(
      entity.startedAt,
      dayjs().toISOString()
    ),
    totalWorkTime: minuteToHour(totalWorkTimeSecond / 60),
    totalWorkTimeSecond,
    breakTimes,
    tasks: _.sortBy(
      entity.workTrackingTasks.filter(
        (trackingTask) => trackingTask.task != null
      ),
      (workTrackingTask) =>
        statusOrder[workTrackingTask.task!.status as TaskStatus]
    ).map((trackingTask) => {
      const task = trackingTask.task!;
      let totalSeconds = (trackingTask.totalMinutes || 0) * 60;
      // 作業開始日時がある場合はDOINGなので作業時間が計測されている状態
      if (trackingTask.startedAt) {
        totalSeconds += dayjs().diff(dayjs(trackingTask.startedAt), "second");
      }
      return {
        id: task.id,
        name: task.name,
        projectName: task.project?.name as string,
        projectTagName: task.projectTaskTag?.name as string,
        workTime: minuteToHour(totalSeconds / 60),
        workTimeSecond: totalSeconds,
        status: task.status as TaskStatus,
      };
    }),
  };
};
