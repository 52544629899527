import { Box } from "@mantine/core";
import { ReactNode } from "react";

type asideSectionBodyWrapperType = {
  show: boolean;
  children: ReactNode;
};

const AsideSectionBodyWrapper: React.FC<asideSectionBodyWrapperType> = ({
  show,
  children,
}) => {
  return (
    <Box
      sx={{
        display: show ? "block" : "none",
        overflowY: "scroll",
        overflowX: "hidden",
        flex: 1,
      }}
    >
      {children}
    </Box>
  );
};

export default AsideSectionBodyWrapper;
