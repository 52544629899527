import { Flex, Menu, Text } from "@mantine/core";
import { useCallback, useState } from "react";
import UserAvatar from "../../common/UserAvatar";
import TeamMemberMenuItems from "../../common/teamMember/TeamMemberMenuItems";
import { ActivityType } from "../activity/constant";
import ActivityContent from "./ActivityContent";
import { useDialog } from "@/hooks/useDialog";
import { ModalTaskDetail } from "../../createTask/ModalTaskDetail";

const Activity: React.FC<ActivityType> = ({ time, content, user, task }) => {
  const [opened, setOpened] = useState(false);
  const { openDialog, closeDialog, isDialogMode } = useDialog<"detailTask">();

  const onClickTaskDetails = useCallback(() => {
    openDialog("detailTask");
  }, [openDialog]);

  return (
    <Menu
      shadow="md"
      width={200}
      position="left-start"
      withinPortal
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <Flex align={"center"} px={10} py={4} gap={6}>
          <Text fz={"xs"}>{time}</Text>
          <UserAvatar
            src={user.avatar}
            size={24}
            radius={"xl"}
            userFullName={user.name}
          />
          <ActivityContent {...content} truncate />
        </Flex>
      </Menu.Target>
      <Menu.Dropdown>
        <TeamMemberMenuItems id={user.teamMemberId} name={user.name} />
        {content.type === "TASK" && (
          <>
            <Menu.Divider />
            <Menu.Item onClick={onClickTaskDetails}>タスク詳細</Menu.Item>
          </>
        )}
      </Menu.Dropdown>
      {isDialogMode("detailTask") && task && (
        <ModalTaskDetail
          opened={true}
          task={task}
          onClose={closeDialog}
        ></ModalTaskDetail>
      )}
    </Menu>
  );
};

export default Activity;
