import { TaskServiceType } from "@/libs/api/generated/enum";
import { TaskAttributesFragment } from "@/libs/api/generated/types";

export type TaskView = {
  id: string;
  projectId: string;
  projectName: string;
  name: string;
  tag: string;
  tagId: string;
  lock: boolean;
  status: string;
  teamMemberId: string;
  serviceType: TaskServiceType | undefined;
  serviceTypeName: string | undefined;
};

export const taskServiceName = {
  [TaskServiceType.GITHUB]: "GitHub",
  [TaskServiceType.BACKLOG]: "Backlog",
  [TaskServiceType.GOOGLE_CALENDAR]: "GoogleCalendar",
};

export const toTaskView = (task: TaskAttributesFragment) => {
  return {
    id: task.id,
    name: task.name,
    projectId: task.projectId,
    projectName: task.project?.name || "",
    tagId: task.projectTaskTag?.id || "",
    tag: task.projectTaskTag?.name || "",
    lock: task.lock,
    status: task.status,
    teamMemberId: task.teamMemberId,
    serviceType: task.serviceType
      ? (task.serviceType as TaskServiceType)
      : undefined,
    serviceTypeName: task.serviceType
      ? taskServiceName[task.serviceType as TaskServiceType]
      : undefined,
  };
};
