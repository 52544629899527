import { Routes, usePath } from "@/hooks/usePath";
import { Box, Menu } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

const TeamMenuSettings: React.FC = () => {
  const router = useRouter();
  const teamId = useMemo(
    () => (router.query.teamId as string) || "",
    [router.query.teamId]
  );
  const { generatePath } = usePath();

  const teamMenuList = [
    {
      label: "チーム",
      path: generatePath({
        routePath: Routes.TEAM_SETTINGS.path,
        dynamicParams: { teamId, activeTab: "teams" },
      }),
    },
    {
      label: "連携",
      path: generatePath({
        routePath: Routes.TEAM_SETTINGS.path,
        dynamicParams: { teamId, activeTab: "linkages" },
      }),
    },
    {
      label: "プラン",
      path: generatePath({
        routePath: Routes.TEAM_SETTINGS.path,
        dynamicParams: { teamId, activeTab: "plans" },
      }),
    },
    {
      label: "ログイン・セキュリティ",
      path: generatePath({
        routePath: Routes.TEAM_SETTINGS.path,
        dynamicParams: { teamId, activeTab: "security" },
      }),
    },
    {
      label: "その他",
      path: generatePath({
        routePath: Routes.TEAM_SETTINGS.path,
        dynamicParams: { teamId, activeTab: "others" },
      }),
    },
  ];

  return (
    <Box>
      <Menu.Label>設定</Menu.Label>
      {teamMenuList.map((item) => (
        <Menu.Item key={item.path} component={Link} href={item.path}>
          {item.label}
        </Menu.Item>
      ))}
    </Box>
  );
};

export default TeamMenuSettings;
