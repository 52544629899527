import { Flex, Paper, Text } from "@mantine/core";
import { IconHourglassHigh } from "@tabler/icons";

const TaskTimeLabel: React.FC<{ time: string; size?: "sm" }> = ({
  time,
  size,
}) => {
  return (
    <Paper
      withBorder
      p={size === "sm" ? 2 : 4}
      c={"dimmed"}
      display={"inline-block"}
    >
      <Flex gap={4} justify={"center"} align={"center"} h={"100%"}>
        <IconHourglassHigh size={size === "sm" ? 12 : 16} />
        <Text fz={size === "sm" ? 11 : 14} lh={1}>
          {time}
        </Text>
      </Flex>
    </Paper>
  );
};
export default TaskTimeLabel;
