import { Button, Flex, Image, Stack, Text, Title } from "@mantine/core";
import { useRouter } from "next/router";

type Props = {
  title: string;
  description: string;
  buttonText?: string;
  onClick?: () => void;
};

export const PageError = (props: Props): JSX.Element => {
  const router = useRouter();
  return (
    <Flex justify={"center"}>
      <Stack spacing={40} sx={{ maxWidth: "432px", textAlign: "center" }}>
        <Flex justify={"center"}>
          <Image src="/logo-factog-v.png" alt="Factog" width={240} />
        </Flex>
        <Title
          color={"white"}
          weight={"bold"}
          sx={{ whiteSpace: "pre-wrap", textAlign: "center" }}
        >
          {props.title}
        </Title>
        <Text
          color={"white"}
          fz="lg"
          sx={{ whiteSpace: "pre-wrap", textAlign: "center" }}
        >
          {props.description}
        </Text>
        <div>
          <Button
            variant="default"
            color={"gray"}
            size={"md"}
            onClick={props.onClick ? props.onClick : router.back}
          >
            {props.buttonText ?? "もどる"}
          </Button>
        </div>
      </Stack>
    </Flex>
  );
};
