import { NotificationAlert } from "@/components/NotificationAlert";
import { useDevice } from "@/hooks/features/useDevice";
import { useWorks } from "@/hooks/features/useWorks";
import { ConflictError } from "@/libs/api/errors";
import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useCallback, useState } from "react";

export const StartButton = (): JSX.Element => {
  const { startWork } = useWorks();
  const [loading, setLoading] = useState(false);
  const { isTabletOrSmartphone } = useDevice();

  const onClick = useCallback(async () => {
    try {
      setLoading(true);
      await startWork();
      notifications.show({
        title: "🔆 作業開始しました",
        message: "",
      });
    } catch (e) {
      if (e instanceof ConflictError) {
        // 既に作業を開始している場合はリロードする
        location.reload();
      } else {
        throw e;
      }
    } finally {
      setLoading(false);
    }
  }, [startWork]);

  return (
    <Button
      size={isTabletOrSmartphone ? "xs" : "sm"}
      color={"blue"}
      onClick={onClick}
      loading={loading}
      disabled={loading}
    >
      作業開始
    </Button>
  );
};
