import {
  CursorOrdering,
  OrderBy,
  SubscribeActivitiesStreamDocument,
  SubscribeActivitiesStreamSubscription,
  SubscribeActivitiesStreamSubscriptionVariables,
} from "@/libs/api/generated/types";
import { SWR_KEYS } from "@/stores/swr";
import { useCallback, useMemo, useState } from "react";
import useSWRInfinite from "swr/infinite";
import useSWRSubscription from "swr/subscription";
import { Subscription } from "zen-observable-ts";

import { useApi } from "../useApi";

import { logger } from "@/libs/utils/logger";
import { flattenArray } from "@/utils/array";
import { ActivityView, toActivityView } from "./views/ActivityView";

const PAGE_SIZE = 50;

export const useActivitiesQuery = (
  params: {
    teamMemberIds: string[];
    projectIds: string[];
    enableSubscription: boolean;
  } = {
    teamMemberIds: [],
    projectIds: [],
    enableSubscription: true,
  }
) => {
  const { getClientWithSession, getApolloClientWithSession } = useApi();
  const [hasMore, setHasMore] = useState(true);

  // 検索条件
  const whereCondition: SubscribeActivitiesStreamSubscriptionVariables["where"] =
    useMemo(
      () => ({
        _or: [
          {
            taskId: {
              _isNull: true,
            },
          },
          {
            task: {
              projectId: {
                _in: params.projectIds,
              },
            },
          },
        ],
        teamMember: {
          projectMembers: {
            _and: [
              {
                projectId: {
                  _in: params.projectIds,
                },
              },
              {
                deletedAt: {
                  _isNull: true,
                },
              },
            ],
          },
        },
        teamMemberId: params.teamMemberIds.length
          ? {
              _in: params.teamMemberIds,
            }
          : undefined,
      }),
      [params.projectIds, params.teamMemberIds]
    );

  // アクティビティ情報を購読
  const [subscribedActivities, setSubscribedActivities] = useState<
    ActivityView[]
  >([]);
  useSWRSubscription(
    [
      SWR_KEYS.SUBSCRIPTION_ACTIVITIES,
      ...params.teamMemberIds,
      ...params.projectIds,
    ],
    (key: any, { next }: any) => {
      if (!params.enableSubscription) return;
      logger.info("Subscriptionの初期化");

      let subscription: Subscription;
      void getApolloClientWithSession().then(async (client) => {
        const variables: SubscribeActivitiesStreamSubscriptionVariables = {
          batchSize: PAGE_SIZE,
          cursor: {
            initialValue: {
              createdAt: new Date().toISOString(),
            },
            ordering: CursorOrdering.Asc,
          },
          where: whereCondition,
        };
        const observable = client.subscribe({
          query: SubscribeActivitiesStreamDocument,
          variables,
        });

        subscription = observable.subscribe({
          start() {
            logger.debug("購読開始");
          },
          async next({ data }: any) {
            logger.debug("データ取得", data);
            const activities = (data as SubscribeActivitiesStreamSubscription)
              .activitiesStream;
            logger.debug("アクティビティの購読でデータ取得", activities);
            next(null, activities);

            // 作成日時の降順でソート
            const sortedActivities = activities.sort((a, b) => {
              if (a.createdAt > b.createdAt) return -1;
              if (a.createdAt < b.createdAt) return 1;
              return 0;
            });

            setSubscribedActivities((previousData) => [
              ...sortedActivities.map(toActivityView),
              ...previousData,
            ]);
          },
          error(error: any) {
            logger.error("データ取得でエラー発生", error);
            next(error);
          },
          complete() {
            logger.error("購読完了");
          },
        });
      });

      return () => {
        logger.info("購読を解除");
        subscription?.unsubscribe();
      };
    }
  );

  // アクティビティを取得
  const {
    data: activities,
    isLoading: activitiesLoading,
    setSize: setSizeActivities,
    size: activitySize,
  } = useSWRInfinite<ActivityView[]>(
    (pageIndex: number, previousPageData: ActivityView[]) => {
      return [
        SWR_KEYS.QUERY_ACTIVITIES,
        pageIndex,
        ...params.teamMemberIds,
        ...params.projectIds,
      ];
    },
    async ([_, page]) => {
      // アクティビティの取得
      const offset = (page as number) * PAGE_SIZE;
      const limit = PAGE_SIZE;

      const client = await getClientWithSession();
      const { activities } = await client.activities({
        where: whereCondition,
        offset,
        limit,
        orderBy: {
          createdAt: OrderBy.Desc,
        },
      });

      if (activities.length === 0) {
        logger.debug("全件取得済み", { offset, limit });
        setHasMore(false);
      }

      return activities.map(toActivityView);
    },
    {
      // 画面フォーカス時は再取得しない
      revalidateOnFocus: false,
      // 検索機能で利用するためキャッシュしない
      revalidateAll: true,
    }
  );

  // 無限ローディングの次のページを取得する
  const nextActivityPage = useCallback(() => {
    void setSizeActivities(activitySize + 1);
  }, [activitySize, setSizeActivities]);

  const liveActivities = [
    ...subscribedActivities,
    ...(activities ? flattenArray<ActivityView>(activities) : []),
  ];

  // 日付でグルーピング
  const liveActivitiesGroupByDate = liveActivities.reduce((acc, activity) => {
    const index = acc.findIndex(
      (activityGroupByDate) =>
        activityGroupByDate.date === activity.activityDate
    );
    if (index === -1) {
      return [
        ...acc,
        {
          date: activity.activityDate,
          activities: [activity],
        },
      ];
    } else {
      acc[index].activities.push(activity);
      return acc;
    }
  }, [] as { date: string; activities: ActivityView[] }[]);

  // 検索結果のクリア
  const clearCondition = useCallback(async () => {
    setHasMore(true);
  }, []);

  return {
    hasMore,
    clearCondition,
    nextActivityPage,
    liveActivities,
    liveActivitiesGroupByDate,
    activitiesLoading,
  };
};
