import { useMantineTheme } from "@mantine/core";
import { IconCircleCheck, IconCircleDashed } from "@tabler/icons";
import { IconProgress, IconProgressCheck } from "@tabler/icons-react";
import { TaskConstantType } from "../userTasks/constant";

type UserAttendanceStatusIndicatorProps = {
  type: TaskConstantType;
  size?: string | number;
};

const TaskStatusIcon: React.FC<UserAttendanceStatusIndicatorProps> = ({
  type,
  size = "16px",
}) => {
  const theme = useMantineTheme();

  const statusMap: Record<TaskConstantType, JSX.Element> = {
    TASK_BOX: <IconCircleDashed size={size} />,
    TODAYS_TASK: (
      <IconProgress size={size} style={{ color: theme.colors.yellow[5] }} />
    ),
    SUSPEND_TASK: (
      <IconProgressCheck
        size={size}
        style={{ color: theme.colors.orange[5] }}
      />
    ),
    DONE_TASK: (
      <IconCircleCheck size={size} style={{ color: theme.colors.green[5] }} />
    ),
  };

  const iconEl = statusMap[type];
  return iconEl;
};

export default TaskStatusIcon;
