import { NotificationSuccess } from "@/components/NotificationSuccess";
import {
  CreateTaskRow,
  FormRowAddTaskProps,
} from "@/components/new/createTask/CreateTaskRow";
import { ProviderName } from "@/components/pages/tasks/FormSelectLinkedTasks";
import { useTasks } from "@/hooks/features/useTasks";
import { AddTaskPage } from "@/libs/api/constants";
import { TaskServiceType, TaskStatus } from "@/libs/api/generated/enum";
import { logger } from "@/libs/utils/logger";
import { addTaskAtom, selectedTasksAtom } from "@/stores";
import { messages } from "@/utils/messages";
import { Box, Button, Divider, Group, Space, Stack } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { TaskConstant, TaskConstantType } from "../userTasks/constant";
import { FormRowAddTaskSchema } from "./CreateAnalysisDetailsTaskRow";
import CreateTaskRowHeader from "./CreateTaskRowHeader";

export const ProviderTaskService = {
  [ProviderName.GOOGLE_CALENDAR]: TaskServiceType.GOOGLE_CALENDAR,
  [ProviderName.GITHUB]: TaskServiceType.GITHUB,
  [ProviderName.BACKLOG]: TaskServiceType.BACKLOG,
};

export const TaskServiceProviderName = {
  [TaskServiceType.GOOGLE_CALENDAR]: ProviderName.GOOGLE_CALENDAR,
  [TaskServiceType.GITHUB]: ProviderName.GITHUB,
  [TaskServiceType.BACKLOG]: ProviderName.BACKLOG,
};

export const TaskConstantStatus = {
  [TaskConstant.TASK_BOX]: TaskStatus.TODO,
  [TaskConstant.TODAYS_TASK]: TaskStatus.TODAY,
  [TaskConstant.SUSPEND_TASK]: TaskStatus.SUSPEND,
  [TaskConstant.DONE_TASK]: TaskStatus.DONE,
};

type Props = {
  onClose: () => void;
  type?: TaskConstantType;
};

const AddRelationTaskBody = (props: Props): JSX.Element => {
  const { onClose, type } = props;
  const [addTaskState, setAddTaskState] = useRecoilState(addTaskAtom);
  const [selectedTasks, setSelectedTasks] = useRecoilState(selectedTasksAtom);
  const { insertTasks } = useTasks();
  const [loading, setLoading] = useState(false);

  const form = useForm<FormRowAddTaskProps>({
    initialValues: {
      tasks: selectedTasks.map((selectedTask) => {
        return {
          projectId: selectedTask.projectId || "",
          projectTagId: "",
          name: selectedTask.text,
          lock: false,
          status: type || TaskConstant.TASK_BOX,
          serviceType: ProviderTaskService[selectedTask.providerName],
        };
      }),
    },
    validate: yupResolver(FormRowAddTaskSchema),
  });

  const { values, setFieldValue } = form;

  useEffect(() => {
    setFieldValue(
      "tasks",
      selectedTasks.map((selectedTask) => {
        return {
          projectId: selectedTask.projectId || "",
          projectTagId: "",
          name: selectedTask.text,
          lock: false,
          status: type || TaskConstant.TASK_BOX,
          serviceType: ProviderTaskService[selectedTask.providerName],
          serviceId: selectedTask.serviceId,
        };
      })
    );
  }, [selectedTasks, setFieldValue, type]);

  const onSubmit = form.onSubmit(async (values) => {
    logger.info("タスクを追加", values);
    try {
      setLoading(true);
      await insertTasks(
        values.tasks.map((task) => {
          return {
            name: task.name,
            projectId: task.projectId,
            projectTaskTagId: task.projectTagId,
            status: TaskConstantStatus[task.status as TaskConstantType],
            lock: task.lock,
            serviceType: task.serviceType,
            serviceId: task.serviceId,
          };
        })
      );
      NotificationSuccess({ message: messages.insertTask.success });
      // 連携タスクの選択を初期化
      setSelectedTasks([]);
      setAddTaskState({ page: AddTaskPage.ADD_TASK });
      onClose();
    } finally {
      setLoading(false);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Group position="center">
        <Box pr={36}>
          <CreateTaskRowHeader></CreateTaskRowHeader>
        </Box>
        <Stack spacing={16}>
          {values.tasks.map((_, i) => (
            <CreateTaskRow key={i} index={i} form={form}></CreateTaskRow>
          ))}
        </Stack>
      </Group>
      <Space h={24}></Space>
      <Divider></Divider>
      <Space h={16}></Space>
      <Group position="center">
        <Button type="submit" size="sm" loading={loading} disabled={loading}>
          追加
        </Button>
      </Group>
    </form>
  );
};

export default AddRelationTaskBody;
