import { useCallback } from "react";
import { useSession } from "./features/useSession";
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl as presignedGetSignedUrl } from "@aws-sdk/s3-request-presigner";

export const useS3 = () => {
  const { reloadCurrentAWSCredentials } = useSession();

  const getS3Client = useCallback(async () => {
    const session = await reloadCurrentAWSCredentials();
    return new S3Client({
      region: "ap-northeast-1",
      credentials: {
        accessKeyId: session!.credentials.accessKeyId,
        secretAccessKey: session!.credentials.secretAccessKey,
        sessionToken: session!.credentials.sessionToken,
      },
    });
  }, [reloadCurrentAWSCredentials]);

  const putObject = useCallback(
    async (command: PutObjectCommand) => {
      const client = await getS3Client();
      return client.send(command);
    },
    [getS3Client]
  );

  const getSignedUrl = async (
    command: GetObjectCommand,
    options?: any
  ): Promise<string> => {
    const client = await getS3Client();
    return await presignedGetSignedUrl(client, command, {
      expiresIn: 60 * 60 * 24 * 7,
      ...options,
    });
  };

  return { putObject, getSignedUrl };
};
