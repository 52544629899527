import { ProjectRole } from "@/libs/api/generated/enum";
import { SWR_KEYS } from "@/stores/swr";
import _ from "lodash";
import { useMemo } from "react";
import useSWR from "swr";
import { useSession } from "../features/useSession";
import { useApi } from "../useApi";
import { toProjectMemberView } from "./views/ProjectMemberView";
import { TaskView } from "./views/TasksView";

export type Items = {
  projectId: string;
  tasks: TaskView[];
}[];

export const useProjectMembersQuery = (projectId: string) => {
  const { getClientWithSession } = useApi();
  const { session } = useSession();
  // プロジェクトメンバー情報を取得
  const {
    data: projectMembers,
    isLoading: projectMembersLoading,
    error: projectMembersError,
    mutate: mutateProjectMembers,
  } = useSWR([SWR_KEYS.QUERY_PROJECT_MEMBERS, projectId], async () => {
    const client = await getClientWithSession();
    const { projectMembers } = await client.projectMembers({
      where: {
        projectId: {
          _eq: projectId,
        },
        deletedAt: {
          _isNull: true,
        },
      },
    });
    return _.orderBy(
      projectMembers,
      ["role", (item) => item.teamMember?.user.lastName],
      ["asc", "asc"]
    ).sort((a, b) => {
      if (a.teamMemberId === session?.teamMemberId) {
        return -1;
      }
      if (b.teamMemberId === session?.teamMemberId) {
        return 1;
      }
      return 0;
    });
  });

  // 自身の情報を取得
  const currentProjectMember = useMemo(() => {
    if (!projectMembers) return null;

    return projectMembers.find((member) => {
      return member.teamMemberId === session?.teamMemberId;
    });
  }, [projectMembers, session?.teamMemberId]);

  // 自身の権限を判定
  const isProjectAdmin = useMemo(() => {
    if (!currentProjectMember) return false;

    return currentProjectMember.role === ProjectRole.ADMIN;
  }, [currentProjectMember]);

  return {
    projectMembers: projectMembers?.map(toProjectMemberView) || [],
    mutateProjectMembers,
    currentProjectMember,
    isProjectAdmin,
  };
};
