import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { useTeamsQuery } from "@/hooks/queries/useTeamsQuery";
import { Routes, usePath } from "@/hooks/usePath";
import { Box, Flex, Menu, Text } from "@mantine/core";
import { IconSettings } from "@tabler/icons";
import Link from "next/link";
import { useState } from "react";
import UnstyledButtonWrapper from "../common/UnstyledButtonWrapper";
import UserAvatar from "../common/UserAvatar";

const UserMenu: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const { team } = useTeamsQuery();
  const { currentTeamMember } = useTeamMembersQuery();
  const [opened, setOpened] = useState(false);
  const { generatePath } = usePath();

  const userMenuList = [
    {
      label: "プロフィール",
      path: generatePath({
        routePath: Routes.TEAM_MEMBER_PROFILE.path,
        dynamicParams: {
          teamId: team?.id || "",
          teamMemberId: currentTeamMember?.id || "",
        },
      }),
    },
    {
      label: "アカウント設定",
      path: generatePath({
        routePath: Routes.TEAM_MEMBER_SETTINGS.path,
        dynamicParams: {
          teamId: team?.id || "",
          teamMemberId: currentTeamMember?.id || "",
        },
      }),
    },
  ];

  return (
    <Menu
      shadow="md"
      width={200}
      position="right-start"
      withinPortal
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <Box>
          <UnstyledButtonWrapper>
            <Flex align={"center"} justify={"space-between"}>
              <Flex align={"center"} gap="xs">
                <UserAvatar
                  size={24}
                  userFullName={`${currentTeamMember?.user.lastName || ""} ${
                    currentTeamMember?.user.firstName || ""
                  }`}
                  src={
                    currentTeamMember?.user.imageFileName
                      ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${currentTeamMember?.user.imageFileName}`
                      : undefined
                  }
                />
                <Text truncate w={"112px"}>
                  {`${currentTeamMember?.user.lastName || ""} ${
                    currentTeamMember?.user.firstName || ""
                  }`}
                </Text>
              </Flex>
              <IconSettings size="19px" stroke={1.0} />
            </Flex>
          </UnstyledButtonWrapper>
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{`${currentTeamMember?.user.lastName} ${currentTeamMember?.user.firstName}`}</Menu.Label>
        {!isMobile &&
          userMenuList.map((item) => (
            <Menu.Item key={item.path} component={Link} href={item.path}>
              {item.label}
            </Menu.Item>
          ))}
        <Menu.Divider />
        <Menu.Item component={Link} href="/sign-out">
          ログアウト
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
export default UserMenu;
