import { ReactNode } from "react";

export const GtmClickEvent = ({
  category,
  data,
  children,
}: {
  category: string;
  data: Record<string, any>;
  children: ReactNode;
}): JSX.Element => {
  return (
    <div
      className="gtm-event-click"
      data-ga-event-category={category}
      data-ga-event-action="click"
      data-ga-event-label={JSON.stringify(data)}
    >
      {children}
    </div>
  );
};
