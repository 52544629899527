import { Image } from "@mantine/core";
import { useCallback } from "react";

export enum ExternalServiceType {
  GOOGLE_CALENDAR = "GOOGLE_CALENDAR",
  JIRA = "JIRA",
  BACKLOG = "BACKLOG",
  GITHUB = "GITHUB",
}

export const externalServiceNames = {
  [ExternalServiceType.GOOGLE_CALENDAR]: "Google Calendar",
  [ExternalServiceType.JIRA]: "JIRA",
  [ExternalServiceType.BACKLOG]: "Backlog",
  [ExternalServiceType.GITHUB]: "GitHub",
};

const externalServiceImagePaths = {
  [ExternalServiceType.GOOGLE_CALENDAR]: "/icon-google-calendar-xs.png",
  [ExternalServiceType.JIRA]: "/icon-jira-xs.png",
  [ExternalServiceType.BACKLOG]: "/icon-backlog-xs.png",
  [ExternalServiceType.GITHUB]: "/icon-github-xs.png",
};

export const ExternalServiceIcon = ({
  service,
  disabled,
}: {
  service: ExternalServiceType;
  disabled?: boolean;
}): JSX.Element => {
  const onClick = useCallback(() => {
    if (disabled) return;
    alert(`${service}の該当タスク（Issueなど）を新しいタブで開いてください`);
  }, [disabled, service]);

  return (
    <Image
      src={externalServiceImagePaths[service]}
      width={40}
      height={40}
      sx={{ cursor: disabled ? "" : "pointer" }}
      // TODO: 外部サービスの該当タスク（Issueなど）に遷移する処理を実装すること
      onClick={disabled ? undefined : onClick}
    />
  );
};
