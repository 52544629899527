import { TeamMembersAttributeFragment } from "@/libs/api/generated/types";
import { formatDateTimeForWork } from "@/utils/date";

export type TeamMemberListView = {
  raw: TeamMembersAttributeFragment;
  image: string | null | undefined;
  fullName: string;
  description: string;
  workStatus: "作業中" | "休憩中" | "作業終了";
  roleName: "管理者" | "メンバー";
  statusColor: "green" | "yellow" | "gray";
};

export const toTeamMemberView = (
  teamMember: TeamMembersAttributeFragment
): TeamMemberListView => {
  const workStatus =
    teamMember.workStatus === "WORKING"
      ? "作業中"
      : teamMember.workStatus === "SUSPENDED"
      ? "休憩中"
      : "作業終了";

  const statusColor =
    teamMember.workStatus === "WORKING"
      ? "green"
      : teamMember.workStatus === "SUSPENDED"
      ? "yellow"
      : "gray";

  return {
    raw: teamMember,
    image: teamMember.user.imageFileName
      ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${teamMember.user.imageFileName}`
      : null,
    fullName: `${teamMember.user.lastName} ${teamMember.user.firstName}`,
    description: formatDateTimeForWork(teamMember.lastWorkedAt),
    workStatus,
    roleName: teamMember.role === "TEAM_ADMIN" ? "管理者" : "メンバー",
    statusColor,
  };
};
