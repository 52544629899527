import ActivityDates from "@/components/new/aside/activity/ActivityDates";
import {
  ActivityDateType,
  ActivityStatus,
} from "@/components/new/aside/activity/constant";
import { useActivitiesQuery } from "@/hooks/queries/useActivitiesQuery";
import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { ActivityType } from "@/libs/api/generated/enum";
import { localizedYup } from "@/utils/localized-yup";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  MultiSelect,
  Space,
  Text,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { forwardRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { filterMembers } from "../projects/ModalAddProjectMember";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  email: string;
}

interface FormProps {
  teamMemberIds: string[];
}

const schema = localizedYup.object({});

export const ModalInnerActivity = ({
  teamMemberId,
}: {
  teamMemberId?: string;
}): JSX.Element => {
  const { activeProjects } = useProjectsQuery();
  const { teamMemberList } = useTeamMembersQuery(
    activeProjects.map((project) => project.id)
  );
  const [searchTeamMemberIds, setSearchTeamMemberIds] = useState<string[]>(
    teamMemberId
      ? [teamMemberId]
      : teamMemberList.map((teamMember) => teamMember.raw.id)
  );
  const {
    liveActivities,
    liveActivitiesGroupByDate,
    hasMore,
    nextActivityPage,
    clearCondition,
  } = useActivitiesQuery({
    teamMemberIds: searchTeamMemberIds,
    projectIds: activeProjects.map((project) => project.id),
    enableSubscription: true,
  });

  const form = useForm<FormProps>({
    initialValues: { teamMemberIds: teamMemberId ? [teamMemberId] : [] },
    validate: yupResolver(schema),
  });

  const onSubmit = form.onSubmit(async (values) => {
    await clearCondition();
    setSearchTeamMemberIds(values.teamMemberIds);
  });

  // eslint-disable-next-line react/display-name
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Avatar src={image} />
          <div>
            <Text>{label}</Text>
            <Text size="sm" color="dimmed">
              最終作業日時 {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  const loader = (
    <Flex justify={"center"}>
      <Loader key={0} />
    </Flex>
  );

  return (
    <Box sx={{ maxHeight: "600px", overflow: "auto" }}>
      <form onSubmit={onSubmit}>
        <Flex align={"flex-end"}>
          <MultiSelect
            label="メンバー絞り込み"
            placeholder="メンバーを選択してください"
            itemComponent={SelectItem}
            data={teamMemberList.map((teamMember) => ({
              label: teamMember.fullName,
              value: teamMember.raw.id,
              description: teamMember.description,
              email: teamMember.raw.user.email,
            }))}
            size={"sm"}
            searchable
            nothingFound="メンバーが見つかりません"
            maxDropdownHeight={400}
            filter={filterMembers}
            sx={{ flex: 1 }}
            {...form.getInputProps("teamMemberIds")}
          />
          <Space w={16}></Space>
          <Button size={"sm"} type="submit">
            絞り込み
          </Button>
        </Flex>
      </form>
      <Divider my={24}></Divider>
      <Box
        sx={{
          overflowY: "scroll",
          height: "790px",
        }}
      >
        <InfiniteScroll
          dataLength={liveActivities.length} //現在のデータの長さ
          next={nextActivityPage} // スクロール位置を監視してコールバック（次のデータを読み込ませる）
          hasMore={hasMore} // さらにスクロールするかどうか（ある一定数のデータ数に達したらfalseを返すことで無限スクロールを回避）
          loader={loader} // ローディング中のコンポーネント
          height={"790px"} // 高さ
        >
          {liveActivitiesGroupByDate.map((item, i) => {
            // TODO:ActivityViewの改修
            const activityDate: ActivityDateType = {
              date: item.date,
              activities: item.activities.map((activity) => ({
                time: activity.activityTime,
                user: {
                  name: `${activity.lastName} ${activity.firstName}`,
                  avatar: activity.raw.teamMember?.user.imageFileName
                    ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${activity.raw.teamMember?.user.imageFileName}`
                    : "",
                  teamMemberId: activity.teamMemberId,
                },
                content: {
                  type:
                    activity.activityType === ActivityType.STARTED
                      ? ActivityStatus.START
                      : activity.activityType === ActivityType.SUSPENDED
                      ? ActivityStatus.BREAK
                      : activity.activityType === ActivityType.RESUMED
                      ? ActivityStatus.RESTART
                      : activity.activityType === ActivityType.FINISHED
                      ? ActivityStatus.FINISH
                      : ActivityStatus.TASK,
                  workTime: activity.workTime,
                  projectName: activity.projectName,
                  tagName: activity.projectTagName,
                  taskName: activity.task?.name,
                },
                task: activity.task,
              })),
            };
            // MEMO: typeがTASKかつprojectNameとprojectTagNameとtaskNameが存在しないものは除外
            // (自分が属していないプロジェクトのメンバータスク)
            const filteredActivityDate = {
              ...activityDate,
              activities: activityDate.activities.filter(
                (activity) =>
                  activity.content.type !== ActivityStatus.TASK ||
                  (activity.content.type === ActivityStatus.TASK &&
                    activity.content.projectName &&
                    activity.content.tagName &&
                    activity.content.taskName)
              ),
            };
            return (
              <Box key={i}>
                <ActivityDates {...filteredActivityDate}></ActivityDates>
              </Box>
            );
          })}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};
