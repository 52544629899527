import { useAuth } from "@/hooks/features/useAuth";
import { useTeamsQuery } from "@/hooks/queries/useTeamsQuery";
import { ForceSsoType, UserLoginType } from "@/libs/api/generated/enum";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { HeadTitle } from "../HeadTitle";
import { PageError } from "../PageError";
import { logger } from "@/libs/utils/logger";
import { useContractsQuery } from "@/hooks/queries/useContractsQuery";
import { Plan } from "@/types/enums";
import { useRouter } from "next/router";
import { Routes } from "@/hooks/usePath";

type Props = {
  children: ReactElement;
};

const ForceSsoTypeName = {
  [ForceSsoType.NONE]: "なし",
  [ForceSsoType.GOOGLE]: "Google",
  [ForceSsoType.MICROSOFT]: "Microsoft",
};

export const ForceSsoMiddleware = (props: Props): JSX.Element => {
  const router = useRouter();
  const { session } = useAuth();
  const { team } = useTeamsQuery();
  const { latestContract } = useContractsQuery();

  const [isForceSso, setIsForceSso] = useState<boolean>(false);
  const [forceSsoName, setForceSsoName] = useState<string>("");

  const onClick = useCallback(() => {
    location.href = "/sign-out";
  }, []);

  // チームSSOログイン強制がGOOGLEかMICROSOFTで、
  // UserLoginTypeが一致しなければログイン制限を表示
  useEffect(() => {
    // エンタープライズプランでない場合は無効
    if (latestContract?.plan.code !== Plan.ENTERPRISE) {
      return;
    }

    const teamForceSso = team?.forceSso as ForceSsoType | undefined;
    setForceSsoName(teamForceSso ? ForceSsoTypeName[teamForceSso] : "");

    if (`${router.pathname}/` === Routes.TEAM_SETTINGS.path) {
      return;
    }

    if (!team?.forceSso || team?.forceSso === ForceSsoType.NONE) {
      return;
    }
    if (
      team?.forceSso === ForceSsoType.GOOGLE &&
      session?.loginType === UserLoginType.GOOGLE
    ) {
      return;
    }
    if (
      team?.forceSso === ForceSsoType.MICROSOFT &&
      session?.loginType === UserLoginType.MICROSOFT
    ) {
      return;
    }
    logger.info(
      "SSOログイン制限 | ",
      `チーム外部ログイン強制設定: ${teamForceSso} | `,
      `ユーザーログインタイプ: ${session?.loginType}`
    );
    setIsForceSso(true);
  }, [latestContract?.plan.code, router.pathname, session, team?.forceSso]);

  if (isForceSso) {
    return (
      <>
        <HeadTitle title="SSOログイン制限"></HeadTitle>
        <PageError
          title="外部サービスログインの強制が有効です"
          description={`現在チーム設定の外部サービスログインの強制が${forceSsoName}に設定されています。一度ログアウトし、${forceSsoName}アカウントでSSOログインしてください。`}
          buttonText={"ログアウト"}
          onClick={onClick}
        ></PageError>
      </>
    );
  }

  return <>{props.children}</>;
};
