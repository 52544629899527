import { useContents } from "@/hooks/useContents";
import { WorkStatus } from "@/libs/api/generated/enum";
import { logger } from "@/libs/utils/logger";
import { SWR_KEYS } from "@/stores/swr";
import { blobToFile } from "@/utils/file";
import { useCallback, useMemo, useState } from "react";
import useSWR from "swr";
import { useTeamMembersQuery } from "../queries/useTeamMembersQuery";
import { useApi } from "../useApi";
import { useTeamsQuery } from "../queries/useTeamsQuery";

type SnapshotOptions = {
  refreshWhenHidden: boolean;
  revalidateIfStale: boolean;
  revalidateOnFocus: boolean;
  revalidateOnReconnect: boolean;
};
const SnapshotEnabledOptions: SnapshotOptions = {
  refreshWhenHidden: true,
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
};
const SnapshotDisabledOptions: SnapshotOptions = {
  refreshWhenHidden: false,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const useSnapshot = () => {
  const { getClientWithSession } = useApi();
  const { currentTeamMember } = useTeamMembersQuery();
  const { team } = useTeamsQuery();
  const { uploadTeamMemberSnapshot } = useContents();
  const [debugImageData, setDebugImageData] = useState<string>("");

  const snapshotOptions = useMemo(() => {
    if (!team?.enabledSnapshot) {
      logger.info("スナップショット機能を無効化");
      return {
        refreshInterval: 0,
        ...SnapshotDisabledOptions,
      };
    }

    logger.info("スナップショット機能を有効化");
    return {
      refreshInterval: Number(
        process.env.NEXT_PUBLIC_S3_SNAPSHOT_MILLISECONDS!
      ),
      ...SnapshotEnabledOptions,
    };
  }, [team?.enabledSnapshot]);

  const uploadSnapshot = useCallback(
    async (file: File) => {
      if (!currentTeamMember) return;

      try {
        // ファイルをアップロード
        const { key: snapshotFilePath } = await uploadTeamMemberSnapshot({
          teamId: currentTeamMember.teamId,
          teamMemberId: currentTeamMember.id,
          file,
        });

        // DBに保存
        const client = await getClientWithSession();
        await client.updateTeamMemberSnapshotsByPk({
          pkColumns: {
            teamId: currentTeamMember.teamId,
            teamMemberId: currentTeamMember.id,
          },
          _set: {
            snapshotFilePath,
            updatedAt: new Date(),
          },
        });
      } catch (error) {
        // スナップショットの取得エラーはUIに影響しないのでログだけ出す
        logger.error("スナップショットのアップロードエラー", error);
      }
    },
    [currentTeamMember, getClientWithSession, uploadTeamMemberSnapshot]
  );

  const captureSnapshot = useCallback(async () => {
    if (!currentTeamMember) return;
    if (!team?.enabledSnapshot) {
      logger.debug("スナップショット機能が無効化されているため取得しない");
      return;
    }
    if (currentTeamMember.workStatus !== WorkStatus.WORKING) {
      logger.debug(
        "ユーザーステータスが作業中ではないのでスナップショットを取得しない"
      );
      return;
    }

    // スナップショットの瞬間だけカメラを起動する
    const video = document.createElement("video");
    let stream: MediaStream | undefined;
    try {
      stream = await navigator.mediaDevices.getUserMedia({ video: true });
    } catch (error) {
      logger.debug(
        "カメラデバイスの取得に失敗したためスナップショットを取得しない"
      );
      return;
    }
    video.srcObject = stream;
    video.onloadedmetadata = async () => {
      await video.play();

      // videoのフレームが読み込まれてからスナップショットを取得
      setTimeout(async () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // アスペクト比をカメラサイズに固定
        const targetSize = 80;
        const aspectRatio = video.videoWidth / video.videoHeight;
        let width, height;

        if (aspectRatio > 1) {
          width = targetSize;
          height = targetSize / aspectRatio;
        } else {
          height = targetSize;
          width = targetSize * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(video, 0, 0, width, height);

        // カメラを閉じる（リソース開放）
        stream?.getTracks().forEach((track) => track.stop());

        // JPEG圧縮
        const imageQuality = 0.7;
        const dataUrl = canvas.toDataURL("image/jpeg", imageQuality);

        // バイナリに変換
        const blob = await fetch(dataUrl).then((res) => res.blob());
        const file = blobToFile(blob, "snapshot.jpg");

        logger.debug("スナップショットをアップロード");
        await uploadSnapshot(file);

        // デバッグ用画像を設定
        setDebugImageData(dataUrl);
      }, 200);
    };
  }, [currentTeamMember, team?.enabledSnapshot, uploadSnapshot]);

  const { mutate: mutateSnapshot } = useSWR(
    SWR_KEYS.SCHEDULER_SNAPSHOT,
    captureSnapshot,
    snapshotOptions
  );

  return {
    mutateSnapshot,
    debugImageData,
  };
};
