import { Avatar, Box, Flex, Menu, Space, Stack, Text } from "@mantine/core";
import { IconPlus, IconSettings } from "@tabler/icons";
import { useCallback, useState } from "react";
import UnstyledButtonWrapper from "../common/UnstyledButtonWrapper";
import SwitchTeamButton from "./SwitchTeamButton";
import TeamMenuSettings from "./TeamMenuSettings";
import { useTeamsQuery } from "@/hooks/queries/useTeamsQuery";
import Link from "next/link";
import ModelInviteMembers from "@/components/pages/team-members/ModalInviteMembers";
import { useDialog } from "@/hooks/useDialog";
import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";

const TeamMenu: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const { openDialog, closeDialog, isDialogMode } = useDialog<"inviteMember">();
  const { team, belongTeams } = useTeamsQuery();
  const { isTeamAdmin } = useTeamMembersQuery();
  const [opened, setOpened] = useState(false);

  const onClick = useCallback(() => openDialog("inviteMember"), [openDialog]);

  return (
    <>
      <Menu
        shadow="md"
        width={200}
        position={isMobile ? "bottom" : "right-start"}
        withinPortal
        opened={opened}
        onChange={setOpened}
      >
        <Menu.Target>
          <Box>
            <UnstyledButtonWrapper>
              <Flex align={"center"} justify={"space-between"}>
                <Flex align={"center"}>
                  <Avatar
                    src={
                      team?.imageFilePath
                        ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${team.imageFilePath}`
                        : null
                    }
                    alt={team?.name || ""}
                    radius="sm"
                    size={24}
                  >
                    {team?.name.slice(0, 1)}
                  </Avatar>
                  <Space w="xs" />
                  <Text truncate w={"112px"}>
                    {team?.name}
                  </Text>
                </Flex>
                <IconSettings size="19px" stroke={1.0} />
              </Flex>
            </UnstyledButtonWrapper>
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>チーム切替</Menu.Label>
          <Stack spacing={8}>
            {belongTeams.map((item, i) => {
              const isCurrentTeam = item.id === team?.id;
              return (
                <SwitchTeamButton
                  key={i}
                  id={item.id}
                  name={item.name}
                  imgPath={item.imageFilePath || ""}
                  active={isCurrentTeam}
                />
              );
            })}
          </Stack>
          {!isMobile && (
            <>
              <Menu.Divider />
              {isTeamAdmin && (
                <>
                  <TeamMenuSettings />
                  <Menu.Divider />
                  <Menu.Item onClick={onClick}>メンバー招待</Menu.Item>
                </>
              )}
              <Menu.Item
                icon={<IconPlus size={14} />}
                component={Link}
                href="/team-creation-flow/team-creation"
              >
                チーム作成
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
      {/* Modal: invitation(メンバー招待) */}
      {isDialogMode("inviteMember") && (
        <ModelInviteMembers
          opened={isDialogMode("inviteMember")}
          onCloseDialog={closeDialog}
        ></ModelInviteMembers>
      )}
    </>
  );
};

export default TeamMenu;
