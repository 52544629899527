import { TeamRoleNames } from "@/libs/api/constants";
import { TeamRole } from "@/libs/api/generated/enum";
import { TeamMembersAttributeFragment } from "@/libs/api/generated/types";
import { formatDateTimeForWork } from "@/utils/date";

export type TeamMembersDataTableColumnView = {
  raw: TeamMembersAttributeFragment;
  id: string;
  name: string;
  lastName: string;
  firstName: string;
  email: string;
  role: string;
  roleName: string;
  imagePath?: string | null;
  lastWorkedAt: string;
};

export const toTeamMemberDataTableColumnView = (
  teamMember: TeamMembersAttributeFragment
): TeamMembersDataTableColumnView => {
  return {
    raw: teamMember,
    id: teamMember.id,
    name: `${teamMember.user.lastName} ${teamMember.user.firstName}`,
    lastName: teamMember.user.lastName,
    firstName: teamMember.user.firstName,
    email: teamMember.user.email,
    role: teamMember.role,
    roleName: TeamRoleNames[teamMember.role as TeamRole],
    imagePath: teamMember.user.imageFileName
      ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${teamMember.user.imageFileName}`
      : null,
    lastWorkedAt: formatDateTimeForWork(teamMember.lastWorkedAt),
  };
};
