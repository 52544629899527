import { LoggedInHeaderActionsButtons } from "@/components/layouts/LoggedInHeaderActionsButtons";
import { LoggedInHeaderTitle } from "@/components/layouts/LoggedInHeaderTitle";
import { useTimeTracker } from "@/hooks/features/useTimeTracker";
import { formatDateWithWeek } from "@/utils/date";
import { Divider, Flex, Header, Text } from "@mantine/core";
import { IconCalendarTime, IconHourglassHigh } from "@tabler/icons";

export const HeaderContent = () => {
  const {
    doingTaskTrackingTime,
    workTrackingTime,
    currentTeamMember,
    workTracking,
  } = useTimeTracker();
  const now = new Date().toISOString();

  return (
    <>
      <LoggedInHeaderTitle workTime={workTrackingTime} />
      <Header height={{ sm: 40, lg: 40 }}>
        <Flex h={"100%"}>
          <Flex align={"center"} gap={8} px={16} h={"100%"}>
            <IconCalendarTime size="19px" stroke={1.0} />
            <Text lh={1} fz={"sm"} weight={500} w={56}>
              {workTrackingTime}
            </Text>
            <Text lh={1} fz={"sm"} color="dimmed">
              {workTracking?.startedDate || formatDateWithWeek(now)}
            </Text>
          </Flex>
          <Divider orientation="vertical" />
          <Flex align={"center"} gap={8} px={16} sx={{ flex: 1 }}>
            {currentTeamMember?.doingTask && (
              <>
                <IconHourglassHigh size="19px" stroke={1.0} />
                <Text lh={1} fz={"sm"} weight={500} w={56}>
                  {doingTaskTrackingTime}
                </Text>
                <Text
                  fz={"sm"}
                  color="dimmed"
                  maw={{ sm: 250, lg: 450 }}
                  truncate
                >
                  {`${currentTeamMember.doingTask.project?.name || ""} | ${
                    currentTeamMember.doingTask.projectTaskTag?.name || ""
                  } | ${currentTeamMember.doingTask.name}`}
                </Text>
              </>
            )}
          </Flex>
          <Divider orientation="vertical" />
          <Flex gap={8} px={16} justify={"center"} align={"center"} h={"100%"}>
            <LoggedInHeaderActionsButtons
              workStatus={currentTeamMember?.workStatus}
            ></LoggedInHeaderActionsButtons>
          </Flex>
        </Flex>
      </Header>
    </>
  );
};
