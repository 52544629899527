import {
  ExternalServiceType,
  externalServiceNames,
} from "@/components/ExternalServiceIcon";
import { useTeamMemberQuery } from "@/hooks/queries/useTeamMemberQuery";
import { useWorkTrackingQuery } from "@/hooks/queries/useWorkTrackingQuery";
import { TaskView } from "@/hooks/queries/views/TasksView";
import { TaskStatus } from "@/libs/api/generated/enum";
import { Flex, Group, Modal, Stack, Text } from "@mantine/core";
import { IconLock } from "@tabler/icons";
import { ReactNode, useMemo } from "react";
import UserAvatar from "../common/UserAvatar";
import TaskStatusIcon from "../navbar/TaskStatusIcon";
import UserAttendanceStatusIndicator from "../navbar/UserAttendanceStatusIndicator";
import TaskTimeLabel from "../teamBoard/TaskTimeLabel";
import { TaskStatusTitleMap } from "../userTasks/TaskBoxHeader";
import { TaskStatusToTaskConstant } from "../userTasks/constant";

export type ModalTaskDetailProps = {
  opened: boolean;
  task: TaskView;
  onClose: () => void;
};

const Row: React.FC<{ label: string; value: ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <Flex gap={8} align={"start"}>
      <Text w={96} color="dark.2" size={"sm"} lh={1.7}>
        {label}
      </Text>
      <Text
        sx={{
          flex: 1,
        }}
      >
        {value}
      </Text>
    </Flex>
  );
};

export const ModalTaskDetail = (props: ModalTaskDetailProps): JSX.Element => {
  const { onClose, opened, task } = props;
  const { teamMember } = useTeamMemberQuery(task.teamMemberId);
  const { workTracking } = useWorkTrackingQuery(task.teamMemberId);

  const taskWorkTime = useMemo(
    () =>
      workTracking?.tasks.find(
        (workTrackingTask) => workTrackingTask.id === task.id
      )?.workTime,
    [task.id, workTracking?.tasks]
  );

  const detailTask = {
    id: task.id,
    username: teamMember?.fullName || "",
    status: TaskStatusToTaskConstant(task.status),
    task: task.name,
    project: task.projectName,
    tag: task.tag,
    lock: task.lock,
    active: task.status === TaskStatus.DOING,
    workTime: taskWorkTime,
    service: ExternalServiceType.GITHUB,
  };

  const detailList = [
    {
      label: "ユーザー名",
      value: (
        <Group spacing={8}>
          <UserAvatar
            size={24}
            src={teamMember?.image || undefined}
            userFullName={detailTask.username}
          />
          <Text>{detailTask.username}</Text>
        </Group>
      ),
    },
    {
      label: "ステータス",
      value: (
        <Group spacing={8}>
          <TaskStatusIcon type={detailTask.status} />
          <Text>{TaskStatusTitleMap[detailTask.status]}</Text>
        </Group>
      ),
    },
    {
      label: "タスク",
      value: detailTask.task,
    },
    {
      label: "プロジェクト",
      value: detailTask.project,
    },
    {
      label: "タグ",
      value: detailTask.tag,
    },
    {
      label: "ロック",
      value: detailTask.lock ? (
        <Group spacing={8}>
          <Flex justify={"center"} align={"center"} w={16} h={22}>
            <IconLock size="16px" />
          </Flex>
          <Text>ロック</Text>
        </Group>
      ) : (
        "ー"
      ),
    },
    {
      label: "現在作業状況",
      value: detailTask.active ? (
        <Group spacing={8}>
          <Flex justify={"center"} align={"center"} w={16} h={22}>
            <UserAttendanceStatusIndicator type="ACTIVE" />
          </Flex>
          <Text>現在作業中</Text>
        </Group>
      ) : (
        "ー"
      ),
    },
    {
      label: "作業時間",
      value: detailTask.workTime ? (
        <TaskTimeLabel time={detailTask.workTime} />
      ) : (
        "ー"
      ),
    },
    {
      label: "連携サービス",
      value: detailTask.service
        ? externalServiceNames[detailTask.service]
        : "ー",
    },
  ];
  return (
    <>
      <Modal
        centered
        opened={props.opened}
        onClose={props.onClose}
        title="タスク詳細"
        size={400}
        closeOnClickOutside={true}
      >
        <Modal.Body p={0}>
          <Stack spacing={24}>
            {detailList.map((item, i) => (
              <Row key={i} label={item.label} value={item.value} />
            ))}
          </Stack>
        </Modal.Body>
      </Modal>
    </>
  );
};
