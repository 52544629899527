import { usePage } from "@/hooks/usePage";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";

type Props = {
  children: ReactElement;
};

// アクセスページのメタ情報を保存
// 本ミドルウェアでは非同期処理禁止
export const MetaStateMiddleware = (props: Props): JSX.Element => {
  const [initialized, setInvalidAccessToken] = useState(false);
  const { initializePageMeta } = usePage();

  const router = useRouter();
  useEffect(() => {
    if (initializePageMeta()) {
      setInvalidAccessToken(true);
    }
  }, [initializePageMeta]);

  // 初期化完了までは下位コンポーネントを表示しない
  if (!initialized) {
    return <></>;
  }

  return <>{props.children}</>;
};
