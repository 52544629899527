import { Flex, Stack, Text } from "@mantine/core";
import { IconClock, IconUser } from "@tabler/icons";
import ActivityContent from "./ActivityContent";
import { ActivityType } from "./constant";

const ActivityTooltipLabel: React.FC<ActivityType> = ({
  content,
  time,
  user,
}) => {
  return (
    <Stack spacing={4} maw={240} miw={160}>
      <Flex gap={2}>
        <Flex align={"center"}>
          <IconClock size="16px" stroke={1.0} />
        </Flex>
        <Text
          fz={13}
          sx={{
            flex: 1,
          }}
        >
          {time}
        </Text>
      </Flex>
      <Flex gap={2}>
        <Flex align={"center"}>
          <IconUser size="16px" stroke={1.0} />
        </Flex>
        <Text
          fz={13}
          sx={{
            flex: 1,
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
          }}
        >
          {user.name}
        </Text>
      </Flex>
      <ActivityContent {...content} />
    </Stack>
  );
};

export default ActivityTooltipLabel;
