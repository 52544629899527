import React, { ReactElement } from "react";
import { AppShellCustomLoggedIn } from "../AppShellCustomLoggedIn";

type Props = {
  children: ReactElement;
};
export const LoggedInDesktop = React.memo((props: Props): JSX.Element => {
  return <AppShellCustomLoggedIn>{props.children}</AppShellCustomLoggedIn>;
});
LoggedInDesktop.displayName = "LoggedInDesktop";
