import { useAuth } from "@/hooks/features/useAuth";
import { useContractsQuery } from "@/hooks/queries/useContractsQuery";
import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { usePage } from "@/hooks/usePage";
import { logger } from "@/libs/utils/logger";
import { useRouter } from "next/router";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { HeadTitle } from "../HeadTitle";
import { PageError } from "../PageError";

type Props = {
  children: ReactElement;
};

export const SettlementFailureMiddleware = (props: Props): JSX.Element => {
  const router = useRouter();
  const { session } = useAuth();
  const { latestContract } = useContractsQuery();
  const { isTeamAdmin } = useTeamMembersQuery();
  const { createTeamPagePath } = usePage();
  // 決済失敗時許容
  const settlementFailedPaths = [
    "/t/[teamId]/teams/settings/[activeTab]",
    "/t/[teamId]/teams/settings/plans/payment-history",
    "/t/[teamId]/teams/settings/plans/compare-plans",
    "/t/[teamId]/teams/settings/plans/downgrade",
    "/t/[teamId]/teams/members",
    "/t/[teamId]/projects",
  ];

  const [isInvalidAccessPath, setInvalidAccessPath] = useState<boolean>(false);

  useEffect(() => {
    if (
      latestContract?.settlementFailedAt &&
      !settlementFailedPaths.includes(router.pathname)
    ) {
      logger.info("決済失敗でアクセス制限", router.pathname);
      setInvalidAccessPath(true);
    } else {
      setInvalidAccessPath(false);
    }
  }, [session, router, latestContract]);

  const onClick = useCallback(async () => {
    if (isTeamAdmin) {
      void router.push(createTeamPagePath(`/teams/settings/plans/`));
    } else {
      router.back();
    }
  }, [isTeamAdmin]);

  if (isInvalidAccessPath) {
    return (
      <>
        <HeadTitle title="アクセス制限"></HeadTitle>
        <PageError
          title="現在アクセスが制限されてます"
          description={
            isTeamAdmin
              ? "支払履歴から再決済処理を行うか、\nプランを変更してください"
              : "チーム管理者に問い合わせてください"
          }
          buttonText={isTeamAdmin ? "支払概要" : "戻る"}
          onClick={onClick}
        ></PageError>
      </>
    );
  }

  return <>{props.children}</>;
};
