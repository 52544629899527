import {
  ProjectMembersInsertInput,
  RemoveProjectMemberInput,
} from "@/libs/api/generated/types";
import { useCallback } from "react";
import { ulid } from "ulid";
import { useProjectMembersQuery } from "../queries/useProjectMembersQuery";
import { useApi } from "../useApi";
import { ProjectRole } from "@/libs/api/generated/enum";

export const useProjectMembers = (projectId: string) => {
  const { getClientWithSession } = useApi();
  const { mutateProjectMembers } = useProjectMembersQuery(projectId);

  // プロジェクトメンバーを追加
  const addProjectMember = useCallback(
    async (teamMemberIds: string[]) => {
      const client = await getClientWithSession();
      const insertData: ProjectMembersInsertInput[] = teamMemberIds.map(
        (teamMemberId) => {
          return {
            id: ulid(),
            projectId,
            teamMemberId,
            role: "PROJECT_MEMBER",
          };
        }
      );

      await client.insertProjectMembers({
        objects: insertData,
      });

      await mutateProjectMembers();
    },
    [getClientWithSession, mutateProjectMembers, projectId]
  );

  // プロジェクトメンバーの権限変更
  const changeProjectMemberRole = useCallback(
    async (teamMemberId: string, role: ProjectRole) => {
      const client = await getClientWithSession();
      await client.updateProjectMembersByPk({
        pkColumns: {
          id: teamMemberId,
        },
        _set: {
          role,
        },
      });

      await mutateProjectMembers();
    },
    [getClientWithSession, mutateProjectMembers]
  );

  // プロジェクトメンバーを削除
  const deleteProjectMember = useCallback(
    async (input: RemoveProjectMemberInput) => {
      const client = await getClientWithSession();
      await client.removeProjectMember({
        input,
      });

      await mutateProjectMembers();
    },
    [getClientWithSession, mutateProjectMembers]
  );

  return {
    addProjectMember,
    changeProjectMemberRole,
    deleteProjectMember,
  };
};
