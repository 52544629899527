import { NotificationSuccess } from "@/components/NotificationSuccess";
import AddTaskFooter from "@/components/new/createTask/AddTaskFooter";
import CreateTaskRowHeader from "@/components/new/createTask/CreateTaskRowHeader";
import { FormSelectLinkedTasks } from "@/components/pages/tasks/FormSelectLinkedTasks";
import { useDevice } from "@/hooks/features/useDevice";
import { useTasks } from "@/hooks/features/useTasks";
import { AddTaskPage } from "@/libs/api/constants";
import { logger } from "@/libs/utils/logger";
import { addTaskAtom, addTaskContinueAtom } from "@/stores";
import { messages } from "@/utils/messages";
import { Divider, Modal, Space, Tabs } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { FormEvent, KeyboardEventHandler, useState } from "react";
import { useRecoilState } from "recoil";
import { ulid } from "ulid";
import {
  TaskConstant,
  TaskConstantToTaskStatus,
  TaskConstantType,
} from "../userTasks/constant";
import AddRelationTaskBody from "./AddRelationTaskBody";
import AddTaskModalTitle from "./AddTaskModalTitle";
import {
  CreateTaskRow,
  FormRowAddTaskProps,
  FormRowAddTaskSchema,
} from "./CreateTaskRow";
import { CreateTaskRowSp } from "./CreateTaskRowSp";

export type ModalTaskAddProps = {
  opened: boolean;
  isAddTodayTask?: boolean;
  type?: TaskConstantType;
  focusOnProjectSelect?: boolean;
  project?: {
    id: string;
    name: string;
  };
  onClose: () => void;
};

export const enterSubmit: (
  onSubmit: (event?: FormEvent<HTMLFormElement> | undefined) => void
) => KeyboardEventHandler<HTMLDivElement> =
  (onSubmit) =>
  // ⌘+Enter押下時にタスク作成・追加処理
  (keyEvent) => {
    // Enterキーのみが押下された場合は送信しないようにする
    if (keyEvent.key === "Enter") keyEvent.preventDefault();
    if (keyEvent.key === "Enter" && (keyEvent.ctrlKey || keyEvent.metaKey))
      onSubmit();
  };

export const ModalTaskAdd = (props: ModalTaskAddProps): JSX.Element => {
  const [addTaskState] = useRecoilState(addTaskAtom);
  const [addTaskContinue] = useRecoilState(addTaskContinueAtom);
  const { onClose, focusOnProjectSelect, project, isAddTodayTask, type } =
    props;
  const [loading, setLoading] = useState(false);
  const [modalKey, setModalKey] = useState("");
  const { isTabletOrSmartphone } = useDevice();

  const form = useForm<FormRowAddTaskProps>({
    initialValues: {
      tasks: [
        {
          projectId: project?.id || "",
          projectTagId: "",
          name: "",
          lock: false,
          status: isAddTodayTask
            ? TaskConstant.TODAYS_TASK
            : props.type
            ? props.type
            : TaskConstant.TASK_BOX,
        },
      ],
    },
    validate: yupResolver(FormRowAddTaskSchema),
  });

  const { insertTasks } = useTasks();

  const onSubmit = form.onSubmit(async (values) => {
    logger.info("タスクを追加", values);
    try {
      setLoading(true);
      await insertTasks(
        values.tasks.map((task) => ({
          name: task.name,
          projectId: task.projectId,
          projectTaskTagId: task.projectTagId,
          status: TaskConstantToTaskStatus(task.status),
          lock: task.lock,
        }))
      );
      NotificationSuccess({ message: messages.insertTask.success });
      if (addTaskContinue) {
        form.reset();
      } else {
        onClose();
      }
    } finally {
      setLoading(false);
      setModalKey(ulid());
    }
  });

  return (
    <Modal
      centered
      opened={props.opened}
      onClose={props.onClose}
      title={<AddTaskModalTitle></AddTaskModalTitle>}
      size={
        addTaskState.page === AddTaskPage.ADD_TASK
          ? 800
          : addTaskState.page === AddTaskPage.SELECT_GOOGLE_TASK
          ? 1000
          : 880
      }
      closeOnClickOutside={!isTabletOrSmartphone}
      onKeyDown={enterSubmit(onSubmit)}
      key={modalKey}
    >
      {!isTabletOrSmartphone && (
        <Modal.Body p={0}>
          <Tabs value={addTaskState.page}>
            <Tabs.Panel value={AddTaskPage.ADD_TASK}>
              <form onSubmit={onSubmit}>
                <CreateTaskRowHeader />
                <Space h={4}></Space>
                <CreateTaskRow
                  index={0}
                  form={form}
                  focusOnProjectSelect={focusOnProjectSelect}
                ></CreateTaskRow>
                <Space h={24}></Space>
                <Divider></Divider>
                <Space h={16}></Space>
                <AddTaskFooter loading={loading} />
              </form>
            </Tabs.Panel>
            <Tabs.Panel value={AddTaskPage.SELECT_GOOGLE_TASK}>
              <FormSelectLinkedTasks></FormSelectLinkedTasks>
            </Tabs.Panel>
            <Tabs.Panel value={AddTaskPage.ADD_GOOGLE_TASK}>
              <AddRelationTaskBody
                onClose={onClose}
                type={type}
              ></AddRelationTaskBody>
            </Tabs.Panel>
          </Tabs>
        </Modal.Body>
      )}
      {isTabletOrSmartphone && (
        <Modal.Body p={0}>
          <form onSubmit={onSubmit}>
            <CreateTaskRowSp
              index={0}
              form={form}
              focusOnProjectSelect={focusOnProjectSelect}
            ></CreateTaskRowSp>
            <Space h={24}></Space>
            <Divider></Divider>
            <Space h={16}></Space>
            <AddTaskFooter loading={loading} />
          </form>
        </Modal.Body>
      )}
    </Modal>
  );
};
