import { AttendanceStatusType } from "@/components/new/navbar/UserAttendanceStatusIndicator";
import { Avatar, Indicator, useMantineTheme } from "@mantine/core";
import { useMemo } from "react";

type UserAvatarProps = {
  userFullName: string;
  size?: number;
  src?: string;
  type?: AttendanceStatusType;
  radius?: "sm" | "md" | "lg" | "xl";
};

const UserAvatar: React.FC<UserAvatarProps> = ({
  size = 28,
  src,
  type,
  userFullName,
  radius = "sm",
}) => {
  const theme = useMantineTheme();
  const statusColorMap: Record<AttendanceStatusType, string> = useMemo(
    () => ({
      ACTIVE: theme.colors.teal[5],
      BREAK: theme.colors.yellow[5],
      FINISHED: theme.colors.dark[3],
    }),
    [theme.colors.dark, theme.colors.teal, theme.colors.yellow]
  );

  const color = useMemo(
    () => (type ? statusColorMap[type] : ""),
    [statusColorMap, type]
  );

  const initial = useMemo(() => {
    const splitFullName = userFullName.split(" ");
    const lastNameInitial = splitFullName[0]?.slice(0, 1) || "";
    const firstNameInitial = splitFullName[1]?.slice(0, 1) || "";
    return `${lastNameInitial}${firstNameInitial}`;
  }, [userFullName]);

  return (
    <Indicator
      position="bottom-end"
      size={7}
      color={color}
      disabled={!type}
      zIndex="1"
      offset={2}
    >
      {src ? (
        <Avatar radius={radius} size={size} src={src} color="blue" />
      ) : (
        <Avatar radius={radius} size={size} color="blue">
          {initial}
        </Avatar>
      )}
    </Indicator>
  );
};

export default UserAvatar;
