import { ProjectTaskTagAttributesFragment } from "@/libs/api/generated/types";

export type ProjectTagView = {
  raw: ProjectTaskTagAttributesFragment;
  id: number;
  name: string;
  tagId: string;
};

// プロジェクトタグ表示用の形式に変換
export const toProjectTagView = (
  projectTag: ProjectTaskTagAttributesFragment
): ProjectTagView => {
  return {
    raw: projectTag,
    id: Number(projectTag.order),
    name: projectTag.name,
    tagId: projectTag.id,
  };
};
