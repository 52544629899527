import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";

export const useTeamsQuery = () => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();

  // チーム情報を取得
  const {
    data: team,
    isLoading: teamLoading,
    error: teamError,
    mutate: mutateTeam,
  } = useSWR([SWR_KEYS.QUERY_TEAM, session?.teamId], async () => {
    const client = await getClientWithSession();
    const { teamsByPk: team } = await client.teamsByPk({
      id: session?.teamId as string,
    });
    return team;
  });

  // 所属しているチーム情報の取得
  const {
    data: belongTeams,
    isLoading: belongTeamsLoading,
    error: belongTeamsError,
    mutate: mutateBelongTeams,
  } = useSWR([SWR_KEYS.QUERY_BELONG_TEAMS, session?.userId], async () => {
    const client = await getClientWithSession();
    const { teams } = await client.teams({
      where: {
        deletedAt: {
          _isNull: true,
        },
      },
    });

    return teams.map((team) => {
      return {
        ...team,
        imageFilePath: team.imageFilePath
          ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${team.imageFilePath}`
          : null,
      };
    });
  });

  return {
    team,
    belongTeams: belongTeams || [],
    mutateTeam,
    mutateBelongTeams,
  };
};
