import { localizedYup } from "./localized-yup";

export const validationRules = {
  user: {
    lastName: localizedYup.string().label("性").max(20),
    firstName: localizedYup.string().label("名").max(20),
    email: localizedYup
      .string()
      .label("メールアドレス")
      .email("メールアドレス形式で入力してください"),
    password: localizedYup
      .string()
      .label("パスワード")
      .matches(
        /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "パスワードの形式が正しくありません"
      )
      .min(12)
      .max(128),
    passwordConfirm: localizedYup
      .string()
      .label("パスワード確認")
      .min(12)
      .max(128)
      .oneOf(
        [localizedYup.ref("newPassword")],
        "新しいパスワードと確認用パスワードが一致しません"
      ),
    image: localizedYup
      .mixed((input): input is File => input instanceof File)
      .label("プロフィール画像")
      .test(
        "fileSize",
        "画像サイズが大きすぎます（最大 5MB）",
        (value) => !value || value.size <= 1024 * 1024 * 5
      )
      .test(
        "fileType",
        "サポートされていないファイル形式です（JPEG, PNGのみ）",
        (value) => {
          if (!value) return true;
          const allowedFileTypes = ["image/jpeg", "image/png"];
          return allowedFileTypes.includes(value.type);
        }
      ),
  },
  team: {
    name: localizedYup.string().label("チーム名").max(20),
    enabledSnapshot: localizedYup.boolean().label("スナップショット有効化"),
    image: localizedYup
      .mixed((input): input is File => input instanceof File)
      .label("チーム画像")
      .test(
        "fileSize",
        "画像サイズが大きすぎます（最大 5MB）",
        (value) => !value || value.size <= 1024 * 1024 * 5
      )
      .test(
        "fileType",
        "サポートされていないファイル形式です（JPEG, PNGのみ）",
        (value) => {
          if (!value) return true;
          const allowedFileTypes = ["image/jpeg", "image/png"];
          return allowedFileTypes.includes(value.type);
        }
      ),
    enqueteAnswer: localizedYup.array().min(1, "1つ以上選択してください"),
  },
  teamMember: {
    email: localizedYup.string().label("メールアドレス").email(),
    role: localizedYup.string().label("権限"),
  },
  project: {
    name: localizedYup.string().label("プロジェクト名").max(50),
  },
  projectMember: {
    role: localizedYup.string().label("権限"),
  },
  tag: {
    name: localizedYup.string().label("タグ名").max(255),
  },
  task: {
    projectId: localizedYup.string().label("プロジェクト"),
    projectTagId: localizedYup.string().label("タグ"),
    name: localizedYup.string().label("タスク名").max(255),
    lock: localizedYup.boolean().label("ロック"),
  },
  bill: {
    email: localizedYup.string().label("メールアドレス").email(),
    postalCode: localizedYup
      .string()
      .label("郵便番号")
      .matches(/^\d+$/, "郵便番号は数字のみの形式で入力してください")
      .length(7),
    state: localizedYup.string().label("都道府県"),
    line1: localizedYup.string().label("市区町村番地").max(255),
    line2: localizedYup.string().label("建物"),
  },
  contact: {
    email: localizedYup.string().label("メールアドレス").email(),
    name: localizedYup.string().label("会社目・団体名・お名前").max(20),
    comment: localizedYup.string().label("お問い合わせ内容").max(200),
    inquiryCategory: localizedYup.string().label("お問い合わせ区分").max(20),
  },
};
