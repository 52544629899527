import { Anchor, Divider, Flex, Group, Text, Footer } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";

export const FooterInner = (): JSX.Element => {
  return (
    <Flex align="center" justify="space-between">
      <Group>
        <Image
          src="/logo-grandream.png"
          alt="Grandreamロゴ"
          width={32}
          height={20}
          priority
        />
        <Text size="sm">{`© ${new Date().getFullYear()} Grandream, Inc.`}</Text>
      </Group>
      <Group>
        <Anchor component={Link} href="/" size="sm" color="dimmed">
          Factog
        </Anchor>
        <Divider orientation="vertical" />
        <Anchor component={Link} href="/sign-in" size="sm" color="dimmed">
          ログイン
        </Anchor>
        <Anchor component={Link} href="/sign-up" size="sm" color="dimmed">
          アカウント作成
        </Anchor>
        <Divider orientation="vertical" />
        <Anchor href="/terms-of-use" target={"_blank"} size="sm" color="dimmed">
          利用規約
        </Anchor>
        <Anchor
          href="/privacy-policy"
          target={"_blank"}
          size="sm"
          color="dimmed"
        >
          プライバシーポリシー
        </Anchor>
        <Anchor href="/specific" target={"_blank"} size="sm" color="dimmed">
          特定商取引法に基づく表記
        </Anchor>
        <Anchor href="/contact" target={"_blank"} size="sm" color="dimmed">
          お問い合わせ
        </Anchor>
      </Group>
    </Flex>
  );
};
