import { useDevice } from "@/hooks/features/useDevice";
import { minuteToHour } from "@/utils/date";
import { ActionIcon, Flex, Space, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconTrash } from "@tabler/icons";
import { CustomTimeInput } from "./pages/works/CustomTimeInput";

interface FormBreakTimeProps<T> {
  form: UseFormReturnType<T, (values: T) => T>;
  breakDiffTimes: number[];
  index: number;
  onClickIcon: (index: number) => () => void;
}

export const FormBreakTime = <EndOfWorkForm,>({
  form,
  breakDiffTimes,
  index,
  onClickIcon,
}: FormBreakTimeProps<EndOfWorkForm>): JSX.Element => {
  const { isTabletOrSmartphone } = useDevice();
  return (
    <Flex key={index} align={"center"}>
      <Flex gap={8} align="center">
        <CustomTimeInput
          {...form.getInputProps(`breakTimes.${index}.startedTime`)}
        />
        <div>〜</div>
        <CustomTimeInput
          {...form.getInputProps(`breakTimes.${index}.finishedTime`)}
        />
      </Flex>
      <Space w="md" />
      <Text size={isTabletOrSmartphone ? "xs" : "sm"}>
        {minuteToHour(breakDiffTimes[index], { isJapanese: true })}
      </Text>
      <Space w="xl" />
      <ActionIcon
        size={isTabletOrSmartphone ? "sm" : "md"}
        variant="filled"
        color="red"
        onClick={onClickIcon(index)}
      >
        <IconTrash size={18} />
      </ActionIcon>
    </Flex>
  );
};
