import {
  AttendanceStatus,
  AttendanceStatusType,
} from "@/components/new/navbar/UserAttendanceStatusIndicator";
import { useTeamsQuery } from "@/hooks/queries/useTeamsQuery";
import { TeamMemberSubscriptionView } from "@/hooks/queries/views/TeamMemberSubscriptionView";
import { useDialog } from "@/hooks/useDialog";
import { Box, Flex, Menu } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import ProjectTagTaskLabel from "../../common/ProjectTagTaskLabel";
import TooltipWrapper from "../../common/TooltipWrapper";
import UnstyledButtonWrapper from "../../common/UnstyledButtonWrapper";
import UserAvatar from "../../common/UserAvatar";
import TeamMemberMenuItems from "../../common/teamMember/TeamMemberMenuItems";
import { ModalTaskDetail } from "../../createTask/ModalTaskDetail";
import TeamMemberTooltipLabel from "./TeamMemberTooltipLabel";
import { TeamMemberItemType } from "./constant";

export type TeamMemberProps = {
  teamMemberId: string;
  teamMemberName: string;
  teamMemberStatus: AttendanceStatusType;
  teamMemberImgPath: string;
  taskId: string;
  taskName: string;
  projectName: string;
  tagName: string;
};

const TeamMember: React.FC<{ teamMember: TeamMemberSubscriptionView }> = ({
  teamMember,
}) => {
  const [opened, setOpened] = useState(false);
  const { openDialog, closeDialog, isDialogMode } = useDialog<"detailTask">();
  const { team } = useTeamsQuery();

  const teamMemberImgPath = useMemo(() => {
    if (!team?.enabledSnapshot) {
      return teamMember.fallbackImagePath;
    }

    return teamMember.avatarImagePath || "";
  }, [
    team?.enabledSnapshot,
    teamMember.avatarImagePath,
    teamMember.fallbackImagePath,
  ]);

  const teamMemberInformation: TeamMemberItemType = {
    teamMemberId: teamMember.raw.id,
    teamMemberName: teamMember.fullName,
    teamMemberStatus:
      teamMember.statusColor === "green"
        ? AttendanceStatus.ACTIVE
        : teamMember.statusColor === "yellow"
        ? AttendanceStatus.BREAK
        : AttendanceStatus.FINISHED,
    teamMemberImgPath,
    taskId: teamMember.raw.doingTaskId || "",
    taskName: teamMember.raw.doingTaskId
      ? teamMember.raw.doingTask?.name || "****"
      : "",
    projectName: teamMember.raw.doingTaskId
      ? teamMember.raw.doingTask?.project?.name || "****"
      : "",
    tagName: teamMember.raw.doingTaskId
      ? teamMember.raw.doingTask?.projectTaskTag?.name || "****"
      : "",
  };

  const onClickTaskDetails = useCallback(() => {
    openDialog("detailTask");
  }, [openDialog]);

  return (
    <Menu
      shadow="md"
      width={200}
      position="left-start"
      withinPortal
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <Box>
          <UnstyledButtonWrapper px={10} py={6}>
            <TooltipWrapper
              position="left-start"
              label={<TeamMemberTooltipLabel {...teamMemberInformation} />}
            >
              <Flex gap={8} align={"flex-start"}>
                <UserAvatar
                  src={teamMemberInformation.teamMemberImgPath}
                  type={teamMemberInformation.teamMemberStatus}
                  userFullName={teamMember.fullName}
                />
                <ProjectTagTaskLabel
                  username={teamMemberInformation.teamMemberName}
                  project={teamMemberInformation.projectName}
                  tag={teamMemberInformation.tagName}
                  task={teamMemberInformation.taskName}
                  w={"calc(100% - 28px - 8px)"}
                  truncate
                />
              </Flex>
            </TooltipWrapper>
          </UnstyledButtonWrapper>
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <TeamMemberMenuItems
          id={teamMemberInformation.teamMemberId}
          name={teamMemberInformation.teamMemberName}
        />
        {teamMember.doingTask && (
          <>
            <Menu.Divider />
            <Menu.Item onClick={onClickTaskDetails}>タスク詳細</Menu.Item>
          </>
        )}
      </Menu.Dropdown>
      {isDialogMode("detailTask") && teamMember.doingTask && (
        <ModalTaskDetail
          opened={true}
          task={teamMember.doingTask}
          onClose={closeDialog}
        ></ModalTaskDetail>
      )}
    </Menu>
  );
};

export default TeamMember;
