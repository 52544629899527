/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from "react";

export const useAsync = () => {
  const compositeLoading = (loadings: boolean[]) => {
    return useMemo(() => {
      return loadings.some((loading) => loading);
    }, [loadings]);
  };

  return {
    compositeLoading,
  };
};
