import { ActionIcon, ActionIconProps, Box } from "@mantine/core";
import Link from "next/link";
import { MouseEvent, ReactNode, forwardRef } from "react";

interface ActionIconWrapperProps extends ActionIconProps {
  children: ReactNode;
  to?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  m?: number | string;
}

const ActionIconWrapper = forwardRef<
  HTMLAnchorElement | HTMLDivElement,
  ActionIconWrapperProps
>(({ children, to, onClick, m, ...props }, ref) => {
  const defaultProps = {
    m: m,
    size: "md",
    variant: "default",
    ...props,
  };

  if (to) {
    return (
      <Link href={to} ref={ref as React.RefObject<HTMLAnchorElement>}>
        <ActionIcon {...defaultProps}>{children}</ActionIcon>
      </Link>
    );
  }

  if (onClick) {
    return (
      <Box display="inline-block" ref={ref as React.RefObject<HTMLDivElement>}>
        <ActionIcon {...defaultProps} onClick={onClick}>
          {children}
        </ActionIcon>
      </Box>
    );
  }

  return (
    <Box display="inline-block" ref={ref as React.RefObject<HTMLDivElement>}>
      <ActionIcon {...defaultProps}>{children}</ActionIcon>
    </Box>
  );
});

ActionIconWrapper.displayName = "ActionIconWrapper";

export default ActionIconWrapper;
