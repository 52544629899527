export const messages = {
  signIn: {
    success: "ログインしました",
    failed:
      "ログインできませんでした。メールアドレスとパスワードを確認してください",
  },
  signUp: {
    sentInvitationEmail: "メールアドレス確認メールを送信しました",
  },
  signUpConfirm: {
    success: "アカウントを登録しました",
    invalidToken: "有効期限が切れました。再度登録をお願いします。",
  },
  createTeam: {
    success: "チームを作成しました",
  },
  updateTeam: {
    success: "チームを更新しました",
  },
  updateEmail: {
    success: "メールアドレスを更新しました",
    failed: "メールアドレスの更新に失敗しました",
  },
  updatePassword: {
    success: "パスワードを更新しました",
  },
  resetPassword: {
    sentEmail: "パスワードリセットメールを送信しました",
    success: "パスワードを再設定しました",
  },
  updateProfile: {
    success: "プロフィールを更新しました",
  },
  updateProject: {
    success: "プロジェクト名を更新しました",
  },
  inviteTeamMember: {
    success: "招待メールを送信しました",
    alreadyExists: "入力されたメールアドレスを持つメンバーがチームに存在します",
    alreadyInviteExists: "入力されたメールアドレスは招待中です",
    quantityOver: "ご契約中のアカウント数が上限に達しています",
  },
  resendTeamMemberInvitation: {
    success: "招待メールを再送信しました",
  },
  cancelTeamMemberInvitation: {
    success: "招待メールを取り消しました",
  },
  changeTeamMemberRoles: {
    success: "チームメンバーの権限を変更しました",
    doNotChangeYourself: "自分自身の権限は変更できません",
  },
  removeTeamMembers: {
    success: "チームメンバーを退出させました",
    doNotChangeYourself: "自分自身は退出できません",
  },
  insertTask: {
    success: "タスクを追加しました",
  },
  updatePlan: {
    success: "プランを更新しました",
    excessMember: "チームメンバー数がライセンス数を超過しています",
    excessFreeMember: "チームメンバー数がフリープラン許容数を超過しています",
    excessProject:
      "アクティブプロジェクト数がフリープラン許容数を超過しています",
  },
  updateCustomer: {
    success: "請求先住所を更新しました",
    failed: "請求先住所の更新に失敗しました",
  },
  teamAllowedIpAddresses: {
    updateSuccess: "IPアドレス制限設定を更新しました",
    deleteSuccess: "IPアドレスを削除しました",
  },
  teamForceSso: {
    updateSuccess: "外部サービスログインの強制設定を更新しました",
  },
  googleCalendarLinkage: {
    deleteLinkage: {
      success: "連携を解除しました",
    },
  },
  githubLinkage: {
    unlinkSuccess: "GitHub連携設定を解除しました",
    isNotLinkCompleted: "GitHub管理者に承認を依頼し、設定を完了してください",
    linkProjectSuccess: "GitHub連携設定を更新しました",
    sameProjectError: "GitHubリポジトリとプロジェクトの紐付けが重複しています",
    isRefreshTokenExpired:
      "GitHub連携の有効期限が切れました。連携設定から再認証を実施してください",
    invalidParams:
      "Github連携で想定外のエラーが発生しました。再度やり直してください",
  },
  backlogLinkage: {
    linkProjectSuccess: "Backlog連携設定を更新しました",
    deleteProjectSuccess: "Backlog連携設定を解除しました",
    tooManyRequests: "時間を置いて再度操作してください",
    sameBacklogUrlError: "既に連携済みのURLです",
    sameProjectError:
      "Backlogプロジェクトとプロジェクトの紐付けが重複しています",
  },
  common: {
    networkError:
      "サーバーが不安定、またはブラウザからインターネットに接続できません",
    systemError: "システムエラーが発生しました",
    forbidden: "権限がありません",
    conflict: "他のユーザーにより更新が行われました。ページを更新してください",
    sessionExpired: "セッションが切れました。再度ログインしてください",
  },
};
