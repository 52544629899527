import { Task } from "@/components/pages/tasks/FormSelectLinkedTasks";
import { Team } from "@/hooks/features/useTeam";
import { TaskContainer } from "@/hooks/queries/useTasksQuery";
import { TaskView } from "@/hooks/queries/views/TasksView";
import { AddTaskPage } from "@/libs/api/constants";
import { UserLoginType, WorkStatus } from "@/libs/api/generated/enum";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export interface PlanForm {
  planId: string;
  name: string;
  price: number;
}
export const planFormAtom = atom<PlanForm | null>({
  key: "planFormAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const switchingTeamAtom = atom<boolean>({
  key: "switchingTeamAtom",
  default: false,
});

export const isTabletOrSmartphoneAtom = atom<boolean>({
  key: "isTabletOrSmartphoneAtom",
  default: false,
});

export const isNavExpandedAtom = atom<boolean>({
  key: "isNavBarExpandedAtom",
  default: true,
  effects_UNSTABLE: [persistAtom],
});

// 認証ミドルウェアで認証エラーになった場合に、ログイン後に遷移するパスを保持する
export const beforePathAtom = atom<string | null>({
  key: "beforePath",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

// チームメンバー招待時に、ログイン・会員登録後に遷移するパスを保持する
export const beforeTeamInvitationPathAtom = atom<string | null>({
  key: "beforeTeamInvitationPathAtom",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const inflowRouteAtom = atom<string>({
  key: "inflowRouteAtom",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export interface AuthAtom {
  userId: string;
  teamId: string;
  teamMemberId: string;
  loginType: UserLoginType;
  accessToken: string;
  refreshToken: string;
  credentials: {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    expiration: string;
  };
  belongingTeams: Team[];
  twoFAEnabled: boolean;
  twoFACertified: boolean;
}

export const authAtom = atom<AuthAtom | null>({
  key: "auth",
  default: null,
  effects_UNSTABLE: [persistAtom],
});
export const getAuthFromLocalStorage = () => {
  const persistedDataString = localStorage.getItem("recoil-persist");
  if (!persistedDataString) return null;
  const persistedData = JSON.parse(persistedDataString);

  return persistedData["auth"] as AuthAtom;
};

export type PageMetaAtom = {
  teamId?: string | null;
  isTeamPage: boolean;
  isMobile: boolean;
};

export const pageMetaAtom = atom<PageMetaAtom | null>({
  key: "pageMeta",
  default: null,
});

interface TeamAtom {
  id: string;
}

export const teamAtom = atom<TeamAtom>({
  key: "team",
  default: {
    id: "teamId",
  },
  effects_UNSTABLE: [persistAtom],
});

interface ErrorAtom {
  title: string;
  message: string;
}

export const errorAtom = atom<ErrorAtom | null>({
  key: "errorAtom",
  default: null,
});

interface AddTaskAtom {
  page: AddTaskPage;
}

export const addTaskAtom = atom<AddTaskAtom>({
  key: "addTaskAtom",
  default: {
    page: AddTaskPage.ADD_TASK,
  },
});

export const addTaskContinueAtom = atom<boolean>({
  key: "addTaskContinueAtom",
  default: false,
});

export const selectedTasksAtom = atom<Task[]>({
  key: "selectedTasksAtom",
  default: [],
});

// Attendance Status
interface AttendanceStatusAtom {
  status: WorkStatus;
}

export const attendanceStatusAtom = atom<AttendanceStatusAtom>({
  key: "attendanceStatusAtom",
  default: {
    status: WorkStatus.NOT_WORKING,
  },
  // effects_UNSTABLE: [persistAtom],
});

export const projectFilterValueAtom = atom<string[] | undefined>({
  key: "projectFilterValueAtom",
  default: undefined,
});

export const teamMemberFilterValueAtom = atom<string[] | undefined>({
  key: "teamMemberFilterValueAtom",
  default: undefined,
});

export const taskBoxSearchTextAtom = atom<string>({
  key: "taskBoxSearchTextAtom",
  default: "",
});

export const backlogUrlAtom = atom<string>({
  key: "backlogUrlAtom",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const reAuthGithubOrgNameAtom = atom<string>({
  key: "reAuthGithubOrgNameAtom",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const inviteProjectAtom = atom<string>({
  key: "inviteProjectAtom",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const todoTasksAtom = atom<TaskView[]>({
  key: "todoTasksAtom",
  default: [],
});

export const suspendTasksAtom = atom<TaskView[]>({
  key: "suspendTasksAtom",
  default: [],
});

export const doneTasksAtom = atom<TaskView[]>({
  key: "doneTasksAtom",
  default: [],
});

export const projectTasksAtom = atom<TaskContainer[]>({
  key: "projectTasksAtom",
  default: [],
});
