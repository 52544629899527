import { Box, Tooltip, TooltipProps } from "@mantine/core";
import { ReactNode, useRef } from "react";

interface TooltipWrapperProps extends TooltipProps {
  children: ReactNode;
  inline?: boolean;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  inline,
  ...props
}) => {
  return (
    <Tooltip
      withinPortal
      position="right"
      color="gray"
      {...props}
      transitionProps={{ transition: "fade", duration: 200 }}
    >
      <Box display={inline ? "inline-block" : "block"}>{children}</Box>
    </Tooltip>
  );
};

export default TooltipWrapper;
