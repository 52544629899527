import { notifications } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons";

export const NotificationAlert = ({ message }: { message: string }) => {
  notifications.show({
    message,
    autoClose: 6000,
    color: "red",
    icon: <IconAlertCircle size={18} />,
  });
};
