import { NotificationAlert } from "@/components/NotificationAlert";
import { BadRequestError, NotFoundError } from "@/libs/api/errors";
import { messages } from "@/utils/messages";
import { useCallback } from "react";
import { useApi } from "../useApi";

export const useBacklogIssuesQuery = () => {
  const { getClientWithSession } = useApi();

  const getBacklogIssues = useCallback(
    async (params: {
      backlogUrl: string;
      projectId: number;
      target?: string;
      page: number;
    }) => {
      const { backlogUrl, projectId, page, target } = params;
      const client = await getClientWithSession();

      try {
        const { backlogIssues } = await client.backlogIssues({
          input: {
            backlogUrl,
            projectId,
            target,
            pages: page,
          },
        });
        return backlogIssues;
      } catch (err: any) {
        if (err instanceof NotFoundError || err instanceof BadRequestError) {
          NotificationAlert({
            message: err.message,
          });
          return [];
        }
        if (err?.response?.errors[0]?.message === "Too Many Requests") {
          NotificationAlert({
            message: messages.backlogLinkage.tooManyRequests,
          });
          return [];
        }

        throw err;
      }
    },
    [getClientWithSession]
  );

  return {
    getBacklogIssues,
  };
};
