import { useProjectTagQuery } from "@/hooks/queries/useProjectTagQuery";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { TaskServiceType } from "@/libs/api/generated/enum";
import { localizedYup } from "@/utils/localized-yup";
import { validationRules } from "@/utils/yup-rules";
import {
  Box,
  Checkbox,
  Flex,
  Grid,
  Select,
  SelectItem,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconLock } from "@tabler/icons";
import _ from "lodash";
import React, { forwardRef, useCallback } from "react";
import TooltipWrapper from "../common/TooltipWrapper";
import TaskStatusIcon from "../navbar/TaskStatusIcon";
import { TaskConstant, TaskConstantType } from "../userTasks/constant";
import TaskStatusSelect from "./TaskStatusSelect";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
}

export type FormRowAddTaskProps = {
  tasks: {
    projectId: string;
    projectTagId: string;
    name: string;
    lock: boolean;
    status: string;
    serviceType?: TaskServiceType;
    serviceId?: string;
  }[];
};

// バリデーションルール
export const FormRowAddTaskSchema = localizedYup.object().shape({
  tasks: localizedYup.array().of(
    localizedYup.object({
      projectId: validationRules.task.projectId.required(),
      projectTagId: validationRules.task.projectTagId.required(),
      name: validationRules.task.name.required(),
      lock: validationRules.task.lock.required(),
    })
  ),
});

type Props = {
  form: UseFormReturnType<
    FormRowAddTaskProps,
    (values: FormRowAddTaskProps) => FormRowAddTaskProps
  >;
  index: number;
  focusOnProjectSelect?: boolean;
  isEdit?: boolean;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>
    </div>
  )
);
SelectItem.displayName = "SelectItem";

export const CreateTaskRowSp = (props: Props): JSX.Element => {
  const { form, isEdit, focusOnProjectSelect } = props;
  const { activeProjects } = useProjectsQuery();
  const selectedTask = props.form.values["tasks"];
  const selectedProjectId = selectedTask[props.index]["projectId"];
  const selectedTaskStatus = selectedTask[props.index]["status"];
  const selectedTaskServiceType = selectedTask[props.index]["serviceType"];
  const { projectTags } = useProjectTagQuery({
    projectId: selectedProjectId,
  });

  const filter = useCallback(
    (value: string, item: SelectItem) =>
      item.label!.toLowerCase().includes(value.toLowerCase().trim()),
    []
  );

  const onClickStatusSelect = useCallback(
    (status: TaskConstantType) => {
      form.setValues({
        tasks: props.form.values.tasks.map((task, i) => {
          if (i === props.index) {
            return {
              ...props.form.values["tasks"][props.index],
              status,
            };
          } else {
            return task;
          }
        }),
      });
    },
    [form, props.form.values, props.index]
  );

  return (
    <Grid>
      <Grid.Col span={4} w={90}>
        <Stack>
          <Flex align={"center"} h={36}>
            <TooltipWrapper
              position="top"
              label={
                <Box>
                  <Text size={"sm"}>ステータス</Text>
                </Box>
              }
            >
              <TaskStatusIcon type={TaskConstant.TASK_BOX} />
            </TooltipWrapper>
          </Flex>
          <Flex align={"center"} h={36}>
            <Text size={"sm"}>プロジェクト</Text>
          </Flex>
          <Flex align={"center"} h={36}>
            <Text size={"sm"}>タグ</Text>
          </Flex>
          <Flex align={"center"} h={36}>
            <Text size={"sm"}>タスク名</Text>
          </Flex>
          <Flex align={"center"} h={36}>
            <TooltipWrapper
              w={240}
              label={
                <Box
                  w={"100%"}
                  sx={{
                    whiteSpace: "pre-wrap",
                    lineHeight: 1.7,
                  }}
                >
                  <Text size={"sm"}>タスクをロック</Text>
                  <Text size={"sm"} color="dimmed">
                    {
                      "ロックしたタスクは、Done Taskへ移動\nした状態で作業終了しても削除されず、\nTask Boxに戻ります。"
                    }
                  </Text>
                </Box>
              }
              position="top-start"
            >
              <IconLock size="19px" />
            </TooltipWrapper>
          </Flex>
        </Stack>
      </Grid.Col>
      <Grid.Col span={8}>
        <Stack>
          <TaskStatusSelect
            status={selectedTaskStatus as TaskConstantType}
            statusOptions={_.pick(TaskConstant, ["TASK_BOX", "TODAYS_TASK"])}
            onClick={onClickStatusSelect}
          />
          <Select
            id="project"
            placeholder="プロジェクト名"
            itemComponent={SelectItem}
            data={activeProjects.map((project) => ({
              label: project.name,
              value: project.id,
            }))}
            maxDropdownHeight={160}
            nothingFound="プロジェクトが見つかりません"
            searchable
            dropdownPosition="bottom"
            filter={filter}
            disabled={
              isEdit ||
              selectedTaskServiceType === TaskServiceType.BACKLOG ||
              selectedTaskServiceType === TaskServiceType.GITHUB
            }
            data-autofocus={focusOnProjectSelect ? true : undefined}
            withinPortal
            {...form.getInputProps(`tasks.${props.index}.projectId`)}
          />
          <Select
            id="tag"
            placeholder="タグ名"
            itemComponent={SelectItem}
            data={projectTags.map((tag) => ({
              label: tag.name,
              value: tag.id,
            }))}
            maxDropdownHeight={460}
            nothingFound="タグが見つかりません"
            searchable
            dropdownPosition="bottom"
            filter={filter}
            disabled={!selectedProjectId}
            data-autofocus={!focusOnProjectSelect ? true : undefined}
            withinPortal
            {...form.getInputProps(`tasks.${props.index}.projectTagId`)}
          />
          <TextInput
            id="taskName"
            placeholder="タスク名"
            autoComplete="off"
            {...form.getInputProps(`tasks.${props.index}.name`)}
          />
          <Flex align={"center"} w={36} h={36}>
            <Checkbox
              id="lock"
              checked={form.values.tasks[props.index].lock}
              {...form.getInputProps(`tasks.${props.index}.lock`)}
            />
          </Flex>
        </Stack>
      </Grid.Col>
    </Grid>
  );
};
