import { GtmClickEvent } from "@/components/analytics/GtmClickEvent";
import TooltipWrapper from "@/components/new/common/TooltipWrapper";
import { useDevice } from "@/hooks/features/useDevice";
import { useContractsQuery } from "@/hooks/queries/useContractsQuery";
import { AddTaskPage } from "@/libs/api/constants";
import { addTaskAtom, addTaskContinueAtom, inflowRouteAtom } from "@/stores";
import { Plan } from "@/types/enums";
import { Box, Button, Flex, Group, Kbd, Switch, Text } from "@mantine/core";
import { IconArrowsExchange2 } from "@tabler/icons";
import React, { useCallback } from "react";
import { useRecoilState } from "recoil";

const LinkedAddTaskButton: React.FC = () => {
  const [_, setAddTaskState] = useRecoilState(addTaskAtom);
  const { latestContract } = useContractsQuery();

  // 候補からタスクを追加ページに遷移する
  const onClickLinkedAddTaskButton = useCallback(
    () => setAddTaskState({ page: AddTaskPage.SELECT_GOOGLE_TASK }),
    [setAddTaskState]
  );

  return (
    <TooltipWrapper
      position="bottom-start"
      label={
        <Box>
          <Text size={"sm"}>
            Google Calendar、GitHub、Backlogからタスクをインポートできます。
          </Text>
        </Box>
      }
    >
      <Button
        variant="subtle"
        size="sm"
        leftIcon={<IconArrowsExchange2 size="16px"></IconArrowsExchange2>}
        onClick={onClickLinkedAddTaskButton}
        disabled={latestContract?.plan.code === Plan.FREE}
      >
        候補からタスクを追加
      </Button>
    </TooltipWrapper>
  );
};

const AddTaskFooter: React.FC<{ loading: boolean }> = ({ loading }) => {
  const [addTaskContinue, setAddTaskContinue] =
    useRecoilState(addTaskContinueAtom);
  const [inflowRouteCode, _] = useRecoilState(inflowRouteAtom);
  const { isTabletOrSmartphone } = useDevice();

  const onChangeSwitch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => setAddTaskContinue(event.currentTarget.checked),
      [setAddTaskContinue]
    );

  return (
    <Flex justify={"space-between"} align={"center"}>
      {!isTabletOrSmartphone && <LinkedAddTaskButton />}
      <Flex
        gap={32}
        justify={isTabletOrSmartphone ? "space-between" : "start"}
        align={"center"}
        w={isTabletOrSmartphone ? "100%" : "auto"}
      >
        <Switch
          size="sm"
          label="続けて追加"
          checked={addTaskContinue}
          onChange={onChangeSwitch}
        />
        <TooltipWrapper
          position="bottom-start"
          label={
            <Box>
              <Text size={"sm"}>
                <Kbd>⌘</Kbd>
                <Kbd>Enter</Kbd>タスクを追加
              </Text>
            </Box>
          }
        >
          <GtmClickEvent
            category="create-task"
            data={{
              route: inflowRouteCode,
            }}
          >
            <Button
              type="submit"
              size="sm"
              loading={loading}
              disabled={loading}
            >
              追加
            </Button>
          </GtmClickEvent>
        </TooltipWrapper>
      </Flex>
    </Flex>
  );
};

export default AddTaskFooter;
