import { newLogger } from "@/libs/utils/logger";
import { authAtom, errorAtom } from "@/stores";
import { useRecoilState, useRecoilValue } from "recoil";
import { useSession } from "./features/useSession";
import { useAsync } from "./useAsync";

const logger = newLogger({ prefix: "usApi" });
// ゲスト・ログイン済みのクライアントの取得（stateの連携）
export const useApi = () => {
  const session = useRecoilValue(authAtom);
  const [_, setErrorState] = useRecoilState(errorAtom);
  const { setCredentials } = useSession();

  return {
    ...useSession(),
    // APIと非同期処理はセットなのでまとめて返却
    ...useAsync(),
  };
};
