import React, { ErrorInfo, ReactNode } from "react";
import Error from "../Error";
import { logger } from "@/libs/utils/logger";
import { ErrorCode } from "@/libs/api/generated/codes";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
}

export class ComponentErrorHandlingMiddleware extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logger.info("ErrorBoundary.componentDidCatch", { error, errorInfo });
  }

  render(): JSX.Element {
    if (!this.state.error) {
      return <>{this.props.children}</>;
    }

    logger.error("ErrorBoundary", "システムエラー発生", {
      error: this.state.error,
    });
    return <Error errorCode={ErrorCode.Unknown} />;
  }
}
