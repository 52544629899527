import { TeamMemberInvitationsInsertInput } from "@/libs/api/generated/types";
import { authAtom } from "@/stores";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { ulid } from "ulid";
import { useTeamMemberInvitationsQuery } from "../queries/useTeamMemberInvitationsQuery";
import { useApi } from "../useApi";

export type Team = {
  createdAt: string;
  deletedAt?: string | null | undefined;
  enabledBlurBackground: boolean;
  enabledSnapshot: boolean;
  imageFilePath?: string | null | undefined;
  name: string;
  teamId: string;
  updatedAt: string;
};

export const useTeamMemberInvitations = () => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();
  const { mutateTeamMemberInvitation } = useTeamMemberInvitationsQuery();

  // チームメンバーを招待
  const inviteTeamMember = useCallback(
    async (
      input: Pick<
        TeamMemberInvitationsInsertInput,
        "email" | "role" | "projectIds"
      >[]
    ) => {
      const client = await getClientWithSession();
      const inputObjects = input.map((item) => ({
        ...item,
        id: ulid(),
        code: ulid(),
        lastSendAt: new Date().toISOString(),
        teamId: session?.teamId,
      }));

      await client.insertTeamMemberInvitations({
        objects: inputObjects,
      });
      await mutateTeamMemberInvitation();
    },
    [getClientWithSession, mutateTeamMemberInvitation, session?.teamId]
  );

  // チームメンバーを再招待
  const resendTeamMemberInvitation = useCallback(
    async (input: { id: string }) => {
      const client = await getClientWithSession();
      await client.updateTeamMemberInvitations({
        where: {
          id: {
            _eq: input.id,
          },
        },
        _set: {
          lastSendAt: new Date().toISOString(),
        },
      });
      await mutateTeamMemberInvitation();
    },
    [getClientWithSession, mutateTeamMemberInvitation]
  );

  // チームメンバーの招待を取り消し
  const cancelTeamMemberInvitation = useCallback(
    async (input: { id: string }) => {
      const client = await getClientWithSession();
      await client.deleteTeamMemberInvitationsByPk({
        id: input.id,
      });
      await mutateTeamMemberInvitation();
    },
    [getClientWithSession, mutateTeamMemberInvitation]
  );

  return {
    inviteTeamMember,
    resendTeamMemberInvitation,
    cancelTeamMemberInvitation,
  };
};
