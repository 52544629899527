import { AppShell, useMantineTheme } from "@mantine/core";
import { ReactElement } from "react";
import { HeaderContent } from "./new/header/HeaderContent";
import NavbarContent from "./new/navbar/NavbarContent";
import AsideContent from "./new/aside/AsideContent";

export const AppShellCustomLoggedIn = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const theme = useMantineTheme();
  const styles = {
    main: {
      background: theme.colors.dark[8],
    },
  };
  return (
    <>
      <AppShell
        layout="alt"
        styles={styles}
        asideOffsetBreakpoint="sm"
        navbarOffsetBreakpoint="sm"
        aside={<AsideContent />}
        header={<HeaderContent />}
        navbar={<NavbarContent />}
        footer={<></>}
      >
        {children}
      </AppShell>
    </>
  );
};
