import { SWR_KEYS } from "@/stores/swr";
import useSWR from "swr";
import { useApi } from "../useApi";
import { toTeamMemberView } from "./views/TeamMemberView";

export const useTeamMemberQuery = (teamMemberId: string) => {
  const { getClientWithSession } = useApi();

  // チームメンバー情報を取得
  const {
    data: teamMember,
    isLoading: teamMemberLoading,
    mutate: mutateTeamMember,
  } = useSWR([SWR_KEYS.QUERY_TEAM_MEMBER, teamMemberId], async () => {
    // teamMemberIdが""で渡ってくる場合はGraphQLエラーを防ぐためnullを返す
    if (!teamMemberId) return null;

    const client = await getClientWithSession();
    const { teamMembersByPk: teamMember } = await client.teamMembersByPk({
      id: teamMemberId,
    });
    return teamMember ? toTeamMemberView(teamMember) : null;
  });

  return {
    teamMember,
    mutateTeamMember,
  };
};
