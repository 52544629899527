import { SWR_KEYS } from "@/stores/swr";
import { useCallback } from "react";
import useSWR from "swr";
import { useApi } from "../useApi";
import { useTeamMembersQuery } from "./useTeamMembersQuery";

export const useTeamMemberInvitationsQuery = () => {
  const { getClientWithSession } = useApi();
  const { isTeamAdmin } = useTeamMembersQuery();

  // チームメンバーの招待情報を取得
  const {
    data: teamMemberInvitations,
    isLoading: teamMemberInvitationsLoading,
    error: teamMemberInvitationsError,
    mutate: mutateTeamMemberInvitation,
  } = useSWR(SWR_KEYS.QUERY_TEAM_MEMBER_INVITATIONS, async () => {
    if (!isTeamAdmin) return [];
    const client = await getClientWithSession();
    const { teamMemberInvitations } = await client.teamMemberInvitations();
    return teamMemberInvitations;
  });

  const currentTeamMemberInvitation = useCallback(
    (id: string) =>
      teamMemberInvitations?.filter((invitation) => invitation.id === id)[0],
    [teamMemberInvitations]
  );

  return {
    teamMemberInvitations,
    currentTeamMemberInvitation,
    mutateTeamMemberInvitation,
  };
};
