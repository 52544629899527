import Head from "next/head";

export const HeadTitleWrap = ({ title }: { title?: string }): JSX.Element => {
  return (
    <Head>
      <title>{title ? `${title} | Factog` : "Factog"}</title>
    </Head>
  );
};

export const HeadTitle = ({ title }: { title?: string }): JSX.Element => {
  if (title) {
    return <HeadTitleWrap title={title} />;
  }

  return <HeadTitleWrap />;
};
