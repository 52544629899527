/* eslint-disable no-console */
import { formatDateTimeWithMill } from "@/utils/date";
import dayjs, { PluginFunc, UnitType } from "dayjs";
class Logger {
  private _debug = false;
  private _prefix = "";

  constructor(prefix?: string) {
    this._prefix = prefix || "";
    if (process.env.NEXT_PUBLIC_LOGGER_ENABLED === "1") {
      // ロガーのデバッグモードを有効化(本番環境では環境変数 NEXT_PUBLIC_LOGGER_ENABLED を0に設定して無効化すること)
      this.enableDebug();
    }
  }

  enableDebug() {
    this._debug = true;
  }

  createPrefix(serverity: "D" | "I" | "E") {
    const time = formatDateTimeWithMill(new Date().toISOString());
    if (this._prefix) {
      return `${serverity} - ${time}: (${this._prefix})`;
    } else {
      return `${serverity} - ${time}:`;
    }
  }

  debug(...msg: any) {
    if (!this._debug) {
      return;
    }
    console.debug(`${this.createPrefix("D")}`, ...msg);
  }

  info(...msg: any) {
    if (!this._debug) {
      return;
    }
    console.info(`${this.createPrefix("I")}`, ...msg);
  }

  error(...msg: any) {
    if (!this._debug) {
      return;
    }
    console.error(`${this.createPrefix("E")}`, ...msg);
  }
}

export const newLogger = (options: { prefix: string } = { prefix: "" }) => {
  return new Logger(options.prefix);
};

const _logger = newLogger();

export const logger = _logger;
