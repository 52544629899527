import {
  ReportDetailsSortColumn,
  SummaryType,
} from "@/libs/api/generated/enum";
import dayjs from "dayjs";
import { DataTableSortStatus } from "mantine-datatable";
import { atom } from "recoil";
import { DateRangePresetType } from "../types/reports";

export type ReportsParams = {
  projectIds: string[] | undefined;
  teamMemberIds: string[] | undefined;
  dateRange: { to: Date | null; from: Date | null };
  dateRangePreset: DateRangePresetType;
  isRounding: boolean;
  roundType: "round" | "floor" | "ceil";
  roundTime: string;
  summaryType: SummaryType;
  summaryTableSortStatus: DataTableSortStatus;
  detailTableSortStatus: DataTableSortStatus;
};

export const defaultReportsParams: ReportsParams = {
  projectIds: undefined,
  teamMemberIds: undefined,
  dateRange: {
    from: dayjs().startOf("month").toDate(),
    to: dayjs().endOf("month").toDate(),
  },
  dateRangePreset: DateRangePresetType.THIS_MONTH,
  isRounding: false,
  roundType: "ceil",
  roundTime: "60",
  summaryType: SummaryType.PROJECT,
  summaryTableSortStatus: {
    columnAccessor: "title",
    direction: "asc",
  },
  detailTableSortStatus: {
    columnAccessor: "date",
    direction: "asc",
  },
};

export const reportsParamsAtom = atom<ReportsParams>({
  key: "ReportsParamsAtom",
  default: defaultReportsParams,
});
