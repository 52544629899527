import { WorkStatus } from "@/libs/api/generated/enum";
import { FinishWorkInput } from "@/libs/api/generated/types";
import { useCallback } from "react";
import { useTasksQuery } from "../queries/useTasksQuery";
import { useTeamMembersQuery } from "../queries/useTeamMembersQuery";
import { useWorkTrackingQuery } from "../queries/useWorkTrackingQuery";
import { useApi } from "../useApi";
import { useContents } from "../useContents";
import { useSnapshot } from "./useSnapshot";

export const useWorks = () => {
  const { teamMembers, currentTeamMember, mutateTeamMembers } =
    useTeamMembersQuery();
  const { getClientWithSession } = useApi();
  const { mutateSnapshot } = useSnapshot();
  const { getSignedUrl } = useContents();
  const { mutateTasks } = useTasksQuery(currentTeamMember?.id || "");
  const { mutateWorkTracking } = useWorkTrackingQuery(
    currentTeamMember?.id || ""
  );

  // 勤怠一覧をExcel出力
  const exportWorksExcel = useCallback(
    async (month: number, year: number, teamMemberId: string) => {
      const client = await getClientWithSession();
      const { exportWorks } = await client.exportWorks({
        input: {
          month,
          year,
          teamMemberId,
        },
      });
      const path = await getSignedUrl(exportWorks.path);
      return path;
    },
    [getClientWithSession, getSignedUrl]
  );

  const startWork = useCallback(async () => {
    const client = await getClientWithSession();
    await client.startWork({});
    await mutateTeamMembers();
    await mutateTasks();
    await mutateWorkTracking();
    await mutateSnapshot();
  }, [
    getClientWithSession,
    mutateSnapshot,
    mutateTasks,
    mutateTeamMembers,
    mutateWorkTracking,
  ]);

  const suspendWork = useCallback(async () => {
    const client = await getClientWithSession();
    await client.suspendWork();
    await mutateTeamMembers();
    await mutateTasks();
    await mutateWorkTracking();
  }, [
    getClientWithSession,
    mutateTasks,
    mutateTeamMembers,
    mutateWorkTracking,
  ]);

  const resumeWork = useCallback(async () => {
    const client = await getClientWithSession();
    await client.resumeWork({});
    await mutateTeamMembers();
    await mutateTasks();
    await mutateWorkTracking();
    await mutateSnapshot();
  }, [
    getClientWithSession,
    mutateSnapshot,
    mutateTasks,
    mutateTeamMembers,
    mutateWorkTracking,
  ]);

  const finishWork = useCallback(
    async ({ input }: { input: FinishWorkInput }) => {
      const client = await getClientWithSession();
      await client.finishWork({ input });
      await mutateTeamMembers();
      await mutateSnapshot();
      await mutateTasks();
      await mutateWorkTracking();
    },
    [
      getClientWithSession,
      mutateSnapshot,
      mutateTasks,
      mutateTeamMembers,
      mutateWorkTracking,
    ]
  );

  return {
    exportWorksExcel,
    startWork,
    suspendWork,
    resumeWork,
    finishWork,
  };
};
