import { Body } from "@/components/Body";
import { GtmInit } from "@/components/analytics/GtmInit";
import { Middleware } from "@/components/middlewares";
import { rtlCache } from "@/utils/rtl-cache";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "dayjs/locale/ja";
import { AppProps } from "next/app";
import getConfig from "next/config";
import Head from "next/head";
import { Suspense, useEffect } from "react";
import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";
import "../styles/font.css";
import "../styles/globals.css";

const { publicRuntimeConfig } = getConfig();
Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
  // next.config.jsで指定したversionと同じversionを指定
  appVersion: publicRuntimeConfig.version,
  releaseStage: process.env.NEXT_PUBLIC_STAGE,
  plugins: [new BugsnagPluginReact()],
});

export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const globalStyles = () => ({
    // react-datepickerのstyleのoverride
    ".react-datepicker": {
      background: "#25262b",
      color: "white",
    },
    ".react-datepicker__header": {
      background: "#25262b",
      color: "white",
    },
    ".react-datepicker__input-container input": {
      background: "#25262b",
      border: "1px solid #373a40",
      borderRadius: "4px",
      fontSize: "14px",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
      color: "#C1C2C5",
      padding: "8px 16px",
    },
    ".react-datepicker__month-text:hover": {
      background: "#373a40",
    },
  });

  const themeSecondary: MantineThemeOverride = {
    colorScheme: "dark",
    loader: "oval",
    datesLocale: "ja",
    fontSizes: {
      xs: "11px",
      sm: "12px",
      md: "14px",
      lg: "16px",
      xl: "18px",
    },
    colors: {
      black: ["#2D1F4D", "#191326", "#07060B"],
      factog: ["#FF4A28", "#FFAF1A"],
    },
    headings: {
      sizes: {
        h1: { fontSize: "40px" },
        h2: { fontSize: "32px" },
        h3: { fontSize: "24px" },
        h4: { fontSize: "20px" },
        h5: { fontSize: "18px" },
        h6: { fontSize: "16px" },
      },
    },
    globalStyles,
  };

  const theme: MantineThemeOverride = {
    /** Put your mantine theme override here */
    colorScheme: "dark",
    loader: "oval",
    // dateFormat: "YYYY/MM/DD",
    datesLocale: "ja",
    fontFamily:
      "Noto Sans JP, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
    colors: {
      black: ["#2D1F4D", "#191326", "#07060B"],
      factog: ["#FF4A28", "#FFAF1A"],
    },
    headings: {
      sizes: {
        h1: { fontSize: "40px" },
        h2: { fontSize: "32px" },
        h3: { fontSize: "24px" },
        h4: { fontSize: "20px" },
        h5: { fontSize: "18px" },
        h6: { fontSize: "16px" },
      },
    },
    globalStyles,
  };

  // 画面の初回描画から一定時間経過した場合は強制的にリロードする(環境変数で12時間に設定)
  useEffect(() => {
    setTimeout(
      () => location.reload(),
      Number(process.env.NEXT_PUBLIC_FORCE_RELOAD_TIME)
    );
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1.0"
        />
        <link rel="icon" href="/favicon-factog.ico" />
      </Head>
      <RecoilRoot>
        <div>
          <ModalsProvider>
            <MantineProvider
              theme={themeSecondary}
              withGlobalStyles
              withNormalizeCSS
              emotionCache={rtlCache}
            >
              <SWRConfig
                value={{
                  // ネットワーク再接続時にクエリを実行
                  revalidateOnReconnect: true,
                  onError: (err) => {
                    // AsyncErrorHandlerで処理する
                    throw err;
                  },
                }}
              >
                <Notifications
                  position="bottom-left"
                  limit={1}
                  zIndex={99999}
                />
                <Suspense>
                  <GtmInit />
                </Suspense>
                <Middleware>
                  {/* TODO: pages/newディレクトリ以下のファイルを正常に動かすにはBodyを一時的にコメントアウトしてください */}
                  <Body>
                    <Component {...pageProps} />
                  </Body>
                </Middleware>
              </SWRConfig>
            </MantineProvider>
          </ModalsProvider>
        </div>
      </RecoilRoot>
    </>
  );
}
