import { Aside, Divider } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { useEffect, useState } from "react";
import TeamMemberSection from "./teamMember/TeamMemberSection";
import ActivitySection from "./activity/ActivitySection";

const AsideContent: React.FC = () => {
  const [memberShow, toggleMemberShow] = useToggle([true, false]);
  const [activityShow, toggleActivityShow] = useToggle([true, false]);
  const [showStyle, setShowStyle] = useState({
    member: { height: "50vh" },
    activity: { height: "50vh" },
  });

  useEffect(() => {
    if (memberShow && activityShow) {
      setShowStyle({
        member: { height: "50vh" },
        activity: { height: "50vh" },
      });
    } else if (memberShow && !activityShow) {
      setShowStyle({
        member: { height: "50vh" },
        activity: { height: "calc(100vh - 40px)" },
      });
    } else if (!memberShow && activityShow) {
      setShowStyle({
        member: { height: "40px" },
        activity: { height: "calc(100vh - 40px)" },
      });
    } else {
      setShowStyle({
        member: { height: "40px" },
        activity: { height: "40px" },
      });
    }
  }, [memberShow, activityShow]);

  return (
    <Aside width={{ sm: 230, lg: 256 }} sx={{ overflow: "hidden" }}>
      <Aside.Section>
        <TeamMemberSection
          show={memberShow}
          toggle={toggleMemberShow}
          style={showStyle.member}
        />
      </Aside.Section>
      <Divider />
      <Aside.Section>
        <ActivitySection
          show={activityShow}
          toggle={toggleActivityShow}
          style={showStyle.activity}
        />
      </Aside.Section>
      <Divider />
    </Aside>
  );
};

export default AsideContent;
