import { OrderBy } from "@/libs/api/generated/types";
import { SWR_KEYS } from "@/stores/swr";
import { useMemo } from "react";
import useSWR from "swr";
import { useApi } from "../useApi";

export const useProjectsQuery = (projectId?: string) => {
  const { getClientWithSession } = useApi();
  // プロジェクト情報を取得
  const {
    data: projects,
    isLoading: projectsLoading,
    error: projectsError,
    mutate: mutateProjects,
  } = useSWR(SWR_KEYS.QUERY_PROJECTS, async () => {
    const client = await getClientWithSession();
    const { projects } = await client.projects({
      where: {
        deletedAt: {
          _isNull: true,
        },
      },
      orderBy: {
        name: OrderBy.Asc,
      },
    });
    return projects;
  });

  // アーカイブされていないプロジェクト一覧
  const activeProjects = useMemo(() => {
    if (!projects) return [];

    return projects.filter((project) => !project.archived);
  }, [projects]);

  // 現在開いているプロジェクト情報の取得
  const currentProject = useMemo(() => {
    if (!projects) return null;

    return projects.find((project) => {
      return project.id === projectId;
    });
  }, [projectId, projects]);

  return {
    projects: projects || [],
    mutateProjects,
    currentProject,
    activeProjects,
  };
};
