import { AddTaskPage } from "@/libs/api/constants";
import { addTaskAtom } from "@/stores";
import { ActionIcon, Group } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import React, { useCallback } from "react";
import { useRecoilState } from "recoil";

const BackButton: React.FC<{ to: AddTaskPage }> = ({ to }) => {
  const [addTaskState, setAddTaskState] = useRecoilState(addTaskAtom);

  const onClickBuckButton = useCallback(
    () => setAddTaskState({ page: to }),
    [addTaskState, setAddTaskState]
  );

  return (
    <ActionIcon variant="light" onClick={onClickBuckButton}>
      <IconArrowLeft></IconArrowLeft>
    </ActionIcon>
  );
};

const AddTaskModalTitle: React.FC = () => {
  const [addTaskState, _] = useRecoilState(addTaskAtom);

  if (addTaskState.page === AddTaskPage.ADD_GOOGLE_TASK) {
    return (
      <Group>
        <BackButton to={AddTaskPage.SELECT_GOOGLE_TASK}></BackButton>
        <div>候補からタスクを追加</div>
      </Group>
    );
  }

  if (addTaskState.page === AddTaskPage.SELECT_GOOGLE_TASK) {
    return (
      <Group>
        <BackButton to={AddTaskPage.ADD_TASK}></BackButton>
        <div>候補からタスクを追加</div>
      </Group>
    );
  }

  return <div>タスクを追加</div>;
};

export default AddTaskModalTitle;
