import {
  SubscribeTeamMembersDocument,
  SubscribeTeamMembersSubscription,
} from "@/libs/api/generated/types";
import { newLogger } from "@/libs/utils/logger";
import { SWR_KEYS } from "@/stores/swr";
import useSWRSubscription from "swr/subscription";
import { Subscription } from "zen-observable-ts";
import { useApi } from "../useApi";
import { useContents } from "../useContents";
import {
  TeamMemberSubscriptionView,
  toTeamMemberSubscriptionView,
} from "./views/TeamMemberSubscriptionView";

const logger = newLogger({ prefix: "useTeamMembersSubscription" });

export const useTeamMembersSubscription = (
  projectIds: string[] | undefined
) => {
  const { getApolloClientWithSession } = useApi();
  const { getSignedUrl } = useContents();

  // チームメンバー情報を購読
  const { data: liveTeamMembers, error: liveTeamMembersError } =
    useSWRSubscription<TeamMemberSubscriptionView[]>(
      [SWR_KEYS.SUBSCRIPTION_TEAM_MEMBERS, ...(projectIds || [])],
      (key: string, { next }: any) => {
        logger.info("Subscriptionの初期化");

        let subscription: Subscription;
        void getApolloClientWithSession().then(async (client) => {
          const observable = client.subscribe({
            query: SubscribeTeamMembersDocument,
            variables: {
              where: {
                deletedAt: {
                  _isNull: true,
                },
                projectMembers: {
                  _and: [
                    {
                      projectId: {
                        _in: projectIds,
                      },
                    },
                    {
                      deletedAt: {
                        _isNull: true,
                      },
                    },
                  ],
                },
              },
            },
          });

          subscription = observable.subscribe({
            start() {
              logger.debug("購読開始");
            },
            async next({ data }: any) {
              logger.debug("データ取得", data);
              const teamMembers = (data as SubscribeTeamMembersSubscription)
                .teamMembers;
              const _teamMembers = await Promise.all(
                teamMembers.map((teamMember) =>
                  toTeamMemberSubscriptionView(teamMember, getSignedUrl)
                )
              );
              next(null, _teamMembers);
            },
            error(error: any) {
              logger.error("データ取得でエラー発生", error);
              next(error);
            },
            complete() {
              logger.error("購読完了");
            },
          });
        });

        return () => {
          logger.info("購読を解除");
          subscription?.unsubscribe();
        };
      }
    );

  return {
    liveTeamMembers,
  };
};
