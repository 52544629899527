import { UnauthorizedError } from "@/libs/api/errors";
import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";
import { OrderBy } from "@/libs/api/generated/types";

export const useTeamBacklogLinkageQuery = (backlogUrl?: string) => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();

  const {
    data: teamBacklogLinkages,
    isLoading: teamBacklogLinkageLoading,
    mutate: mutateTeamBacklogLinkage,
  } = useSWR(
    [SWR_KEYS.QUERY_TEAM_BACKLOG_LINKAGE, session?.teamId, backlogUrl],
    async () => {
      const client = await getClientWithSession();

      if (!session?.teamId) {
        throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
      }
      const { teamBacklogLinkages } = await client.teamBacklogLinkages({
        where: {
          teamId: {
            _eq: session.teamId,
          },
          backlogUrl: backlogUrl ? { _eq: backlogUrl } : {},
        },
        orderBy: {
          createdAt: OrderBy.Asc,
        },
      });

      return teamBacklogLinkages;
    }
  );

  return {
    teamBacklogLinkages: teamBacklogLinkages || [],
    teamBacklogLinkageLoading,
    mutateTeamBacklogLinkage,
  };
};
