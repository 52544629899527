import { UnauthorizedError } from "@/libs/api/errors";
import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";

export const useTeamGoogleCalendarLinkageQuery = () => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();

  const {
    data: teamGoogleCalendarLinkage,
    isLoading: teamGoogleCalendarLinkageLoading,
    mutate: mutateTeamGoogleCalendarLinkage,
  } = useSWR(
    [SWR_KEYS.QUERY_TEAM_GOOGLE_CALENDAR_LINKAGE, session?.teamId],
    async () => {
      const client = await getClientWithSession();

      if (!session?.teamId) {
        throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
      }
      const { teamGoogleCalendarLinkages } =
        await client.teamGoogleCalendarLinkages({
          where: {
            teamId: {
              _eq: session.teamId,
            },
          },
        });

      return teamGoogleCalendarLinkages[0];
    }
  );

  return {
    teamGoogleCalendarLinkage,
    teamGoogleCalendarLinkageLoading,
    mutateTeamGoogleCalendarLinkage,
  };
};
