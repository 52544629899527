import React, { ReactElement } from "react";
import { AppShellCustomGuest } from "../AppShellCustomGuest";

type Props = {
  children: ReactElement;
};
export const LoggedOutDesktop = React.memo((props: Props): JSX.Element => {
  return <AppShellCustomGuest>{props.children}</AppShellCustomGuest>;
});
LoggedOutDesktop.displayName = "LoggedOutDesktop";
