import TooltipWrapper from "@/components/new/common/TooltipWrapper";
import TaskStatusIcon from "@/components/new/navbar/TaskStatusIcon";
import { Box, Flex, Text } from "@mantine/core";
import { IconLock } from "@tabler/icons";
import { TaskConstant } from "../userTasks/constant";

const CreateTaskRowHeader: React.FC = () => {
  return (
    <Flex gap={16}>
      {/* ステータス */}
      <TooltipWrapper
        position="top"
        label={
          <Box>
            <Text size={"sm"}>ステータス</Text>
          </Box>
        }
      >
        <Flex justify={"center"} align={"center"} w={36}>
          <TaskStatusIcon type={TaskConstant.TASK_BOX} />
        </Flex>
      </TooltipWrapper>
      {/* プロジェクト */}
      <Box w={200}>
        <Text size={"sm"}>プロジェクト</Text>
      </Box>
      {/* タグ */}
      <Box w={160}>
        <Text size={"sm"}>タグ</Text>
      </Box>
      {/* タスク名 */}
      <Box w={320}>
        <Text size={"sm"}>タスク名</Text>
      </Box>
      {/* タスクをロック */}
      <TooltipWrapper
        w={240}
        label={
          <Box
            w={"100%"}
            sx={{
              whiteSpace: "pre-wrap",
              lineHeight: 1.7,
            }}
          >
            <Text size={"sm"}>タスクをロック</Text>
            <Text size={"sm"} color="dimmed">
              {
                "ロックしたタスクは、Done Taskへ移動\nした状態で作業終了しても削除されず、\nTask Boxに戻ります。"
              }
            </Text>
          </Box>
        }
        position="top-start"
      >
        <Flex justify={"center"} align={"center"} w={36}>
          <IconLock size="19px" />
        </Flex>
      </TooltipWrapper>
    </Flex>
  );
};

export default CreateTaskRowHeader;
