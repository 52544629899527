import { useSession } from "@/hooks/features/useSession";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { useTeamMembersSubscription } from "@/hooks/queries/useTeamMembersSubscription";
import {
  TeamMemberSubscriptionView,
  sortTeamMemberSubscription,
} from "@/hooks/queries/views/TeamMemberSubscriptionView";
import { TeamMemberListView } from "@/hooks/queries/views/TeamMemberView";
import { Routes, usePath } from "@/hooks/usePath";
import { ActionIcon, Divider, Flex, TextInput } from "@mantine/core";
import {
  IconArrowLeft,
  IconLayoutKanban,
  IconSearch,
  IconX,
} from "@tabler/icons";
import { useRouter } from "next/router";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import ActionIconWrapper from "../../common/ActionIconWrapper";
import TooltipWrapper from "../../common/TooltipWrapper";
import AsideSectionBodyWrapper from "../AsideSectionBodyWrapper";
import AsideSectionHeader from "../AsideSectionHeader";
import TeamMember from "./TeamMember";

type TeamMemberSectionType = {
  show: boolean;
  toggle: (value?: SetStateAction<boolean> | undefined) => void;
  style: any;
};

export const isMatchedTeamMember = (
  teamMember: TeamMemberSubscriptionView | TeamMemberListView,
  searchText: string
) => {
  if (!searchText) return true;

  const lowerCaseSearchText = searchText.toLocaleLowerCase();
  const nameMatch = teamMember.fullName.includes(lowerCaseSearchText);
  const emailMatch = teamMember.raw.user.email.includes(lowerCaseSearchText);

  // name, email のいずれかで一致するものがあれば true を返す
  return nameMatch || emailMatch;
};

const TeamMemberSection: React.FC<TeamMemberSectionType> = ({
  show,
  toggle,
  style,
}) => {
  const router = useRouter();
  const { generatePath } = usePath();
  const { session } = useSession();
  const { activeProjects } = useProjectsQuery();
  const { liveTeamMembers } = useTeamMembersSubscription(
    activeProjects.map((project) => project.id)
  );
  const [teamMembers, setTeamMembers] =
    useState<TeamMemberSubscriptionView[]>();

  useEffect(() => {
    if (!liveTeamMembers) return;

    const sortedTeamMembers = sortTeamMemberSubscription(liveTeamMembers).sort(
      (a, b) => {
        // 自分自身を最上位に移動
        if (a.raw.user.id === session?.userId) {
          return -1;
        }
        if (b.raw.user.id === session?.userId) {
          return 1;
        }
        return 0;
      }
    );

    setTeamMembers(sortedTeamMembers);
  }, [liveTeamMembers, session?.userId]);

  const [isSearchMode, setIsSearchMode] = useState(false);

  const onClickToggleSearch = useCallback(() => {
    setIsSearchMode((prev) => !prev);
  }, [setIsSearchMode]);

  // 検索フォーム
  const [searchText, setSearchText] = useState("");
  const onChangeSearchText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    },
    [setSearchText]
  );
  const clearSearchText = useCallback(() => setSearchText(""), []);

  const onClickBack = useCallback(() => {
    setIsSearchMode(false);
    clearSearchText();
  }, [clearSearchText]);

  return (
    <Flex direction={"column"} sx={{ overflow: "hidden" }} style={style}>
      {isSearchMode ? (
        <Flex align={"center"} px={10} py={0} h={40} gap={8}>
          <ActionIcon size={"sm"} onClick={onClickBack}>
            <IconArrowLeft size="16px" stroke={1.0} />
          </ActionIcon>
          <TextInput
            h={28}
            w={204}
            size="xs"
            icon={<IconSearch size="13px" />}
            radius={999}
            placeholder="メンバーを絞り込む"
            autoFocus
            rightSection={
              <ActionIcon
                size={"xs"}
                display={searchText.length > 0 ? "flex" : "none"}
                sx={{ justifyContent: "center", alignItems: "center" }}
                onClick={clearSearchText}
              >
                <IconX size="13px" />
              </ActionIcon>
            }
            value={searchText}
            onChange={onChangeSearchText}
          />
        </Flex>
      ) : (
        <AsideSectionHeader
          show={show}
          toggle={toggle}
          label={"チームメンバー"}
          rightIconEl={
            <TooltipWrapper position="bottom" label={"検索"}>
              <ActionIcon size={"sm"} onClick={onClickToggleSearch}>
                <IconSearch size={13.5} stroke={1.0} />
              </ActionIcon>
            </TooltipWrapper>
          }
          buttonEl={
            <TooltipWrapper position="bottom" label={"チームボード"}>
              <ActionIconWrapper
                m={6}
                to={generatePath({
                  routePath: Routes.TEAM_KANBAN.path,
                  dynamicParams: {
                    teamId: router.query.teamId as string,
                  },
                })}
              >
                <IconLayoutKanban size="19px" stroke={1.0} />
              </ActionIconWrapper>
            </TooltipWrapper>
          }
        />
      )}
      <Divider />
      <AsideSectionBodyWrapper show={show}>
        {teamMembers?.map(
          (member) =>
            isMatchedTeamMember(member, searchText) && (
              <TeamMember key={member.raw.id} teamMember={member} />
            )
        )}
      </AsideSectionBodyWrapper>
    </Flex>
  );
};

export default TeamMemberSection;
