import { NotificationAlert } from "@/components/NotificationAlert";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { useDialog } from "@/hooks/useDialog";
import { Box, Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconPlus } from "@tabler/icons";
import { useCallback, useState } from "react";
import { ModalTaskAdd, ModalTaskAddProps } from "../createTask/ModalTaskAdd";

export const onClickAddTaskButton = (isNotWorking: boolean) => () => {
  if (isNotWorking) {
    NotificationAlert({
      message: "作業開始、または再開するとタスクを作成できます",
    });
    return;
  }
};

const AddTaskButton: React.FC<{
  disabled: boolean;
  isNotWorking: boolean;
  isAddTodayTask?: boolean;
  size?: "xs" | "sm";
}> = ({ disabled, isNotWorking, isAddTodayTask, size = "sm" }) => {
  const { activeProjects } = useProjectsQuery();

  const { openDialog, closeDialog, isDialogMode } = useDialog<"addTask">();
  // タスク追加ダイアログのProps
  const [modalTaskAddProps, setModalTaskAddProps] = useState<ModalTaskAddProps>(
    {
      opened: false,
      focusOnProjectSelect: false,
      onClose: closeDialog,
    }
  );

  // タスク追加
  const onOpenAddTaskDialog = useCallback(() => {
    if (activeProjects.length === 0) {
      notifications.hide("NotExistTodaysTask");
      NotificationAlert({
        message: "プロジェクトに所属していません。管理者に連絡してください",
      });
      notifications.show({
        id: "NotExistTodaysTask",
        withCloseButton: false,
        autoClose: false,
        title: "「Today's Task」にタスクを移動してください",
        message: "",
        color: "red",
      });
      return;
    }
    openDialog("addTask");
    setModalTaskAddProps({
      ...modalTaskAddProps,
      opened: true,
      isAddTodayTask,
      focusOnProjectSelect: true,
      project: {
        id: activeProjects[0]?.id || "",
        name: activeProjects[0]?.name || "",
      },
    });
  }, [isAddTodayTask, modalTaskAddProps, activeProjects, openDialog]);

  return (
    <>
      <Box onClick={onClickAddTaskButton(isNotWorking)}>
        <Button
          size={size}
          variant="default"
          leftIcon={<IconPlus size="16px" />}
          onClick={onOpenAddTaskDialog}
          disabled={disabled || isNotWorking}
        >
          タスク追加
        </Button>
      </Box>
      {/* Modal: addTask */}
      {isDialogMode("addTask") && <ModalTaskAdd {...modalTaskAddProps} />}
    </>
  );
};

export default AddTaskButton;
