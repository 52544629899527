type SortDirection = "asc" | "desc";

export function sortByColumn<T, K extends keyof T>(
  list: T[],
  columnAccessor: K,
  direction: SortDirection = "asc"
): T[] {
  const sortedList = list.slice().sort((a, b) => {
    const nameA = a[columnAccessor];
    const nameB = b[columnAccessor];

    if (direction === "desc") {
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
    } else {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }

    return 0;
  });

  return sortedList as T[];
}

export function flattenArray<T>(arr: T[][]): T[] {
  return arr.reduce((result, values) => {
    result.push(...values);
    return result;
  }, [] as T[]);
}

export function uniqArray<T>(
  array: T[],
  comparator?: (result: T[], item: T) => boolean
): T[] {
  const result = [];
  for (const item of array) {
    if (comparator) {
      if (comparator(result, item)) {
        result.push(item);
      }
    } else {
      if (result.indexOf(item) === -1) {
        result.push(item);
      }
    }
  }
  return result;
}
