export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum JWTType {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  VERIFY_EMAIL_FOR_CREATE_USER = 'VERIFY_EMAIL_FOR_CREATE_USER',
  VERIFY_EMAIL_FOR_UPDATE_USER_EMAIL = 'VERIFY_EMAIL_FOR_UPDATE_USER_EMAIL',
  RESET_PASSWORD = 'RESET_PASSWORD',
  REMOVE_TEAM_ALLOWED_IP_ADDRESSES = 'REMOVE_TEAM_ALLOWED_IP_ADDRESSES',
}

export enum WorkStatus {
  WORKING = 'WORKING',
  NOT_WORKING = 'NOT_WORKING',
  SUSPENDED = 'SUSPENDED',
}

export enum TeamRole {
  ADMIN = 'TEAM_ADMIN',
  MEMBER = 'TEAM_MEMBER',
}

export enum ProjectRole {
  ADMIN = 'PROJECT_ADMIN',
  MEMBER = 'PROJECT_MEMBER',
}

export enum ActivityType {
  STARTED = 'USER_WORK_STARTED',
  SUSPENDED = 'USER_WORK_SUSPENDED',
  RESUMED = 'USER_WORK_RESUMED',
  FINISHED = 'USER_WORK_FINISHED',
  TASK_STARTED = 'USER_TASK_STARTED',
}

export enum TaskStatus {
  TODO = 'TODO',
  TODAY = 'TODAY',
  DOING = 'DOING',
  SUSPEND = 'SUSPEND',
  DONE = 'DONE',
}

export enum UserLoginType {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export enum TaskServiceType {
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
  GITHUB = 'GITHUB',
  BACKLOG = 'BACKLOG',
}

export enum LoginAction {
  LOGIN_SUCCESS = 'login_success',
  LOGIN_FAILURE = 'login_failure',
  CREATE_USER = 'create_user',
  LOGIN_BY_GOOGLE = 'login_by_google',
  LOGIN_BY_MICROSOFT = 'login_by_microsoft',
  RESET_PASSWORD = 'reset_password',
}

export enum EnqueteType {
  TEAM_CREATION = 'team_creation',
  WITHDRAWAL = 'withdrawal',
}

export enum PeriodCode {
  CUSTOM = 'custom',
  THIS_WEEK = 'thisWeek',
  LAST_WEEK = 'lastWeek',
  THIS_MONTH = 'thisMonth',
  LAST_MONTH = 'lastMonth',
  LAST_30_DAYS = 'last30days',
  LAST_90_DAYS = 'last90days',
  LAST_12_MONTHS = 'last12months',
  THIS_YEAR = 'thisYear',
  LAST_YEAR = 'lastYear',
  ALL = 'all',
}

export enum SummaryType {
  USER = 'user',
  PROJECT = 'project',
  PROJECT_TASK_TAG = 'project_task_tag',
  TASK = 'task',
}

export enum RoundType {
  CEIL = 'ceil',
  ROUND = 'round',
  FLOOR = 'floor',
}

export enum ViewDate {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ALL = 'all',
}

export enum PlanCode {
  FREE = 'FREE',
  STANDARD = 'STANDARD',
  ENTERPRISE = 'ENTERPRISE',
}

export enum PaymentCycle {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum SettlementService {
  STRIPE = 'STRIPE',
}

export enum SettlementStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

// Card brand. Can be amex, diners, discover, eftpos_au, jcb, mastercard, unionpay, visa, or unknown.
export enum CardBrand {
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  EFTPOS_AU = 'eftpos_au',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
  UNKNOWN = 'unknown',
}

export enum StripeEventType {
  CHECKOUT_SESSION_COMPLETED = 'checkout.session.completed',
  PAYMENT_SUCCEEDED = 'invoice.payment_succeeded',
  PAYMENT_FAILED = 'invoice.payment_failed',
}

export enum ForceSsoType {
  NONE = 'NONE',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export enum InquiryCategory {
  REQUEST = 'request',
  BUG_REPORT = 'bug_report',
  OTHER = 'other',
}
export const InquiryCategoryName = {
  [InquiryCategory.REQUEST]: 'ご要望',
  [InquiryCategory.BUG_REPORT]: '不具合報告',
  [InquiryCategory.OTHER]: 'その他',
};

export enum ReportSummaryPercentagesSortColumn {
  TITLE = 'title',
  DURATION = 'duration',
  PERCENTAGE = 'percentage',
}

export enum ReportDetailsSortColumn {
  VIEW_DATE = 'view_date',
  PROJECT_NAME = 'project_name',
  PROJECT_TASK_TAG_NAME = 'project_task_tag_name',
  TASK_NAME = 'task_name',
  USER_NAME = 'user_name',
  DURATION = 'duration',
}
