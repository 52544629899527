import { NotificationSuccess } from "@/components/NotificationSuccess";
import { useProjectMembers } from "@/hooks/features/useProjectMembers";
import { useProjectMembersQuery } from "@/hooks/queries/useProjectMembersQuery";
import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { localizedYup } from "@/utils/localized-yup";
import {
  Avatar,
  Button,
  Flex,
  Group,
  Modal,
  MultiSelect,
  SelectItem,
  Space,
  Text,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useRouter } from "next/router";
import { forwardRef, useCallback, useState } from "react";

type Props = {
  opened: boolean;
  onClose: () => void;
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  email: string;
}

interface FormProps {
  teamMemberIds: string[];
}

const schema = localizedYup.object({
  teamMemberIds: localizedYup.array().min(1),
});

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />
        <div>
          <Text>{label}</Text>
          <Text size="sm" color="dimmed">
            最終作業日時 {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export const filterMembers = (
  value: string,
  selected: boolean,
  item: SelectItem
) =>
  !selected &&
  (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
    item.description.toLowerCase().includes(value.toLowerCase().trim()) ||
    item.email.toLowerCase().includes(value.toLowerCase().trim()));

export const ModalAddProjectMember = (props: Props): JSX.Element => {
  const router = useRouter();
  const projectId = router.query.projectId as string;
  const [loading, setLoading] = useState(false);
  const [isSelectOpened, setIsSelectOpened] = useState(false);
  const { teamMemberList } = useTeamMembersQuery();
  const { addProjectMember } = useProjectMembers(projectId);
  const { projectMembers } = useProjectMembersQuery(projectId);

  const onDropdown = useCallback(
    (opened: boolean) => () => setIsSelectOpened(opened),
    []
  );

  const form = useForm<FormProps>({
    initialValues: { teamMemberIds: [] },
    validate: yupResolver(schema),
  });

  const onSubmit = form.onSubmit(async (values) => {
    try {
      setLoading(true);
      await addProjectMember(values.teamMemberIds);
      form.reset();
      NotificationSuccess({ message: "プロジェクトメンバーを追加しました" });
      props.onClose();
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal
      centered
      opened={props.opened}
      onClose={props.onClose}
      title="メンバー追加"
      size={480}
      closeOnClickOutside={true}
    >
      <form onSubmit={onSubmit}>
        <Text ta={"center"}>メンバーを追加します</Text>
        <Space h={"xl"}></Space>
        <MultiSelect
          label="メンバー名"
          placeholder="メンバーを選択してください"
          required
          itemComponent={SelectItem}
          data={teamMemberList
            .filter((teamMember) =>
              projectMembers.every(
                (projectMember) =>
                  projectMember.teamMemberId !== teamMember.raw.id
              )
            )
            .map((teamMember) => {
              return {
                image: teamMember.image,
                label: teamMember.fullName,
                value: teamMember.raw.id,
                description: teamMember.description,
                email: teamMember.raw.user.email,
              };
            })}
          searchable
          nothingFound="メンバーが見つかりません"
          dropdownPosition="bottom"
          maxDropdownHeight={320}
          onDropdownOpen={onDropdown(true)}
          onDropdownClose={onDropdown(false)}
          filter={filterMembers}
          {...form.getInputProps("teamMemberIds")}
        />
        {isSelectOpened && <Space h={320} />}
        <Space h={48}></Space>
        <Flex justify={"center"}>
          <Button type="submit" loading={loading} disabled={loading}>
            追加
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
