import { ActionIcon } from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { IconClock } from "@tabler/icons";
import { useCallback, useRef } from "react";

export const CustomTimeInput = ({ ...props }: any): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => ref.current?.showPicker(), []);

  return (
    <TimeInput
      {...props}
      ref={ref}
      rightSection={
        <ActionIcon onClick={onClick}>
          <IconClock size="19px" stroke={1.0} />
        </ActionIcon>
      }
      maw={400}
      mx="auto"
    />
  );
};
