import {
  ApplicationError,
  ConflictError,
  ForbiddenError,
  UnauthorizedError,
} from "@/libs/api/errors";
import { logger } from "@/libs/utils/logger";
import { messages } from "@/utils/messages";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import Error from "../Error";
import { NotificationAlert } from "../NotificationAlert";
import { ErrorCode } from "@/libs/api/generated/codes";
import { useAuth } from "@/hooks/features/useAuth";

const AsyncErrorHandlingMiddleware = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const router = useRouter();
  const { signOut } = useAuth();
  const [isError, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(ErrorCode.Unknown);

  const errorHandler = useCallback(
    async (error: Error) => {
      if (error instanceof ApplicationError) {
        if (error instanceof UnauthorizedError) {
          logger.info("未認証のためログイン画面を表示");
          // 認証切れは原則として発生しないはず
          signOut();
          return;
        }

        if (error instanceof ForbiddenError) {
          if (error.code === ErrorCode.NotAllowedIpAddress) {
            setError(true);
            setErrorCode(ErrorCode.NotAllowedIpAddress);
          } else {
            NotificationAlert({ message: messages.common.forbidden });
            return;
          }
        }

        if (error instanceof ConflictError) {
          NotificationAlert({ message: messages.common.conflict });
          return;
        }
      }

      if (error.message == "Network request failed") {
        NotificationAlert({ message: messages.common.networkError });
        return;
      }

      // システムエラー
      logger.error("AsyncErrorHandler: システムエラー発生", error);
      setError(true);
    },
    [setError]
  );

  const onUnhandledRejection = useCallback(
    (event: PromiseRejectionEvent): void => {
      event.promise.catch((error) => {
        logger.info("onUnhandledRejection", { error });
        void errorHandler(error);
      });
    },
    [errorHandler]
  );

  useEffect(() => {
    logger.debug("エラーハンドリングを有効化");
    window.addEventListener("unhandledrejection", onUnhandledRejection);
    return () => {
      window.removeEventListener("unhandledrejection", onUnhandledRejection);
    };
  }, [onUnhandledRejection]);

  if (isError) {
    return <Error errorCode={errorCode} />;
  }

  return <>{children}</>;
};

export default AsyncErrorHandlingMiddleware;
