import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: any;
  jsonb: any;
  timestamptz: any;
};

export type AcceptTeamMemberInvitationInput = {
  code: Scalars["String"];
};

export type AcceptTeamMemberInvitationResponse = {
  teamId: Scalars["String"];
  teamMemberId: Scalars["String"];
};

/** columns and relationships of "activities" */
export type Activities = {
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  /** An object relationship */
  task?: Maybe<Tasks>;
  taskId?: Maybe<Scalars["String"]>;
  teamId: Scalars["String"];
  /** An object relationship */
  teamMember?: Maybe<TeamMembers>;
  teamMemberId: Scalars["String"];
  type: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
  /** An object relationship */
  workTracking?: Maybe<WorkTrackings>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "activities" */
export type ActivitiesAggregate = {
  aggregate?: Maybe<ActivitiesAggregateFields>;
  nodes: Array<Activities>;
};

export type ActivitiesAggregateBoolExp = {
  count?: InputMaybe<ActivitiesAggregateBoolExpCount>;
};

/** aggregate fields of "activities" */
export type ActivitiesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<ActivitiesMaxFields>;
  min?: Maybe<ActivitiesMinFields>;
};

/** aggregate fields of "activities" */
export type ActivitiesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActivitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "activities" */
export type ActivitiesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ActivitiesMaxOrderBy>;
  min?: InputMaybe<ActivitiesMinOrderBy>;
};

/** input type for inserting array relation for remote table "activities" */
export type ActivitiesArrRelInsertInput = {
  data: Array<ActivitiesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ActivitiesOnConflict>;
};

/** Boolean expression to filter rows from the table "activities". All fields are combined with a logical 'AND'. */
export type ActivitiesBoolExp = {
  _and?: InputMaybe<Array<ActivitiesBoolExp>>;
  _not?: InputMaybe<ActivitiesBoolExp>;
  _or?: InputMaybe<Array<ActivitiesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  task?: InputMaybe<TasksBoolExp>;
  taskId?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMember?: InputMaybe<TeamMembersBoolExp>;
  teamMemberId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  workTracking?: InputMaybe<WorkTrackingsBoolExp>;
  workTrackingId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "activities" */
export enum ActivitiesConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_7f4004429f731ffb9c88eb486a8 = "PK_7f4004429f731ffb9c88eb486a8",
}

/** input type for inserting data into table "activities" */
export type ActivitiesInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  task?: InputMaybe<TasksObjRelInsertInput>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMember?: InputMaybe<TeamMembersObjRelInsertInput>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTracking?: InputMaybe<WorkTrackingsObjRelInsertInput>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type ActivitiesMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "activities" */
export type ActivitiesMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ActivitiesMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  taskId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "activities" */
export type ActivitiesMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "activities" */
export type ActivitiesMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Activities>;
};

/** on_conflict condition type for table "activities" */
export type ActivitiesOnConflict = {
  constraint: ActivitiesConstraint;
  updateColumns?: Array<ActivitiesUpdateColumn>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

/** Ordering options when selecting data from "activities". */
export type ActivitiesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task?: InputMaybe<TasksOrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMember?: InputMaybe<TeamMembersOrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTracking?: InputMaybe<WorkTrackingsOrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: activities */
export type ActivitiesPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "activities" */
export enum ActivitiesSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "taskId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

/** input type for updating data in table "activities" */
export type ActivitiesSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "activities" */
export type ActivitiesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ActivitiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActivitiesStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "activities" */
export enum ActivitiesUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  TaskId = "taskId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

export type ActivitiesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActivitiesSetInput>;
  /** filter the rows which have to be updated */
  where: ActivitiesBoolExp;
};

export type ApproveGitHubInstallationInput = {
  code: Scalars["String"];
  installationId: Scalars["Float"];
};

export type ApproveGitHubInstallationResult = {
  isSettingCompleted: Scalars["Boolean"];
  organizationName?: Maybe<Scalars["String"]>;
};

export type ArchiveProjectInput = {
  projectId: Scalars["String"];
};

export type AuthenticateTwoFaInput = {
  twoFACode: Scalars["String"];
};

export type BacklogIssuesInput = {
  backlogUrl: Scalars["String"];
  pages: Scalars["Int"];
  projectId: Scalars["Int"];
  target?: InputMaybe<Scalars["String"]>;
};

export type BacklogIssuesResponse = {
  id: Scalars["Float"];
  name: Scalars["String"];
};

export type BacklogProjectsInput = {
  backlogUrl: Scalars["String"];
};

export type BacklogProjectsResponse = {
  id: Scalars["Float"];
  name: Scalars["String"];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

export type BooleanResult = {
  result: Scalars["Boolean"];
};

/** columns and relationships of "contracts" */
export type Contracts = {
  accountQuantity: Scalars["Int"];
  contractedAt: Scalars["timestamptz"];
  createdAt: Scalars["timestamptz"];
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  /** An object relationship */
  plan: Plans;
  planId: Scalars["String"];
  settlementFailedAt?: Maybe<Scalars["timestamptz"]>;
  settlementService: Scalars["String"];
  /** An object relationship */
  team: Teams;
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "contracts" */
export type ContractsAggregate = {
  aggregate?: Maybe<ContractsAggregateFields>;
  nodes: Array<Contracts>;
};

export type ContractsAggregateBoolExp = {
  count?: InputMaybe<ContractsAggregateBoolExpCount>;
};

/** aggregate fields of "contracts" */
export type ContractsAggregateFields = {
  avg?: Maybe<ContractsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<ContractsMaxFields>;
  min?: Maybe<ContractsMinFields>;
  stddev?: Maybe<ContractsStddevFields>;
  stddevPop?: Maybe<ContractsStddevPopFields>;
  stddevSamp?: Maybe<ContractsStddevSampFields>;
  sum?: Maybe<ContractsSumFields>;
  varPop?: Maybe<ContractsVarPopFields>;
  varSamp?: Maybe<ContractsVarSampFields>;
  variance?: Maybe<ContractsVarianceFields>;
};

/** aggregate fields of "contracts" */
export type ContractsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContractsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "contracts" */
export type ContractsAggregateOrderBy = {
  avg?: InputMaybe<ContractsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContractsMaxOrderBy>;
  min?: InputMaybe<ContractsMinOrderBy>;
  stddev?: InputMaybe<ContractsStddevOrderBy>;
  stddevPop?: InputMaybe<ContractsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ContractsStddevSampOrderBy>;
  sum?: InputMaybe<ContractsSumOrderBy>;
  varPop?: InputMaybe<ContractsVarPopOrderBy>;
  varSamp?: InputMaybe<ContractsVarSampOrderBy>;
  variance?: InputMaybe<ContractsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "contracts" */
export type ContractsArrRelInsertInput = {
  data: Array<ContractsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ContractsOnConflict>;
};

/** aggregate avg on columns */
export type ContractsAvgFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "contracts" */
export type ContractsAvgOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "contracts". All fields are combined with a logical 'AND'. */
export type ContractsBoolExp = {
  _and?: InputMaybe<Array<ContractsBoolExp>>;
  _not?: InputMaybe<ContractsBoolExp>;
  _or?: InputMaybe<Array<ContractsBoolExp>>;
  accountQuantity?: InputMaybe<IntComparisonExp>;
  contractedAt?: InputMaybe<TimestamptzComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  finishedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  plan?: InputMaybe<PlansBoolExp>;
  planId?: InputMaybe<StringComparisonExp>;
  settlementFailedAt?: InputMaybe<TimestamptzComparisonExp>;
  settlementService?: InputMaybe<StringComparisonExp>;
  team?: InputMaybe<TeamsBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "contracts" */
export enum ContractsConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_2c7b8f3a7b1acdd49497d83d0fb = "PK_2c7b8f3a7b1acdd49497d83d0fb",
}

/** input type for incrementing numeric columns in table "contracts" */
export type ContractsIncInput = {
  accountQuantity?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "contracts" */
export type ContractsInsertInput = {
  accountQuantity?: InputMaybe<Scalars["Int"]>;
  contractedAt?: InputMaybe<Scalars["timestamptz"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  plan?: InputMaybe<PlansObjRelInsertInput>;
  planId?: InputMaybe<Scalars["String"]>;
  settlementFailedAt?: InputMaybe<Scalars["timestamptz"]>;
  settlementService?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<TeamsObjRelInsertInput>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type ContractsMaxFields = {
  accountQuantity?: Maybe<Scalars["Int"]>;
  contractedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  planId?: Maybe<Scalars["String"]>;
  settlementFailedAt?: Maybe<Scalars["timestamptz"]>;
  settlementService?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "contracts" */
export type ContractsMaxOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
  contractedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  settlementFailedAt?: InputMaybe<OrderBy>;
  settlementService?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContractsMinFields = {
  accountQuantity?: Maybe<Scalars["Int"]>;
  contractedAt?: Maybe<Scalars["timestamptz"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  planId?: Maybe<Scalars["String"]>;
  settlementFailedAt?: Maybe<Scalars["timestamptz"]>;
  settlementService?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "contracts" */
export type ContractsMinOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
  contractedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  planId?: InputMaybe<OrderBy>;
  settlementFailedAt?: InputMaybe<OrderBy>;
  settlementService?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "contracts" */
export type ContractsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Contracts>;
};

/** input type for inserting object relation for remote table "contracts" */
export type ContractsObjRelInsertInput = {
  data: ContractsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ContractsOnConflict>;
};

/** on_conflict condition type for table "contracts" */
export type ContractsOnConflict = {
  constraint: ContractsConstraint;
  updateColumns?: Array<ContractsUpdateColumn>;
  where?: InputMaybe<ContractsBoolExp>;
};

/** Ordering options when selecting data from "contracts". */
export type ContractsOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
  contractedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  plan?: InputMaybe<PlansOrderBy>;
  planId?: InputMaybe<OrderBy>;
  settlementFailedAt?: InputMaybe<OrderBy>;
  settlementService?: InputMaybe<OrderBy>;
  team?: InputMaybe<TeamsOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: contracts */
export type ContractsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "contracts" */
export enum ContractsSelectColumn {
  /** column name */
  AccountQuantity = "accountQuantity",
  /** column name */
  ContractedAt = "contractedAt",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  PlanId = "planId",
  /** column name */
  SettlementFailedAt = "settlementFailedAt",
  /** column name */
  SettlementService = "settlementService",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "contracts" */
export type ContractsSetInput = {
  accountQuantity?: InputMaybe<Scalars["Int"]>;
  contractedAt?: InputMaybe<Scalars["timestamptz"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  planId?: InputMaybe<Scalars["String"]>;
  settlementFailedAt?: InputMaybe<Scalars["timestamptz"]>;
  settlementService?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type ContractsStddevFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "contracts" */
export type ContractsStddevOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ContractsStddevPopFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "contracts" */
export type ContractsStddevPopOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ContractsStddevSampFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "contracts" */
export type ContractsStddevSampOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "contracts" */
export type ContractsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ContractsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContractsStreamCursorValueInput = {
  accountQuantity?: InputMaybe<Scalars["Int"]>;
  contractedAt?: InputMaybe<Scalars["timestamptz"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  planId?: InputMaybe<Scalars["String"]>;
  settlementFailedAt?: InputMaybe<Scalars["timestamptz"]>;
  settlementService?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type ContractsSumFields = {
  accountQuantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "contracts" */
export type ContractsSumOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

/** update columns of table "contracts" */
export enum ContractsUpdateColumn {
  /** column name */
  AccountQuantity = "accountQuantity",
  /** column name */
  ContractedAt = "contractedAt",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  PlanId = "planId",
  /** column name */
  SettlementFailedAt = "settlementFailedAt",
  /** column name */
  SettlementService = "settlementService",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type ContractsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ContractsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContractsSetInput>;
  /** filter the rows which have to be updated */
  where: ContractsBoolExp;
};

/** aggregate varPop on columns */
export type ContractsVarPopFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "contracts" */
export type ContractsVarPopOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ContractsVarSampFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "contracts" */
export type ContractsVarSampOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ContractsVarianceFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "contracts" */
export type ContractsVarianceOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
};

export type CreateBacklogAuthzUrlInput = {
  backlogUrl: Scalars["String"];
};

export type CreateBacklogAuthzUrlResponse = {
  backlogAuthzUrl: Scalars["String"];
};

export type CreateFreeeAuthzUrlResponse = {
  freeeAuthzUrl: Scalars["String"];
};

export type CreateGoogleCalendarAuthzUrlResponse = {
  url: Scalars["String"];
};

export type CreateGoogleLoginAuthzUrlInput = {
  inflowRouteCode?: InputMaybe<Scalars["String"]>;
};

export type CreateGoogleLoginAuthzUrlResponse = {
  url: Scalars["String"];
};

export type CreateMicrosoftLoginAuthzUrlInput = {
  inflowRouteCode?: InputMaybe<Scalars["String"]>;
};

export type CreateMicrosoftLoginAuthzUrlResponse = {
  url: Scalars["String"];
};

export type CreateProjectInput = {
  name: Scalars["String"];
};

export type CreateSubscriptionSessionInput = {
  isTrial: Scalars["Boolean"];
  planId: Scalars["String"];
  quantity: Scalars["Float"];
};

export type CreateTeamInput = {
  enabledBlurBackground: Scalars["Boolean"];
  enabledSnapshot: Scalars["Boolean"];
  imageFilePath?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateUserInput = {
  firstName: Scalars["String"];
  invitationCode?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  password: Scalars["String"];
  token?: InputMaybe<Scalars["String"]>;
};

export type Credentials = {
  accessKeyId: Scalars["String"];
  expiration: Scalars["String"];
  secretAccessKey: Scalars["String"];
  sessionToken: Scalars["String"];
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** columns and relationships of "enquete_answer" */
export type EnqueteAnswer = {
  answer: Scalars["jsonb"];
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  teamId?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
  userId?: Maybe<Scalars["String"]>;
  version: Scalars["String"];
};

/** columns and relationships of "enquete_answer" */
export type EnqueteAnswerAnswerArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "enquete_answer" */
export type EnqueteAnswerAggregate = {
  aggregate?: Maybe<EnqueteAnswerAggregateFields>;
  nodes: Array<EnqueteAnswer>;
};

/** aggregate fields of "enquete_answer" */
export type EnqueteAnswerAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<EnqueteAnswerMaxFields>;
  min?: Maybe<EnqueteAnswerMinFields>;
};

/** aggregate fields of "enquete_answer" */
export type EnqueteAnswerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnqueteAnswerSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EnqueteAnswerAppendInput = {
  answer?: InputMaybe<Scalars["jsonb"]>;
};

/** Boolean expression to filter rows from the table "enquete_answer". All fields are combined with a logical 'AND'. */
export type EnqueteAnswerBoolExp = {
  _and?: InputMaybe<Array<EnqueteAnswerBoolExp>>;
  _not?: InputMaybe<EnqueteAnswerBoolExp>;
  _or?: InputMaybe<Array<EnqueteAnswerBoolExp>>;
  answer?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "enquete_answer" */
export enum EnqueteAnswerConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_99f02f9fb2d18f6ce4e8c32b410 = "PK_99f02f9fb2d18f6ce4e8c32b410",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EnqueteAnswerDeleteAtPathInput = {
  answer?: InputMaybe<Array<Scalars["String"]>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type EnqueteAnswerDeleteElemInput = {
  answer?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EnqueteAnswerDeleteKeyInput = {
  answer?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "enquete_answer" */
export type EnqueteAnswerInsertInput = {
  answer?: InputMaybe<Scalars["jsonb"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  userId?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type EnqueteAnswerMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  userId?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type EnqueteAnswerMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  userId?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "enquete_answer" */
export type EnqueteAnswerMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<EnqueteAnswer>;
};

/** on_conflict condition type for table "enquete_answer" */
export type EnqueteAnswerOnConflict = {
  constraint: EnqueteAnswerConstraint;
  updateColumns?: Array<EnqueteAnswerUpdateColumn>;
  where?: InputMaybe<EnqueteAnswerBoolExp>;
};

/** Ordering options when selecting data from "enquete_answer". */
export type EnqueteAnswerOrderBy = {
  answer?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: enquete_answer */
export type EnqueteAnswerPkColumnsInput = {
  id: Scalars["String"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EnqueteAnswerPrependInput = {
  answer?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "enquete_answer" */
export enum EnqueteAnswerSelectColumn {
  /** column name */
  Answer = "answer",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  TeamId = "teamId",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "enquete_answer" */
export type EnqueteAnswerSetInput = {
  answer?: InputMaybe<Scalars["jsonb"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  userId?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "enquete_answer" */
export type EnqueteAnswerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnqueteAnswerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnqueteAnswerStreamCursorValueInput = {
  answer?: InputMaybe<Scalars["jsonb"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  userId?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "enquete_answer" */
export enum EnqueteAnswerUpdateColumn {
  /** column name */
  Answer = "answer",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  TeamId = "teamId",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
  /** column name */
  Version = "version",
}

export type EnqueteAnswerUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EnqueteAnswerAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<EnqueteAnswerDeleteAtPathInput>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _deleteElem?: InputMaybe<EnqueteAnswerDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<EnqueteAnswerDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EnqueteAnswerPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EnqueteAnswerSetInput>;
  /** filter the rows which have to be updated */
  where: EnqueteAnswerBoolExp;
};

export type Event = {
  summary: Scalars["String"];
};

export type ExportTeamReportInput = {
  from: Scalars["String"];
  projectIds?: InputMaybe<Array<Scalars["String"]>>;
  roundMinutes: Scalars["Float"];
  roundType: Scalars["String"];
  teamMemberIds?: InputMaybe<Array<Scalars["String"]>>;
  to: Scalars["String"];
};

export type ExportWorksInput = {
  month: Scalars["Int"];
  teamMemberId?: InputMaybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export type FinishWorkBreak = {
  finishedAt: Scalars["String"];
  startedAt: Scalars["String"];
};

export type FinishWorkInput = {
  breaks: Array<FinishWorkBreak>;
  finishedAt: Scalars["String"];
  startedAt: Scalars["String"];
  tasks: Array<FinishWorkTask>;
};

export type FinishWorkTask = {
  taskId: Scalars["String"];
  totalMinutes: Scalars["Float"];
};

export type FreeeEmployeesInput = {
  companyId: Scalars["Int"];
};

export type FreeeEmployeesResponse = {
  email: Scalars["String"];
  employeeId: Scalars["Int"];
  employeeName: Scalars["String"];
};

export type GetIpAddressResponse = {
  ipAddress: Scalars["String"];
};

export type GithubAuthzUrl = {
  state: Scalars["String"];
  url: Scalars["String"];
};

export type GithubIssue = {
  id: Scalars["Float"];
  name: Scalars["String"];
};

export type GithubIssuesInput = {
  organizationName: Scalars["String"];
  page: Scalars["Float"];
  repositoryName: Scalars["String"];
  target?: InputMaybe<Scalars["String"]>;
};

export type GithubRepositoriesInput = {
  organizationName: Scalars["String"];
  page: Scalars["Float"];
};

export type GithubRepository = {
  id: Scalars["Float"];
  name: Scalars["String"];
};

export type GoogleCalendarEventInput = {
  maxResults?: InputMaybe<Scalars["Int"]>;
  pageToken?: InputMaybe<Scalars["String"]>;
};

export type GoogleCalendarEventResponse = {
  events: Array<Event>;
  nextPageToken?: Maybe<Scalars["String"]>;
};

export type InitialLinkFreeeEmployeesInput = {
  companyId: Scalars["Int"];
  relations: Array<TeamMemberEmployeeRelation>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]>;
  _eq?: InputMaybe<Scalars["jsonb"]>;
  _gt?: InputMaybe<Scalars["jsonb"]>;
  _gte?: InputMaybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["jsonb"]>;
  _lte?: InputMaybe<Scalars["jsonb"]>;
  _neq?: InputMaybe<Scalars["jsonb"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]>>;
};

export type LinkBacklogInput = {
  backlogUrl: Scalars["String"];
  code: Scalars["String"];
  state: Scalars["String"];
};

export type LinkFreeeEmployeesInput = {
  companyId: Scalars["Int"];
  relations: Array<TeamMemberEmployeeRelation>;
};

export type LinkGithubInput = {
  code: Scalars["String"];
  installationId?: InputMaybe<Scalars["Float"]>;
  state: Scalars["String"];
};

export type LinkGithubResult = {
  isSettingCompleted: Scalars["Boolean"];
  organizationName?: Maybe<Scalars["String"]>;
};

export type LinkGoogleCalendarInput = {
  authorizeCode: Scalars["String"];
  state: Scalars["String"];
};

export type LoginByGoogleInput = {
  authorizeCode: Scalars["String"];
  state: Scalars["String"];
};

export type LoginByMicrosoftInput = {
  authorizeCode: Scalars["String"];
  state: Scalars["String"];
};

export type LoginByRefreshTokenInput = {
  refreshToken: Scalars["String"];
  teamId?: InputMaybe<Scalars["String"]>;
};

export type LoginInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  teamId?: InputMaybe<Scalars["String"]>;
};

export type LoginResult = {
  accessToken: Scalars["String"];
  belongingTeams: Array<Team>;
  credentials: Credentials;
  refreshToken: Scalars["String"];
};

export type NextInvoice = {
  amount: Scalars["Float"];
  isTrial?: Maybe<Scalars["Boolean"]>;
  settleDate?: Maybe<Scalars["String"]>;
};

export type NextInvoicePreview = {
  amount: Scalars["Float"];
  isTrial: Scalars["Boolean"];
  proratedNumberOfDays?: Maybe<Scalars["Float"]>;
  prorationAmount?: Maybe<Scalars["Float"]>;
  settleDate?: Maybe<Scalars["String"]>;
};

export type NextInvoicePreviewInput = {
  quantity: Scalars["Float"];
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = "ASC",
  /** in ascending order, nulls first */
  AscNullsFirst = "ASC_NULLS_FIRST",
  /** in ascending order, nulls last */
  AscNullsLast = "ASC_NULLS_LAST",
  /** in descending order, nulls first */
  Desc = "DESC",
  /** in descending order, nulls first */
  DescNullsFirst = "DESC_NULLS_FIRST",
  /** in descending order, nulls last */
  DescNullsLast = "DESC_NULLS_LAST",
}

/** columns and relationships of "plans" */
export type Plans = {
  code: Scalars["String"];
  /** An array relationship */
  contracts: Array<Contracts>;
  /** An aggregate relationship */
  contractsAggregate: ContractsAggregate;
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  name: Scalars["String"];
  paymentCycle?: Maybe<Scalars["String"]>;
  price: Scalars["Int"];
  stripePriceId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["timestamptz"];
};

/** columns and relationships of "plans" */
export type PlansContractsArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

/** columns and relationships of "plans" */
export type PlansContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

/** aggregated selection of "plans" */
export type PlansAggregate = {
  aggregate?: Maybe<PlansAggregateFields>;
  nodes: Array<Plans>;
};

/** aggregate fields of "plans" */
export type PlansAggregateFields = {
  avg?: Maybe<PlansAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<PlansMaxFields>;
  min?: Maybe<PlansMinFields>;
  stddev?: Maybe<PlansStddevFields>;
  stddevPop?: Maybe<PlansStddevPopFields>;
  stddevSamp?: Maybe<PlansStddevSampFields>;
  sum?: Maybe<PlansSumFields>;
  varPop?: Maybe<PlansVarPopFields>;
  varSamp?: Maybe<PlansVarSampFields>;
  variance?: Maybe<PlansVarianceFields>;
};

/** aggregate fields of "plans" */
export type PlansAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlansSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type PlansAvgFields = {
  price?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type PlansBoolExp = {
  _and?: InputMaybe<Array<PlansBoolExp>>;
  _not?: InputMaybe<PlansBoolExp>;
  _or?: InputMaybe<Array<PlansBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  contracts?: InputMaybe<ContractsBoolExp>;
  contractsAggregate?: InputMaybe<ContractsAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  paymentCycle?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  stripePriceId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "plans" */
export enum PlansConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_3720521a81c7c24fe9b7202ba61 = "PK_3720521a81c7c24fe9b7202ba61",
}

/** input type for incrementing numeric columns in table "plans" */
export type PlansIncInput = {
  price?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "plans" */
export type PlansInsertInput = {
  code?: InputMaybe<Scalars["String"]>;
  contracts?: InputMaybe<ContractsArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentCycle?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  stripePriceId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type PlansMaxFields = {
  code?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  paymentCycle?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Int"]>;
  stripePriceId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type PlansMinFields = {
  code?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  paymentCycle?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Int"]>;
  stripePriceId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "plans" */
export type PlansMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Plans>;
};

/** input type for inserting object relation for remote table "plans" */
export type PlansObjRelInsertInput = {
  data: PlansInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PlansOnConflict>;
};

/** on_conflict condition type for table "plans" */
export type PlansOnConflict = {
  constraint: PlansConstraint;
  updateColumns?: Array<PlansUpdateColumn>;
  where?: InputMaybe<PlansBoolExp>;
};

/** Ordering options when selecting data from "plans". */
export type PlansOrderBy = {
  code?: InputMaybe<OrderBy>;
  contractsAggregate?: InputMaybe<ContractsAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  paymentCycle?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  stripePriceId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: plans */
export type PlansPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "plans" */
export enum PlansSelectColumn {
  /** column name */
  Code = "code",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  PaymentCycle = "paymentCycle",
  /** column name */
  Price = "price",
  /** column name */
  StripePriceId = "stripePriceId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "plans" */
export type PlansSetInput = {
  code?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentCycle?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  stripePriceId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type PlansStddevFields = {
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevPop on columns */
export type PlansStddevPopFields = {
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevSamp on columns */
export type PlansStddevSampFields = {
  price?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "plans" */
export type PlansStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlansStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlansStreamCursorValueInput = {
  code?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentCycle?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Int"]>;
  stripePriceId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type PlansSumFields = {
  price?: Maybe<Scalars["Int"]>;
};

/** update columns of table "plans" */
export enum PlansUpdateColumn {
  /** column name */
  Code = "code",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  PaymentCycle = "paymentCycle",
  /** column name */
  Price = "price",
  /** column name */
  StripePriceId = "stripePriceId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type PlansUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlansIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlansSetInput>;
  /** filter the rows which have to be updated */
  where: PlansBoolExp;
};

/** aggregate varPop on columns */
export type PlansVarPopFields = {
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate varSamp on columns */
export type PlansVarSampFields = {
  price?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type PlansVarianceFields = {
  price?: Maybe<Scalars["Float"]>;
};

export type Project = {
  archived: Scalars["Boolean"];
  createdAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  projectId: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["String"];
};

/** columns and relationships of "project_alerts" */
export type ProjectAlerts = {
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  lastSentAt?: Maybe<Scalars["timestamptz"]>;
  name: Scalars["String"];
  /** An object relationship */
  project?: Maybe<Projects>;
  projectId: Scalars["String"];
  teamId: Scalars["String"];
  threshold: Scalars["Int"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "project_alerts" */
export type ProjectAlertsAggregate = {
  aggregate?: Maybe<ProjectAlertsAggregateFields>;
  nodes: Array<ProjectAlerts>;
};

export type ProjectAlertsAggregateBoolExp = {
  count?: InputMaybe<ProjectAlertsAggregateBoolExpCount>;
};

/** aggregate fields of "project_alerts" */
export type ProjectAlertsAggregateFields = {
  avg?: Maybe<ProjectAlertsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<ProjectAlertsMaxFields>;
  min?: Maybe<ProjectAlertsMinFields>;
  stddev?: Maybe<ProjectAlertsStddevFields>;
  stddevPop?: Maybe<ProjectAlertsStddevPopFields>;
  stddevSamp?: Maybe<ProjectAlertsStddevSampFields>;
  sum?: Maybe<ProjectAlertsSumFields>;
  varPop?: Maybe<ProjectAlertsVarPopFields>;
  varSamp?: Maybe<ProjectAlertsVarSampFields>;
  variance?: Maybe<ProjectAlertsVarianceFields>;
};

/** aggregate fields of "project_alerts" */
export type ProjectAlertsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "project_alerts" */
export type ProjectAlertsAggregateOrderBy = {
  avg?: InputMaybe<ProjectAlertsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProjectAlertsMaxOrderBy>;
  min?: InputMaybe<ProjectAlertsMinOrderBy>;
  stddev?: InputMaybe<ProjectAlertsStddevOrderBy>;
  stddevPop?: InputMaybe<ProjectAlertsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<ProjectAlertsStddevSampOrderBy>;
  sum?: InputMaybe<ProjectAlertsSumOrderBy>;
  varPop?: InputMaybe<ProjectAlertsVarPopOrderBy>;
  varSamp?: InputMaybe<ProjectAlertsVarSampOrderBy>;
  variance?: InputMaybe<ProjectAlertsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "project_alerts" */
export type ProjectAlertsArrRelInsertInput = {
  data: Array<ProjectAlertsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectAlertsOnConflict>;
};

/** aggregate avg on columns */
export type ProjectAlertsAvgFields = {
  threshold?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "project_alerts" */
export type ProjectAlertsAvgOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "project_alerts". All fields are combined with a logical 'AND'. */
export type ProjectAlertsBoolExp = {
  _and?: InputMaybe<Array<ProjectAlertsBoolExp>>;
  _not?: InputMaybe<ProjectAlertsBoolExp>;
  _or?: InputMaybe<Array<ProjectAlertsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastSentAt?: InputMaybe<TimestamptzComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  project?: InputMaybe<ProjectsBoolExp>;
  projectId?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  threshold?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "project_alerts" */
export enum ProjectAlertsConstraint {
  /** unique or primary key constraint on columns "project_id", "id", "team_id" */
  Idx_75c11e5640639aa6642c685a3f = "IDX_75c11e5640639aa6642c685a3f",
  /** unique or primary key constraint on columns "id" */
  PkE06b855d9840397c6038b2b16de = "PK_e06b855d9840397c6038b2b16de",
}

/** input type for incrementing numeric columns in table "project_alerts" */
export type ProjectAlertsIncInput = {
  threshold?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "project_alerts" */
export type ProjectAlertsInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastSentAt?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<ProjectsObjRelInsertInput>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  threshold?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type ProjectAlertsMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  lastSentAt?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  threshold?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "project_alerts" */
export type ProjectAlertsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSentAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  threshold?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectAlertsMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  lastSentAt?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  threshold?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "project_alerts" */
export type ProjectAlertsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSentAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  threshold?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "project_alerts" */
export type ProjectAlertsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectAlerts>;
};

/** on_conflict condition type for table "project_alerts" */
export type ProjectAlertsOnConflict = {
  constraint: ProjectAlertsConstraint;
  updateColumns?: Array<ProjectAlertsUpdateColumn>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

/** Ordering options when selecting data from "project_alerts". */
export type ProjectAlertsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSentAt?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  project?: InputMaybe<ProjectsOrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  threshold?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: project_alerts */
export type ProjectAlertsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "project_alerts" */
export enum ProjectAlertsSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  LastSentAt = "lastSentAt",
  /** column name */
  Name = "name",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  Threshold = "threshold",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "project_alerts" */
export type ProjectAlertsSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastSentAt?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  threshold?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type ProjectAlertsStddevFields = {
  threshold?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "project_alerts" */
export type ProjectAlertsStddevOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProjectAlertsStddevPopFields = {
  threshold?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "project_alerts" */
export type ProjectAlertsStddevPopOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProjectAlertsStddevSampFields = {
  threshold?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "project_alerts" */
export type ProjectAlertsStddevSampOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "project_alerts" */
export type ProjectAlertsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProjectAlertsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectAlertsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastSentAt?: InputMaybe<Scalars["timestamptz"]>;
  name?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  threshold?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type ProjectAlertsSumFields = {
  threshold?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "project_alerts" */
export type ProjectAlertsSumOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** update columns of table "project_alerts" */
export enum ProjectAlertsUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  LastSentAt = "lastSentAt",
  /** column name */
  Name = "name",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  Threshold = "threshold",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type ProjectAlertsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProjectAlertsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProjectAlertsSetInput>;
  /** filter the rows which have to be updated */
  where: ProjectAlertsBoolExp;
};

/** aggregate varPop on columns */
export type ProjectAlertsVarPopFields = {
  threshold?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "project_alerts" */
export type ProjectAlertsVarPopOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProjectAlertsVarSampFields = {
  threshold?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "project_alerts" */
export type ProjectAlertsVarSampOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProjectAlertsVarianceFields = {
  threshold?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "project_alerts" */
export type ProjectAlertsVarianceOrderBy = {
  threshold?: InputMaybe<OrderBy>;
};

/** columns and relationships of "project_creatable_count_view" */
export type ProjectCreatableCountView = {
  activeProjectCount?: Maybe<Scalars["Int"]>;
  creatableCount?: Maybe<Scalars["Int"]>;
  teamId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "project_creatable_count_view" */
export type ProjectCreatableCountViewAggregate = {
  aggregate?: Maybe<ProjectCreatableCountViewAggregateFields>;
  nodes: Array<ProjectCreatableCountView>;
};

/** aggregate fields of "project_creatable_count_view" */
export type ProjectCreatableCountViewAggregateFields = {
  avg?: Maybe<ProjectCreatableCountViewAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<ProjectCreatableCountViewMaxFields>;
  min?: Maybe<ProjectCreatableCountViewMinFields>;
  stddev?: Maybe<ProjectCreatableCountViewStddevFields>;
  stddevPop?: Maybe<ProjectCreatableCountViewStddevPopFields>;
  stddevSamp?: Maybe<ProjectCreatableCountViewStddevSampFields>;
  sum?: Maybe<ProjectCreatableCountViewSumFields>;
  varPop?: Maybe<ProjectCreatableCountViewVarPopFields>;
  varSamp?: Maybe<ProjectCreatableCountViewVarSampFields>;
  variance?: Maybe<ProjectCreatableCountViewVarianceFields>;
};

/** aggregate fields of "project_creatable_count_view" */
export type ProjectCreatableCountViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProjectCreatableCountViewSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type ProjectCreatableCountViewAvgFields = {
  activeProjectCount?: Maybe<Scalars["Float"]>;
  creatableCount?: Maybe<Scalars["Float"]>;
};

/**
 * Boolean expression to filter rows from the table "project_creatable_count_view".
 * All fields are combined with a logical 'AND'.
 */
export type ProjectCreatableCountViewBoolExp = {
  _and?: InputMaybe<Array<ProjectCreatableCountViewBoolExp>>;
  _not?: InputMaybe<ProjectCreatableCountViewBoolExp>;
  _or?: InputMaybe<Array<ProjectCreatableCountViewBoolExp>>;
  activeProjectCount?: InputMaybe<IntComparisonExp>;
  creatableCount?: InputMaybe<IntComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
};

/** input type for inserting data into table "project_creatable_count_view" */
export type ProjectCreatableCountViewInsertInput = {
  activeProjectCount?: InputMaybe<Scalars["Int"]>;
  creatableCount?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type ProjectCreatableCountViewMaxFields = {
  activeProjectCount?: Maybe<Scalars["Int"]>;
  creatableCount?: Maybe<Scalars["Int"]>;
  teamId?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type ProjectCreatableCountViewMinFields = {
  activeProjectCount?: Maybe<Scalars["Int"]>;
  creatableCount?: Maybe<Scalars["Int"]>;
  teamId?: Maybe<Scalars["String"]>;
};

/** input type for inserting object relation for remote table "project_creatable_count_view" */
export type ProjectCreatableCountViewObjRelInsertInput = {
  data: ProjectCreatableCountViewInsertInput;
};

/** Ordering options when selecting data from "project_creatable_count_view". */
export type ProjectCreatableCountViewOrderBy = {
  activeProjectCount?: InputMaybe<OrderBy>;
  creatableCount?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
};

/** select columns of table "project_creatable_count_view" */
export enum ProjectCreatableCountViewSelectColumn {
  /** column name */
  ActiveProjectCount = "activeProjectCount",
  /** column name */
  CreatableCount = "creatableCount",
  /** column name */
  TeamId = "teamId",
}

/** aggregate stddev on columns */
export type ProjectCreatableCountViewStddevFields = {
  activeProjectCount?: Maybe<Scalars["Float"]>;
  creatableCount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevPop on columns */
export type ProjectCreatableCountViewStddevPopFields = {
  activeProjectCount?: Maybe<Scalars["Float"]>;
  creatableCount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevSamp on columns */
export type ProjectCreatableCountViewStddevSampFields = {
  activeProjectCount?: Maybe<Scalars["Float"]>;
  creatableCount?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "project_creatable_count_view" */
export type ProjectCreatableCountViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProjectCreatableCountViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectCreatableCountViewStreamCursorValueInput = {
  activeProjectCount?: InputMaybe<Scalars["Int"]>;
  creatableCount?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type ProjectCreatableCountViewSumFields = {
  activeProjectCount?: Maybe<Scalars["Int"]>;
  creatableCount?: Maybe<Scalars["Int"]>;
};

/** aggregate varPop on columns */
export type ProjectCreatableCountViewVarPopFields = {
  activeProjectCount?: Maybe<Scalars["Float"]>;
  creatableCount?: Maybe<Scalars["Float"]>;
};

/** aggregate varSamp on columns */
export type ProjectCreatableCountViewVarSampFields = {
  activeProjectCount?: Maybe<Scalars["Float"]>;
  creatableCount?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type ProjectCreatableCountViewVarianceFields = {
  activeProjectCount?: Maybe<Scalars["Float"]>;
  creatableCount?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "project_members" */
export type ProjectMembers = {
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  /** An object relationship */
  project?: Maybe<Projects>;
  projectId: Scalars["String"];
  role: Scalars["String"];
  teamId: Scalars["String"];
  /** An object relationship */
  teamMember?: Maybe<TeamMembers>;
  teamMemberId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "project_members" */
export type ProjectMembersAggregate = {
  aggregate?: Maybe<ProjectMembersAggregateFields>;
  nodes: Array<ProjectMembers>;
};

export type ProjectMembersAggregateBoolExp = {
  count?: InputMaybe<ProjectMembersAggregateBoolExpCount>;
};

/** aggregate fields of "project_members" */
export type ProjectMembersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<ProjectMembersMaxFields>;
  min?: Maybe<ProjectMembersMinFields>;
};

/** aggregate fields of "project_members" */
export type ProjectMembersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "project_members" */
export type ProjectMembersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProjectMembersMaxOrderBy>;
  min?: InputMaybe<ProjectMembersMinOrderBy>;
};

/** input type for inserting array relation for remote table "project_members" */
export type ProjectMembersArrRelInsertInput = {
  data: Array<ProjectMembersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectMembersOnConflict>;
};

/** Boolean expression to filter rows from the table "project_members". All fields are combined with a logical 'AND'. */
export type ProjectMembersBoolExp = {
  _and?: InputMaybe<Array<ProjectMembersBoolExp>>;
  _not?: InputMaybe<ProjectMembersBoolExp>;
  _or?: InputMaybe<Array<ProjectMembersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  project?: InputMaybe<ProjectsBoolExp>;
  projectId?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMember?: InputMaybe<TeamMembersBoolExp>;
  teamMemberId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "project_members" */
export enum ProjectMembersConstraint {
  /** unique or primary key constraint on columns "id", "team_id" */
  Idx_02e6d3d221931f6874c644c68f = "IDX_02e6d3d221931f6874c644c68f",
  /** unique or primary key constraint on columns "project_id", "team_member_id", "team_id" */
  Idx_8472e4395f71abf0ed84f87490 = "IDX_8472e4395f71abf0ed84f87490",
  /** unique or primary key constraint on columns "id" */
  Pk_0b2f46f804be4aea9234c78bcc9 = "PK_0b2f46f804be4aea9234c78bcc9",
}

/** input type for inserting data into table "project_members" */
export type ProjectMembersInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<ProjectsObjRelInsertInput>;
  projectId?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMember?: InputMaybe<TeamMembersObjRelInsertInput>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type ProjectMembersMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "project_members" */
export type ProjectMembersMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectMembersMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "project_members" */
export type ProjectMembersMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "project_members" */
export type ProjectMembersMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectMembers>;
};

/** on_conflict condition type for table "project_members" */
export type ProjectMembersOnConflict = {
  constraint: ProjectMembersConstraint;
  updateColumns?: Array<ProjectMembersUpdateColumn>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

/** Ordering options when selecting data from "project_members". */
export type ProjectMembersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  project?: InputMaybe<ProjectsOrderBy>;
  projectId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMember?: InputMaybe<TeamMembersOrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: project_members */
export type ProjectMembersPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "project_members" */
export enum ProjectMembersSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  Role = "role",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "project_members" */
export type ProjectMembersSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "project_members" */
export type ProjectMembersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProjectMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectMembersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "project_members" */
export enum ProjectMembersUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  Role = "role",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type ProjectMembersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProjectMembersSetInput>;
  /** filter the rows which have to be updated */
  where: ProjectMembersBoolExp;
};

/** columns and relationships of "project_task_tags" */
export type ProjectTaskTags = {
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  order: Scalars["String"];
  /** An object relationship */
  project?: Maybe<Projects>;
  projectId: Scalars["String"];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** columns and relationships of "project_task_tags" */
export type ProjectTaskTagsTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** columns and relationships of "project_task_tags" */
export type ProjectTaskTagsTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** aggregated selection of "project_task_tags" */
export type ProjectTaskTagsAggregate = {
  aggregate?: Maybe<ProjectTaskTagsAggregateFields>;
  nodes: Array<ProjectTaskTags>;
};

export type ProjectTaskTagsAggregateBoolExp = {
  count?: InputMaybe<ProjectTaskTagsAggregateBoolExpCount>;
};

/** aggregate fields of "project_task_tags" */
export type ProjectTaskTagsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<ProjectTaskTagsMaxFields>;
  min?: Maybe<ProjectTaskTagsMinFields>;
};

/** aggregate fields of "project_task_tags" */
export type ProjectTaskTagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "project_task_tags" */
export type ProjectTaskTagsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProjectTaskTagsMaxOrderBy>;
  min?: InputMaybe<ProjectTaskTagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "project_task_tags" */
export type ProjectTaskTagsArrRelInsertInput = {
  data: Array<ProjectTaskTagsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectTaskTagsOnConflict>;
};

/** Boolean expression to filter rows from the table "project_task_tags". All fields are combined with a logical 'AND'. */
export type ProjectTaskTagsBoolExp = {
  _and?: InputMaybe<Array<ProjectTaskTagsBoolExp>>;
  _not?: InputMaybe<ProjectTaskTagsBoolExp>;
  _or?: InputMaybe<Array<ProjectTaskTagsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<StringComparisonExp>;
  project?: InputMaybe<ProjectsBoolExp>;
  projectId?: InputMaybe<StringComparisonExp>;
  tasks?: InputMaybe<TasksBoolExp>;
  tasksAggregate?: InputMaybe<TasksAggregateBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "project_task_tags" */
export enum ProjectTaskTagsConstraint {
  /** unique or primary key constraint on columns "project_id", "id", "team_id" */
  Idx_4f752dbe1b5b7dfffeda7ab4de = "IDX_4f752dbe1b5b7dfffeda7ab4de",
  /** unique or primary key constraint on columns "id", "team_id" */
  Idx_96b4562f6f427d93fe5bdfb1ee = "IDX_96b4562f6f427d93fe5bdfb1ee",
  /** unique or primary key constraint on columns "id" */
  Pk_965ede015ad0a097f69885272ef = "PK_965ede015ad0a097f69885272ef",
}

/** input type for inserting data into table "project_task_tags" */
export type ProjectTaskTagsInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<ProjectsObjRelInsertInput>;
  projectId?: InputMaybe<Scalars["String"]>;
  tasks?: InputMaybe<TasksArrRelInsertInput>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type ProjectTaskTagsMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "project_task_tags" */
export type ProjectTaskTagsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectTaskTagsMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "project_task_tags" */
export type ProjectTaskTagsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "project_task_tags" */
export type ProjectTaskTagsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<ProjectTaskTags>;
};

/** input type for inserting object relation for remote table "project_task_tags" */
export type ProjectTaskTagsObjRelInsertInput = {
  data: ProjectTaskTagsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectTaskTagsOnConflict>;
};

/** on_conflict condition type for table "project_task_tags" */
export type ProjectTaskTagsOnConflict = {
  constraint: ProjectTaskTagsConstraint;
  updateColumns?: Array<ProjectTaskTagsUpdateColumn>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

/** Ordering options when selecting data from "project_task_tags". */
export type ProjectTaskTagsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  project?: InputMaybe<ProjectsOrderBy>;
  projectId?: InputMaybe<OrderBy>;
  tasksAggregate?: InputMaybe<TasksAggregateOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: project_task_tags */
export type ProjectTaskTagsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "project_task_tags" */
export enum ProjectTaskTagsSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "project_task_tags" */
export type ProjectTaskTagsSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "project_task_tags" */
export type ProjectTaskTagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProjectTaskTagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectTaskTagsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "project_task_tags" */
export enum ProjectTaskTagsUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type ProjectTaskTagsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProjectTaskTagsSetInput>;
  /** filter the rows which have to be updated */
  where: ProjectTaskTagsBoolExp;
};

/** columns and relationships of "projects" */
export type Projects = {
  archived: Scalars["Boolean"];
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  /** An array relationship */
  projectAlerts: Array<ProjectAlerts>;
  /** An aggregate relationship */
  projectAlertsAggregate: ProjectAlertsAggregate;
  /** An object relationship */
  projectCreatableCountView?: Maybe<ProjectCreatableCountView>;
  /** An array relationship */
  projectMembers: Array<ProjectMembers>;
  /** An aggregate relationship */
  projectMembersAggregate: ProjectMembersAggregate;
  /** An array relationship */
  projectTaskTags: Array<ProjectTaskTags>;
  /** An aggregate relationship */
  projectTaskTagsAggregate: ProjectTaskTagsAggregate;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  /** An object relationship */
  team: Teams;
  /** An array relationship */
  teamBacklogLinkageProjects: Array<TeamBacklogLinkageProjects>;
  /** An aggregate relationship */
  teamBacklogLinkageProjectsAggregate: TeamBacklogLinkageProjectsAggregate;
  /** An array relationship */
  teamGithubLinkageProjects: Array<TeamGithubLinkageProjects>;
  /** An aggregate relationship */
  teamGithubLinkageProjectsAggregate: TeamGithubLinkageProjectsAggregate;
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** columns and relationships of "projects" */
export type ProjectsProjectAlertsArgs = {
  distinctOn?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectAlertsOrderBy>>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsProjectAlertsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectAlertsOrderBy>>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsProjectMembersArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsProjectMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsProjectTaskTagsArgs = {
  distinctOn?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectTaskTagsOrderBy>>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsProjectTaskTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectTaskTagsOrderBy>>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsTeamBacklogLinkageProjectsArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsTeamBacklogLinkageProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsTeamGithubLinkageProjectsArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

/** columns and relationships of "projects" */
export type ProjectsTeamGithubLinkageProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

/** aggregated selection of "projects" */
export type ProjectsAggregate = {
  aggregate?: Maybe<ProjectsAggregateFields>;
  nodes: Array<Projects>;
};

export type ProjectsAggregateBoolExp = {
  bool_and?: InputMaybe<ProjectsAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<ProjectsAggregateBoolExpBool_Or>;
  count?: InputMaybe<ProjectsAggregateBoolExpCount>;
};

/** aggregate fields of "projects" */
export type ProjectsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<ProjectsMaxFields>;
  min?: Maybe<ProjectsMinFields>;
};

/** aggregate fields of "projects" */
export type ProjectsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "projects" */
export type ProjectsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ProjectsMaxOrderBy>;
  min?: InputMaybe<ProjectsMinOrderBy>;
};

/** input type for inserting array relation for remote table "projects" */
export type ProjectsArrRelInsertInput = {
  data: Array<ProjectsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectsOnConflict>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type ProjectsBoolExp = {
  _and?: InputMaybe<Array<ProjectsBoolExp>>;
  _not?: InputMaybe<ProjectsBoolExp>;
  _or?: InputMaybe<Array<ProjectsBoolExp>>;
  archived?: InputMaybe<BooleanComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  projectAlerts?: InputMaybe<ProjectAlertsBoolExp>;
  projectAlertsAggregate?: InputMaybe<ProjectAlertsAggregateBoolExp>;
  projectCreatableCountView?: InputMaybe<ProjectCreatableCountViewBoolExp>;
  projectMembers?: InputMaybe<ProjectMembersBoolExp>;
  projectMembersAggregate?: InputMaybe<ProjectMembersAggregateBoolExp>;
  projectTaskTags?: InputMaybe<ProjectTaskTagsBoolExp>;
  projectTaskTagsAggregate?: InputMaybe<ProjectTaskTagsAggregateBoolExp>;
  tasks?: InputMaybe<TasksBoolExp>;
  tasksAggregate?: InputMaybe<TasksAggregateBoolExp>;
  team?: InputMaybe<TeamsBoolExp>;
  teamBacklogLinkageProjects?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
  teamBacklogLinkageProjectsAggregate?: InputMaybe<TeamBacklogLinkageProjectsAggregateBoolExp>;
  teamGithubLinkageProjects?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
  teamGithubLinkageProjectsAggregate?: InputMaybe<TeamGithubLinkageProjectsAggregateBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "projects" */
export enum ProjectsConstraint {
  /** unique or primary key constraint on columns "id", "team_id" */
  Idx_0d270a413211766fe72678c501 = "IDX_0d270a413211766fe72678c501",
  /** unique or primary key constraint on columns "id" */
  Pk_6271df0a7aed1d6c0691ce6ac50 = "PK_6271df0a7aed1d6c0691ce6ac50",
}

/** input type for inserting data into table "projects" */
export type ProjectsInsertInput = {
  archived?: InputMaybe<Scalars["Boolean"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  projectAlerts?: InputMaybe<ProjectAlertsArrRelInsertInput>;
  projectCreatableCountView?: InputMaybe<ProjectCreatableCountViewObjRelInsertInput>;
  projectMembers?: InputMaybe<ProjectMembersArrRelInsertInput>;
  projectTaskTags?: InputMaybe<ProjectTaskTagsArrRelInsertInput>;
  tasks?: InputMaybe<TasksArrRelInsertInput>;
  team?: InputMaybe<TeamsObjRelInsertInput>;
  teamBacklogLinkageProjects?: InputMaybe<TeamBacklogLinkageProjectsArrRelInsertInput>;
  teamGithubLinkageProjects?: InputMaybe<TeamGithubLinkageProjectsArrRelInsertInput>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type ProjectsMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "projects" */
export type ProjectsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectsMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "projects" */
export type ProjectsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "projects" */
export type ProjectsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type ProjectsObjRelInsertInput = {
  data: ProjectsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectsOnConflict>;
};

/** on_conflict condition type for table "projects" */
export type ProjectsOnConflict = {
  constraint: ProjectsConstraint;
  updateColumns?: Array<ProjectsUpdateColumn>;
  where?: InputMaybe<ProjectsBoolExp>;
};

/** Ordering options when selecting data from "projects". */
export type ProjectsOrderBy = {
  archived?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  projectAlertsAggregate?: InputMaybe<ProjectAlertsAggregateOrderBy>;
  projectCreatableCountView?: InputMaybe<ProjectCreatableCountViewOrderBy>;
  projectMembersAggregate?: InputMaybe<ProjectMembersAggregateOrderBy>;
  projectTaskTagsAggregate?: InputMaybe<ProjectTaskTagsAggregateOrderBy>;
  tasksAggregate?: InputMaybe<TasksAggregateOrderBy>;
  team?: InputMaybe<TeamsOrderBy>;
  teamBacklogLinkageProjectsAggregate?: InputMaybe<TeamBacklogLinkageProjectsAggregateOrderBy>;
  teamGithubLinkageProjectsAggregate?: InputMaybe<TeamGithubLinkageProjectsAggregateOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: projects */
export type ProjectsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "projects" */
export enum ProjectsSelectColumn {
  /** column name */
  Archived = "archived",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** select "projectsAggregateBoolExpBool_andArgumentsColumns" columns of table "projects" */
export enum ProjectsSelectColumnProjectsAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Archived = "archived",
}

/** select "projectsAggregateBoolExpBool_orArgumentsColumns" columns of table "projects" */
export enum ProjectsSelectColumnProjectsAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Archived = "archived",
}

/** input type for updating data in table "projects" */
export type ProjectsSetInput = {
  archived?: InputMaybe<Scalars["Boolean"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "projects" */
export type ProjectsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProjectsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectsStreamCursorValueInput = {
  archived?: InputMaybe<Scalars["Boolean"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "projects" */
export enum ProjectsUpdateColumn {
  /** column name */
  Archived = "archived",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type ProjectsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProjectsSetInput>;
  /** filter the rows which have to be updated */
  where: ProjectsBoolExp;
};

export type ReLinkGithubInput = {
  code: Scalars["String"];
  organizationName: Scalars["String"];
  state: Scalars["String"];
};

export type RemoveProjectMemberInput = {
  projectId: Scalars["String"];
  projectMemberId: Scalars["String"];
};

export type RemoveTeamAllowedIpAddressInput = {
  token: Scalars["String"];
};

export type RemoveTeamMemberInput = {
  teamMemberIds: Array<Scalars["String"]>;
};

export type ReportDetailsInput = {
  from?: InputMaybe<Scalars["String"]>;
  periodCode: Scalars["String"];
  projectIds?: InputMaybe<Array<Scalars["String"]>>;
  roundMinutes: Scalars["Float"];
  roundType: Scalars["String"];
  sortColumn: Scalars["String"];
  sortOrder: Scalars["String"];
  teamMemberIds?: InputMaybe<Array<Scalars["String"]>>;
  to?: InputMaybe<Scalars["String"]>;
};

export type ReportDetailsResponse = {
  minutes: Scalars["Int"];
  projectId: Scalars["String"];
  projectName: Scalars["String"];
  projectTaskTagId: Scalars["String"];
  projectTaskTagName: Scalars["String"];
  taskId: Scalars["String"];
  taskName: Scalars["String"];
  teamMemberId: Scalars["String"];
  userId: Scalars["String"];
  userName: Scalars["String"];
  viewDate: Scalars["String"];
  workId: Scalars["String"];
};

export type ReportPath = {
  path: Scalars["String"];
};

export type ReportSummariesInput = {
  from?: InputMaybe<Scalars["String"]>;
  periodCode: Scalars["String"];
  projectIds?: InputMaybe<Array<Scalars["String"]>>;
  roundMinutes: Scalars["Float"];
  roundType: Scalars["String"];
  teamMemberIds?: InputMaybe<Array<Scalars["String"]>>;
  to?: InputMaybe<Scalars["String"]>;
};

export type ReportSummariesResponse = {
  minutes: Scalars["Int"];
  viewDate: Scalars["String"];
};

export type ReportSummaryPercentagesInput = {
  from?: InputMaybe<Scalars["String"]>;
  periodCode: Scalars["String"];
  projectIds?: InputMaybe<Array<Scalars["String"]>>;
  roundMinutes: Scalars["Float"];
  roundType: Scalars["String"];
  sortColumn: Scalars["String"];
  sortOrder: Scalars["String"];
  summaryType: Scalars["String"];
  teamMemberIds?: InputMaybe<Array<Scalars["String"]>>;
  to?: InputMaybe<Scalars["String"]>;
};

export type ReportSummaryPercentagesResponse = {
  minutes: Scalars["Int"];
  percentage: Scalars["Float"];
  title: Scalars["String"];
};

export type ResetPasswordInput = {
  email: Scalars["String"];
};

export type Sample = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SendContactMailInput = {
  comment: Scalars["String"];
  email: Scalars["String"];
  inquiryCategory: Scalars["String"];
  name: Scalars["String"];
};

export type SessionUrl = {
  sessionUrl: Scalars["String"];
};

/** columns and relationships of "settlement_histories" */
export type SettlementHistories = {
  amount: Scalars["Int"];
  contractId: Scalars["String"];
  /** An object relationship */
  contracts?: Maybe<Contracts>;
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  settledAt?: Maybe<Scalars["timestamptz"]>;
  settlementService: Scalars["String"];
  status: Scalars["String"];
  /** An object relationship */
  stripeSettlementHistory?: Maybe<StripeSettlementHistories>;
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "settlement_histories" */
export type SettlementHistoriesAggregate = {
  aggregate?: Maybe<SettlementHistoriesAggregateFields>;
  nodes: Array<SettlementHistories>;
};

/** aggregate fields of "settlement_histories" */
export type SettlementHistoriesAggregateFields = {
  avg?: Maybe<SettlementHistoriesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<SettlementHistoriesMaxFields>;
  min?: Maybe<SettlementHistoriesMinFields>;
  stddev?: Maybe<SettlementHistoriesStddevFields>;
  stddevPop?: Maybe<SettlementHistoriesStddevPopFields>;
  stddevSamp?: Maybe<SettlementHistoriesStddevSampFields>;
  sum?: Maybe<SettlementHistoriesSumFields>;
  varPop?: Maybe<SettlementHistoriesVarPopFields>;
  varSamp?: Maybe<SettlementHistoriesVarSampFields>;
  variance?: Maybe<SettlementHistoriesVarianceFields>;
};

/** aggregate fields of "settlement_histories" */
export type SettlementHistoriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SettlementHistoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type SettlementHistoriesAvgFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "settlement_histories". All fields are combined with a logical 'AND'. */
export type SettlementHistoriesBoolExp = {
  _and?: InputMaybe<Array<SettlementHistoriesBoolExp>>;
  _not?: InputMaybe<SettlementHistoriesBoolExp>;
  _or?: InputMaybe<Array<SettlementHistoriesBoolExp>>;
  amount?: InputMaybe<IntComparisonExp>;
  contractId?: InputMaybe<StringComparisonExp>;
  contracts?: InputMaybe<ContractsBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  settledAt?: InputMaybe<TimestamptzComparisonExp>;
  settlementService?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  stripeSettlementHistory?: InputMaybe<StripeSettlementHistoriesBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "settlement_histories" */
export enum SettlementHistoriesConstraint {
  /** unique or primary key constraint on columns "id" */
  PkB668a1a2686d4386abd7f0ef21a = "PK_b668a1a2686d4386abd7f0ef21a",
}

/** input type for incrementing numeric columns in table "settlement_histories" */
export type SettlementHistoriesIncInput = {
  amount?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "settlement_histories" */
export type SettlementHistoriesInsertInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  contractId?: InputMaybe<Scalars["String"]>;
  contracts?: InputMaybe<ContractsObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  settledAt?: InputMaybe<Scalars["timestamptz"]>;
  settlementService?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  stripeSettlementHistory?: InputMaybe<StripeSettlementHistoriesObjRelInsertInput>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type SettlementHistoriesMaxFields = {
  amount?: Maybe<Scalars["Int"]>;
  contractId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  settledAt?: Maybe<Scalars["timestamptz"]>;
  settlementService?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type SettlementHistoriesMinFields = {
  amount?: Maybe<Scalars["Int"]>;
  contractId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  settledAt?: Maybe<Scalars["timestamptz"]>;
  settlementService?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "settlement_histories" */
export type SettlementHistoriesMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<SettlementHistories>;
};

/** input type for inserting object relation for remote table "settlement_histories" */
export type SettlementHistoriesObjRelInsertInput = {
  data: SettlementHistoriesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<SettlementHistoriesOnConflict>;
};

/** on_conflict condition type for table "settlement_histories" */
export type SettlementHistoriesOnConflict = {
  constraint: SettlementHistoriesConstraint;
  updateColumns?: Array<SettlementHistoriesUpdateColumn>;
  where?: InputMaybe<SettlementHistoriesBoolExp>;
};

/** Ordering options when selecting data from "settlement_histories". */
export type SettlementHistoriesOrderBy = {
  amount?: InputMaybe<OrderBy>;
  contractId?: InputMaybe<OrderBy>;
  contracts?: InputMaybe<ContractsOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  settledAt?: InputMaybe<OrderBy>;
  settlementService?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stripeSettlementHistory?: InputMaybe<StripeSettlementHistoriesOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: settlement_histories */
export type SettlementHistoriesPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "settlement_histories" */
export enum SettlementHistoriesSelectColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  ContractId = "contractId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  SettledAt = "settledAt",
  /** column name */
  SettlementService = "settlementService",
  /** column name */
  Status = "status",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "settlement_histories" */
export type SettlementHistoriesSetInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  contractId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  settledAt?: InputMaybe<Scalars["timestamptz"]>;
  settlementService?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type SettlementHistoriesStddevFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevPop on columns */
export type SettlementHistoriesStddevPopFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevSamp on columns */
export type SettlementHistoriesStddevSampFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "settlement_histories" */
export type SettlementHistoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SettlementHistoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SettlementHistoriesStreamCursorValueInput = {
  amount?: InputMaybe<Scalars["Int"]>;
  contractId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  settledAt?: InputMaybe<Scalars["timestamptz"]>;
  settlementService?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type SettlementHistoriesSumFields = {
  amount?: Maybe<Scalars["Int"]>;
};

/** update columns of table "settlement_histories" */
export enum SettlementHistoriesUpdateColumn {
  /** column name */
  Amount = "amount",
  /** column name */
  ContractId = "contractId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  SettledAt = "settledAt",
  /** column name */
  SettlementService = "settlementService",
  /** column name */
  Status = "status",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type SettlementHistoriesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SettlementHistoriesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SettlementHistoriesSetInput>;
  /** filter the rows which have to be updated */
  where: SettlementHistoriesBoolExp;
};

/** aggregate varPop on columns */
export type SettlementHistoriesVarPopFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** aggregate varSamp on columns */
export type SettlementHistoriesVarSampFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type SettlementHistoriesVarianceFields = {
  amount?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _isNull?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "stripe_customers" */
export type StripeCustomers = {
  country: Scalars["String"];
  createdAt: Scalars["timestamptz"];
  email: Scalars["String"];
  id: Scalars["String"];
  line1: Scalars["String"];
  line2?: Maybe<Scalars["String"]>;
  paymentMethodId: Scalars["String"];
  postalCode: Scalars["String"];
  state: Scalars["String"];
  subscriptionId: Scalars["String"];
  subscriptionItemId: Scalars["String"];
  /** An object relationship */
  team: Teams;
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "stripe_customers" */
export type StripeCustomersAggregate = {
  aggregate?: Maybe<StripeCustomersAggregateFields>;
  nodes: Array<StripeCustomers>;
};

/** aggregate fields of "stripe_customers" */
export type StripeCustomersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<StripeCustomersMaxFields>;
  min?: Maybe<StripeCustomersMinFields>;
};

/** aggregate fields of "stripe_customers" */
export type StripeCustomersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StripeCustomersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "stripe_customers". All fields are combined with a logical 'AND'. */
export type StripeCustomersBoolExp = {
  _and?: InputMaybe<Array<StripeCustomersBoolExp>>;
  _not?: InputMaybe<StripeCustomersBoolExp>;
  _or?: InputMaybe<Array<StripeCustomersBoolExp>>;
  country?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  line1?: InputMaybe<StringComparisonExp>;
  line2?: InputMaybe<StringComparisonExp>;
  paymentMethodId?: InputMaybe<StringComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  state?: InputMaybe<StringComparisonExp>;
  subscriptionId?: InputMaybe<StringComparisonExp>;
  subscriptionItemId?: InputMaybe<StringComparisonExp>;
  team?: InputMaybe<TeamsBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "stripe_customers" */
export enum StripeCustomersConstraint {
  /** unique or primary key constraint on columns "team_id" */
  Idx_0054c04052546e4c0dd37ed234 = "IDX_0054c04052546e4c0dd37ed234",
  /** unique or primary key constraint on columns "id" */
  Pk_04d1cce981fe2fc3688e52bf2b6 = "PK_04d1cce981fe2fc3688e52bf2b6",
  /** unique or primary key constraint on columns "team_id" */
  Rel_0054c04052546e4c0dd37ed234 = "REL_0054c04052546e4c0dd37ed234",
}

/** input type for inserting data into table "stripe_customers" */
export type StripeCustomersInsertInput = {
  country?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  line1?: InputMaybe<Scalars["String"]>;
  line2?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
  subscriptionItemId?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<TeamsObjRelInsertInput>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type StripeCustomersMaxFields = {
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionItemId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type StripeCustomersMinFields = {
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionItemId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "stripe_customers" */
export type StripeCustomersMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<StripeCustomers>;
};

/** input type for inserting object relation for remote table "stripe_customers" */
export type StripeCustomersObjRelInsertInput = {
  data: StripeCustomersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StripeCustomersOnConflict>;
};

/** on_conflict condition type for table "stripe_customers" */
export type StripeCustomersOnConflict = {
  constraint: StripeCustomersConstraint;
  updateColumns?: Array<StripeCustomersUpdateColumn>;
  where?: InputMaybe<StripeCustomersBoolExp>;
};

/** Ordering options when selecting data from "stripe_customers". */
export type StripeCustomersOrderBy = {
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  line1?: InputMaybe<OrderBy>;
  line2?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  subscriptionId?: InputMaybe<OrderBy>;
  subscriptionItemId?: InputMaybe<OrderBy>;
  team?: InputMaybe<TeamsOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stripe_customers */
export type StripeCustomersPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "stripe_customers" */
export enum StripeCustomersSelectColumn {
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Line1 = "line1",
  /** column name */
  Line2 = "line2",
  /** column name */
  PaymentMethodId = "paymentMethodId",
  /** column name */
  PostalCode = "postalCode",
  /** column name */
  State = "state",
  /** column name */
  SubscriptionId = "subscriptionId",
  /** column name */
  SubscriptionItemId = "subscriptionItemId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "stripe_customers" */
export type StripeCustomersSetInput = {
  country?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  line1?: InputMaybe<Scalars["String"]>;
  line2?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
  subscriptionItemId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "stripe_customers" */
export type StripeCustomersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StripeCustomersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StripeCustomersStreamCursorValueInput = {
  country?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  line1?: InputMaybe<Scalars["String"]>;
  line2?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
  subscriptionItemId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "stripe_customers" */
export enum StripeCustomersUpdateColumn {
  /** column name */
  Country = "country",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Line1 = "line1",
  /** column name */
  Line2 = "line2",
  /** column name */
  PaymentMethodId = "paymentMethodId",
  /** column name */
  PostalCode = "postalCode",
  /** column name */
  State = "state",
  /** column name */
  SubscriptionId = "subscriptionId",
  /** column name */
  SubscriptionItemId = "subscriptionItemId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type StripeCustomersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StripeCustomersSetInput>;
  /** filter the rows which have to be updated */
  where: StripeCustomersBoolExp;
};

/** columns and relationships of "stripe_settlement_histories" */
export type StripeSettlementHistories = {
  cardBrand?: Maybe<Scalars["String"]>;
  chargeId: Scalars["String"];
  createdAt: Scalars["timestamptz"];
  customerId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  errorCode?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  errorType?: Maybe<Scalars["String"]>;
  eventId: Scalars["String"];
  expMonth?: Maybe<Scalars["String"]>;
  expYear?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  invoiceId: Scalars["String"];
  invoicePdfUrl?: Maybe<Scalars["String"]>;
  last4?: Maybe<Scalars["String"]>;
  paymentIntentId: Scalars["String"];
  paymentMethodId: Scalars["String"];
  /** An object relationship */
  settlementHistory: SettlementHistories;
  settlementHistoryId: Scalars["String"];
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionItemId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "stripe_settlement_histories" */
export type StripeSettlementHistoriesAggregate = {
  aggregate?: Maybe<StripeSettlementHistoriesAggregateFields>;
  nodes: Array<StripeSettlementHistories>;
};

/** aggregate fields of "stripe_settlement_histories" */
export type StripeSettlementHistoriesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<StripeSettlementHistoriesMaxFields>;
  min?: Maybe<StripeSettlementHistoriesMinFields>;
};

/** aggregate fields of "stripe_settlement_histories" */
export type StripeSettlementHistoriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StripeSettlementHistoriesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Boolean expression to filter rows from the table "stripe_settlement_histories".
 * All fields are combined with a logical 'AND'.
 */
export type StripeSettlementHistoriesBoolExp = {
  _and?: InputMaybe<Array<StripeSettlementHistoriesBoolExp>>;
  _not?: InputMaybe<StripeSettlementHistoriesBoolExp>;
  _or?: InputMaybe<Array<StripeSettlementHistoriesBoolExp>>;
  cardBrand?: InputMaybe<StringComparisonExp>;
  chargeId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  customerId?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  errorCode?: InputMaybe<StringComparisonExp>;
  errorMessage?: InputMaybe<StringComparisonExp>;
  errorType?: InputMaybe<StringComparisonExp>;
  eventId?: InputMaybe<StringComparisonExp>;
  expMonth?: InputMaybe<StringComparisonExp>;
  expYear?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  invoiceId?: InputMaybe<StringComparisonExp>;
  invoicePdfUrl?: InputMaybe<StringComparisonExp>;
  last4?: InputMaybe<StringComparisonExp>;
  paymentIntentId?: InputMaybe<StringComparisonExp>;
  paymentMethodId?: InputMaybe<StringComparisonExp>;
  settlementHistory?: InputMaybe<SettlementHistoriesBoolExp>;
  settlementHistoryId?: InputMaybe<StringComparisonExp>;
  subscriptionId?: InputMaybe<StringComparisonExp>;
  subscriptionItemId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "stripe_settlement_histories" */
export enum StripeSettlementHistoriesConstraint {
  /** unique or primary key constraint on columns "settlement_history_id" */
  IdxBf1e72bb7de54e0133a81baf04 = "IDX_bf1e72bb7de54e0133a81baf04",
  /** unique or primary key constraint on columns "id" */
  PkFc857394ebd9cb49031e6fc9344 = "PK_fc857394ebd9cb49031e6fc9344",
  /** unique or primary key constraint on columns "settlement_history_id" */
  RelBf1e72bb7de54e0133a81baf04 = "REL_bf1e72bb7de54e0133a81baf04",
}

/** input type for inserting data into table "stripe_settlement_histories" */
export type StripeSettlementHistoriesInsertInput = {
  cardBrand?: InputMaybe<Scalars["String"]>;
  chargeId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  errorCode?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  errorType?: InputMaybe<Scalars["String"]>;
  eventId?: InputMaybe<Scalars["String"]>;
  expMonth?: InputMaybe<Scalars["String"]>;
  expYear?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  invoiceId?: InputMaybe<Scalars["String"]>;
  invoicePdfUrl?: InputMaybe<Scalars["String"]>;
  last4?: InputMaybe<Scalars["String"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  settlementHistory?: InputMaybe<SettlementHistoriesObjRelInsertInput>;
  settlementHistoryId?: InputMaybe<Scalars["String"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
  subscriptionItemId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type StripeSettlementHistoriesMaxFields = {
  cardBrand?: Maybe<Scalars["String"]>;
  chargeId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  customerId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  errorCode?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  errorType?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["String"]>;
  expMonth?: Maybe<Scalars["String"]>;
  expYear?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invoiceId?: Maybe<Scalars["String"]>;
  invoicePdfUrl?: Maybe<Scalars["String"]>;
  last4?: Maybe<Scalars["String"]>;
  paymentIntentId?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  settlementHistoryId?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionItemId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type StripeSettlementHistoriesMinFields = {
  cardBrand?: Maybe<Scalars["String"]>;
  chargeId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  customerId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  errorCode?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  errorType?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["String"]>;
  expMonth?: Maybe<Scalars["String"]>;
  expYear?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invoiceId?: Maybe<Scalars["String"]>;
  invoicePdfUrl?: Maybe<Scalars["String"]>;
  last4?: Maybe<Scalars["String"]>;
  paymentIntentId?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  settlementHistoryId?: Maybe<Scalars["String"]>;
  subscriptionId?: Maybe<Scalars["String"]>;
  subscriptionItemId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "stripe_settlement_histories" */
export type StripeSettlementHistoriesMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<StripeSettlementHistories>;
};

/** input type for inserting object relation for remote table "stripe_settlement_histories" */
export type StripeSettlementHistoriesObjRelInsertInput = {
  data: StripeSettlementHistoriesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StripeSettlementHistoriesOnConflict>;
};

/** on_conflict condition type for table "stripe_settlement_histories" */
export type StripeSettlementHistoriesOnConflict = {
  constraint: StripeSettlementHistoriesConstraint;
  updateColumns?: Array<StripeSettlementHistoriesUpdateColumn>;
  where?: InputMaybe<StripeSettlementHistoriesBoolExp>;
};

/** Ordering options when selecting data from "stripe_settlement_histories". */
export type StripeSettlementHistoriesOrderBy = {
  cardBrand?: InputMaybe<OrderBy>;
  chargeId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  customerId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  errorCode?: InputMaybe<OrderBy>;
  errorMessage?: InputMaybe<OrderBy>;
  errorType?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  expMonth?: InputMaybe<OrderBy>;
  expYear?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  invoiceId?: InputMaybe<OrderBy>;
  invoicePdfUrl?: InputMaybe<OrderBy>;
  last4?: InputMaybe<OrderBy>;
  paymentIntentId?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  settlementHistory?: InputMaybe<SettlementHistoriesOrderBy>;
  settlementHistoryId?: InputMaybe<OrderBy>;
  subscriptionId?: InputMaybe<OrderBy>;
  subscriptionItemId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stripe_settlement_histories */
export type StripeSettlementHistoriesPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "stripe_settlement_histories" */
export enum StripeSettlementHistoriesSelectColumn {
  /** column name */
  CardBrand = "cardBrand",
  /** column name */
  ChargeId = "chargeId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  CustomerId = "customerId",
  /** column name */
  Description = "description",
  /** column name */
  ErrorCode = "errorCode",
  /** column name */
  ErrorMessage = "errorMessage",
  /** column name */
  ErrorType = "errorType",
  /** column name */
  EventId = "eventId",
  /** column name */
  ExpMonth = "expMonth",
  /** column name */
  ExpYear = "expYear",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  InvoicePdfUrl = "invoicePdfUrl",
  /** column name */
  Last4 = "last4",
  /** column name */
  PaymentIntentId = "paymentIntentId",
  /** column name */
  PaymentMethodId = "paymentMethodId",
  /** column name */
  SettlementHistoryId = "settlementHistoryId",
  /** column name */
  SubscriptionId = "subscriptionId",
  /** column name */
  SubscriptionItemId = "subscriptionItemId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "stripe_settlement_histories" */
export type StripeSettlementHistoriesSetInput = {
  cardBrand?: InputMaybe<Scalars["String"]>;
  chargeId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  errorCode?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  errorType?: InputMaybe<Scalars["String"]>;
  eventId?: InputMaybe<Scalars["String"]>;
  expMonth?: InputMaybe<Scalars["String"]>;
  expYear?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  invoiceId?: InputMaybe<Scalars["String"]>;
  invoicePdfUrl?: InputMaybe<Scalars["String"]>;
  last4?: InputMaybe<Scalars["String"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  settlementHistoryId?: InputMaybe<Scalars["String"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
  subscriptionItemId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "stripe_settlement_histories" */
export type StripeSettlementHistoriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StripeSettlementHistoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StripeSettlementHistoriesStreamCursorValueInput = {
  cardBrand?: InputMaybe<Scalars["String"]>;
  chargeId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  errorCode?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  errorType?: InputMaybe<Scalars["String"]>;
  eventId?: InputMaybe<Scalars["String"]>;
  expMonth?: InputMaybe<Scalars["String"]>;
  expYear?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  invoiceId?: InputMaybe<Scalars["String"]>;
  invoicePdfUrl?: InputMaybe<Scalars["String"]>;
  last4?: InputMaybe<Scalars["String"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  settlementHistoryId?: InputMaybe<Scalars["String"]>;
  subscriptionId?: InputMaybe<Scalars["String"]>;
  subscriptionItemId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "stripe_settlement_histories" */
export enum StripeSettlementHistoriesUpdateColumn {
  /** column name */
  CardBrand = "cardBrand",
  /** column name */
  ChargeId = "chargeId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  CustomerId = "customerId",
  /** column name */
  Description = "description",
  /** column name */
  ErrorCode = "errorCode",
  /** column name */
  ErrorMessage = "errorMessage",
  /** column name */
  ErrorType = "errorType",
  /** column name */
  EventId = "eventId",
  /** column name */
  ExpMonth = "expMonth",
  /** column name */
  ExpYear = "expYear",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceId = "invoiceId",
  /** column name */
  InvoicePdfUrl = "invoicePdfUrl",
  /** column name */
  Last4 = "last4",
  /** column name */
  PaymentIntentId = "paymentIntentId",
  /** column name */
  PaymentMethodId = "paymentMethodId",
  /** column name */
  SettlementHistoryId = "settlementHistoryId",
  /** column name */
  SubscriptionId = "subscriptionId",
  /** column name */
  SubscriptionItemId = "subscriptionItemId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type StripeSettlementHistoriesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StripeSettlementHistoriesSetInput>;
  /** filter the rows which have to be updated */
  where: StripeSettlementHistoriesBoolExp;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  lock: Scalars["Boolean"];
  name: Scalars["String"];
  order: Scalars["Int"];
  /** An object relationship */
  project?: Maybe<Projects>;
  projectId: Scalars["String"];
  projectMemberId: Scalars["String"];
  /** An object relationship */
  projectTaskTag?: Maybe<ProjectTaskTags>;
  projectTaskTagId: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  serviceType?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  teamId: Scalars["String"];
  /** An object relationship */
  teamMember?: Maybe<TeamMembers>;
  teamMemberId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
  /** An array relationship */
  workTasks: Array<WorkTasks>;
  /** An aggregate relationship */
  workTasksAggregate: WorkTasksAggregate;
  /** An array relationship */
  workTrackingTasks: Array<WorkTrackingTasks>;
  /** An aggregate relationship */
  workTrackingTasksAggregate: WorkTrackingTasksAggregate;
};

/** columns and relationships of "tasks" */
export type TasksWorkTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

/** columns and relationships of "tasks" */
export type TasksWorkTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

/** columns and relationships of "tasks" */
export type TasksWorkTrackingTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

/** columns and relationships of "tasks" */
export type TasksWorkTrackingTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

/** aggregated selection of "tasks" */
export type TasksAggregate = {
  aggregate?: Maybe<TasksAggregateFields>;
  nodes: Array<Tasks>;
};

export type TasksAggregateBoolExp = {
  bool_and?: InputMaybe<TasksAggregateBoolExpBool_And>;
  bool_or?: InputMaybe<TasksAggregateBoolExpBool_Or>;
  count?: InputMaybe<TasksAggregateBoolExpCount>;
};

/** aggregate fields of "tasks" */
export type TasksAggregateFields = {
  avg?: Maybe<TasksAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<TasksMaxFields>;
  min?: Maybe<TasksMinFields>;
  stddev?: Maybe<TasksStddevFields>;
  stddevPop?: Maybe<TasksStddevPopFields>;
  stddevSamp?: Maybe<TasksStddevSampFields>;
  sum?: Maybe<TasksSumFields>;
  varPop?: Maybe<TasksVarPopFields>;
  varSamp?: Maybe<TasksVarSampFields>;
  variance?: Maybe<TasksVarianceFields>;
};

/** aggregate fields of "tasks" */
export type TasksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TasksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "tasks" */
export type TasksAggregateOrderBy = {
  avg?: InputMaybe<TasksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TasksMaxOrderBy>;
  min?: InputMaybe<TasksMinOrderBy>;
  stddev?: InputMaybe<TasksStddevOrderBy>;
  stddevPop?: InputMaybe<TasksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TasksStddevSampOrderBy>;
  sum?: InputMaybe<TasksSumOrderBy>;
  varPop?: InputMaybe<TasksVarPopOrderBy>;
  varSamp?: InputMaybe<TasksVarSampOrderBy>;
  variance?: InputMaybe<TasksVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "tasks" */
export type TasksArrRelInsertInput = {
  data: Array<TasksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** aggregate avg on columns */
export type TasksAvgFields = {
  order?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "tasks" */
export type TasksAvgOrderBy = {
  order?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type TasksBoolExp = {
  _and?: InputMaybe<Array<TasksBoolExp>>;
  _not?: InputMaybe<TasksBoolExp>;
  _or?: InputMaybe<Array<TasksBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lock?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<IntComparisonExp>;
  project?: InputMaybe<ProjectsBoolExp>;
  projectId?: InputMaybe<StringComparisonExp>;
  projectMemberId?: InputMaybe<StringComparisonExp>;
  projectTaskTag?: InputMaybe<ProjectTaskTagsBoolExp>;
  projectTaskTagId?: InputMaybe<StringComparisonExp>;
  serviceId?: InputMaybe<StringComparisonExp>;
  serviceType?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMember?: InputMaybe<TeamMembersBoolExp>;
  teamMemberId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  workTasks?: InputMaybe<WorkTasksBoolExp>;
  workTasksAggregate?: InputMaybe<WorkTasksAggregateBoolExp>;
  workTrackingTasks?: InputMaybe<WorkTrackingTasksBoolExp>;
  workTrackingTasksAggregate?: InputMaybe<WorkTrackingTasksAggregateBoolExp>;
};

/** unique or primary key constraints on table "tasks" */
export enum TasksConstraint {
  /** unique or primary key constraint on columns "team_member_id", "team_id" */
  Idx_8bd347f75693f81e38f49daaaa = "IDX_8bd347f75693f81e38f49daaaa",
  /** unique or primary key constraint on columns "id", "team_id" */
  IdxA10b179693f6e11c785356c010 = "IDX_a10b179693f6e11c785356c010",
  /** unique or primary key constraint on columns "id" */
  Pk_8d12ff38fcc62aaba2cab748772 = "PK_8d12ff38fcc62aaba2cab748772",
}

/** input type for incrementing numeric columns in table "tasks" */
export type TasksIncInput = {
  order?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "tasks" */
export type TasksInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  lock?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  project?: InputMaybe<ProjectsObjRelInsertInput>;
  projectId?: InputMaybe<Scalars["String"]>;
  projectMemberId?: InputMaybe<Scalars["String"]>;
  projectTaskTag?: InputMaybe<ProjectTaskTagsObjRelInsertInput>;
  projectTaskTagId?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
  serviceType?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMember?: InputMaybe<TeamMembersObjRelInsertInput>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTasks?: InputMaybe<WorkTasksArrRelInsertInput>;
  workTrackingTasks?: InputMaybe<WorkTrackingTasksArrRelInsertInput>;
};

/** aggregate max on columns */
export type TasksMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  projectId?: Maybe<Scalars["String"]>;
  projectMemberId?: Maybe<Scalars["String"]>;
  projectTaskTagId?: Maybe<Scalars["String"]>;
  serviceId?: Maybe<Scalars["String"]>;
  serviceType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "tasks" */
export type TasksMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  projectMemberId?: InputMaybe<OrderBy>;
  projectTaskTagId?: InputMaybe<OrderBy>;
  serviceId?: InputMaybe<OrderBy>;
  serviceType?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TasksMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  projectId?: Maybe<Scalars["String"]>;
  projectMemberId?: Maybe<Scalars["String"]>;
  projectTaskTagId?: Maybe<Scalars["String"]>;
  serviceId?: Maybe<Scalars["String"]>;
  serviceType?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "tasks" */
export type TasksMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  projectMemberId?: InputMaybe<OrderBy>;
  projectTaskTagId?: InputMaybe<OrderBy>;
  serviceId?: InputMaybe<OrderBy>;
  serviceType?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tasks" */
export type TasksMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type TasksObjRelInsertInput = {
  data: TasksInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** on_conflict condition type for table "tasks" */
export type TasksOnConflict = {
  constraint: TasksConstraint;
  updateColumns?: Array<TasksUpdateColumn>;
  where?: InputMaybe<TasksBoolExp>;
};

/** Ordering options when selecting data from "tasks". */
export type TasksOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lock?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderBy>;
  project?: InputMaybe<ProjectsOrderBy>;
  projectId?: InputMaybe<OrderBy>;
  projectMemberId?: InputMaybe<OrderBy>;
  projectTaskTag?: InputMaybe<ProjectTaskTagsOrderBy>;
  projectTaskTagId?: InputMaybe<OrderBy>;
  serviceId?: InputMaybe<OrderBy>;
  serviceType?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMember?: InputMaybe<TeamMembersOrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTasksAggregate?: InputMaybe<WorkTasksAggregateOrderBy>;
  workTrackingTasksAggregate?: InputMaybe<WorkTrackingTasksAggregateOrderBy>;
};

/** primary key columns input for table: tasks */
export type TasksPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "tasks" */
export enum TasksSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Lock = "lock",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  ProjectMemberId = "projectMemberId",
  /** column name */
  ProjectTaskTagId = "projectTaskTagId",
  /** column name */
  ServiceId = "serviceId",
  /** column name */
  ServiceType = "serviceType",
  /** column name */
  Status = "status",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** select "tasksAggregateBoolExpBool_andArgumentsColumns" columns of table "tasks" */
export enum TasksSelectColumnTasksAggregateBoolExpBool_AndArgumentsColumns {
  /** column name */
  Lock = "lock",
}

/** select "tasksAggregateBoolExpBool_orArgumentsColumns" columns of table "tasks" */
export enum TasksSelectColumnTasksAggregateBoolExpBool_OrArgumentsColumns {
  /** column name */
  Lock = "lock",
}

/** input type for updating data in table "tasks" */
export type TasksSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  lock?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  projectMemberId?: InputMaybe<Scalars["String"]>;
  projectTaskTagId?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
  serviceType?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type TasksStddevFields = {
  order?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "tasks" */
export type TasksStddevOrderBy = {
  order?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TasksStddevPopFields = {
  order?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "tasks" */
export type TasksStddevPopOrderBy = {
  order?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TasksStddevSampFields = {
  order?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "tasks" */
export type TasksStddevSampOrderBy = {
  order?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "tasks" */
export type TasksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TasksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TasksStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  lock?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Int"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  projectMemberId?: InputMaybe<Scalars["String"]>;
  projectTaskTagId?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
  serviceType?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type TasksSumFields = {
  order?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "tasks" */
export type TasksSumOrderBy = {
  order?: InputMaybe<OrderBy>;
};

/** update columns of table "tasks" */
export enum TasksUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Id = "id",
  /** column name */
  Lock = "lock",
  /** column name */
  Name = "name",
  /** column name */
  Order = "order",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  ProjectMemberId = "projectMemberId",
  /** column name */
  ProjectTaskTagId = "projectTaskTagId",
  /** column name */
  ServiceId = "serviceId",
  /** column name */
  ServiceType = "serviceType",
  /** column name */
  Status = "status",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TasksUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TasksIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TasksSetInput>;
  /** filter the rows which have to be updated */
  where: TasksBoolExp;
};

/** aggregate varPop on columns */
export type TasksVarPopFields = {
  order?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "tasks" */
export type TasksVarPopOrderBy = {
  order?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TasksVarSampFields = {
  order?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "tasks" */
export type TasksVarSampOrderBy = {
  order?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TasksVarianceFields = {
  order?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "tasks" */
export type TasksVarianceOrderBy = {
  order?: InputMaybe<OrderBy>;
};

export type Team = {
  createdAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  enabledBlurBackground: Scalars["Boolean"];
  enabledSnapshot: Scalars["Boolean"];
  imageFilePath?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["String"];
};

/** columns and relationships of "team_allowed_ip_addresses" */
export type TeamAllowedIpAddresses = {
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  ipAddress: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesAggregate = {
  aggregate?: Maybe<TeamAllowedIpAddressesAggregateFields>;
  nodes: Array<TeamAllowedIpAddresses>;
};

/** aggregate fields of "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TeamAllowedIpAddressesMaxFields>;
  min?: Maybe<TeamAllowedIpAddressesMinFields>;
};

/** aggregate fields of "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamAllowedIpAddressesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "team_allowed_ip_addresses". All fields are combined with a logical 'AND'. */
export type TeamAllowedIpAddressesBoolExp = {
  _and?: InputMaybe<Array<TeamAllowedIpAddressesBoolExp>>;
  _not?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
  _or?: InputMaybe<Array<TeamAllowedIpAddressesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  ipAddress?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_allowed_ip_addresses" */
export enum TeamAllowedIpAddressesConstraint {
  /** unique or primary key constraint on columns "id", "team_id" */
  Idx_2e73330301ebc6df80a61833ab = "IDX_2e73330301ebc6df80a61833ab",
  /** unique or primary key constraint on columns "id" */
  Pk_5a00efd8fa83f1aa4161b30a19f = "PK_5a00efd8fa83f1aa4161b30a19f",
}

/** input type for inserting data into table "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  ipAddress?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamAllowedIpAddressesMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type TeamAllowedIpAddressesMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamAllowedIpAddresses>;
};

/** on_conflict condition type for table "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesOnConflict = {
  constraint: TeamAllowedIpAddressesConstraint;
  updateColumns?: Array<TeamAllowedIpAddressesUpdateColumn>;
  where?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
};

/** Ordering options when selecting data from "team_allowed_ip_addresses". */
export type TeamAllowedIpAddressesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_allowed_ip_addresses */
export type TeamAllowedIpAddressesPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_allowed_ip_addresses" */
export enum TeamAllowedIpAddressesSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  ipAddress?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "team_allowed_ip_addresses" */
export type TeamAllowedIpAddressesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamAllowedIpAddressesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamAllowedIpAddressesStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  ipAddress?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "team_allowed_ip_addresses" */
export enum TeamAllowedIpAddressesUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  IpAddress = "ipAddress",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamAllowedIpAddressesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamAllowedIpAddressesSetInput>;
  /** filter the rows which have to be updated */
  where: TeamAllowedIpAddressesBoolExp;
};

/** columns and relationships of "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjects = {
  backlogProjectId: Scalars["Int"];
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  linkId: Scalars["String"];
  /** An object relationship */
  project: Projects;
  projectId: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsAggregate = {
  aggregate?: Maybe<TeamBacklogLinkageProjectsAggregateFields>;
  nodes: Array<TeamBacklogLinkageProjects>;
};

export type TeamBacklogLinkageProjectsAggregateBoolExp = {
  count?: InputMaybe<TeamBacklogLinkageProjectsAggregateBoolExpCount>;
};

/** aggregate fields of "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsAggregateFields = {
  avg?: Maybe<TeamBacklogLinkageProjectsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<TeamBacklogLinkageProjectsMaxFields>;
  min?: Maybe<TeamBacklogLinkageProjectsMinFields>;
  stddev?: Maybe<TeamBacklogLinkageProjectsStddevFields>;
  stddevPop?: Maybe<TeamBacklogLinkageProjectsStddevPopFields>;
  stddevSamp?: Maybe<TeamBacklogLinkageProjectsStddevSampFields>;
  sum?: Maybe<TeamBacklogLinkageProjectsSumFields>;
  varPop?: Maybe<TeamBacklogLinkageProjectsVarPopFields>;
  varSamp?: Maybe<TeamBacklogLinkageProjectsVarSampFields>;
  variance?: Maybe<TeamBacklogLinkageProjectsVarianceFields>;
};

/** aggregate fields of "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsAggregateOrderBy = {
  avg?: InputMaybe<TeamBacklogLinkageProjectsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TeamBacklogLinkageProjectsMaxOrderBy>;
  min?: InputMaybe<TeamBacklogLinkageProjectsMinOrderBy>;
  stddev?: InputMaybe<TeamBacklogLinkageProjectsStddevOrderBy>;
  stddevPop?: InputMaybe<TeamBacklogLinkageProjectsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TeamBacklogLinkageProjectsStddevSampOrderBy>;
  sum?: InputMaybe<TeamBacklogLinkageProjectsSumOrderBy>;
  varPop?: InputMaybe<TeamBacklogLinkageProjectsVarPopOrderBy>;
  varSamp?: InputMaybe<TeamBacklogLinkageProjectsVarSampOrderBy>;
  variance?: InputMaybe<TeamBacklogLinkageProjectsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsArrRelInsertInput = {
  data: Array<TeamBacklogLinkageProjectsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TeamBacklogLinkageProjectsOnConflict>;
};

/** aggregate avg on columns */
export type TeamBacklogLinkageProjectsAvgFields = {
  backlogProjectId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsAvgOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "team_backlog_linkage_projects". All fields are combined with a logical 'AND'.
 */
export type TeamBacklogLinkageProjectsBoolExp = {
  _and?: InputMaybe<Array<TeamBacklogLinkageProjectsBoolExp>>;
  _not?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
  _or?: InputMaybe<Array<TeamBacklogLinkageProjectsBoolExp>>;
  backlogProjectId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  linkId?: InputMaybe<StringComparisonExp>;
  project?: InputMaybe<ProjectsBoolExp>;
  projectId?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_backlog_linkage_projects" */
export enum TeamBacklogLinkageProjectsConstraint {
  /** unique or primary key constraint on columns "link_id", "project_id", "backlog_project_id", "team_id" */
  Idx_8d1c62ac7c7dc919c0f278fb48 = "IDX_8d1c62ac7c7dc919c0f278fb48",
  /** unique or primary key constraint on columns "id" */
  Pk_32c42423616e410a835a1083f64 = "PK_32c42423616e410a835a1083f64",
}

/** input type for incrementing numeric columns in table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsIncInput = {
  backlogProjectId?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsInsertInput = {
  backlogProjectId?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  linkId?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<ProjectsObjRelInsertInput>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamBacklogLinkageProjectsMaxFields = {
  backlogProjectId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  linkId?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsMaxOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkId?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TeamBacklogLinkageProjectsMinFields = {
  backlogProjectId?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  linkId?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsMinOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkId?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamBacklogLinkageProjects>;
};

/** on_conflict condition type for table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsOnConflict = {
  constraint: TeamBacklogLinkageProjectsConstraint;
  updateColumns?: Array<TeamBacklogLinkageProjectsUpdateColumn>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

/** Ordering options when selecting data from "team_backlog_linkage_projects". */
export type TeamBacklogLinkageProjectsOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkId?: InputMaybe<OrderBy>;
  project?: InputMaybe<ProjectsOrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_backlog_linkage_projects */
export type TeamBacklogLinkageProjectsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_backlog_linkage_projects" */
export enum TeamBacklogLinkageProjectsSelectColumn {
  /** column name */
  BacklogProjectId = "backlogProjectId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  LinkId = "linkId",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsSetInput = {
  backlogProjectId?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  linkId?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type TeamBacklogLinkageProjectsStddevFields = {
  backlogProjectId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsStddevOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TeamBacklogLinkageProjectsStddevPopFields = {
  backlogProjectId?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsStddevPopOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TeamBacklogLinkageProjectsStddevSampFields = {
  backlogProjectId?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsStddevSampOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamBacklogLinkageProjectsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamBacklogLinkageProjectsStreamCursorValueInput = {
  backlogProjectId?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  linkId?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type TeamBacklogLinkageProjectsSumFields = {
  backlogProjectId?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsSumOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/** update columns of table "team_backlog_linkage_projects" */
export enum TeamBacklogLinkageProjectsUpdateColumn {
  /** column name */
  BacklogProjectId = "backlogProjectId",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  LinkId = "linkId",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamBacklogLinkageProjectsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TeamBacklogLinkageProjectsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamBacklogLinkageProjectsSetInput>;
  /** filter the rows which have to be updated */
  where: TeamBacklogLinkageProjectsBoolExp;
};

/** aggregate varPop on columns */
export type TeamBacklogLinkageProjectsVarPopFields = {
  backlogProjectId?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsVarPopOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TeamBacklogLinkageProjectsVarSampFields = {
  backlogProjectId?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsVarSampOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TeamBacklogLinkageProjectsVarianceFields = {
  backlogProjectId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "team_backlog_linkage_projects" */
export type TeamBacklogLinkageProjectsVarianceOrderBy = {
  backlogProjectId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "team_backlog_linkages" */
export type TeamBacklogLinkages = {
  accessToken: Scalars["String"];
  backlogUrl: Scalars["String"];
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  refreshToken: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_backlog_linkages" */
export type TeamBacklogLinkagesAggregate = {
  aggregate?: Maybe<TeamBacklogLinkagesAggregateFields>;
  nodes: Array<TeamBacklogLinkages>;
};

/** aggregate fields of "team_backlog_linkages" */
export type TeamBacklogLinkagesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TeamBacklogLinkagesMaxFields>;
  min?: Maybe<TeamBacklogLinkagesMinFields>;
};

/** aggregate fields of "team_backlog_linkages" */
export type TeamBacklogLinkagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamBacklogLinkagesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "team_backlog_linkages". All fields are combined with a logical 'AND'. */
export type TeamBacklogLinkagesBoolExp = {
  _and?: InputMaybe<Array<TeamBacklogLinkagesBoolExp>>;
  _not?: InputMaybe<TeamBacklogLinkagesBoolExp>;
  _or?: InputMaybe<Array<TeamBacklogLinkagesBoolExp>>;
  accessToken?: InputMaybe<StringComparisonExp>;
  backlogUrl?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  refreshToken?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_backlog_linkages" */
export enum TeamBacklogLinkagesConstraint {
  /** unique or primary key constraint on columns "backlog_url", "team_id" */
  Idx_8ac181521de5b1ab7c3fa4d65e = "IDX_8ac181521de5b1ab7c3fa4d65e",
  /** unique or primary key constraint on columns "id" */
  Pk_28d506c0c42eee8bad02ba0de21 = "PK_28d506c0c42eee8bad02ba0de21",
}

/** input type for inserting data into table "team_backlog_linkages" */
export type TeamBacklogLinkagesInsertInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  backlogUrl?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamBacklogLinkagesMaxFields = {
  accessToken?: Maybe<Scalars["String"]>;
  backlogUrl?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type TeamBacklogLinkagesMinFields = {
  accessToken?: Maybe<Scalars["String"]>;
  backlogUrl?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "team_backlog_linkages" */
export type TeamBacklogLinkagesMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamBacklogLinkages>;
};

/** on_conflict condition type for table "team_backlog_linkages" */
export type TeamBacklogLinkagesOnConflict = {
  constraint: TeamBacklogLinkagesConstraint;
  updateColumns?: Array<TeamBacklogLinkagesUpdateColumn>;
  where?: InputMaybe<TeamBacklogLinkagesBoolExp>;
};

/** Ordering options when selecting data from "team_backlog_linkages". */
export type TeamBacklogLinkagesOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  backlogUrl?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_backlog_linkages */
export type TeamBacklogLinkagesPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_backlog_linkages" */
export enum TeamBacklogLinkagesSelectColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  BacklogUrl = "backlogUrl",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_backlog_linkages" */
export type TeamBacklogLinkagesSetInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  backlogUrl?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "team_backlog_linkages" */
export type TeamBacklogLinkagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamBacklogLinkagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamBacklogLinkagesStreamCursorValueInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  backlogUrl?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "team_backlog_linkages" */
export enum TeamBacklogLinkagesUpdateColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  BacklogUrl = "backlogUrl",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamBacklogLinkagesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamBacklogLinkagesSetInput>;
  /** filter the rows which have to be updated */
  where: TeamBacklogLinkagesBoolExp;
};

/** columns and relationships of "team_freee_linkage_members" */
export type TeamFreeeLinkageMembers = {
  createdAt: Scalars["timestamptz"];
  employeeId: Scalars["Int"];
  id: Scalars["String"];
  teamId: Scalars["String"];
  teamMemberId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersAggregate = {
  aggregate?: Maybe<TeamFreeeLinkageMembersAggregateFields>;
  nodes: Array<TeamFreeeLinkageMembers>;
};

/** aggregate fields of "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersAggregateFields = {
  avg?: Maybe<TeamFreeeLinkageMembersAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<TeamFreeeLinkageMembersMaxFields>;
  min?: Maybe<TeamFreeeLinkageMembersMinFields>;
  stddev?: Maybe<TeamFreeeLinkageMembersStddevFields>;
  stddevPop?: Maybe<TeamFreeeLinkageMembersStddevPopFields>;
  stddevSamp?: Maybe<TeamFreeeLinkageMembersStddevSampFields>;
  sum?: Maybe<TeamFreeeLinkageMembersSumFields>;
  varPop?: Maybe<TeamFreeeLinkageMembersVarPopFields>;
  varSamp?: Maybe<TeamFreeeLinkageMembersVarSampFields>;
  variance?: Maybe<TeamFreeeLinkageMembersVarianceFields>;
};

/** aggregate fields of "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamFreeeLinkageMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type TeamFreeeLinkageMembersAvgFields = {
  employeeId?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "team_freee_linkage_members". All fields are combined with a logical 'AND'. */
export type TeamFreeeLinkageMembersBoolExp = {
  _and?: InputMaybe<Array<TeamFreeeLinkageMembersBoolExp>>;
  _not?: InputMaybe<TeamFreeeLinkageMembersBoolExp>;
  _or?: InputMaybe<Array<TeamFreeeLinkageMembersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  employeeId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMemberId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_freee_linkage_members" */
export enum TeamFreeeLinkageMembersConstraint {
  /** unique or primary key constraint on columns "id", "team_id" */
  Idx_4f36736f5028697a749aadf0a0 = "IDX_4f36736f5028697a749aadf0a0",
  /** unique or primary key constraint on columns "team_member_id", "team_id" */
  Idx_484bc5c1ff3c5847c707e8457b = "IDX_484bc5c1ff3c5847c707e8457b",
  /** unique or primary key constraint on columns "employee_id", "team_id" */
  Idx_39015bd01bb0dca6db90447d66 = "IDX_39015bd01bb0dca6db90447d66",
  /** unique or primary key constraint on columns "id" */
  Pk_2a01fec7b9e752ef6bbb7108ae7 = "PK_2a01fec7b9e752ef6bbb7108ae7",
}

/** input type for incrementing numeric columns in table "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersIncInput = {
  employeeId?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  employeeId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamFreeeLinkageMembersMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  employeeId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type TeamFreeeLinkageMembersMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  employeeId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamFreeeLinkageMembers>;
};

/** on_conflict condition type for table "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersOnConflict = {
  constraint: TeamFreeeLinkageMembersConstraint;
  updateColumns?: Array<TeamFreeeLinkageMembersUpdateColumn>;
  where?: InputMaybe<TeamFreeeLinkageMembersBoolExp>;
};

/** Ordering options when selecting data from "team_freee_linkage_members". */
export type TeamFreeeLinkageMembersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  employeeId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_freee_linkage_members */
export type TeamFreeeLinkageMembersPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_freee_linkage_members" */
export enum TeamFreeeLinkageMembersSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  EmployeeId = "employeeId",
  /** column name */
  Id = "id",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  employeeId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type TeamFreeeLinkageMembersStddevFields = {
  employeeId?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevPop on columns */
export type TeamFreeeLinkageMembersStddevPopFields = {
  employeeId?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevSamp on columns */
export type TeamFreeeLinkageMembersStddevSampFields = {
  employeeId?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "team_freee_linkage_members" */
export type TeamFreeeLinkageMembersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamFreeeLinkageMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamFreeeLinkageMembersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  employeeId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type TeamFreeeLinkageMembersSumFields = {
  employeeId?: Maybe<Scalars["Int"]>;
};

/** update columns of table "team_freee_linkage_members" */
export enum TeamFreeeLinkageMembersUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  EmployeeId = "employeeId",
  /** column name */
  Id = "id",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamFreeeLinkageMembersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TeamFreeeLinkageMembersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamFreeeLinkageMembersSetInput>;
  /** filter the rows which have to be updated */
  where: TeamFreeeLinkageMembersBoolExp;
};

/** aggregate varPop on columns */
export type TeamFreeeLinkageMembersVarPopFields = {
  employeeId?: Maybe<Scalars["Float"]>;
};

/** aggregate varSamp on columns */
export type TeamFreeeLinkageMembersVarSampFields = {
  employeeId?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type TeamFreeeLinkageMembersVarianceFields = {
  employeeId?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "team_github_linkage_projects" */
export type TeamGithubLinkageProjects = {
  createdAt: Scalars["timestamptz"];
  githubRepositoryId: Scalars["Int"];
  id: Scalars["String"];
  linkId: Scalars["String"];
  /** An object relationship */
  project: Projects;
  projectId: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsAggregate = {
  aggregate?: Maybe<TeamGithubLinkageProjectsAggregateFields>;
  nodes: Array<TeamGithubLinkageProjects>;
};

export type TeamGithubLinkageProjectsAggregateBoolExp = {
  count?: InputMaybe<TeamGithubLinkageProjectsAggregateBoolExpCount>;
};

/** aggregate fields of "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsAggregateFields = {
  avg?: Maybe<TeamGithubLinkageProjectsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<TeamGithubLinkageProjectsMaxFields>;
  min?: Maybe<TeamGithubLinkageProjectsMinFields>;
  stddev?: Maybe<TeamGithubLinkageProjectsStddevFields>;
  stddevPop?: Maybe<TeamGithubLinkageProjectsStddevPopFields>;
  stddevSamp?: Maybe<TeamGithubLinkageProjectsStddevSampFields>;
  sum?: Maybe<TeamGithubLinkageProjectsSumFields>;
  varPop?: Maybe<TeamGithubLinkageProjectsVarPopFields>;
  varSamp?: Maybe<TeamGithubLinkageProjectsVarSampFields>;
  variance?: Maybe<TeamGithubLinkageProjectsVarianceFields>;
};

/** aggregate fields of "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsAggregateOrderBy = {
  avg?: InputMaybe<TeamGithubLinkageProjectsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TeamGithubLinkageProjectsMaxOrderBy>;
  min?: InputMaybe<TeamGithubLinkageProjectsMinOrderBy>;
  stddev?: InputMaybe<TeamGithubLinkageProjectsStddevOrderBy>;
  stddevPop?: InputMaybe<TeamGithubLinkageProjectsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<TeamGithubLinkageProjectsStddevSampOrderBy>;
  sum?: InputMaybe<TeamGithubLinkageProjectsSumOrderBy>;
  varPop?: InputMaybe<TeamGithubLinkageProjectsVarPopOrderBy>;
  varSamp?: InputMaybe<TeamGithubLinkageProjectsVarSampOrderBy>;
  variance?: InputMaybe<TeamGithubLinkageProjectsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsArrRelInsertInput = {
  data: Array<TeamGithubLinkageProjectsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TeamGithubLinkageProjectsOnConflict>;
};

/** aggregate avg on columns */
export type TeamGithubLinkageProjectsAvgFields = {
  githubRepositoryId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsAvgOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table "team_github_linkage_projects".
 * All fields are combined with a logical 'AND'.
 */
export type TeamGithubLinkageProjectsBoolExp = {
  _and?: InputMaybe<Array<TeamGithubLinkageProjectsBoolExp>>;
  _not?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
  _or?: InputMaybe<Array<TeamGithubLinkageProjectsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  githubRepositoryId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  linkId?: InputMaybe<StringComparisonExp>;
  project?: InputMaybe<ProjectsBoolExp>;
  projectId?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_github_linkage_projects" */
export enum TeamGithubLinkageProjectsConstraint {
  /** unique or primary key constraint on columns "link_id", "project_id", "github_repository_id", "team_id" */
  IdxCdf783272efee8c55d96ae4de1 = "IDX_cdf783272efee8c55d96ae4de1",
  /** unique or primary key constraint on columns "id" */
  PkE62eb727e5c7d04f53e2ecf0caf = "PK_e62eb727e5c7d04f53e2ecf0caf",
}

/** input type for incrementing numeric columns in table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsIncInput = {
  githubRepositoryId?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  githubRepositoryId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  linkId?: InputMaybe<Scalars["String"]>;
  project?: InputMaybe<ProjectsObjRelInsertInput>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamGithubLinkageProjectsMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  githubRepositoryId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  linkId?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  githubRepositoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkId?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TeamGithubLinkageProjectsMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  githubRepositoryId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  linkId?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  githubRepositoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkId?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamGithubLinkageProjects>;
};

/** on_conflict condition type for table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsOnConflict = {
  constraint: TeamGithubLinkageProjectsConstraint;
  updateColumns?: Array<TeamGithubLinkageProjectsUpdateColumn>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

/** Ordering options when selecting data from "team_github_linkage_projects". */
export type TeamGithubLinkageProjectsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  githubRepositoryId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkId?: InputMaybe<OrderBy>;
  project?: InputMaybe<ProjectsOrderBy>;
  projectId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_github_linkage_projects */
export type TeamGithubLinkageProjectsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_github_linkage_projects" */
export enum TeamGithubLinkageProjectsSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  GithubRepositoryId = "githubRepositoryId",
  /** column name */
  Id = "id",
  /** column name */
  LinkId = "linkId",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  githubRepositoryId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  linkId?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type TeamGithubLinkageProjectsStddevFields = {
  githubRepositoryId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsStddevOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TeamGithubLinkageProjectsStddevPopFields = {
  githubRepositoryId?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsStddevPopOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TeamGithubLinkageProjectsStddevSampFields = {
  githubRepositoryId?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsStddevSampOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamGithubLinkageProjectsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamGithubLinkageProjectsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  githubRepositoryId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  linkId?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type TeamGithubLinkageProjectsSumFields = {
  githubRepositoryId?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsSumOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/** update columns of table "team_github_linkage_projects" */
export enum TeamGithubLinkageProjectsUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  GithubRepositoryId = "githubRepositoryId",
  /** column name */
  Id = "id",
  /** column name */
  LinkId = "linkId",
  /** column name */
  ProjectId = "projectId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamGithubLinkageProjectsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TeamGithubLinkageProjectsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamGithubLinkageProjectsSetInput>;
  /** filter the rows which have to be updated */
  where: TeamGithubLinkageProjectsBoolExp;
};

/** aggregate varPop on columns */
export type TeamGithubLinkageProjectsVarPopFields = {
  githubRepositoryId?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsVarPopOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TeamGithubLinkageProjectsVarSampFields = {
  githubRepositoryId?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsVarSampOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TeamGithubLinkageProjectsVarianceFields = {
  githubRepositoryId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "team_github_linkage_projects" */
export type TeamGithubLinkageProjectsVarianceOrderBy = {
  githubRepositoryId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "team_github_linkages" */
export type TeamGithubLinkages = {
  accessToken: Scalars["String"];
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  installationId?: Maybe<Scalars["Int"]>;
  isRefreshTokenExpired: Scalars["Boolean"];
  organizationName?: Maybe<Scalars["String"]>;
  refreshToken: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_github_linkages" */
export type TeamGithubLinkagesAggregate = {
  aggregate?: Maybe<TeamGithubLinkagesAggregateFields>;
  nodes: Array<TeamGithubLinkages>;
};

/** aggregate fields of "team_github_linkages" */
export type TeamGithubLinkagesAggregateFields = {
  avg?: Maybe<TeamGithubLinkagesAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<TeamGithubLinkagesMaxFields>;
  min?: Maybe<TeamGithubLinkagesMinFields>;
  stddev?: Maybe<TeamGithubLinkagesStddevFields>;
  stddevPop?: Maybe<TeamGithubLinkagesStddevPopFields>;
  stddevSamp?: Maybe<TeamGithubLinkagesStddevSampFields>;
  sum?: Maybe<TeamGithubLinkagesSumFields>;
  varPop?: Maybe<TeamGithubLinkagesVarPopFields>;
  varSamp?: Maybe<TeamGithubLinkagesVarSampFields>;
  variance?: Maybe<TeamGithubLinkagesVarianceFields>;
};

/** aggregate fields of "team_github_linkages" */
export type TeamGithubLinkagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamGithubLinkagesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type TeamGithubLinkagesAvgFields = {
  installationId?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "team_github_linkages". All fields are combined with a logical 'AND'. */
export type TeamGithubLinkagesBoolExp = {
  _and?: InputMaybe<Array<TeamGithubLinkagesBoolExp>>;
  _not?: InputMaybe<TeamGithubLinkagesBoolExp>;
  _or?: InputMaybe<Array<TeamGithubLinkagesBoolExp>>;
  accessToken?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  installationId?: InputMaybe<IntComparisonExp>;
  isRefreshTokenExpired?: InputMaybe<BooleanComparisonExp>;
  organizationName?: InputMaybe<StringComparisonExp>;
  refreshToken?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_github_linkages" */
export enum TeamGithubLinkagesConstraint {
  /** unique or primary key constraint on columns "organization_name", "team_id" */
  Idx_235aa019991d794a8d644311d4 = "IDX_235aa019991d794a8d644311d4",
  /** unique or primary key constraint on columns "id" */
  Pk_391e65cb5e5129cc7a3de64596a = "PK_391e65cb5e5129cc7a3de64596a",
}

/** input type for incrementing numeric columns in table "team_github_linkages" */
export type TeamGithubLinkagesIncInput = {
  installationId?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "team_github_linkages" */
export type TeamGithubLinkagesInsertInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  installationId?: InputMaybe<Scalars["Int"]>;
  isRefreshTokenExpired?: InputMaybe<Scalars["Boolean"]>;
  organizationName?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamGithubLinkagesMaxFields = {
  accessToken?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  installationId?: Maybe<Scalars["Int"]>;
  organizationName?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type TeamGithubLinkagesMinFields = {
  accessToken?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  installationId?: Maybe<Scalars["Int"]>;
  organizationName?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "team_github_linkages" */
export type TeamGithubLinkagesMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamGithubLinkages>;
};

/** on_conflict condition type for table "team_github_linkages" */
export type TeamGithubLinkagesOnConflict = {
  constraint: TeamGithubLinkagesConstraint;
  updateColumns?: Array<TeamGithubLinkagesUpdateColumn>;
  where?: InputMaybe<TeamGithubLinkagesBoolExp>;
};

/** Ordering options when selecting data from "team_github_linkages". */
export type TeamGithubLinkagesOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  installationId?: InputMaybe<OrderBy>;
  isRefreshTokenExpired?: InputMaybe<OrderBy>;
  organizationName?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_github_linkages */
export type TeamGithubLinkagesPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_github_linkages" */
export enum TeamGithubLinkagesSelectColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  InstallationId = "installationId",
  /** column name */
  IsRefreshTokenExpired = "isRefreshTokenExpired",
  /** column name */
  OrganizationName = "organizationName",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_github_linkages" */
export type TeamGithubLinkagesSetInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  installationId?: InputMaybe<Scalars["Int"]>;
  isRefreshTokenExpired?: InputMaybe<Scalars["Boolean"]>;
  organizationName?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type TeamGithubLinkagesStddevFields = {
  installationId?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevPop on columns */
export type TeamGithubLinkagesStddevPopFields = {
  installationId?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevSamp on columns */
export type TeamGithubLinkagesStddevSampFields = {
  installationId?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "team_github_linkages" */
export type TeamGithubLinkagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamGithubLinkagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamGithubLinkagesStreamCursorValueInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  installationId?: InputMaybe<Scalars["Int"]>;
  isRefreshTokenExpired?: InputMaybe<Scalars["Boolean"]>;
  organizationName?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type TeamGithubLinkagesSumFields = {
  installationId?: Maybe<Scalars["Int"]>;
};

/** update columns of table "team_github_linkages" */
export enum TeamGithubLinkagesUpdateColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  InstallationId = "installationId",
  /** column name */
  IsRefreshTokenExpired = "isRefreshTokenExpired",
  /** column name */
  OrganizationName = "organizationName",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamGithubLinkagesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TeamGithubLinkagesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamGithubLinkagesSetInput>;
  /** filter the rows which have to be updated */
  where: TeamGithubLinkagesBoolExp;
};

/** aggregate varPop on columns */
export type TeamGithubLinkagesVarPopFields = {
  installationId?: Maybe<Scalars["Float"]>;
};

/** aggregate varSamp on columns */
export type TeamGithubLinkagesVarSampFields = {
  installationId?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type TeamGithubLinkagesVarianceFields = {
  installationId?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkages = {
  accessToken: Scalars["String"];
  createdAt: Scalars["timestamptz"];
  id: Scalars["String"];
  refreshToken: Scalars["String"];
  scope: Scalars["String"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesAggregate = {
  aggregate?: Maybe<TeamGoogleCalendarLinkagesAggregateFields>;
  nodes: Array<TeamGoogleCalendarLinkages>;
};

/** aggregate fields of "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TeamGoogleCalendarLinkagesMaxFields>;
  min?: Maybe<TeamGoogleCalendarLinkagesMinFields>;
};

/** aggregate fields of "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamGoogleCalendarLinkagesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Boolean expression to filter rows from the table
 * "team_google_calendar_linkages". All fields are combined with a logical 'AND'.
 */
export type TeamGoogleCalendarLinkagesBoolExp = {
  _and?: InputMaybe<Array<TeamGoogleCalendarLinkagesBoolExp>>;
  _not?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
  _or?: InputMaybe<Array<TeamGoogleCalendarLinkagesBoolExp>>;
  accessToken?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  refreshToken?: InputMaybe<StringComparisonExp>;
  scope?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_google_calendar_linkages" */
export enum TeamGoogleCalendarLinkagesConstraint {
  /** unique or primary key constraint on columns "team_id" */
  Idx_07e64f158b7cddd932e9578265 = "IDX_07e64f158b7cddd932e9578265",
  /** unique or primary key constraint on columns "id" */
  Pk_2b7bd5caa9b0c3f6e51b6b93e8b = "PK_2b7bd5caa9b0c3f6e51b6b93e8b",
}

/** input type for inserting data into table "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesInsertInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  scope?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamGoogleCalendarLinkagesMaxFields = {
  accessToken?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  scope?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type TeamGoogleCalendarLinkagesMinFields = {
  accessToken?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  scope?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamGoogleCalendarLinkages>;
};

/** on_conflict condition type for table "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesOnConflict = {
  constraint: TeamGoogleCalendarLinkagesConstraint;
  updateColumns?: Array<TeamGoogleCalendarLinkagesUpdateColumn>;
  where?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
};

/** Ordering options when selecting data from "team_google_calendar_linkages". */
export type TeamGoogleCalendarLinkagesOrderBy = {
  accessToken?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_google_calendar_linkages */
export type TeamGoogleCalendarLinkagesPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_google_calendar_linkages" */
export enum TeamGoogleCalendarLinkagesSelectColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  Scope = "scope",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesSetInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  scope?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "team_google_calendar_linkages" */
export type TeamGoogleCalendarLinkagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamGoogleCalendarLinkagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamGoogleCalendarLinkagesStreamCursorValueInput = {
  accessToken?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  scope?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "team_google_calendar_linkages" */
export enum TeamGoogleCalendarLinkagesUpdateColumn {
  /** column name */
  AccessToken = "accessToken",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Id = "id",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  Scope = "scope",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamGoogleCalendarLinkagesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamGoogleCalendarLinkagesSetInput>;
  /** filter the rows which have to be updated */
  where: TeamGoogleCalendarLinkagesBoolExp;
};

/** columns and relationships of "team_invitable_count_view" */
export type TeamInvitableCountView = {
  accountQuantity?: Maybe<Scalars["Int"]>;
  invitableCount?: Maybe<Scalars["Int"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberCount?: Maybe<Scalars["Int"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "team_invitable_count_view" */
export type TeamInvitableCountViewAggregate = {
  aggregate?: Maybe<TeamInvitableCountViewAggregateFields>;
  nodes: Array<TeamInvitableCountView>;
};

/** aggregate fields of "team_invitable_count_view" */
export type TeamInvitableCountViewAggregateFields = {
  avg?: Maybe<TeamInvitableCountViewAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<TeamInvitableCountViewMaxFields>;
  min?: Maybe<TeamInvitableCountViewMinFields>;
  stddev?: Maybe<TeamInvitableCountViewStddevFields>;
  stddevPop?: Maybe<TeamInvitableCountViewStddevPopFields>;
  stddevSamp?: Maybe<TeamInvitableCountViewStddevSampFields>;
  sum?: Maybe<TeamInvitableCountViewSumFields>;
  varPop?: Maybe<TeamInvitableCountViewVarPopFields>;
  varSamp?: Maybe<TeamInvitableCountViewVarSampFields>;
  variance?: Maybe<TeamInvitableCountViewVarianceFields>;
};

/** aggregate fields of "team_invitable_count_view" */
export type TeamInvitableCountViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamInvitableCountViewSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type TeamInvitableCountViewAvgFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
  invitableCount?: Maybe<Scalars["Float"]>;
  teamMemberCount?: Maybe<Scalars["Float"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "team_invitable_count_view". All fields are combined with a logical 'AND'. */
export type TeamInvitableCountViewBoolExp = {
  _and?: InputMaybe<Array<TeamInvitableCountViewBoolExp>>;
  _not?: InputMaybe<TeamInvitableCountViewBoolExp>;
  _or?: InputMaybe<Array<TeamInvitableCountViewBoolExp>>;
  accountQuantity?: InputMaybe<IntComparisonExp>;
  invitableCount?: InputMaybe<IntComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMemberCount?: InputMaybe<IntComparisonExp>;
  teamMemberInvitationCount?: InputMaybe<IntComparisonExp>;
};

/** input type for inserting data into table "team_invitable_count_view" */
export type TeamInvitableCountViewInsertInput = {
  accountQuantity?: InputMaybe<Scalars["Int"]>;
  invitableCount?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberCount?: InputMaybe<Scalars["Int"]>;
  teamMemberInvitationCount?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type TeamInvitableCountViewMaxFields = {
  accountQuantity?: Maybe<Scalars["Int"]>;
  invitableCount?: Maybe<Scalars["Int"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberCount?: Maybe<Scalars["Int"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type TeamInvitableCountViewMinFields = {
  accountQuantity?: Maybe<Scalars["Int"]>;
  invitableCount?: Maybe<Scalars["Int"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberCount?: Maybe<Scalars["Int"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Int"]>;
};

/** input type for inserting object relation for remote table "team_invitable_count_view" */
export type TeamInvitableCountViewObjRelInsertInput = {
  data: TeamInvitableCountViewInsertInput;
};

/** Ordering options when selecting data from "team_invitable_count_view". */
export type TeamInvitableCountViewOrderBy = {
  accountQuantity?: InputMaybe<OrderBy>;
  invitableCount?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberCount?: InputMaybe<OrderBy>;
  teamMemberInvitationCount?: InputMaybe<OrderBy>;
};

/** select columns of table "team_invitable_count_view" */
export enum TeamInvitableCountViewSelectColumn {
  /** column name */
  AccountQuantity = "accountQuantity",
  /** column name */
  InvitableCount = "invitableCount",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberCount = "teamMemberCount",
  /** column name */
  TeamMemberInvitationCount = "teamMemberInvitationCount",
}

/** aggregate stddev on columns */
export type TeamInvitableCountViewStddevFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
  invitableCount?: Maybe<Scalars["Float"]>;
  teamMemberCount?: Maybe<Scalars["Float"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevPop on columns */
export type TeamInvitableCountViewStddevPopFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
  invitableCount?: Maybe<Scalars["Float"]>;
  teamMemberCount?: Maybe<Scalars["Float"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Float"]>;
};

/** aggregate stddevSamp on columns */
export type TeamInvitableCountViewStddevSampFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
  invitableCount?: Maybe<Scalars["Float"]>;
  teamMemberCount?: Maybe<Scalars["Float"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "team_invitable_count_view" */
export type TeamInvitableCountViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamInvitableCountViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamInvitableCountViewStreamCursorValueInput = {
  accountQuantity?: InputMaybe<Scalars["Int"]>;
  invitableCount?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberCount?: InputMaybe<Scalars["Int"]>;
  teamMemberInvitationCount?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type TeamInvitableCountViewSumFields = {
  accountQuantity?: Maybe<Scalars["Int"]>;
  invitableCount?: Maybe<Scalars["Int"]>;
  teamMemberCount?: Maybe<Scalars["Int"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Int"]>;
};

/** aggregate varPop on columns */
export type TeamInvitableCountViewVarPopFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
  invitableCount?: Maybe<Scalars["Float"]>;
  teamMemberCount?: Maybe<Scalars["Float"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Float"]>;
};

/** aggregate varSamp on columns */
export type TeamInvitableCountViewVarSampFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
  invitableCount?: Maybe<Scalars["Float"]>;
  teamMemberCount?: Maybe<Scalars["Float"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type TeamInvitableCountViewVarianceFields = {
  accountQuantity?: Maybe<Scalars["Float"]>;
  invitableCount?: Maybe<Scalars["Float"]>;
  teamMemberCount?: Maybe<Scalars["Float"]>;
  teamMemberInvitationCount?: Maybe<Scalars["Float"]>;
};

export type TeamMemberEmployeeRelation = {
  employeeId: Scalars["Int"];
  teamMemberId: Scalars["String"];
};

/** columns and relationships of "team_member_invitations" */
export type TeamMemberInvitations = {
  code: Scalars["String"];
  createdAt: Scalars["timestamptz"];
  email: Scalars["String"];
  id: Scalars["String"];
  lastSendAt: Scalars["timestamptz"];
  projectIds?: Maybe<Scalars["_text"]>;
  role: Scalars["String"];
  /** An object relationship */
  team: Teams;
  teamId: Scalars["String"];
  /** An object relationship */
  team_invitable_count_view?: Maybe<TeamInvitableCountView>;
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_member_invitations" */
export type TeamMemberInvitationsAggregate = {
  aggregate?: Maybe<TeamMemberInvitationsAggregateFields>;
  nodes: Array<TeamMemberInvitations>;
};

export type TeamMemberInvitationsAggregateBoolExp = {
  count?: InputMaybe<TeamMemberInvitationsAggregateBoolExpCount>;
};

/** aggregate fields of "team_member_invitations" */
export type TeamMemberInvitationsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TeamMemberInvitationsMaxFields>;
  min?: Maybe<TeamMemberInvitationsMinFields>;
};

/** aggregate fields of "team_member_invitations" */
export type TeamMemberInvitationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "team_member_invitations" */
export type TeamMemberInvitationsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TeamMemberInvitationsMaxOrderBy>;
  min?: InputMaybe<TeamMemberInvitationsMinOrderBy>;
};

/** input type for inserting array relation for remote table "team_member_invitations" */
export type TeamMemberInvitationsArrRelInsertInput = {
  data: Array<TeamMemberInvitationsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TeamMemberInvitationsOnConflict>;
};

/** Boolean expression to filter rows from the table "team_member_invitations". All fields are combined with a logical 'AND'. */
export type TeamMemberInvitationsBoolExp = {
  _and?: InputMaybe<Array<TeamMemberInvitationsBoolExp>>;
  _not?: InputMaybe<TeamMemberInvitationsBoolExp>;
  _or?: InputMaybe<Array<TeamMemberInvitationsBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastSendAt?: InputMaybe<TimestamptzComparisonExp>;
  projectIds?: InputMaybe<_TextComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  team?: InputMaybe<TeamsBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  team_invitable_count_view?: InputMaybe<TeamInvitableCountViewBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_member_invitations" */
export enum TeamMemberInvitationsConstraint {
  /** unique or primary key constraint on columns "code" */
  Idx_83e1f240329ba16662e89c6f66 = "IDX_83e1f240329ba16662e89c6f66",
  /** unique or primary key constraint on columns "email", "team_id" */
  Idx_1466056d8d2d30107c7e625bf2 = "IDX_1466056d8d2d30107c7e625bf2",
  /** unique or primary key constraint on columns "id" */
  PkAf8e0da663f53b1d341600d0bc9 = "PK_af8e0da663f53b1d341600d0bc9",
}

/** input type for inserting data into table "team_member_invitations" */
export type TeamMemberInvitationsInsertInput = {
  code?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastSendAt?: InputMaybe<Scalars["timestamptz"]>;
  projectIds?: InputMaybe<Scalars["_text"]>;
  role?: InputMaybe<Scalars["String"]>;
  team?: InputMaybe<TeamsObjRelInsertInput>;
  teamId?: InputMaybe<Scalars["String"]>;
  team_invitable_count_view?: InputMaybe<TeamInvitableCountViewObjRelInsertInput>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamMemberInvitationsMaxFields = {
  code?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastSendAt?: Maybe<Scalars["timestamptz"]>;
  role?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "team_member_invitations" */
export type TeamMemberInvitationsMaxOrderBy = {
  code?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSendAt?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TeamMemberInvitationsMinFields = {
  code?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastSendAt?: Maybe<Scalars["timestamptz"]>;
  role?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "team_member_invitations" */
export type TeamMemberInvitationsMinOrderBy = {
  code?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSendAt?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "team_member_invitations" */
export type TeamMemberInvitationsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamMemberInvitations>;
};

/** on_conflict condition type for table "team_member_invitations" */
export type TeamMemberInvitationsOnConflict = {
  constraint: TeamMemberInvitationsConstraint;
  updateColumns?: Array<TeamMemberInvitationsUpdateColumn>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

/** Ordering options when selecting data from "team_member_invitations". */
export type TeamMemberInvitationsOrderBy = {
  code?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSendAt?: InputMaybe<OrderBy>;
  projectIds?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  team?: InputMaybe<TeamsOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  team_invitable_count_view?: InputMaybe<TeamInvitableCountViewOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_member_invitations */
export type TeamMemberInvitationsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_member_invitations" */
export enum TeamMemberInvitationsSelectColumn {
  /** column name */
  Code = "code",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  LastSendAt = "lastSendAt",
  /** column name */
  ProjectIds = "projectIds",
  /** column name */
  Role = "role",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_member_invitations" */
export type TeamMemberInvitationsSetInput = {
  code?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastSendAt?: InputMaybe<Scalars["timestamptz"]>;
  projectIds?: InputMaybe<Scalars["_text"]>;
  role?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "team_member_invitations" */
export type TeamMemberInvitationsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamMemberInvitationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamMemberInvitationsStreamCursorValueInput = {
  code?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastSendAt?: InputMaybe<Scalars["timestamptz"]>;
  projectIds?: InputMaybe<Scalars["_text"]>;
  role?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "team_member_invitations" */
export enum TeamMemberInvitationsUpdateColumn {
  /** column name */
  Code = "code",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  LastSendAt = "lastSendAt",
  /** column name */
  ProjectIds = "projectIds",
  /** column name */
  Role = "role",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamMemberInvitationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamMemberInvitationsSetInput>;
  /** filter the rows which have to be updated */
  where: TeamMemberInvitationsBoolExp;
};

/** columns and relationships of "team_member_snapshots" */
export type TeamMemberSnapshots = {
  createdAt: Scalars["timestamptz"];
  snapshotFilePath?: Maybe<Scalars["String"]>;
  teamId: Scalars["String"];
  /** An object relationship */
  teamMember?: Maybe<TeamMembers>;
  teamMemberId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
};

/** aggregated selection of "team_member_snapshots" */
export type TeamMemberSnapshotsAggregate = {
  aggregate?: Maybe<TeamMemberSnapshotsAggregateFields>;
  nodes: Array<TeamMemberSnapshots>;
};

/** aggregate fields of "team_member_snapshots" */
export type TeamMemberSnapshotsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TeamMemberSnapshotsMaxFields>;
  min?: Maybe<TeamMemberSnapshotsMinFields>;
};

/** aggregate fields of "team_member_snapshots" */
export type TeamMemberSnapshotsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamMemberSnapshotsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "team_member_snapshots". All fields are combined with a logical 'AND'. */
export type TeamMemberSnapshotsBoolExp = {
  _and?: InputMaybe<Array<TeamMemberSnapshotsBoolExp>>;
  _not?: InputMaybe<TeamMemberSnapshotsBoolExp>;
  _or?: InputMaybe<Array<TeamMemberSnapshotsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  snapshotFilePath?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMember?: InputMaybe<TeamMembersBoolExp>;
  teamMemberId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "team_member_snapshots" */
export enum TeamMemberSnapshotsConstraint {
  /** unique or primary key constraint on columns "team_member_id", "team_id" */
  Pk_726ebf24d976c40068533a80127 = "PK_726ebf24d976c40068533a80127",
  /** unique or primary key constraint on columns "team_member_id", "team_id" */
  Rel_726ebf24d976c40068533a8012 = "REL_726ebf24d976c40068533a8012",
}

/** input type for inserting data into table "team_member_snapshots" */
export type TeamMemberSnapshotsInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  snapshotFilePath?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMember?: InputMaybe<TeamMembersObjRelInsertInput>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamMemberSnapshotsMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  snapshotFilePath?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type TeamMemberSnapshotsMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  snapshotFilePath?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "team_member_snapshots" */
export type TeamMemberSnapshotsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamMemberSnapshots>;
};

/** input type for inserting object relation for remote table "team_member_snapshots" */
export type TeamMemberSnapshotsObjRelInsertInput = {
  data: TeamMemberSnapshotsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TeamMemberSnapshotsOnConflict>;
};

/** on_conflict condition type for table "team_member_snapshots" */
export type TeamMemberSnapshotsOnConflict = {
  constraint: TeamMemberSnapshotsConstraint;
  updateColumns?: Array<TeamMemberSnapshotsUpdateColumn>;
  where?: InputMaybe<TeamMemberSnapshotsBoolExp>;
};

/** Ordering options when selecting data from "team_member_snapshots". */
export type TeamMemberSnapshotsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  snapshotFilePath?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMember?: InputMaybe<TeamMembersOrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: team_member_snapshots */
export type TeamMemberSnapshotsPkColumnsInput = {
  teamId: Scalars["String"];
  teamMemberId: Scalars["String"];
};

/** select columns of table "team_member_snapshots" */
export enum TeamMemberSnapshotsSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  SnapshotFilePath = "snapshotFilePath",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "team_member_snapshots" */
export type TeamMemberSnapshotsSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  snapshotFilePath?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "team_member_snapshots" */
export type TeamMemberSnapshotsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamMemberSnapshotsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamMemberSnapshotsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  snapshotFilePath?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "team_member_snapshots" */
export enum TeamMemberSnapshotsUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  SnapshotFilePath = "snapshotFilePath",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamMemberSnapshotsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamMemberSnapshotsSetInput>;
  /** filter the rows which have to be updated */
  where: TeamMemberSnapshotsBoolExp;
};

/** columns and relationships of "team_members" */
export type TeamMembers = {
  /** An array relationship */
  activities: Array<Activities>;
  /** An aggregate relationship */
  activitiesAggregate: ActivitiesAggregate;
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  doingTask?: Maybe<Tasks>;
  doingTaskId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastWorkedAt?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  projectMembers: Array<ProjectMembers>;
  /** An aggregate relationship */
  projectMembersAggregate: ProjectMembersAggregate;
  role: Scalars["String"];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  /** An object relationship */
  team: Teams;
  teamId: Scalars["String"];
  /** An object relationship */
  teamMemberSnapshot?: Maybe<TeamMemberSnapshots>;
  updatedAt: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
  userId: Scalars["String"];
  workStatus: Scalars["String"];
  /** An array relationship */
  workTrackings: Array<WorkTrackings>;
  /** An aggregate relationship */
  workTrackingsAggregate: WorkTrackingsAggregate;
  /** An array relationship */
  works: Array<Works>;
  /** An aggregate relationship */
  worksAggregate: WorksAggregate;
};

/** columns and relationships of "team_members" */
export type TeamMembersActivitiesArgs = {
  distinctOn?: InputMaybe<Array<ActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersProjectMembersArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersProjectMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersWorkTrackingsArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingsOrderBy>>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersWorkTrackingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingsOrderBy>>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersWorksArgs = {
  distinctOn?: InputMaybe<Array<WorksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorksOrderBy>>;
  where?: InputMaybe<WorksBoolExp>;
};

/** columns and relationships of "team_members" */
export type TeamMembersWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorksOrderBy>>;
  where?: InputMaybe<WorksBoolExp>;
};

/** aggregated selection of "team_members" */
export type TeamMembersAggregate = {
  aggregate?: Maybe<TeamMembersAggregateFields>;
  nodes: Array<TeamMembers>;
};

export type TeamMembersAggregateBoolExp = {
  count?: InputMaybe<TeamMembersAggregateBoolExpCount>;
};

/** aggregate fields of "team_members" */
export type TeamMembersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TeamMembersMaxFields>;
  min?: Maybe<TeamMembersMinFields>;
};

/** aggregate fields of "team_members" */
export type TeamMembersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "team_members" */
export type TeamMembersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TeamMembersMaxOrderBy>;
  min?: InputMaybe<TeamMembersMinOrderBy>;
};

/** input type for inserting array relation for remote table "team_members" */
export type TeamMembersArrRelInsertInput = {
  data: Array<TeamMembersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TeamMembersOnConflict>;
};

/** Boolean expression to filter rows from the table "team_members". All fields are combined with a logical 'AND'. */
export type TeamMembersBoolExp = {
  _and?: InputMaybe<Array<TeamMembersBoolExp>>;
  _not?: InputMaybe<TeamMembersBoolExp>;
  _or?: InputMaybe<Array<TeamMembersBoolExp>>;
  activities?: InputMaybe<ActivitiesBoolExp>;
  activitiesAggregate?: InputMaybe<ActivitiesAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  doingTask?: InputMaybe<TasksBoolExp>;
  doingTaskId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastWorkedAt?: InputMaybe<TimestamptzComparisonExp>;
  projectMembers?: InputMaybe<ProjectMembersBoolExp>;
  projectMembersAggregate?: InputMaybe<ProjectMembersAggregateBoolExp>;
  role?: InputMaybe<StringComparisonExp>;
  tasks?: InputMaybe<TasksBoolExp>;
  tasksAggregate?: InputMaybe<TasksAggregateBoolExp>;
  team?: InputMaybe<TeamsBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMemberSnapshot?: InputMaybe<TeamMemberSnapshotsBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  workStatus?: InputMaybe<StringComparisonExp>;
  workTrackings?: InputMaybe<WorkTrackingsBoolExp>;
  workTrackingsAggregate?: InputMaybe<WorkTrackingsAggregateBoolExp>;
  works?: InputMaybe<WorksBoolExp>;
  worksAggregate?: InputMaybe<WorksAggregateBoolExp>;
};

/** unique or primary key constraints on table "team_members" */
export enum TeamMembersConstraint {
  /** unique or primary key constraint on columns "id", "team_id" */
  Idx_2e2db0f66b5ad97a3b907d9479 = "IDX_2e2db0f66b5ad97a3b907d9479",
  /** unique or primary key constraint on columns "user_id", "team_id" */
  Idx_16700535e2b087051331197718 = "IDX_16700535e2b087051331197718",
  /** unique or primary key constraint on columns "id" */
  PkCa3eae89dcf20c9fd95bf7460aa = "PK_ca3eae89dcf20c9fd95bf7460aa",
}

/** input type for inserting data into table "team_members" */
export type TeamMembersInsertInput = {
  activities?: InputMaybe<ActivitiesArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  doingTask?: InputMaybe<TasksObjRelInsertInput>;
  doingTaskId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastWorkedAt?: InputMaybe<Scalars["timestamptz"]>;
  projectMembers?: InputMaybe<ProjectMembersArrRelInsertInput>;
  role?: InputMaybe<Scalars["String"]>;
  tasks?: InputMaybe<TasksArrRelInsertInput>;
  team?: InputMaybe<TeamsObjRelInsertInput>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberSnapshot?: InputMaybe<TeamMemberSnapshotsObjRelInsertInput>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars["String"]>;
  workStatus?: InputMaybe<Scalars["String"]>;
  workTrackings?: InputMaybe<WorkTrackingsArrRelInsertInput>;
  works?: InputMaybe<WorksArrRelInsertInput>;
};

/** aggregate max on columns */
export type TeamMembersMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  doingTaskId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastWorkedAt?: Maybe<Scalars["timestamptz"]>;
  role?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  userId?: Maybe<Scalars["String"]>;
  workStatus?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "team_members" */
export type TeamMembersMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  doingTaskId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastWorkedAt?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  workStatus?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TeamMembersMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  doingTaskId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastWorkedAt?: Maybe<Scalars["timestamptz"]>;
  role?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  userId?: Maybe<Scalars["String"]>;
  workStatus?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "team_members" */
export type TeamMembersMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  doingTaskId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastWorkedAt?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  workStatus?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "team_members" */
export type TeamMembersMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<TeamMembers>;
};

/** input type for inserting object relation for remote table "team_members" */
export type TeamMembersObjRelInsertInput = {
  data: TeamMembersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TeamMembersOnConflict>;
};

/** on_conflict condition type for table "team_members" */
export type TeamMembersOnConflict = {
  constraint: TeamMembersConstraint;
  updateColumns?: Array<TeamMembersUpdateColumn>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

/** Ordering options when selecting data from "team_members". */
export type TeamMembersOrderBy = {
  activitiesAggregate?: InputMaybe<ActivitiesAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  doingTask?: InputMaybe<TasksOrderBy>;
  doingTaskId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastWorkedAt?: InputMaybe<OrderBy>;
  projectMembersAggregate?: InputMaybe<ProjectMembersAggregateOrderBy>;
  role?: InputMaybe<OrderBy>;
  tasksAggregate?: InputMaybe<TasksAggregateOrderBy>;
  team?: InputMaybe<TeamsOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberSnapshot?: InputMaybe<TeamMemberSnapshotsOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  workStatus?: InputMaybe<OrderBy>;
  workTrackingsAggregate?: InputMaybe<WorkTrackingsAggregateOrderBy>;
  worksAggregate?: InputMaybe<WorksAggregateOrderBy>;
};

/** primary key columns input for table: team_members */
export type TeamMembersPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "team_members" */
export enum TeamMembersSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DoingTaskId = "doingTaskId",
  /** column name */
  Id = "id",
  /** column name */
  LastWorkedAt = "lastWorkedAt",
  /** column name */
  Role = "role",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
  /** column name */
  WorkStatus = "workStatus",
}

/** input type for updating data in table "team_members" */
export type TeamMembersSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  doingTaskId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastWorkedAt?: InputMaybe<Scalars["timestamptz"]>;
  role?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  userId?: InputMaybe<Scalars["String"]>;
  workStatus?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "team_members" */
export type TeamMembersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamMembersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamMembersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  doingTaskId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  lastWorkedAt?: InputMaybe<Scalars["timestamptz"]>;
  role?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  userId?: InputMaybe<Scalars["String"]>;
  workStatus?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "team_members" */
export enum TeamMembersUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  DoingTaskId = "doingTaskId",
  /** column name */
  Id = "id",
  /** column name */
  LastWorkedAt = "lastWorkedAt",
  /** column name */
  Role = "role",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  UserId = "userId",
  /** column name */
  WorkStatus = "workStatus",
}

export type TeamMembersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamMembersSetInput>;
  /** filter the rows which have to be updated */
  where: TeamMembersBoolExp;
};

/** columns and relationships of "teams" */
export type Teams = {
  /** An array relationship */
  contracts: Array<Contracts>;
  /** An aggregate relationship */
  contractsAggregate: ContractsAggregate;
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  enabledBlurBackground: Scalars["Boolean"];
  enabledSnapshot: Scalars["Boolean"];
  forceSso: Scalars["String"];
  forceTwofa: Scalars["Boolean"];
  id: Scalars["String"];
  imageFilePath?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projectsAggregate: ProjectsAggregate;
  /** An object relationship */
  stripeCustomer?: Maybe<StripeCustomers>;
  /** An array relationship */
  teamMemberInvitations: Array<TeamMemberInvitations>;
  /** An aggregate relationship */
  teamMemberInvitationsAggregate: TeamMemberInvitationsAggregate;
  /** An array relationship */
  teamMembers: Array<TeamMembers>;
  /** An aggregate relationship */
  teamMembersAggregate: TeamMembersAggregate;
  updatedAt: Scalars["timestamptz"];
};

/** columns and relationships of "teams" */
export type TeamsContractsArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsProjectsArgs = {
  distinctOn?: InputMaybe<Array<ProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  where?: InputMaybe<ProjectsBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  where?: InputMaybe<ProjectsBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsTeamMemberInvitationsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberInvitationsOrderBy>>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsTeamMemberInvitationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberInvitationsOrderBy>>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsTeamMembersArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

/** columns and relationships of "teams" */
export type TeamsTeamMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

/** aggregated selection of "teams" */
export type TeamsAggregate = {
  aggregate?: Maybe<TeamsAggregateFields>;
  nodes: Array<Teams>;
};

/** aggregate fields of "teams" */
export type TeamsAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<TeamsMaxFields>;
  min?: Maybe<TeamsMinFields>;
};

/** aggregate fields of "teams" */
export type TeamsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TeamsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type TeamsBoolExp = {
  _and?: InputMaybe<Array<TeamsBoolExp>>;
  _not?: InputMaybe<TeamsBoolExp>;
  _or?: InputMaybe<Array<TeamsBoolExp>>;
  contracts?: InputMaybe<ContractsBoolExp>;
  contractsAggregate?: InputMaybe<ContractsAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  enabledBlurBackground?: InputMaybe<BooleanComparisonExp>;
  enabledSnapshot?: InputMaybe<BooleanComparisonExp>;
  forceSso?: InputMaybe<StringComparisonExp>;
  forceTwofa?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  imageFilePath?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  projects?: InputMaybe<ProjectsBoolExp>;
  projectsAggregate?: InputMaybe<ProjectsAggregateBoolExp>;
  stripeCustomer?: InputMaybe<StripeCustomersBoolExp>;
  teamMemberInvitations?: InputMaybe<TeamMemberInvitationsBoolExp>;
  teamMemberInvitationsAggregate?: InputMaybe<TeamMemberInvitationsAggregateBoolExp>;
  teamMembers?: InputMaybe<TeamMembersBoolExp>;
  teamMembersAggregate?: InputMaybe<TeamMembersAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "teams" */
export enum TeamsConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_7e5523774a38b08a6236d322403 = "PK_7e5523774a38b08a6236d322403",
}

/** input type for inserting data into table "teams" */
export type TeamsInsertInput = {
  contracts?: InputMaybe<ContractsArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  enabledBlurBackground?: InputMaybe<Scalars["Boolean"]>;
  enabledSnapshot?: InputMaybe<Scalars["Boolean"]>;
  forceSso?: InputMaybe<Scalars["String"]>;
  forceTwofa?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  imageFilePath?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  projects?: InputMaybe<ProjectsArrRelInsertInput>;
  stripeCustomer?: InputMaybe<StripeCustomersObjRelInsertInput>;
  teamMemberInvitations?: InputMaybe<TeamMemberInvitationsArrRelInsertInput>;
  teamMembers?: InputMaybe<TeamMembersArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type TeamsMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  forceSso?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageFilePath?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type TeamsMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  forceSso?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageFilePath?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "teams" */
export type TeamsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** input type for inserting object relation for remote table "teams" */
export type TeamsObjRelInsertInput = {
  data: TeamsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TeamsOnConflict>;
};

/** on_conflict condition type for table "teams" */
export type TeamsOnConflict = {
  constraint: TeamsConstraint;
  updateColumns?: Array<TeamsUpdateColumn>;
  where?: InputMaybe<TeamsBoolExp>;
};

/** Ordering options when selecting data from "teams". */
export type TeamsOrderBy = {
  contractsAggregate?: InputMaybe<ContractsAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  enabledBlurBackground?: InputMaybe<OrderBy>;
  enabledSnapshot?: InputMaybe<OrderBy>;
  forceSso?: InputMaybe<OrderBy>;
  forceTwofa?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFilePath?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  projectsAggregate?: InputMaybe<ProjectsAggregateOrderBy>;
  stripeCustomer?: InputMaybe<StripeCustomersOrderBy>;
  teamMemberInvitationsAggregate?: InputMaybe<TeamMemberInvitationsAggregateOrderBy>;
  teamMembersAggregate?: InputMaybe<TeamMembersAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: teams */
export type TeamsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "teams" */
export enum TeamsSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EnabledBlurBackground = "enabledBlurBackground",
  /** column name */
  EnabledSnapshot = "enabledSnapshot",
  /** column name */
  ForceSso = "forceSso",
  /** column name */
  ForceTwofa = "forceTwofa",
  /** column name */
  Id = "id",
  /** column name */
  ImageFilePath = "imageFilePath",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "teams" */
export type TeamsSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  enabledBlurBackground?: InputMaybe<Scalars["Boolean"]>;
  enabledSnapshot?: InputMaybe<Scalars["Boolean"]>;
  forceSso?: InputMaybe<Scalars["String"]>;
  forceTwofa?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  imageFilePath?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "teams" */
export type TeamsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TeamsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TeamsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  enabledBlurBackground?: InputMaybe<Scalars["Boolean"]>;
  enabledSnapshot?: InputMaybe<Scalars["Boolean"]>;
  forceSso?: InputMaybe<Scalars["String"]>;
  forceTwofa?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  imageFilePath?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "teams" */
export enum TeamsUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  EnabledBlurBackground = "enabledBlurBackground",
  /** column name */
  EnabledSnapshot = "enabledSnapshot",
  /** column name */
  ForceSso = "forceSso",
  /** column name */
  ForceTwofa = "forceTwofa",
  /** column name */
  Id = "id",
  /** column name */
  ImageFilePath = "imageFilePath",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type TeamsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TeamsSetInput>;
  /** filter the rows which have to be updated */
  where: TeamsBoolExp;
};

export type TemporarilyLinkFreeeInput = {
  code: Scalars["String"];
  state: Scalars["String"];
};

export type TemporarilyLinkFreeeResponse = {
  companyId: Scalars["Int"];
  companyName: Scalars["String"];
};

export type TemporaryFreeeEmployeesForLinkInput = {
  companyId: Scalars["Int"];
};

export type TemporaryFreeeEmployeesForLinkResponse = {
  email: Scalars["String"];
  employeeId: Scalars["Int"];
  employeeName: Scalars["String"];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
};

export type UnLinkGithubInput = {
  linkId: Scalars["String"];
};

export type UpdateContractInput = {
  planId: Scalars["String"];
  quantity: Scalars["Float"];
};

export type UpdateCustomerInput = {
  email: Scalars["String"];
  line1: Scalars["String"];
  line2?: InputMaybe<Scalars["String"]>;
  postalCode: Scalars["String"];
  state: Scalars["String"];
};

export type UpdateTeamAllowedIpAddressInput = {
  ipAddress: Array<Scalars["String"]>;
};

export type UpdateTwoFaSettingInput = {
  enable: Scalars["Boolean"];
  twoFASecret?: InputMaybe<Scalars["String"]>;
};

export type UpdateTwoFaSettingResponse = {
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type UpdateUserEmailInput = {
  token: Scalars["String"];
};

export type UpdateUserPasswordForceInput = {
  password: Scalars["String"];
  token: Scalars["String"];
};

export type UpdateUserPasswordInput = {
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type UpdateWorkTaskInput = {
  projectId: Scalars["String"];
  projectTaskTagId: Scalars["String"];
  taskId: Scalars["String"];
  taskName: Scalars["String"];
  workId: Scalars["String"];
};

/** columns and relationships of "users" */
export type Users = {
  createdAt: Scalars["timestamptz"];
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["String"];
  imageFileName?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  password: Scalars["String"];
  refreshToken?: Maybe<Scalars["String"]>;
  /** An aggregate relationship */
  teamMembersAggregate: TeamMembersAggregate;
  /** An array relationship */
  team_members: Array<TeamMembers>;
  tutorialViewedAt?: Maybe<Scalars["timestamptz"]>;
  twofaEnabled: Scalars["Boolean"];
  twofaSecret?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["timestamptz"];
};

/** columns and relationships of "users" */
export type UsersTeamMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

/** columns and relationships of "users" */
export type UsersTeam_MembersArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};

/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  imageFileName?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  password?: InputMaybe<StringComparisonExp>;
  refreshToken?: InputMaybe<StringComparisonExp>;
  team_members?: InputMaybe<TeamMembersBoolExp>;
  team_membersAggregate?: InputMaybe<TeamMembersAggregateBoolExp>;
  tutorialViewedAt?: InputMaybe<TimestamptzComparisonExp>;
  twofaEnabled?: InputMaybe<BooleanComparisonExp>;
  twofaSecret?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "refresh_token" */
  Idx_5230070094e8135a3d763d90e7 = "IDX_5230070094e8135a3d763d90e7",
  /** unique or primary key constraint on columns "email" */
  IdxB383987bfa6e6a8745085621d0 = "IDX_b383987bfa6e6a8745085621d0",
  /** unique or primary key constraint on columns "id" */
  PkA3ffb1c0c8416b9fc6f907b7433 = "PK_a3ffb1c0c8416b9fc6f907b7433",
}

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  imageFileName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  team_members?: InputMaybe<TeamMembersArrRelInsertInput>;
  tutorialViewedAt?: InputMaybe<Scalars["timestamptz"]>;
  twofaEnabled?: InputMaybe<Scalars["Boolean"]>;
  twofaSecret?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageFileName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  tutorialViewedAt?: Maybe<Scalars["timestamptz"]>;
  twofaSecret?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  deletedAt?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageFileName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
  tutorialViewedAt?: Maybe<Scalars["timestamptz"]>;
  twofaSecret?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  updateColumns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageFileName?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  refreshToken?: InputMaybe<OrderBy>;
  team_membersAggregate?: InputMaybe<TeamMembersAggregateOrderBy>;
  tutorialViewedAt?: InputMaybe<OrderBy>;
  twofaEnabled?: InputMaybe<OrderBy>;
  twofaSecret?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Id = "id",
  /** column name */
  ImageFileName = "imageFileName",
  /** column name */
  LastName = "lastName",
  /** column name */
  Password = "password",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  TutorialViewedAt = "tutorialViewedAt",
  /** column name */
  TwofaEnabled = "twofaEnabled",
  /** column name */
  TwofaSecret = "twofaSecret",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  imageFileName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  tutorialViewedAt?: InputMaybe<Scalars["timestamptz"]>;
  twofaEnabled?: InputMaybe<Scalars["Boolean"]>;
  twofaSecret?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  deletedAt?: InputMaybe<Scalars["timestamptz"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  imageFileName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  refreshToken?: InputMaybe<Scalars["String"]>;
  tutorialViewedAt?: InputMaybe<Scalars["timestamptz"]>;
  twofaEnabled?: InputMaybe<Scalars["Boolean"]>;
  twofaSecret?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  DeletedAt = "deletedAt",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "firstName",
  /** column name */
  Id = "id",
  /** column name */
  ImageFileName = "imageFileName",
  /** column name */
  LastName = "lastName",
  /** column name */
  Password = "password",
  /** column name */
  RefreshToken = "refreshToken",
  /** column name */
  TutorialViewedAt = "tutorialViewedAt",
  /** column name */
  TwofaEnabled = "twofaEnabled",
  /** column name */
  TwofaSecret = "twofaSecret",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type UsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

export type VerificateEmailForCreateUserInput = {
  email: Scalars["String"];
  inflowRouteCode?: InputMaybe<Scalars["String"]>;
  invitationCode?: InputMaybe<Scalars["String"]>;
};

export type VerificateEmailForUpdateUserEmailInput = {
  email: Scalars["String"];
};

/** columns and relationships of "work_breaks" */
export type WorkBreaks = {
  createdAt: Scalars["timestamptz"];
  finishedAt: Scalars["timestamptz"];
  id: Scalars["String"];
  startedAt: Scalars["timestamptz"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
  /** An object relationship */
  work?: Maybe<Works>;
  workId: Scalars["String"];
};

/** aggregated selection of "work_breaks" */
export type WorkBreaksAggregate = {
  aggregate?: Maybe<WorkBreaksAggregateFields>;
  nodes: Array<WorkBreaks>;
};

export type WorkBreaksAggregateBoolExp = {
  count?: InputMaybe<WorkBreaksAggregateBoolExpCount>;
};

/** aggregate fields of "work_breaks" */
export type WorkBreaksAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<WorkBreaksMaxFields>;
  min?: Maybe<WorkBreaksMinFields>;
};

/** aggregate fields of "work_breaks" */
export type WorkBreaksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_breaks" */
export type WorkBreaksAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WorkBreaksMaxOrderBy>;
  min?: InputMaybe<WorkBreaksMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_breaks" */
export type WorkBreaksArrRelInsertInput = {
  data: Array<WorkBreaksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WorkBreaksOnConflict>;
};

/** Boolean expression to filter rows from the table "work_breaks". All fields are combined with a logical 'AND'. */
export type WorkBreaksBoolExp = {
  _and?: InputMaybe<Array<WorkBreaksBoolExp>>;
  _not?: InputMaybe<WorkBreaksBoolExp>;
  _or?: InputMaybe<Array<WorkBreaksBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  finishedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  work?: InputMaybe<WorksBoolExp>;
  workId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "work_breaks" */
export enum WorkBreaksConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_50929b921a52d07eecbe9a13ffe = "PK_50929b921a52d07eecbe9a13ffe",
}

/** input type for inserting data into table "work_breaks" */
export type WorkBreaksInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  work?: InputMaybe<WorksObjRelInsertInput>;
  workId?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type WorkBreaksMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "work_breaks" */
export type WorkBreaksMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WorkBreaksMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "work_breaks" */
export type WorkBreaksMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "work_breaks" */
export type WorkBreaksMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<WorkBreaks>;
};

/** on_conflict condition type for table "work_breaks" */
export type WorkBreaksOnConflict = {
  constraint: WorkBreaksConstraint;
  updateColumns?: Array<WorkBreaksUpdateColumn>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

/** Ordering options when selecting data from "work_breaks". */
export type WorkBreaksOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  work?: InputMaybe<WorksOrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: work_breaks */
export type WorkBreaksPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "work_breaks" */
export enum WorkBreaksSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkId = "workId",
}

/** input type for updating data in table "work_breaks" */
export type WorkBreaksSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workId?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "work_breaks" */
export type WorkBreaksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WorkBreaksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkBreaksStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workId?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "work_breaks" */
export enum WorkBreaksUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkId = "workId",
}

export type WorkBreaksUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkBreaksSetInput>;
  /** filter the rows which have to be updated */
  where: WorkBreaksBoolExp;
};

/** columns and relationships of "work_tasks" */
export type WorkTasks = {
  createdAt: Scalars["timestamptz"];
  /** An object relationship */
  task?: Maybe<Tasks>;
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  totalMinutes: Scalars["Int"];
  updatedAt: Scalars["timestamptz"];
  /** An object relationship */
  work?: Maybe<Works>;
  workId: Scalars["String"];
};

/** aggregated selection of "work_tasks" */
export type WorkTasksAggregate = {
  aggregate?: Maybe<WorkTasksAggregateFields>;
  nodes: Array<WorkTasks>;
};

export type WorkTasksAggregateBoolExp = {
  count?: InputMaybe<WorkTasksAggregateBoolExpCount>;
};

/** aggregate fields of "work_tasks" */
export type WorkTasksAggregateFields = {
  avg?: Maybe<WorkTasksAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<WorkTasksMaxFields>;
  min?: Maybe<WorkTasksMinFields>;
  stddev?: Maybe<WorkTasksStddevFields>;
  stddevPop?: Maybe<WorkTasksStddevPopFields>;
  stddevSamp?: Maybe<WorkTasksStddevSampFields>;
  sum?: Maybe<WorkTasksSumFields>;
  varPop?: Maybe<WorkTasksVarPopFields>;
  varSamp?: Maybe<WorkTasksVarSampFields>;
  variance?: Maybe<WorkTasksVarianceFields>;
};

/** aggregate fields of "work_tasks" */
export type WorkTasksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WorkTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_tasks" */
export type WorkTasksAggregateOrderBy = {
  avg?: InputMaybe<WorkTasksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WorkTasksMaxOrderBy>;
  min?: InputMaybe<WorkTasksMinOrderBy>;
  stddev?: InputMaybe<WorkTasksStddevOrderBy>;
  stddevPop?: InputMaybe<WorkTasksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WorkTasksStddevSampOrderBy>;
  sum?: InputMaybe<WorkTasksSumOrderBy>;
  varPop?: InputMaybe<WorkTasksVarPopOrderBy>;
  varSamp?: InputMaybe<WorkTasksVarSampOrderBy>;
  variance?: InputMaybe<WorkTasksVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "work_tasks" */
export type WorkTasksArrRelInsertInput = {
  data: Array<WorkTasksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WorkTasksOnConflict>;
};

/** aggregate avg on columns */
export type WorkTasksAvgFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "work_tasks" */
export type WorkTasksAvgOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "work_tasks". All fields are combined with a logical 'AND'. */
export type WorkTasksBoolExp = {
  _and?: InputMaybe<Array<WorkTasksBoolExp>>;
  _not?: InputMaybe<WorkTasksBoolExp>;
  _or?: InputMaybe<Array<WorkTasksBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  task?: InputMaybe<TasksBoolExp>;
  taskId?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  totalMinutes?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  work?: InputMaybe<WorksBoolExp>;
  workId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "work_tasks" */
export enum WorkTasksConstraint {
  /** unique or primary key constraint on columns "work_id", "task_id", "team_id" */
  Pk_44841a4b840a1e71a0c57d6f068 = "PK_44841a4b840a1e71a0c57d6f068",
}

/** input type for incrementing numeric columns in table "work_tasks" */
export type WorkTasksIncInput = {
  totalMinutes?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "work_tasks" */
export type WorkTasksInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  task?: InputMaybe<TasksObjRelInsertInput>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  totalMinutes?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  work?: InputMaybe<WorksObjRelInsertInput>;
  workId?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type WorkTasksMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  taskId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "work_tasks" */
export type WorkTasksMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  totalMinutes?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WorkTasksMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  taskId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "work_tasks" */
export type WorkTasksMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  totalMinutes?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "work_tasks" */
export type WorkTasksMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<WorkTasks>;
};

/** on_conflict condition type for table "work_tasks" */
export type WorkTasksOnConflict = {
  constraint: WorkTasksConstraint;
  updateColumns?: Array<WorkTasksUpdateColumn>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

/** Ordering options when selecting data from "work_tasks". */
export type WorkTasksOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  task?: InputMaybe<TasksOrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  totalMinutes?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  work?: InputMaybe<WorksOrderBy>;
  workId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: work_tasks */
export type WorkTasksPkColumnsInput = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workId: Scalars["String"];
};

/** select columns of table "work_tasks" */
export enum WorkTasksSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  TaskId = "taskId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TotalMinutes = "totalMinutes",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkId = "workId",
}

/** input type for updating data in table "work_tasks" */
export type WorkTasksSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  totalMinutes?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workId?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type WorkTasksStddevFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "work_tasks" */
export type WorkTasksStddevOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WorkTasksStddevPopFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "work_tasks" */
export type WorkTasksStddevPopOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WorkTasksStddevSampFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "work_tasks" */
export type WorkTasksStddevSampOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "work_tasks" */
export type WorkTasksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WorkTasksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkTasksStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  totalMinutes?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workId?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type WorkTasksSumFields = {
  totalMinutes?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "work_tasks" */
export type WorkTasksSumOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** update columns of table "work_tasks" */
export enum WorkTasksUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  TaskId = "taskId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TotalMinutes = "totalMinutes",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkId = "workId",
}

export type WorkTasksUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WorkTasksIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkTasksSetInput>;
  /** filter the rows which have to be updated */
  where: WorkTasksBoolExp;
};

/** aggregate varPop on columns */
export type WorkTasksVarPopFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "work_tasks" */
export type WorkTasksVarPopOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WorkTasksVarSampFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "work_tasks" */
export type WorkTasksVarSampOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WorkTasksVarianceFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "work_tasks" */
export type WorkTasksVarianceOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
};

/** columns and relationships of "work_tracking_breaks" */
export type WorkTrackingBreaks = {
  createdAt: Scalars["timestamptz"];
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  startedAt: Scalars["timestamptz"];
  teamId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
  /** An object relationship */
  workTracking?: Maybe<WorkTrackings>;
  workTrackingId: Scalars["String"];
};

/** aggregated selection of "work_tracking_breaks" */
export type WorkTrackingBreaksAggregate = {
  aggregate?: Maybe<WorkTrackingBreaksAggregateFields>;
  nodes: Array<WorkTrackingBreaks>;
};

export type WorkTrackingBreaksAggregateBoolExp = {
  count?: InputMaybe<WorkTrackingBreaksAggregateBoolExpCount>;
};

/** aggregate fields of "work_tracking_breaks" */
export type WorkTrackingBreaksAggregateFields = {
  count: Scalars["Int"];
  max?: Maybe<WorkTrackingBreaksMaxFields>;
  min?: Maybe<WorkTrackingBreaksMinFields>;
};

/** aggregate fields of "work_tracking_breaks" */
export type WorkTrackingBreaksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_tracking_breaks" */
export type WorkTrackingBreaksAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WorkTrackingBreaksMaxOrderBy>;
  min?: InputMaybe<WorkTrackingBreaksMinOrderBy>;
};

/** input type for inserting array relation for remote table "work_tracking_breaks" */
export type WorkTrackingBreaksArrRelInsertInput = {
  data: Array<WorkTrackingBreaksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WorkTrackingBreaksOnConflict>;
};

/** Boolean expression to filter rows from the table "work_tracking_breaks". All fields are combined with a logical 'AND'. */
export type WorkTrackingBreaksBoolExp = {
  _and?: InputMaybe<Array<WorkTrackingBreaksBoolExp>>;
  _not?: InputMaybe<WorkTrackingBreaksBoolExp>;
  _or?: InputMaybe<Array<WorkTrackingBreaksBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  finishedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  workTracking?: InputMaybe<WorkTrackingsBoolExp>;
  workTrackingId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "work_tracking_breaks" */
export enum WorkTrackingBreaksConstraint {
  /** unique or primary key constraint on columns "id" */
  Pk_2522afd979ead457855707fc46b = "PK_2522afd979ead457855707fc46b",
}

/** input type for inserting data into table "work_tracking_breaks" */
export type WorkTrackingBreaksInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTracking?: InputMaybe<WorkTrackingsObjRelInsertInput>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type WorkTrackingBreaksMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "work_tracking_breaks" */
export type WorkTrackingBreaksMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WorkTrackingBreaksMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "work_tracking_breaks" */
export type WorkTrackingBreaksMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "work_tracking_breaks" */
export type WorkTrackingBreaksMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<WorkTrackingBreaks>;
};

/** on_conflict condition type for table "work_tracking_breaks" */
export type WorkTrackingBreaksOnConflict = {
  constraint: WorkTrackingBreaksConstraint;
  updateColumns?: Array<WorkTrackingBreaksUpdateColumn>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

/** Ordering options when selecting data from "work_tracking_breaks". */
export type WorkTrackingBreaksOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTracking?: InputMaybe<WorkTrackingsOrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: work_tracking_breaks */
export type WorkTrackingBreaksPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "work_tracking_breaks" */
export enum WorkTrackingBreaksSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

/** input type for updating data in table "work_tracking_breaks" */
export type WorkTrackingBreaksSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "work_tracking_breaks" */
export type WorkTrackingBreaksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WorkTrackingBreaksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkTrackingBreaksStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "work_tracking_breaks" */
export enum WorkTrackingBreaksUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

export type WorkTrackingBreaksUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkTrackingBreaksSetInput>;
  /** filter the rows which have to be updated */
  where: WorkTrackingBreaksBoolExp;
};

/** columns and relationships of "work_tracking_tasks" */
export type WorkTrackingTasks = {
  createdAt: Scalars["timestamptz"];
  startedAt?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  totalMinutes?: Maybe<Scalars["Int"]>;
  trackingMinutes?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["timestamptz"];
  /** An object relationship */
  workTracking?: Maybe<WorkTrackings>;
  workTrackingId: Scalars["String"];
};

/** aggregated selection of "work_tracking_tasks" */
export type WorkTrackingTasksAggregate = {
  aggregate?: Maybe<WorkTrackingTasksAggregateFields>;
  nodes: Array<WorkTrackingTasks>;
};

export type WorkTrackingTasksAggregateBoolExp = {
  count?: InputMaybe<WorkTrackingTasksAggregateBoolExpCount>;
};

/** aggregate fields of "work_tracking_tasks" */
export type WorkTrackingTasksAggregateFields = {
  avg?: Maybe<WorkTrackingTasksAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<WorkTrackingTasksMaxFields>;
  min?: Maybe<WorkTrackingTasksMinFields>;
  stddev?: Maybe<WorkTrackingTasksStddevFields>;
  stddevPop?: Maybe<WorkTrackingTasksStddevPopFields>;
  stddevSamp?: Maybe<WorkTrackingTasksStddevSampFields>;
  sum?: Maybe<WorkTrackingTasksSumFields>;
  varPop?: Maybe<WorkTrackingTasksVarPopFields>;
  varSamp?: Maybe<WorkTrackingTasksVarSampFields>;
  variance?: Maybe<WorkTrackingTasksVarianceFields>;
};

/** aggregate fields of "work_tracking_tasks" */
export type WorkTrackingTasksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_tracking_tasks" */
export type WorkTrackingTasksAggregateOrderBy = {
  avg?: InputMaybe<WorkTrackingTasksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WorkTrackingTasksMaxOrderBy>;
  min?: InputMaybe<WorkTrackingTasksMinOrderBy>;
  stddev?: InputMaybe<WorkTrackingTasksStddevOrderBy>;
  stddevPop?: InputMaybe<WorkTrackingTasksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WorkTrackingTasksStddevSampOrderBy>;
  sum?: InputMaybe<WorkTrackingTasksSumOrderBy>;
  varPop?: InputMaybe<WorkTrackingTasksVarPopOrderBy>;
  varSamp?: InputMaybe<WorkTrackingTasksVarSampOrderBy>;
  variance?: InputMaybe<WorkTrackingTasksVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "work_tracking_tasks" */
export type WorkTrackingTasksArrRelInsertInput = {
  data: Array<WorkTrackingTasksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WorkTrackingTasksOnConflict>;
};

/** aggregate avg on columns */
export type WorkTrackingTasksAvgFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
  trackingMinutes?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksAvgOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "work_tracking_tasks". All fields are combined with a logical 'AND'. */
export type WorkTrackingTasksBoolExp = {
  _and?: InputMaybe<Array<WorkTrackingTasksBoolExp>>;
  _not?: InputMaybe<WorkTrackingTasksBoolExp>;
  _or?: InputMaybe<Array<WorkTrackingTasksBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  task?: InputMaybe<TasksBoolExp>;
  taskId?: InputMaybe<StringComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  totalMinutes?: InputMaybe<IntComparisonExp>;
  trackingMinutes?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  workTracking?: InputMaybe<WorkTrackingsBoolExp>;
  workTrackingId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "work_tracking_tasks" */
export enum WorkTrackingTasksConstraint {
  /** unique or primary key constraint on columns "work_tracking_id", "task_id", "team_id" */
  Pk_1ee40a0c62766b568dafd492939 = "PK_1ee40a0c62766b568dafd492939",
}

/** input type for incrementing numeric columns in table "work_tracking_tasks" */
export type WorkTrackingTasksIncInput = {
  totalMinutes?: InputMaybe<Scalars["Int"]>;
  trackingMinutes?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "work_tracking_tasks" */
export type WorkTrackingTasksInsertInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  task?: InputMaybe<TasksObjRelInsertInput>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  totalMinutes?: InputMaybe<Scalars["Int"]>;
  trackingMinutes?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTracking?: InputMaybe<WorkTrackingsObjRelInsertInput>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type WorkTrackingTasksMaxFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  taskId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  trackingMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WorkTrackingTasksMinFields = {
  createdAt?: Maybe<Scalars["timestamptz"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  taskId?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
  totalMinutes?: Maybe<Scalars["Int"]>;
  trackingMinutes?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "work_tracking_tasks" */
export type WorkTrackingTasksMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<WorkTrackingTasks>;
};

/** on_conflict condition type for table "work_tracking_tasks" */
export type WorkTrackingTasksOnConflict = {
  constraint: WorkTrackingTasksConstraint;
  updateColumns?: Array<WorkTrackingTasksUpdateColumn>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

/** Ordering options when selecting data from "work_tracking_tasks". */
export type WorkTrackingTasksOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  task?: InputMaybe<TasksOrderBy>;
  taskId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTracking?: InputMaybe<WorkTrackingsOrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: work_tracking_tasks */
export type WorkTrackingTasksPkColumnsInput = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workTrackingId: Scalars["String"];
};

/** select columns of table "work_tracking_tasks" */
export enum WorkTrackingTasksSelectColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TaskId = "taskId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TotalMinutes = "totalMinutes",
  /** column name */
  TrackingMinutes = "trackingMinutes",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

/** input type for updating data in table "work_tracking_tasks" */
export type WorkTrackingTasksSetInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  totalMinutes?: InputMaybe<Scalars["Int"]>;
  trackingMinutes?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type WorkTrackingTasksStddevFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
  trackingMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksStddevOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WorkTrackingTasksStddevPopFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
  trackingMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksStddevPopOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WorkTrackingTasksStddevSampFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
  trackingMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksStddevSampOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "work_tracking_tasks" */
export type WorkTrackingTasksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WorkTrackingTasksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkTrackingTasksStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  taskId?: InputMaybe<Scalars["String"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  totalMinutes?: InputMaybe<Scalars["Int"]>;
  trackingMinutes?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type WorkTrackingTasksSumFields = {
  totalMinutes?: Maybe<Scalars["Int"]>;
  trackingMinutes?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksSumOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** update columns of table "work_tracking_tasks" */
export enum WorkTrackingTasksUpdateColumn {
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TaskId = "taskId",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TotalMinutes = "totalMinutes",
  /** column name */
  TrackingMinutes = "trackingMinutes",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

export type WorkTrackingTasksUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WorkTrackingTasksIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkTrackingTasksSetInput>;
  /** filter the rows which have to be updated */
  where: WorkTrackingTasksBoolExp;
};

/** aggregate varPop on columns */
export type WorkTrackingTasksVarPopFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
  trackingMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksVarPopOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WorkTrackingTasksVarSampFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
  trackingMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksVarSampOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WorkTrackingTasksVarianceFields = {
  totalMinutes?: Maybe<Scalars["Float"]>;
  trackingMinutes?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "work_tracking_tasks" */
export type WorkTrackingTasksVarianceOrderBy = {
  totalMinutes?: InputMaybe<OrderBy>;
  trackingMinutes?: InputMaybe<OrderBy>;
};

/** columns and relationships of "work_trackings" */
export type WorkTrackings = {
  breakMinutes: Scalars["Int"];
  createdAt: Scalars["timestamptz"];
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["String"];
  startedAt: Scalars["timestamptz"];
  teamId: Scalars["String"];
  /** An object relationship */
  teamMember?: Maybe<TeamMembers>;
  teamMemberId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
  /** An object relationship */
  work?: Maybe<Works>;
  /** An array relationship */
  workTrackingBreaks: Array<WorkTrackingBreaks>;
  /** An aggregate relationship */
  workTrackingBreaksAggregate: WorkTrackingBreaksAggregate;
  /** An array relationship */
  workTrackingTasks: Array<WorkTrackingTasks>;
  /** An aggregate relationship */
  workTrackingTasksAggregate: WorkTrackingTasksAggregate;
};

/** columns and relationships of "work_trackings" */
export type WorkTrackingsWorkTrackingBreaksArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingBreaksOrderBy>>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

/** columns and relationships of "work_trackings" */
export type WorkTrackingsWorkTrackingBreaksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingBreaksOrderBy>>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

/** columns and relationships of "work_trackings" */
export type WorkTrackingsWorkTrackingTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

/** columns and relationships of "work_trackings" */
export type WorkTrackingsWorkTrackingTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

/** aggregated selection of "work_trackings" */
export type WorkTrackingsAggregate = {
  aggregate?: Maybe<WorkTrackingsAggregateFields>;
  nodes: Array<WorkTrackings>;
};

export type WorkTrackingsAggregateBoolExp = {
  count?: InputMaybe<WorkTrackingsAggregateBoolExpCount>;
};

/** aggregate fields of "work_trackings" */
export type WorkTrackingsAggregateFields = {
  avg?: Maybe<WorkTrackingsAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<WorkTrackingsMaxFields>;
  min?: Maybe<WorkTrackingsMinFields>;
  stddev?: Maybe<WorkTrackingsStddevFields>;
  stddevPop?: Maybe<WorkTrackingsStddevPopFields>;
  stddevSamp?: Maybe<WorkTrackingsStddevSampFields>;
  sum?: Maybe<WorkTrackingsSumFields>;
  varPop?: Maybe<WorkTrackingsVarPopFields>;
  varSamp?: Maybe<WorkTrackingsVarSampFields>;
  variance?: Maybe<WorkTrackingsVarianceFields>;
};

/** aggregate fields of "work_trackings" */
export type WorkTrackingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_trackings" */
export type WorkTrackingsAggregateOrderBy = {
  avg?: InputMaybe<WorkTrackingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WorkTrackingsMaxOrderBy>;
  min?: InputMaybe<WorkTrackingsMinOrderBy>;
  stddev?: InputMaybe<WorkTrackingsStddevOrderBy>;
  stddevPop?: InputMaybe<WorkTrackingsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WorkTrackingsStddevSampOrderBy>;
  sum?: InputMaybe<WorkTrackingsSumOrderBy>;
  varPop?: InputMaybe<WorkTrackingsVarPopOrderBy>;
  varSamp?: InputMaybe<WorkTrackingsVarSampOrderBy>;
  variance?: InputMaybe<WorkTrackingsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "work_trackings" */
export type WorkTrackingsArrRelInsertInput = {
  data: Array<WorkTrackingsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WorkTrackingsOnConflict>;
};

/** aggregate avg on columns */
export type WorkTrackingsAvgFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "work_trackings" */
export type WorkTrackingsAvgOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "work_trackings". All fields are combined with a logical 'AND'. */
export type WorkTrackingsBoolExp = {
  _and?: InputMaybe<Array<WorkTrackingsBoolExp>>;
  _not?: InputMaybe<WorkTrackingsBoolExp>;
  _or?: InputMaybe<Array<WorkTrackingsBoolExp>>;
  breakMinutes?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  finishedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMember?: InputMaybe<TeamMembersBoolExp>;
  teamMemberId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  work?: InputMaybe<WorksBoolExp>;
  workTrackingBreaks?: InputMaybe<WorkTrackingBreaksBoolExp>;
  workTrackingBreaksAggregate?: InputMaybe<WorkTrackingBreaksAggregateBoolExp>;
  workTrackingTasks?: InputMaybe<WorkTrackingTasksBoolExp>;
  workTrackingTasksAggregate?: InputMaybe<WorkTrackingTasksAggregateBoolExp>;
};

/** unique or primary key constraints on table "work_trackings" */
export enum WorkTrackingsConstraint {
  /** unique or primary key constraint on columns "id", "team_id" */
  IdxD8ba76a165ef30b7e59ca35c25 = "IDX_d8ba76a165ef30b7e59ca35c25",
  /** unique or primary key constraint on columns "id" */
  Pk_652312b5117cf0124d0f8b07581 = "PK_652312b5117cf0124d0f8b07581",
}

/** input type for incrementing numeric columns in table "work_trackings" */
export type WorkTrackingsIncInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "work_trackings" */
export type WorkTrackingsInsertInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMember?: InputMaybe<TeamMembersObjRelInsertInput>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  work?: InputMaybe<WorksObjRelInsertInput>;
  workTrackingBreaks?: InputMaybe<WorkTrackingBreaksArrRelInsertInput>;
  workTrackingTasks?: InputMaybe<WorkTrackingTasksArrRelInsertInput>;
};

/** aggregate max on columns */
export type WorkTrackingsMaxFields = {
  breakMinutes?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "work_trackings" */
export type WorkTrackingsMaxOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WorkTrackingsMinFields = {
  breakMinutes?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "work_trackings" */
export type WorkTrackingsMinOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "work_trackings" */
export type WorkTrackingsMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<WorkTrackings>;
};

/** input type for inserting object relation for remote table "work_trackings" */
export type WorkTrackingsObjRelInsertInput = {
  data: WorkTrackingsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WorkTrackingsOnConflict>;
};

/** on_conflict condition type for table "work_trackings" */
export type WorkTrackingsOnConflict = {
  constraint: WorkTrackingsConstraint;
  updateColumns?: Array<WorkTrackingsUpdateColumn>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

/** Ordering options when selecting data from "work_trackings". */
export type WorkTrackingsOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMember?: InputMaybe<TeamMembersOrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  work?: InputMaybe<WorksOrderBy>;
  workTrackingBreaksAggregate?: InputMaybe<WorkTrackingBreaksAggregateOrderBy>;
  workTrackingTasksAggregate?: InputMaybe<WorkTrackingTasksAggregateOrderBy>;
};

/** primary key columns input for table: work_trackings */
export type WorkTrackingsPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "work_trackings" */
export enum WorkTrackingsSelectColumn {
  /** column name */
  BreakMinutes = "breakMinutes",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

/** input type for updating data in table "work_trackings" */
export type WorkTrackingsSetInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type WorkTrackingsStddevFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "work_trackings" */
export type WorkTrackingsStddevOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WorkTrackingsStddevPopFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "work_trackings" */
export type WorkTrackingsStddevPopOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WorkTrackingsStddevSampFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "work_trackings" */
export type WorkTrackingsStddevSampOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "work_trackings" */
export type WorkTrackingsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WorkTrackingsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WorkTrackingsStreamCursorValueInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type WorkTrackingsSumFields = {
  breakMinutes?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "work_trackings" */
export type WorkTrackingsSumOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** update columns of table "work_trackings" */
export enum WorkTrackingsUpdateColumn {
  /** column name */
  BreakMinutes = "breakMinutes",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
}

export type WorkTrackingsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WorkTrackingsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorkTrackingsSetInput>;
  /** filter the rows which have to be updated */
  where: WorkTrackingsBoolExp;
};

/** aggregate varPop on columns */
export type WorkTrackingsVarPopFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "work_trackings" */
export type WorkTrackingsVarPopOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WorkTrackingsVarSampFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "work_trackings" */
export type WorkTrackingsVarSampOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WorkTrackingsVarianceFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "work_trackings" */
export type WorkTrackingsVarianceOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** columns and relationships of "works" */
export type Works = {
  breakMinutes: Scalars["Int"];
  createdAt: Scalars["timestamptz"];
  finishedAt: Scalars["timestamptz"];
  id: Scalars["String"];
  startedAt: Scalars["timestamptz"];
  teamId: Scalars["String"];
  /** An object relationship */
  teamMember?: Maybe<TeamMembers>;
  teamMemberId: Scalars["String"];
  updatedAt: Scalars["timestamptz"];
  /** An array relationship */
  workBreaks: Array<WorkBreaks>;
  /** An aggregate relationship */
  workBreaksAggregate: WorkBreaksAggregate;
  /** An array relationship */
  workTasks: Array<WorkTasks>;
  /** An aggregate relationship */
  workTasksAggregate: WorkTasksAggregate;
  /** An object relationship */
  workTracking?: Maybe<WorkTrackings>;
  workTrackingId: Scalars["String"];
};

/** columns and relationships of "works" */
export type WorksWorkBreaksArgs = {
  distinctOn?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkBreaksOrderBy>>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

/** columns and relationships of "works" */
export type WorksWorkBreaksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkBreaksOrderBy>>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

/** columns and relationships of "works" */
export type WorksWorkTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

/** columns and relationships of "works" */
export type WorksWorkTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

/** aggregated selection of "works" */
export type WorksAggregate = {
  aggregate?: Maybe<WorksAggregateFields>;
  nodes: Array<Works>;
};

export type WorksAggregateBoolExp = {
  count?: InputMaybe<WorksAggregateBoolExpCount>;
};

/** aggregate fields of "works" */
export type WorksAggregateFields = {
  avg?: Maybe<WorksAvgFields>;
  count: Scalars["Int"];
  max?: Maybe<WorksMaxFields>;
  min?: Maybe<WorksMinFields>;
  stddev?: Maybe<WorksStddevFields>;
  stddevPop?: Maybe<WorksStddevPopFields>;
  stddevSamp?: Maybe<WorksStddevSampFields>;
  sum?: Maybe<WorksSumFields>;
  varPop?: Maybe<WorksVarPopFields>;
  varSamp?: Maybe<WorksVarSampFields>;
  variance?: Maybe<WorksVarianceFields>;
};

/** aggregate fields of "works" */
export type WorksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WorksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "works" */
export type WorksAggregateOrderBy = {
  avg?: InputMaybe<WorksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WorksMaxOrderBy>;
  min?: InputMaybe<WorksMinOrderBy>;
  stddev?: InputMaybe<WorksStddevOrderBy>;
  stddevPop?: InputMaybe<WorksStddevPopOrderBy>;
  stddevSamp?: InputMaybe<WorksStddevSampOrderBy>;
  sum?: InputMaybe<WorksSumOrderBy>;
  varPop?: InputMaybe<WorksVarPopOrderBy>;
  varSamp?: InputMaybe<WorksVarSampOrderBy>;
  variance?: InputMaybe<WorksVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "works" */
export type WorksArrRelInsertInput = {
  data: Array<WorksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WorksOnConflict>;
};

/** aggregate avg on columns */
export type WorksAvgFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "works" */
export type WorksAvgOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "works". All fields are combined with a logical 'AND'. */
export type WorksBoolExp = {
  _and?: InputMaybe<Array<WorksBoolExp>>;
  _not?: InputMaybe<WorksBoolExp>;
  _or?: InputMaybe<Array<WorksBoolExp>>;
  breakMinutes?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  finishedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  startedAt?: InputMaybe<TimestamptzComparisonExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  teamMember?: InputMaybe<TeamMembersBoolExp>;
  teamMemberId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  workBreaks?: InputMaybe<WorkBreaksBoolExp>;
  workBreaksAggregate?: InputMaybe<WorkBreaksAggregateBoolExp>;
  workTasks?: InputMaybe<WorkTasksBoolExp>;
  workTasksAggregate?: InputMaybe<WorkTasksAggregateBoolExp>;
  workTracking?: InputMaybe<WorkTrackingsBoolExp>;
  workTrackingId?: InputMaybe<StringComparisonExp>;
};

export type WorksCalendarInput = {
  month: Scalars["Int"];
  teamMemberId?: InputMaybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export type WorksCalendarResponse = {
  breakTime: Scalars["String"];
  finishedTime: Scalars["String"];
  startedTime: Scalars["String"];
  workDate: Scalars["String"];
  workTime: Scalars["String"];
  workTimeMinutes: Scalars["Int"];
};

/** unique or primary key constraints on table "works" */
export enum WorksConstraint {
  /** unique or primary key constraint on columns "id", "team_id" */
  Idx_3c517f2b8ebd139cc678e8c46e = "IDX_3c517f2b8ebd139cc678e8c46e",
  /** unique or primary key constraint on columns "id" */
  PkA9ffbf516ba6e52604b29e5cce0 = "PK_a9ffbf516ba6e52604b29e5cce0",
  /** unique or primary key constraint on columns "work_tracking_id", "team_id" */
  RelDd5710f2ccca337a04d4e25ea1 = "REL_dd5710f2ccca337a04d4e25ea1",
}

/** input type for incrementing numeric columns in table "works" */
export type WorksIncInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "works" */
export type WorksInsertInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMember?: InputMaybe<TeamMembersObjRelInsertInput>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workBreaks?: InputMaybe<WorkBreaksArrRelInsertInput>;
  workTasks?: InputMaybe<WorkTasksArrRelInsertInput>;
  workTracking?: InputMaybe<WorkTrackingsObjRelInsertInput>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type WorksMaxFields = {
  breakMinutes?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "works" */
export type WorksMaxOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WorksMinFields = {
  breakMinutes?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["timestamptz"]>;
  finishedAt?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["String"]>;
  startedAt?: Maybe<Scalars["timestamptz"]>;
  teamId?: Maybe<Scalars["String"]>;
  teamMemberId?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["timestamptz"]>;
  workTrackingId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "works" */
export type WorksMinOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "works" */
export type WorksMutationResponse = {
  /** number of rows affected by the mutation */
  affectedRows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Works>;
};

/** input type for inserting object relation for remote table "works" */
export type WorksObjRelInsertInput = {
  data: WorksInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WorksOnConflict>;
};

/** on_conflict condition type for table "works" */
export type WorksOnConflict = {
  constraint: WorksConstraint;
  updateColumns?: Array<WorksUpdateColumn>;
  where?: InputMaybe<WorksBoolExp>;
};

/** Ordering options when selecting data from "works". */
export type WorksOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  finishedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  teamMember?: InputMaybe<TeamMembersOrderBy>;
  teamMemberId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  workBreaksAggregate?: InputMaybe<WorkBreaksAggregateOrderBy>;
  workTasksAggregate?: InputMaybe<WorkTasksAggregateOrderBy>;
  workTracking?: InputMaybe<WorkTrackingsOrderBy>;
  workTrackingId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: works */
export type WorksPkColumnsInput = {
  id: Scalars["String"];
};

/** select columns of table "works" */
export enum WorksSelectColumn {
  /** column name */
  BreakMinutes = "breakMinutes",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

/** input type for updating data in table "works" */
export type WorksSetInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type WorksStddevFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "works" */
export type WorksStddevOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type WorksStddevPopFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevPop() on columns of table "works" */
export type WorksStddevPopOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type WorksStddevSampFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by stddevSamp() on columns of table "works" */
export type WorksStddevSampOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "works" */
export type WorksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: WorksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WorksStreamCursorValueInput = {
  breakMinutes?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["timestamptz"]>;
  finishedAt?: InputMaybe<Scalars["timestamptz"]>;
  id?: InputMaybe<Scalars["String"]>;
  startedAt?: InputMaybe<Scalars["timestamptz"]>;
  teamId?: InputMaybe<Scalars["String"]>;
  teamMemberId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["timestamptz"]>;
  workTrackingId?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type WorksSumFields = {
  breakMinutes?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "works" */
export type WorksSumOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** update columns of table "works" */
export enum WorksUpdateColumn {
  /** column name */
  BreakMinutes = "breakMinutes",
  /** column name */
  CreatedAt = "createdAt",
  /** column name */
  FinishedAt = "finishedAt",
  /** column name */
  Id = "id",
  /** column name */
  StartedAt = "startedAt",
  /** column name */
  TeamId = "teamId",
  /** column name */
  TeamMemberId = "teamMemberId",
  /** column name */
  UpdatedAt = "updatedAt",
  /** column name */
  WorkTrackingId = "workTrackingId",
}

export type WorksUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WorksIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WorksSetInput>;
  /** filter the rows which have to be updated */
  where: WorksBoolExp;
};

/** aggregate varPop on columns */
export type WorksVarPopFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varPop() on columns of table "works" */
export type WorksVarPopOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type WorksVarSampFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by varSamp() on columns of table "works" */
export type WorksVarSampOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WorksVarianceFields = {
  breakMinutes?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "works" */
export type WorksVarianceOrderBy = {
  breakMinutes?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _TextComparisonExp = {
  _eq?: InputMaybe<Scalars["_text"]>;
  _gt?: InputMaybe<Scalars["_text"]>;
  _gte?: InputMaybe<Scalars["_text"]>;
  _in?: InputMaybe<Array<Scalars["_text"]>>;
  _isNull?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["_text"]>;
  _lte?: InputMaybe<Scalars["_text"]>;
  _neq?: InputMaybe<Scalars["_text"]>;
  _nin?: InputMaybe<Array<Scalars["_text"]>>;
};

export type ActivitiesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ActivitiesSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ActivitiesBoolExp>;
  predicate: IntComparisonExp;
};

export type ContractsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContractsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ContractsBoolExp>;
  predicate: IntComparisonExp;
};

/** mutation root */
export type Mutation_Root = {
  acceptTeamMemberInvitation: AcceptTeamMemberInvitationResponse;
  approveGitHubInstallation: ApproveGitHubInstallationResult;
  archiveProject: BooleanResult;
  authenticateTwoFA: LoginResult;
  createBacklogAuthzUrl: CreateBacklogAuthzUrlResponse;
  createFreeeAuthzUrl: CreateFreeeAuthzUrlResponse;
  createGitHubAuthzUrl: GithubAuthzUrl;
  createGitHubReAuthzUrl: GithubAuthzUrl;
  createGoogleCalendarAuthzUrl: CreateGoogleCalendarAuthzUrlResponse;
  createGoogleLoginAuthzUrl: CreateGoogleLoginAuthzUrlResponse;
  createMicrosoftLoginAuthzUrl: CreateMicrosoftLoginAuthzUrlResponse;
  createProject: Project;
  createSetupSession: SessionUrl;
  createSubscriptionSession: SessionUrl;
  createTeam: Team;
  createUser: LoginResult;
  /** delete data from the table: "activities" */
  deleteActivities?: Maybe<ActivitiesMutationResponse>;
  /** delete single row from the table: "activities" */
  deleteActivitiesByPk?: Maybe<Activities>;
  /** delete data from the table: "contracts" */
  deleteContracts?: Maybe<ContractsMutationResponse>;
  /** delete single row from the table: "contracts" */
  deleteContractsByPk?: Maybe<Contracts>;
  /** delete data from the table: "enquete_answer" */
  deleteEnqueteAnswer?: Maybe<EnqueteAnswerMutationResponse>;
  /** delete single row from the table: "enquete_answer" */
  deleteEnqueteAnswerByPk?: Maybe<EnqueteAnswer>;
  /** delete data from the table: "plans" */
  deletePlans?: Maybe<PlansMutationResponse>;
  /** delete single row from the table: "plans" */
  deletePlansByPk?: Maybe<Plans>;
  /** delete data from the table: "project_alerts" */
  deleteProjectAlerts?: Maybe<ProjectAlertsMutationResponse>;
  /** delete single row from the table: "project_alerts" */
  deleteProjectAlertsByPk?: Maybe<ProjectAlerts>;
  /** delete data from the table: "project_members" */
  deleteProjectMembers?: Maybe<ProjectMembersMutationResponse>;
  /** delete single row from the table: "project_members" */
  deleteProjectMembersByPk?: Maybe<ProjectMembers>;
  /** delete data from the table: "project_task_tags" */
  deleteProjectTaskTags?: Maybe<ProjectTaskTagsMutationResponse>;
  /** delete single row from the table: "project_task_tags" */
  deleteProjectTaskTagsByPk?: Maybe<ProjectTaskTags>;
  /** delete data from the table: "projects" */
  deleteProjects?: Maybe<ProjectsMutationResponse>;
  /** delete single row from the table: "projects" */
  deleteProjectsByPk?: Maybe<Projects>;
  /** delete data from the table: "settlement_histories" */
  deleteSettlementHistories?: Maybe<SettlementHistoriesMutationResponse>;
  /** delete single row from the table: "settlement_histories" */
  deleteSettlementHistoriesByPk?: Maybe<SettlementHistories>;
  /** delete data from the table: "stripe_customers" */
  deleteStripeCustomers?: Maybe<StripeCustomersMutationResponse>;
  /** delete single row from the table: "stripe_customers" */
  deleteStripeCustomersByPk?: Maybe<StripeCustomers>;
  /** delete data from the table: "stripe_settlement_histories" */
  deleteStripeSettlementHistories?: Maybe<StripeSettlementHistoriesMutationResponse>;
  /** delete single row from the table: "stripe_settlement_histories" */
  deleteStripeSettlementHistoriesByPk?: Maybe<StripeSettlementHistories>;
  /** delete data from the table: "tasks" */
  deleteTasks?: Maybe<TasksMutationResponse>;
  /** delete single row from the table: "tasks" */
  deleteTasksByPk?: Maybe<Tasks>;
  deleteTeam: BooleanResult;
  /** delete data from the table: "team_allowed_ip_addresses" */
  deleteTeamAllowedIpAddresses?: Maybe<TeamAllowedIpAddressesMutationResponse>;
  /** delete single row from the table: "team_allowed_ip_addresses" */
  deleteTeamAllowedIpAddressesByPk?: Maybe<TeamAllowedIpAddresses>;
  /** delete data from the table: "team_backlog_linkage_projects" */
  deleteTeamBacklogLinkageProjects?: Maybe<TeamBacklogLinkageProjectsMutationResponse>;
  /** delete single row from the table: "team_backlog_linkage_projects" */
  deleteTeamBacklogLinkageProjectsByPk?: Maybe<TeamBacklogLinkageProjects>;
  /** delete data from the table: "team_backlog_linkages" */
  deleteTeamBacklogLinkages?: Maybe<TeamBacklogLinkagesMutationResponse>;
  /** delete single row from the table: "team_backlog_linkages" */
  deleteTeamBacklogLinkagesByPk?: Maybe<TeamBacklogLinkages>;
  /** delete data from the table: "team_freee_linkage_members" */
  deleteTeamFreeeLinkageMembers?: Maybe<TeamFreeeLinkageMembersMutationResponse>;
  /** delete single row from the table: "team_freee_linkage_members" */
  deleteTeamFreeeLinkageMembersByPk?: Maybe<TeamFreeeLinkageMembers>;
  /** delete data from the table: "team_github_linkage_projects" */
  deleteTeamGithubLinkageProjects?: Maybe<TeamGithubLinkageProjectsMutationResponse>;
  /** delete single row from the table: "team_github_linkage_projects" */
  deleteTeamGithubLinkageProjectsByPk?: Maybe<TeamGithubLinkageProjects>;
  /** delete data from the table: "team_github_linkages" */
  deleteTeamGithubLinkages?: Maybe<TeamGithubLinkagesMutationResponse>;
  /** delete single row from the table: "team_github_linkages" */
  deleteTeamGithubLinkagesByPk?: Maybe<TeamGithubLinkages>;
  /** delete data from the table: "team_google_calendar_linkages" */
  deleteTeamGoogleCalendarLinkages?: Maybe<TeamGoogleCalendarLinkagesMutationResponse>;
  /** delete single row from the table: "team_google_calendar_linkages" */
  deleteTeamGoogleCalendarLinkagesByPk?: Maybe<TeamGoogleCalendarLinkages>;
  /** delete data from the table: "team_member_invitations" */
  deleteTeamMemberInvitations?: Maybe<TeamMemberInvitationsMutationResponse>;
  /** delete single row from the table: "team_member_invitations" */
  deleteTeamMemberInvitationsByPk?: Maybe<TeamMemberInvitations>;
  /** delete data from the table: "team_member_snapshots" */
  deleteTeamMemberSnapshots?: Maybe<TeamMemberSnapshotsMutationResponse>;
  /** delete single row from the table: "team_member_snapshots" */
  deleteTeamMemberSnapshotsByPk?: Maybe<TeamMemberSnapshots>;
  /** delete data from the table: "team_members" */
  deleteTeamMembers?: Maybe<TeamMembersMutationResponse>;
  /** delete single row from the table: "team_members" */
  deleteTeamMembersByPk?: Maybe<TeamMembers>;
  /** delete data from the table: "teams" */
  deleteTeams?: Maybe<TeamsMutationResponse>;
  /** delete single row from the table: "teams" */
  deleteTeamsByPk?: Maybe<Teams>;
  deleteUser: BooleanResult;
  /** delete data from the table: "users" */
  deleteUsers?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  deleteUsersByPk?: Maybe<Users>;
  /** delete data from the table: "work_breaks" */
  deleteWorkBreaks?: Maybe<WorkBreaksMutationResponse>;
  /** delete single row from the table: "work_breaks" */
  deleteWorkBreaksByPk?: Maybe<WorkBreaks>;
  /** delete data from the table: "work_tasks" */
  deleteWorkTasks?: Maybe<WorkTasksMutationResponse>;
  /** delete single row from the table: "work_tasks" */
  deleteWorkTasksByPk?: Maybe<WorkTasks>;
  /** delete data from the table: "work_tracking_breaks" */
  deleteWorkTrackingBreaks?: Maybe<WorkTrackingBreaksMutationResponse>;
  /** delete single row from the table: "work_tracking_breaks" */
  deleteWorkTrackingBreaksByPk?: Maybe<WorkTrackingBreaks>;
  /** delete data from the table: "work_tracking_tasks" */
  deleteWorkTrackingTasks?: Maybe<WorkTrackingTasksMutationResponse>;
  /** delete single row from the table: "work_tracking_tasks" */
  deleteWorkTrackingTasksByPk?: Maybe<WorkTrackingTasks>;
  /** delete data from the table: "work_trackings" */
  deleteWorkTrackings?: Maybe<WorkTrackingsMutationResponse>;
  /** delete single row from the table: "work_trackings" */
  deleteWorkTrackingsByPk?: Maybe<WorkTrackings>;
  /** delete data from the table: "works" */
  deleteWorks?: Maybe<WorksMutationResponse>;
  /** delete single row from the table: "works" */
  deleteWorksByPk?: Maybe<Works>;
  exportTeamReport: ReportPath;
  exportWorks: ReportPath;
  finishWork: BooleanResult;
  initialLinkFreeeEmployees: BooleanResult;
  /** insert data into the table: "activities" */
  insertActivities?: Maybe<ActivitiesMutationResponse>;
  /** insert a single row into the table: "activities" */
  insertActivitiesOne?: Maybe<Activities>;
  /** insert data into the table: "contracts" */
  insertContracts?: Maybe<ContractsMutationResponse>;
  /** insert a single row into the table: "contracts" */
  insertContractsOne?: Maybe<Contracts>;
  /** insert data into the table: "enquete_answer" */
  insertEnqueteAnswer?: Maybe<EnqueteAnswerMutationResponse>;
  /** insert a single row into the table: "enquete_answer" */
  insertEnqueteAnswerOne?: Maybe<EnqueteAnswer>;
  /** insert data into the table: "plans" */
  insertPlans?: Maybe<PlansMutationResponse>;
  /** insert a single row into the table: "plans" */
  insertPlansOne?: Maybe<Plans>;
  /** insert data into the table: "project_alerts" */
  insertProjectAlerts?: Maybe<ProjectAlertsMutationResponse>;
  /** insert a single row into the table: "project_alerts" */
  insertProjectAlertsOne?: Maybe<ProjectAlerts>;
  /** insert data into the table: "project_members" */
  insertProjectMembers?: Maybe<ProjectMembersMutationResponse>;
  /** insert a single row into the table: "project_members" */
  insertProjectMembersOne?: Maybe<ProjectMembers>;
  /** insert data into the table: "project_task_tags" */
  insertProjectTaskTags?: Maybe<ProjectTaskTagsMutationResponse>;
  /** insert a single row into the table: "project_task_tags" */
  insertProjectTaskTagsOne?: Maybe<ProjectTaskTags>;
  /** insert data into the table: "projects" */
  insertProjects?: Maybe<ProjectsMutationResponse>;
  /** insert a single row into the table: "projects" */
  insertProjectsOne?: Maybe<Projects>;
  /** insert data into the table: "settlement_histories" */
  insertSettlementHistories?: Maybe<SettlementHistoriesMutationResponse>;
  /** insert a single row into the table: "settlement_histories" */
  insertSettlementHistoriesOne?: Maybe<SettlementHistories>;
  /** insert data into the table: "stripe_customers" */
  insertStripeCustomers?: Maybe<StripeCustomersMutationResponse>;
  /** insert a single row into the table: "stripe_customers" */
  insertStripeCustomersOne?: Maybe<StripeCustomers>;
  /** insert data into the table: "stripe_settlement_histories" */
  insertStripeSettlementHistories?: Maybe<StripeSettlementHistoriesMutationResponse>;
  /** insert a single row into the table: "stripe_settlement_histories" */
  insertStripeSettlementHistoriesOne?: Maybe<StripeSettlementHistories>;
  /** insert data into the table: "tasks" */
  insertTasks?: Maybe<TasksMutationResponse>;
  /** insert a single row into the table: "tasks" */
  insertTasksOne?: Maybe<Tasks>;
  /** insert data into the table: "team_allowed_ip_addresses" */
  insertTeamAllowedIpAddresses?: Maybe<TeamAllowedIpAddressesMutationResponse>;
  /** insert a single row into the table: "team_allowed_ip_addresses" */
  insertTeamAllowedIpAddressesOne?: Maybe<TeamAllowedIpAddresses>;
  /** insert data into the table: "team_backlog_linkage_projects" */
  insertTeamBacklogLinkageProjects?: Maybe<TeamBacklogLinkageProjectsMutationResponse>;
  /** insert a single row into the table: "team_backlog_linkage_projects" */
  insertTeamBacklogLinkageProjectsOne?: Maybe<TeamBacklogLinkageProjects>;
  /** insert data into the table: "team_backlog_linkages" */
  insertTeamBacklogLinkages?: Maybe<TeamBacklogLinkagesMutationResponse>;
  /** insert a single row into the table: "team_backlog_linkages" */
  insertTeamBacklogLinkagesOne?: Maybe<TeamBacklogLinkages>;
  /** insert data into the table: "team_freee_linkage_members" */
  insertTeamFreeeLinkageMembers?: Maybe<TeamFreeeLinkageMembersMutationResponse>;
  /** insert a single row into the table: "team_freee_linkage_members" */
  insertTeamFreeeLinkageMembersOne?: Maybe<TeamFreeeLinkageMembers>;
  /** insert data into the table: "team_github_linkage_projects" */
  insertTeamGithubLinkageProjects?: Maybe<TeamGithubLinkageProjectsMutationResponse>;
  /** insert a single row into the table: "team_github_linkage_projects" */
  insertTeamGithubLinkageProjectsOne?: Maybe<TeamGithubLinkageProjects>;
  /** insert data into the table: "team_github_linkages" */
  insertTeamGithubLinkages?: Maybe<TeamGithubLinkagesMutationResponse>;
  /** insert a single row into the table: "team_github_linkages" */
  insertTeamGithubLinkagesOne?: Maybe<TeamGithubLinkages>;
  /** insert data into the table: "team_google_calendar_linkages" */
  insertTeamGoogleCalendarLinkages?: Maybe<TeamGoogleCalendarLinkagesMutationResponse>;
  /** insert a single row into the table: "team_google_calendar_linkages" */
  insertTeamGoogleCalendarLinkagesOne?: Maybe<TeamGoogleCalendarLinkages>;
  /** insert data into the table: "team_member_invitations" */
  insertTeamMemberInvitations?: Maybe<TeamMemberInvitationsMutationResponse>;
  /** insert a single row into the table: "team_member_invitations" */
  insertTeamMemberInvitationsOne?: Maybe<TeamMemberInvitations>;
  /** insert data into the table: "team_member_snapshots" */
  insertTeamMemberSnapshots?: Maybe<TeamMemberSnapshotsMutationResponse>;
  /** insert a single row into the table: "team_member_snapshots" */
  insertTeamMemberSnapshotsOne?: Maybe<TeamMemberSnapshots>;
  /** insert data into the table: "team_members" */
  insertTeamMembers?: Maybe<TeamMembersMutationResponse>;
  /** insert a single row into the table: "team_members" */
  insertTeamMembersOne?: Maybe<TeamMembers>;
  /** insert data into the table: "teams" */
  insertTeams?: Maybe<TeamsMutationResponse>;
  /** insert a single row into the table: "teams" */
  insertTeamsOne?: Maybe<Teams>;
  /** insert data into the table: "users" */
  insertUsers?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insertUsersOne?: Maybe<Users>;
  /** insert data into the table: "work_breaks" */
  insertWorkBreaks?: Maybe<WorkBreaksMutationResponse>;
  /** insert a single row into the table: "work_breaks" */
  insertWorkBreaksOne?: Maybe<WorkBreaks>;
  /** insert data into the table: "work_tasks" */
  insertWorkTasks?: Maybe<WorkTasksMutationResponse>;
  /** insert a single row into the table: "work_tasks" */
  insertWorkTasksOne?: Maybe<WorkTasks>;
  /** insert data into the table: "work_tracking_breaks" */
  insertWorkTrackingBreaks?: Maybe<WorkTrackingBreaksMutationResponse>;
  /** insert a single row into the table: "work_tracking_breaks" */
  insertWorkTrackingBreaksOne?: Maybe<WorkTrackingBreaks>;
  /** insert data into the table: "work_tracking_tasks" */
  insertWorkTrackingTasks?: Maybe<WorkTrackingTasksMutationResponse>;
  /** insert a single row into the table: "work_tracking_tasks" */
  insertWorkTrackingTasksOne?: Maybe<WorkTrackingTasks>;
  /** insert data into the table: "work_trackings" */
  insertWorkTrackings?: Maybe<WorkTrackingsMutationResponse>;
  /** insert a single row into the table: "work_trackings" */
  insertWorkTrackingsOne?: Maybe<WorkTrackings>;
  /** insert data into the table: "works" */
  insertWorks?: Maybe<WorksMutationResponse>;
  /** insert a single row into the table: "works" */
  insertWorksOne?: Maybe<Works>;
  linkBacklog: BooleanResult;
  linkFreeeEmployees: BooleanResult;
  linkGitHub: LinkGithubResult;
  linkGoogleCalendar: BooleanResult;
  login: LoginResult;
  loginByGoogle: LoginResult;
  loginByMicrosoft: LoginResult;
  loginByRefreshToken: LoginResult;
  reLinkGitHub: BooleanResult;
  removeProjectMember: BooleanResult;
  removeTeamAllowedIpAddress: BooleanResult;
  removeTeamMember: BooleanResult;
  resetPassword: BooleanResult;
  resumeWork: BooleanResult;
  sendContactMail: BooleanResult;
  startWork: BooleanResult;
  suspendWork: BooleanResult;
  temporarilyLinkFreee: Array<TemporarilyLinkFreeeResponse>;
  unLinkGitHub: BooleanResult;
  /** update data of the table: "activities" */
  updateActivities?: Maybe<ActivitiesMutationResponse>;
  /** update single row of the table: "activities" */
  updateActivitiesByPk?: Maybe<Activities>;
  /** update multiples rows of table: "activities" */
  updateActivitiesMany?: Maybe<Array<Maybe<ActivitiesMutationResponse>>>;
  updateContract: BooleanResult;
  /** update data of the table: "contracts" */
  updateContracts?: Maybe<ContractsMutationResponse>;
  /** update single row of the table: "contracts" */
  updateContractsByPk?: Maybe<Contracts>;
  /** update multiples rows of table: "contracts" */
  updateContractsMany?: Maybe<Array<Maybe<ContractsMutationResponse>>>;
  updateCustomer: BooleanResult;
  /** update data of the table: "enquete_answer" */
  updateEnqueteAnswer?: Maybe<EnqueteAnswerMutationResponse>;
  /** update single row of the table: "enquete_answer" */
  updateEnqueteAnswerByPk?: Maybe<EnqueteAnswer>;
  /** update multiples rows of table: "enquete_answer" */
  updateEnqueteAnswerMany?: Maybe<Array<Maybe<EnqueteAnswerMutationResponse>>>;
  /** update data of the table: "plans" */
  updatePlans?: Maybe<PlansMutationResponse>;
  /** update single row of the table: "plans" */
  updatePlansByPk?: Maybe<Plans>;
  /** update multiples rows of table: "plans" */
  updatePlansMany?: Maybe<Array<Maybe<PlansMutationResponse>>>;
  /** update data of the table: "project_alerts" */
  updateProjectAlerts?: Maybe<ProjectAlertsMutationResponse>;
  /** update single row of the table: "project_alerts" */
  updateProjectAlertsByPk?: Maybe<ProjectAlerts>;
  /** update multiples rows of table: "project_alerts" */
  updateProjectAlertsMany?: Maybe<Array<Maybe<ProjectAlertsMutationResponse>>>;
  /** update data of the table: "project_members" */
  updateProjectMembers?: Maybe<ProjectMembersMutationResponse>;
  /** update single row of the table: "project_members" */
  updateProjectMembersByPk?: Maybe<ProjectMembers>;
  /** update multiples rows of table: "project_members" */
  updateProjectMembersMany?: Maybe<
    Array<Maybe<ProjectMembersMutationResponse>>
  >;
  /** update data of the table: "project_task_tags" */
  updateProjectTaskTags?: Maybe<ProjectTaskTagsMutationResponse>;
  /** update single row of the table: "project_task_tags" */
  updateProjectTaskTagsByPk?: Maybe<ProjectTaskTags>;
  /** update multiples rows of table: "project_task_tags" */
  updateProjectTaskTagsMany?: Maybe<
    Array<Maybe<ProjectTaskTagsMutationResponse>>
  >;
  /** update data of the table: "projects" */
  updateProjects?: Maybe<ProjectsMutationResponse>;
  /** update single row of the table: "projects" */
  updateProjectsByPk?: Maybe<Projects>;
  /** update multiples rows of table: "projects" */
  updateProjectsMany?: Maybe<Array<Maybe<ProjectsMutationResponse>>>;
  /** update data of the table: "settlement_histories" */
  updateSettlementHistories?: Maybe<SettlementHistoriesMutationResponse>;
  /** update single row of the table: "settlement_histories" */
  updateSettlementHistoriesByPk?: Maybe<SettlementHistories>;
  /** update multiples rows of table: "settlement_histories" */
  updateSettlementHistoriesMany?: Maybe<
    Array<Maybe<SettlementHistoriesMutationResponse>>
  >;
  /** update data of the table: "stripe_customers" */
  updateStripeCustomers?: Maybe<StripeCustomersMutationResponse>;
  /** update single row of the table: "stripe_customers" */
  updateStripeCustomersByPk?: Maybe<StripeCustomers>;
  /** update multiples rows of table: "stripe_customers" */
  updateStripeCustomersMany?: Maybe<
    Array<Maybe<StripeCustomersMutationResponse>>
  >;
  /** update data of the table: "stripe_settlement_histories" */
  updateStripeSettlementHistories?: Maybe<StripeSettlementHistoriesMutationResponse>;
  /** update single row of the table: "stripe_settlement_histories" */
  updateStripeSettlementHistoriesByPk?: Maybe<StripeSettlementHistories>;
  /** update multiples rows of table: "stripe_settlement_histories" */
  updateStripeSettlementHistoriesMany?: Maybe<
    Array<Maybe<StripeSettlementHistoriesMutationResponse>>
  >;
  /** update data of the table: "tasks" */
  updateTasks?: Maybe<TasksMutationResponse>;
  /** update single row of the table: "tasks" */
  updateTasksByPk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  updateTasksMany?: Maybe<Array<Maybe<TasksMutationResponse>>>;
  updateTeamAllowedIpAddress: BooleanResult;
  /** update data of the table: "team_allowed_ip_addresses" */
  updateTeamAllowedIpAddresses?: Maybe<TeamAllowedIpAddressesMutationResponse>;
  /** update single row of the table: "team_allowed_ip_addresses" */
  updateTeamAllowedIpAddressesByPk?: Maybe<TeamAllowedIpAddresses>;
  /** update multiples rows of table: "team_allowed_ip_addresses" */
  updateTeamAllowedIpAddressesMany?: Maybe<
    Array<Maybe<TeamAllowedIpAddressesMutationResponse>>
  >;
  /** update data of the table: "team_backlog_linkage_projects" */
  updateTeamBacklogLinkageProjects?: Maybe<TeamBacklogLinkageProjectsMutationResponse>;
  /** update single row of the table: "team_backlog_linkage_projects" */
  updateTeamBacklogLinkageProjectsByPk?: Maybe<TeamBacklogLinkageProjects>;
  /** update multiples rows of table: "team_backlog_linkage_projects" */
  updateTeamBacklogLinkageProjectsMany?: Maybe<
    Array<Maybe<TeamBacklogLinkageProjectsMutationResponse>>
  >;
  /** update data of the table: "team_backlog_linkages" */
  updateTeamBacklogLinkages?: Maybe<TeamBacklogLinkagesMutationResponse>;
  /** update single row of the table: "team_backlog_linkages" */
  updateTeamBacklogLinkagesByPk?: Maybe<TeamBacklogLinkages>;
  /** update multiples rows of table: "team_backlog_linkages" */
  updateTeamBacklogLinkagesMany?: Maybe<
    Array<Maybe<TeamBacklogLinkagesMutationResponse>>
  >;
  /** update data of the table: "team_freee_linkage_members" */
  updateTeamFreeeLinkageMembers?: Maybe<TeamFreeeLinkageMembersMutationResponse>;
  /** update single row of the table: "team_freee_linkage_members" */
  updateTeamFreeeLinkageMembersByPk?: Maybe<TeamFreeeLinkageMembers>;
  /** update multiples rows of table: "team_freee_linkage_members" */
  updateTeamFreeeLinkageMembersMany?: Maybe<
    Array<Maybe<TeamFreeeLinkageMembersMutationResponse>>
  >;
  /** update data of the table: "team_github_linkage_projects" */
  updateTeamGithubLinkageProjects?: Maybe<TeamGithubLinkageProjectsMutationResponse>;
  /** update single row of the table: "team_github_linkage_projects" */
  updateTeamGithubLinkageProjectsByPk?: Maybe<TeamGithubLinkageProjects>;
  /** update multiples rows of table: "team_github_linkage_projects" */
  updateTeamGithubLinkageProjectsMany?: Maybe<
    Array<Maybe<TeamGithubLinkageProjectsMutationResponse>>
  >;
  /** update data of the table: "team_github_linkages" */
  updateTeamGithubLinkages?: Maybe<TeamGithubLinkagesMutationResponse>;
  /** update single row of the table: "team_github_linkages" */
  updateTeamGithubLinkagesByPk?: Maybe<TeamGithubLinkages>;
  /** update multiples rows of table: "team_github_linkages" */
  updateTeamGithubLinkagesMany?: Maybe<
    Array<Maybe<TeamGithubLinkagesMutationResponse>>
  >;
  /** update data of the table: "team_google_calendar_linkages" */
  updateTeamGoogleCalendarLinkages?: Maybe<TeamGoogleCalendarLinkagesMutationResponse>;
  /** update single row of the table: "team_google_calendar_linkages" */
  updateTeamGoogleCalendarLinkagesByPk?: Maybe<TeamGoogleCalendarLinkages>;
  /** update multiples rows of table: "team_google_calendar_linkages" */
  updateTeamGoogleCalendarLinkagesMany?: Maybe<
    Array<Maybe<TeamGoogleCalendarLinkagesMutationResponse>>
  >;
  /** update data of the table: "team_member_invitations" */
  updateTeamMemberInvitations?: Maybe<TeamMemberInvitationsMutationResponse>;
  /** update single row of the table: "team_member_invitations" */
  updateTeamMemberInvitationsByPk?: Maybe<TeamMemberInvitations>;
  /** update multiples rows of table: "team_member_invitations" */
  updateTeamMemberInvitationsMany?: Maybe<
    Array<Maybe<TeamMemberInvitationsMutationResponse>>
  >;
  /** update data of the table: "team_member_snapshots" */
  updateTeamMemberSnapshots?: Maybe<TeamMemberSnapshotsMutationResponse>;
  /** update single row of the table: "team_member_snapshots" */
  updateTeamMemberSnapshotsByPk?: Maybe<TeamMemberSnapshots>;
  /** update multiples rows of table: "team_member_snapshots" */
  updateTeamMemberSnapshotsMany?: Maybe<
    Array<Maybe<TeamMemberSnapshotsMutationResponse>>
  >;
  /** update data of the table: "team_members" */
  updateTeamMembers?: Maybe<TeamMembersMutationResponse>;
  /** update single row of the table: "team_members" */
  updateTeamMembersByPk?: Maybe<TeamMembers>;
  /** update multiples rows of table: "team_members" */
  updateTeamMembersMany?: Maybe<Array<Maybe<TeamMembersMutationResponse>>>;
  /** update data of the table: "teams" */
  updateTeams?: Maybe<TeamsMutationResponse>;
  /** update single row of the table: "teams" */
  updateTeamsByPk?: Maybe<Teams>;
  /** update multiples rows of table: "teams" */
  updateTeamsMany?: Maybe<Array<Maybe<TeamsMutationResponse>>>;
  updateTwoFASetting: UpdateTwoFaSettingResponse;
  updateUserEmail: BooleanResult;
  updateUserPassword: BooleanResult;
  updateUserPasswordForce: BooleanResult;
  /** update data of the table: "users" */
  updateUsers?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  updateUsersByPk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  updateUsersMany?: Maybe<Array<Maybe<UsersMutationResponse>>>;
  /** update data of the table: "work_breaks" */
  updateWorkBreaks?: Maybe<WorkBreaksMutationResponse>;
  /** update single row of the table: "work_breaks" */
  updateWorkBreaksByPk?: Maybe<WorkBreaks>;
  /** update multiples rows of table: "work_breaks" */
  updateWorkBreaksMany?: Maybe<Array<Maybe<WorkBreaksMutationResponse>>>;
  updateWorkTask: BooleanResult;
  /** update data of the table: "work_tasks" */
  updateWorkTasks?: Maybe<WorkTasksMutationResponse>;
  /** update single row of the table: "work_tasks" */
  updateWorkTasksByPk?: Maybe<WorkTasks>;
  /** update multiples rows of table: "work_tasks" */
  updateWorkTasksMany?: Maybe<Array<Maybe<WorkTasksMutationResponse>>>;
  /** update data of the table: "work_tracking_breaks" */
  updateWorkTrackingBreaks?: Maybe<WorkTrackingBreaksMutationResponse>;
  /** update single row of the table: "work_tracking_breaks" */
  updateWorkTrackingBreaksByPk?: Maybe<WorkTrackingBreaks>;
  /** update multiples rows of table: "work_tracking_breaks" */
  updateWorkTrackingBreaksMany?: Maybe<
    Array<Maybe<WorkTrackingBreaksMutationResponse>>
  >;
  /** update data of the table: "work_tracking_tasks" */
  updateWorkTrackingTasks?: Maybe<WorkTrackingTasksMutationResponse>;
  /** update single row of the table: "work_tracking_tasks" */
  updateWorkTrackingTasksByPk?: Maybe<WorkTrackingTasks>;
  /** update multiples rows of table: "work_tracking_tasks" */
  updateWorkTrackingTasksMany?: Maybe<
    Array<Maybe<WorkTrackingTasksMutationResponse>>
  >;
  /** update data of the table: "work_trackings" */
  updateWorkTrackings?: Maybe<WorkTrackingsMutationResponse>;
  /** update single row of the table: "work_trackings" */
  updateWorkTrackingsByPk?: Maybe<WorkTrackings>;
  /** update multiples rows of table: "work_trackings" */
  updateWorkTrackingsMany?: Maybe<Array<Maybe<WorkTrackingsMutationResponse>>>;
  /** update data of the table: "works" */
  updateWorks?: Maybe<WorksMutationResponse>;
  /** update single row of the table: "works" */
  updateWorksByPk?: Maybe<Works>;
  /** update multiples rows of table: "works" */
  updateWorksMany?: Maybe<Array<Maybe<WorksMutationResponse>>>;
  verificateEmailForCreateUser: BooleanResult;
  verificateEmailForUpdateUserEmail: BooleanResult;
};

/** mutation root */
export type Mutation_RootAcceptTeamMemberInvitationArgs = {
  input: AcceptTeamMemberInvitationInput;
};

/** mutation root */
export type Mutation_RootApproveGitHubInstallationArgs = {
  input: ApproveGitHubInstallationInput;
};

/** mutation root */
export type Mutation_RootArchiveProjectArgs = {
  input: ArchiveProjectInput;
};

/** mutation root */
export type Mutation_RootAuthenticateTwoFaArgs = {
  input: AuthenticateTwoFaInput;
};

/** mutation root */
export type Mutation_RootCreateBacklogAuthzUrlArgs = {
  input: CreateBacklogAuthzUrlInput;
};

/** mutation root */
export type Mutation_RootCreateGoogleLoginAuthzUrlArgs = {
  input: CreateGoogleLoginAuthzUrlInput;
};

/** mutation root */
export type Mutation_RootCreateMicrosoftLoginAuthzUrlArgs = {
  input: CreateMicrosoftLoginAuthzUrlInput;
};

/** mutation root */
export type Mutation_RootCreateProjectArgs = {
  input: CreateProjectInput;
};

/** mutation root */
export type Mutation_RootCreateSubscriptionSessionArgs = {
  input: CreateSubscriptionSessionInput;
};

/** mutation root */
export type Mutation_RootCreateTeamArgs = {
  input: CreateTeamInput;
};

/** mutation root */
export type Mutation_RootCreateUserArgs = {
  input: CreateUserInput;
};

/** mutation root */
export type Mutation_RootDeleteActivitiesArgs = {
  where: ActivitiesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteActivitiesByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteContractsArgs = {
  where: ContractsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteContractsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteEnqueteAnswerArgs = {
  where: EnqueteAnswerBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteEnqueteAnswerByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeletePlansArgs = {
  where: PlansBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePlansByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteProjectAlertsArgs = {
  where: ProjectAlertsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProjectAlertsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteProjectMembersArgs = {
  where: ProjectMembersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProjectMembersByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteProjectTaskTagsArgs = {
  where: ProjectTaskTagsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProjectTaskTagsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteProjectsArgs = {
  where: ProjectsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteProjectsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteSettlementHistoriesArgs = {
  where: SettlementHistoriesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteSettlementHistoriesByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteStripeCustomersArgs = {
  where: StripeCustomersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStripeCustomersByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteStripeSettlementHistoriesArgs = {
  where: StripeSettlementHistoriesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStripeSettlementHistoriesByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTasksArgs = {
  where: TasksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTasksByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamAllowedIpAddressesArgs = {
  where: TeamAllowedIpAddressesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamAllowedIpAddressesByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamBacklogLinkageProjectsArgs = {
  where: TeamBacklogLinkageProjectsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamBacklogLinkageProjectsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamBacklogLinkagesArgs = {
  where: TeamBacklogLinkagesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamBacklogLinkagesByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamFreeeLinkageMembersArgs = {
  where: TeamFreeeLinkageMembersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamFreeeLinkageMembersByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamGithubLinkageProjectsArgs = {
  where: TeamGithubLinkageProjectsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamGithubLinkageProjectsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamGithubLinkagesArgs = {
  where: TeamGithubLinkagesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamGithubLinkagesByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamGoogleCalendarLinkagesArgs = {
  where: TeamGoogleCalendarLinkagesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamGoogleCalendarLinkagesByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamMemberInvitationsArgs = {
  where: TeamMemberInvitationsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamMemberInvitationsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamMemberSnapshotsArgs = {
  where: TeamMemberSnapshotsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamMemberSnapshotsByPkArgs = {
  teamId: Scalars["String"];
  teamMemberId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamMembersArgs = {
  where: TeamMembersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamMembersByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteTeamsArgs = {
  where: TeamsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTeamsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: UsersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUsersByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteWorkBreaksArgs = {
  where: WorkBreaksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWorkBreaksByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteWorkTasksArgs = {
  where: WorkTasksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWorkTasksByPkArgs = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteWorkTrackingBreaksArgs = {
  where: WorkTrackingBreaksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWorkTrackingBreaksByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteWorkTrackingTasksArgs = {
  where: WorkTrackingTasksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWorkTrackingTasksByPkArgs = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workTrackingId: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteWorkTrackingsArgs = {
  where: WorkTrackingsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWorkTrackingsByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDeleteWorksArgs = {
  where: WorksBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteWorksByPkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootExportTeamReportArgs = {
  input: ExportTeamReportInput;
};

/** mutation root */
export type Mutation_RootExportWorksArgs = {
  input: ExportWorksInput;
};

/** mutation root */
export type Mutation_RootFinishWorkArgs = {
  input: FinishWorkInput;
};

/** mutation root */
export type Mutation_RootInitialLinkFreeeEmployeesArgs = {
  input: InitialLinkFreeeEmployeesInput;
};

/** mutation root */
export type Mutation_RootInsertActivitiesArgs = {
  objects: Array<ActivitiesInsertInput>;
  onConflict?: InputMaybe<ActivitiesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertActivitiesOneArgs = {
  object: ActivitiesInsertInput;
  onConflict?: InputMaybe<ActivitiesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertContractsArgs = {
  objects: Array<ContractsInsertInput>;
  onConflict?: InputMaybe<ContractsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertContractsOneArgs = {
  object: ContractsInsertInput;
  onConflict?: InputMaybe<ContractsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEnqueteAnswerArgs = {
  objects: Array<EnqueteAnswerInsertInput>;
  onConflict?: InputMaybe<EnqueteAnswerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEnqueteAnswerOneArgs = {
  object: EnqueteAnswerInsertInput;
  onConflict?: InputMaybe<EnqueteAnswerOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlansArgs = {
  objects: Array<PlansInsertInput>;
  onConflict?: InputMaybe<PlansOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlansOneArgs = {
  object: PlansInsertInput;
  onConflict?: InputMaybe<PlansOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectAlertsArgs = {
  objects: Array<ProjectAlertsInsertInput>;
  onConflict?: InputMaybe<ProjectAlertsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectAlertsOneArgs = {
  object: ProjectAlertsInsertInput;
  onConflict?: InputMaybe<ProjectAlertsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectMembersArgs = {
  objects: Array<ProjectMembersInsertInput>;
  onConflict?: InputMaybe<ProjectMembersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectMembersOneArgs = {
  object: ProjectMembersInsertInput;
  onConflict?: InputMaybe<ProjectMembersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectTaskTagsArgs = {
  objects: Array<ProjectTaskTagsInsertInput>;
  onConflict?: InputMaybe<ProjectTaskTagsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectTaskTagsOneArgs = {
  object: ProjectTaskTagsInsertInput;
  onConflict?: InputMaybe<ProjectTaskTagsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectsArgs = {
  objects: Array<ProjectsInsertInput>;
  onConflict?: InputMaybe<ProjectsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertProjectsOneArgs = {
  object: ProjectsInsertInput;
  onConflict?: InputMaybe<ProjectsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSettlementHistoriesArgs = {
  objects: Array<SettlementHistoriesInsertInput>;
  onConflict?: InputMaybe<SettlementHistoriesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertSettlementHistoriesOneArgs = {
  object: SettlementHistoriesInsertInput;
  onConflict?: InputMaybe<SettlementHistoriesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStripeCustomersArgs = {
  objects: Array<StripeCustomersInsertInput>;
  onConflict?: InputMaybe<StripeCustomersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStripeCustomersOneArgs = {
  object: StripeCustomersInsertInput;
  onConflict?: InputMaybe<StripeCustomersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStripeSettlementHistoriesArgs = {
  objects: Array<StripeSettlementHistoriesInsertInput>;
  onConflict?: InputMaybe<StripeSettlementHistoriesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStripeSettlementHistoriesOneArgs = {
  object: StripeSettlementHistoriesInsertInput;
  onConflict?: InputMaybe<StripeSettlementHistoriesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTasksArgs = {
  objects: Array<TasksInsertInput>;
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTasksOneArgs = {
  object: TasksInsertInput;
  onConflict?: InputMaybe<TasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamAllowedIpAddressesArgs = {
  objects: Array<TeamAllowedIpAddressesInsertInput>;
  onConflict?: InputMaybe<TeamAllowedIpAddressesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamAllowedIpAddressesOneArgs = {
  object: TeamAllowedIpAddressesInsertInput;
  onConflict?: InputMaybe<TeamAllowedIpAddressesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamBacklogLinkageProjectsArgs = {
  objects: Array<TeamBacklogLinkageProjectsInsertInput>;
  onConflict?: InputMaybe<TeamBacklogLinkageProjectsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamBacklogLinkageProjectsOneArgs = {
  object: TeamBacklogLinkageProjectsInsertInput;
  onConflict?: InputMaybe<TeamBacklogLinkageProjectsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamBacklogLinkagesArgs = {
  objects: Array<TeamBacklogLinkagesInsertInput>;
  onConflict?: InputMaybe<TeamBacklogLinkagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamBacklogLinkagesOneArgs = {
  object: TeamBacklogLinkagesInsertInput;
  onConflict?: InputMaybe<TeamBacklogLinkagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamFreeeLinkageMembersArgs = {
  objects: Array<TeamFreeeLinkageMembersInsertInput>;
  onConflict?: InputMaybe<TeamFreeeLinkageMembersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamFreeeLinkageMembersOneArgs = {
  object: TeamFreeeLinkageMembersInsertInput;
  onConflict?: InputMaybe<TeamFreeeLinkageMembersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamGithubLinkageProjectsArgs = {
  objects: Array<TeamGithubLinkageProjectsInsertInput>;
  onConflict?: InputMaybe<TeamGithubLinkageProjectsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamGithubLinkageProjectsOneArgs = {
  object: TeamGithubLinkageProjectsInsertInput;
  onConflict?: InputMaybe<TeamGithubLinkageProjectsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamGithubLinkagesArgs = {
  objects: Array<TeamGithubLinkagesInsertInput>;
  onConflict?: InputMaybe<TeamGithubLinkagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamGithubLinkagesOneArgs = {
  object: TeamGithubLinkagesInsertInput;
  onConflict?: InputMaybe<TeamGithubLinkagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamGoogleCalendarLinkagesArgs = {
  objects: Array<TeamGoogleCalendarLinkagesInsertInput>;
  onConflict?: InputMaybe<TeamGoogleCalendarLinkagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamGoogleCalendarLinkagesOneArgs = {
  object: TeamGoogleCalendarLinkagesInsertInput;
  onConflict?: InputMaybe<TeamGoogleCalendarLinkagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamMemberInvitationsArgs = {
  objects: Array<TeamMemberInvitationsInsertInput>;
  onConflict?: InputMaybe<TeamMemberInvitationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamMemberInvitationsOneArgs = {
  object: TeamMemberInvitationsInsertInput;
  onConflict?: InputMaybe<TeamMemberInvitationsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamMemberSnapshotsArgs = {
  objects: Array<TeamMemberSnapshotsInsertInput>;
  onConflict?: InputMaybe<TeamMemberSnapshotsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamMemberSnapshotsOneArgs = {
  object: TeamMemberSnapshotsInsertInput;
  onConflict?: InputMaybe<TeamMemberSnapshotsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamMembersArgs = {
  objects: Array<TeamMembersInsertInput>;
  onConflict?: InputMaybe<TeamMembersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamMembersOneArgs = {
  object: TeamMembersInsertInput;
  onConflict?: InputMaybe<TeamMembersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamsArgs = {
  objects: Array<TeamsInsertInput>;
  onConflict?: InputMaybe<TeamsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTeamsOneArgs = {
  object: TeamsInsertInput;
  onConflict?: InputMaybe<TeamsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersOneArgs = {
  object: UsersInsertInput;
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkBreaksArgs = {
  objects: Array<WorkBreaksInsertInput>;
  onConflict?: InputMaybe<WorkBreaksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkBreaksOneArgs = {
  object: WorkBreaksInsertInput;
  onConflict?: InputMaybe<WorkBreaksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTasksArgs = {
  objects: Array<WorkTasksInsertInput>;
  onConflict?: InputMaybe<WorkTasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTasksOneArgs = {
  object: WorkTasksInsertInput;
  onConflict?: InputMaybe<WorkTasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTrackingBreaksArgs = {
  objects: Array<WorkTrackingBreaksInsertInput>;
  onConflict?: InputMaybe<WorkTrackingBreaksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTrackingBreaksOneArgs = {
  object: WorkTrackingBreaksInsertInput;
  onConflict?: InputMaybe<WorkTrackingBreaksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTrackingTasksArgs = {
  objects: Array<WorkTrackingTasksInsertInput>;
  onConflict?: InputMaybe<WorkTrackingTasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTrackingTasksOneArgs = {
  object: WorkTrackingTasksInsertInput;
  onConflict?: InputMaybe<WorkTrackingTasksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTrackingsArgs = {
  objects: Array<WorkTrackingsInsertInput>;
  onConflict?: InputMaybe<WorkTrackingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorkTrackingsOneArgs = {
  object: WorkTrackingsInsertInput;
  onConflict?: InputMaybe<WorkTrackingsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorksArgs = {
  objects: Array<WorksInsertInput>;
  onConflict?: InputMaybe<WorksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertWorksOneArgs = {
  object: WorksInsertInput;
  onConflict?: InputMaybe<WorksOnConflict>;
};

/** mutation root */
export type Mutation_RootLinkBacklogArgs = {
  input: LinkBacklogInput;
};

/** mutation root */
export type Mutation_RootLinkFreeeEmployeesArgs = {
  input: LinkFreeeEmployeesInput;
};

/** mutation root */
export type Mutation_RootLinkGitHubArgs = {
  input: LinkGithubInput;
};

/** mutation root */
export type Mutation_RootLinkGoogleCalendarArgs = {
  input: LinkGoogleCalendarInput;
};

/** mutation root */
export type Mutation_RootLoginArgs = {
  input: LoginInput;
};

/** mutation root */
export type Mutation_RootLoginByGoogleArgs = {
  input: LoginByGoogleInput;
};

/** mutation root */
export type Mutation_RootLoginByMicrosoftArgs = {
  input: LoginByMicrosoftInput;
};

/** mutation root */
export type Mutation_RootLoginByRefreshTokenArgs = {
  input: LoginByRefreshTokenInput;
};

/** mutation root */
export type Mutation_RootReLinkGitHubArgs = {
  input: ReLinkGithubInput;
};

/** mutation root */
export type Mutation_RootRemoveProjectMemberArgs = {
  input: RemoveProjectMemberInput;
};

/** mutation root */
export type Mutation_RootRemoveTeamAllowedIpAddressArgs = {
  input: RemoveTeamAllowedIpAddressInput;
};

/** mutation root */
export type Mutation_RootRemoveTeamMemberArgs = {
  input: RemoveTeamMemberInput;
};

/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  input: ResetPasswordInput;
};

/** mutation root */
export type Mutation_RootSendContactMailArgs = {
  input: SendContactMailInput;
};

/** mutation root */
export type Mutation_RootTemporarilyLinkFreeeArgs = {
  input: TemporarilyLinkFreeeInput;
};

/** mutation root */
export type Mutation_RootUnLinkGitHubArgs = {
  input: UnLinkGithubInput;
};

/** mutation root */
export type Mutation_RootUpdateActivitiesArgs = {
  _set?: InputMaybe<ActivitiesSetInput>;
  where: ActivitiesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateActivitiesByPkArgs = {
  _set?: InputMaybe<ActivitiesSetInput>;
  pkColumns: ActivitiesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateActivitiesManyArgs = {
  updates: Array<ActivitiesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateContractArgs = {
  input: UpdateContractInput;
};

/** mutation root */
export type Mutation_RootUpdateContractsArgs = {
  _inc?: InputMaybe<ContractsIncInput>;
  _set?: InputMaybe<ContractsSetInput>;
  where: ContractsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateContractsByPkArgs = {
  _inc?: InputMaybe<ContractsIncInput>;
  _set?: InputMaybe<ContractsSetInput>;
  pkColumns: ContractsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateContractsManyArgs = {
  updates: Array<ContractsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

/** mutation root */
export type Mutation_RootUpdateEnqueteAnswerArgs = {
  _append?: InputMaybe<EnqueteAnswerAppendInput>;
  _deleteAtPath?: InputMaybe<EnqueteAnswerDeleteAtPathInput>;
  _deleteElem?: InputMaybe<EnqueteAnswerDeleteElemInput>;
  _deleteKey?: InputMaybe<EnqueteAnswerDeleteKeyInput>;
  _prepend?: InputMaybe<EnqueteAnswerPrependInput>;
  _set?: InputMaybe<EnqueteAnswerSetInput>;
  where: EnqueteAnswerBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateEnqueteAnswerByPkArgs = {
  _append?: InputMaybe<EnqueteAnswerAppendInput>;
  _deleteAtPath?: InputMaybe<EnqueteAnswerDeleteAtPathInput>;
  _deleteElem?: InputMaybe<EnqueteAnswerDeleteElemInput>;
  _deleteKey?: InputMaybe<EnqueteAnswerDeleteKeyInput>;
  _prepend?: InputMaybe<EnqueteAnswerPrependInput>;
  _set?: InputMaybe<EnqueteAnswerSetInput>;
  pkColumns: EnqueteAnswerPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateEnqueteAnswerManyArgs = {
  updates: Array<EnqueteAnswerUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePlansArgs = {
  _inc?: InputMaybe<PlansIncInput>;
  _set?: InputMaybe<PlansSetInput>;
  where: PlansBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePlansByPkArgs = {
  _inc?: InputMaybe<PlansIncInput>;
  _set?: InputMaybe<PlansSetInput>;
  pkColumns: PlansPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePlansManyArgs = {
  updates: Array<PlansUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProjectAlertsArgs = {
  _inc?: InputMaybe<ProjectAlertsIncInput>;
  _set?: InputMaybe<ProjectAlertsSetInput>;
  where: ProjectAlertsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProjectAlertsByPkArgs = {
  _inc?: InputMaybe<ProjectAlertsIncInput>;
  _set?: InputMaybe<ProjectAlertsSetInput>;
  pkColumns: ProjectAlertsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProjectAlertsManyArgs = {
  updates: Array<ProjectAlertsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProjectMembersArgs = {
  _set?: InputMaybe<ProjectMembersSetInput>;
  where: ProjectMembersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProjectMembersByPkArgs = {
  _set?: InputMaybe<ProjectMembersSetInput>;
  pkColumns: ProjectMembersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProjectMembersManyArgs = {
  updates: Array<ProjectMembersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProjectTaskTagsArgs = {
  _set?: InputMaybe<ProjectTaskTagsSetInput>;
  where: ProjectTaskTagsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProjectTaskTagsByPkArgs = {
  _set?: InputMaybe<ProjectTaskTagsSetInput>;
  pkColumns: ProjectTaskTagsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProjectTaskTagsManyArgs = {
  updates: Array<ProjectTaskTagsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateProjectsArgs = {
  _set?: InputMaybe<ProjectsSetInput>;
  where: ProjectsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateProjectsByPkArgs = {
  _set?: InputMaybe<ProjectsSetInput>;
  pkColumns: ProjectsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateProjectsManyArgs = {
  updates: Array<ProjectsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateSettlementHistoriesArgs = {
  _inc?: InputMaybe<SettlementHistoriesIncInput>;
  _set?: InputMaybe<SettlementHistoriesSetInput>;
  where: SettlementHistoriesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateSettlementHistoriesByPkArgs = {
  _inc?: InputMaybe<SettlementHistoriesIncInput>;
  _set?: InputMaybe<SettlementHistoriesSetInput>;
  pkColumns: SettlementHistoriesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateSettlementHistoriesManyArgs = {
  updates: Array<SettlementHistoriesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStripeCustomersArgs = {
  _set?: InputMaybe<StripeCustomersSetInput>;
  where: StripeCustomersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStripeCustomersByPkArgs = {
  _set?: InputMaybe<StripeCustomersSetInput>;
  pkColumns: StripeCustomersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStripeCustomersManyArgs = {
  updates: Array<StripeCustomersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStripeSettlementHistoriesArgs = {
  _set?: InputMaybe<StripeSettlementHistoriesSetInput>;
  where: StripeSettlementHistoriesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStripeSettlementHistoriesByPkArgs = {
  _set?: InputMaybe<StripeSettlementHistoriesSetInput>;
  pkColumns: StripeSettlementHistoriesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStripeSettlementHistoriesManyArgs = {
  updates: Array<StripeSettlementHistoriesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTasksArgs = {
  _inc?: InputMaybe<TasksIncInput>;
  _set?: InputMaybe<TasksSetInput>;
  where: TasksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTasksByPkArgs = {
  _inc?: InputMaybe<TasksIncInput>;
  _set?: InputMaybe<TasksSetInput>;
  pkColumns: TasksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTasksManyArgs = {
  updates: Array<TasksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamAllowedIpAddressArgs = {
  input: UpdateTeamAllowedIpAddressInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamAllowedIpAddressesArgs = {
  _set?: InputMaybe<TeamAllowedIpAddressesSetInput>;
  where: TeamAllowedIpAddressesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamAllowedIpAddressesByPkArgs = {
  _set?: InputMaybe<TeamAllowedIpAddressesSetInput>;
  pkColumns: TeamAllowedIpAddressesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamAllowedIpAddressesManyArgs = {
  updates: Array<TeamAllowedIpAddressesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamBacklogLinkageProjectsArgs = {
  _inc?: InputMaybe<TeamBacklogLinkageProjectsIncInput>;
  _set?: InputMaybe<TeamBacklogLinkageProjectsSetInput>;
  where: TeamBacklogLinkageProjectsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamBacklogLinkageProjectsByPkArgs = {
  _inc?: InputMaybe<TeamBacklogLinkageProjectsIncInput>;
  _set?: InputMaybe<TeamBacklogLinkageProjectsSetInput>;
  pkColumns: TeamBacklogLinkageProjectsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamBacklogLinkageProjectsManyArgs = {
  updates: Array<TeamBacklogLinkageProjectsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamBacklogLinkagesArgs = {
  _set?: InputMaybe<TeamBacklogLinkagesSetInput>;
  where: TeamBacklogLinkagesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamBacklogLinkagesByPkArgs = {
  _set?: InputMaybe<TeamBacklogLinkagesSetInput>;
  pkColumns: TeamBacklogLinkagesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamBacklogLinkagesManyArgs = {
  updates: Array<TeamBacklogLinkagesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamFreeeLinkageMembersArgs = {
  _inc?: InputMaybe<TeamFreeeLinkageMembersIncInput>;
  _set?: InputMaybe<TeamFreeeLinkageMembersSetInput>;
  where: TeamFreeeLinkageMembersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamFreeeLinkageMembersByPkArgs = {
  _inc?: InputMaybe<TeamFreeeLinkageMembersIncInput>;
  _set?: InputMaybe<TeamFreeeLinkageMembersSetInput>;
  pkColumns: TeamFreeeLinkageMembersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamFreeeLinkageMembersManyArgs = {
  updates: Array<TeamFreeeLinkageMembersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamGithubLinkageProjectsArgs = {
  _inc?: InputMaybe<TeamGithubLinkageProjectsIncInput>;
  _set?: InputMaybe<TeamGithubLinkageProjectsSetInput>;
  where: TeamGithubLinkageProjectsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamGithubLinkageProjectsByPkArgs = {
  _inc?: InputMaybe<TeamGithubLinkageProjectsIncInput>;
  _set?: InputMaybe<TeamGithubLinkageProjectsSetInput>;
  pkColumns: TeamGithubLinkageProjectsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamGithubLinkageProjectsManyArgs = {
  updates: Array<TeamGithubLinkageProjectsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamGithubLinkagesArgs = {
  _inc?: InputMaybe<TeamGithubLinkagesIncInput>;
  _set?: InputMaybe<TeamGithubLinkagesSetInput>;
  where: TeamGithubLinkagesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamGithubLinkagesByPkArgs = {
  _inc?: InputMaybe<TeamGithubLinkagesIncInput>;
  _set?: InputMaybe<TeamGithubLinkagesSetInput>;
  pkColumns: TeamGithubLinkagesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamGithubLinkagesManyArgs = {
  updates: Array<TeamGithubLinkagesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamGoogleCalendarLinkagesArgs = {
  _set?: InputMaybe<TeamGoogleCalendarLinkagesSetInput>;
  where: TeamGoogleCalendarLinkagesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamGoogleCalendarLinkagesByPkArgs = {
  _set?: InputMaybe<TeamGoogleCalendarLinkagesSetInput>;
  pkColumns: TeamGoogleCalendarLinkagesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamGoogleCalendarLinkagesManyArgs = {
  updates: Array<TeamGoogleCalendarLinkagesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamMemberInvitationsArgs = {
  _set?: InputMaybe<TeamMemberInvitationsSetInput>;
  where: TeamMemberInvitationsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamMemberInvitationsByPkArgs = {
  _set?: InputMaybe<TeamMemberInvitationsSetInput>;
  pkColumns: TeamMemberInvitationsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamMemberInvitationsManyArgs = {
  updates: Array<TeamMemberInvitationsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamMemberSnapshotsArgs = {
  _set?: InputMaybe<TeamMemberSnapshotsSetInput>;
  where: TeamMemberSnapshotsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamMemberSnapshotsByPkArgs = {
  _set?: InputMaybe<TeamMemberSnapshotsSetInput>;
  pkColumns: TeamMemberSnapshotsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamMemberSnapshotsManyArgs = {
  updates: Array<TeamMemberSnapshotsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamMembersArgs = {
  _set?: InputMaybe<TeamMembersSetInput>;
  where: TeamMembersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamMembersByPkArgs = {
  _set?: InputMaybe<TeamMembersSetInput>;
  pkColumns: TeamMembersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamMembersManyArgs = {
  updates: Array<TeamMembersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTeamsArgs = {
  _set?: InputMaybe<TeamsSetInput>;
  where: TeamsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTeamsByPkArgs = {
  _set?: InputMaybe<TeamsSetInput>;
  pkColumns: TeamsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTeamsManyArgs = {
  updates: Array<TeamsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTwoFaSettingArgs = {
  input: UpdateTwoFaSettingInput;
};

/** mutation root */
export type Mutation_RootUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};

/** mutation root */
export type Mutation_RootUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};

/** mutation root */
export type Mutation_RootUpdateUserPasswordForceArgs = {
  input: UpdateUserPasswordForceInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUsersByPkArgs = {
  _set?: InputMaybe<UsersSetInput>;
  pkColumns: UsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWorkBreaksArgs = {
  _set?: InputMaybe<WorkBreaksSetInput>;
  where: WorkBreaksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWorkBreaksByPkArgs = {
  _set?: InputMaybe<WorkBreaksSetInput>;
  pkColumns: WorkBreaksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWorkBreaksManyArgs = {
  updates: Array<WorkBreaksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWorkTaskArgs = {
  input: UpdateWorkTaskInput;
};

/** mutation root */
export type Mutation_RootUpdateWorkTasksArgs = {
  _inc?: InputMaybe<WorkTasksIncInput>;
  _set?: InputMaybe<WorkTasksSetInput>;
  where: WorkTasksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWorkTasksByPkArgs = {
  _inc?: InputMaybe<WorkTasksIncInput>;
  _set?: InputMaybe<WorkTasksSetInput>;
  pkColumns: WorkTasksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWorkTasksManyArgs = {
  updates: Array<WorkTasksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingBreaksArgs = {
  _set?: InputMaybe<WorkTrackingBreaksSetInput>;
  where: WorkTrackingBreaksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingBreaksByPkArgs = {
  _set?: InputMaybe<WorkTrackingBreaksSetInput>;
  pkColumns: WorkTrackingBreaksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingBreaksManyArgs = {
  updates: Array<WorkTrackingBreaksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingTasksArgs = {
  _inc?: InputMaybe<WorkTrackingTasksIncInput>;
  _set?: InputMaybe<WorkTrackingTasksSetInput>;
  where: WorkTrackingTasksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingTasksByPkArgs = {
  _inc?: InputMaybe<WorkTrackingTasksIncInput>;
  _set?: InputMaybe<WorkTrackingTasksSetInput>;
  pkColumns: WorkTrackingTasksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingTasksManyArgs = {
  updates: Array<WorkTrackingTasksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingsArgs = {
  _inc?: InputMaybe<WorkTrackingsIncInput>;
  _set?: InputMaybe<WorkTrackingsSetInput>;
  where: WorkTrackingsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingsByPkArgs = {
  _inc?: InputMaybe<WorkTrackingsIncInput>;
  _set?: InputMaybe<WorkTrackingsSetInput>;
  pkColumns: WorkTrackingsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWorkTrackingsManyArgs = {
  updates: Array<WorkTrackingsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateWorksArgs = {
  _inc?: InputMaybe<WorksIncInput>;
  _set?: InputMaybe<WorksSetInput>;
  where: WorksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateWorksByPkArgs = {
  _inc?: InputMaybe<WorksIncInput>;
  _set?: InputMaybe<WorksSetInput>;
  pkColumns: WorksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateWorksManyArgs = {
  updates: Array<WorksUpdates>;
};

/** mutation root */
export type Mutation_RootVerificateEmailForCreateUserArgs = {
  input: VerificateEmailForCreateUserInput;
};

/** mutation root */
export type Mutation_RootVerificateEmailForUpdateUserEmailArgs = {
  input: VerificateEmailForUpdateUserEmailInput;
};

export type ProjectAlertsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ProjectAlertsBoolExp>;
  predicate: IntComparisonExp;
};

export type ProjectMembersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ProjectMembersBoolExp>;
  predicate: IntComparisonExp;
};

export type ProjectTaskTagsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ProjectTaskTagsBoolExp>;
  predicate: IntComparisonExp;
};

export type ProjectsAggregateBoolExpBool_And = {
  arguments: ProjectsSelectColumnProjectsAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ProjectsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProjectsAggregateBoolExpBool_Or = {
  arguments: ProjectsSelectColumnProjectsAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ProjectsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProjectsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<ProjectsBoolExp>;
  predicate: IntComparisonExp;
};

export type Query_Root = {
  /** An array relationship */
  activities: Array<Activities>;
  /** An aggregate relationship */
  activitiesAggregate: ActivitiesAggregate;
  /** fetch data from the table: "activities" using primary key columns */
  activitiesByPk?: Maybe<Activities>;
  backlogIssues: Array<BacklogIssuesResponse>;
  backlogProjects: Array<BacklogProjectsResponse>;
  /** An array relationship */
  contracts: Array<Contracts>;
  /** An aggregate relationship */
  contractsAggregate: ContractsAggregate;
  /** fetch data from the table: "contracts" using primary key columns */
  contractsByPk?: Maybe<Contracts>;
  /** fetch data from the table: "enquete_answer" */
  enqueteAnswer: Array<EnqueteAnswer>;
  /** fetch aggregated fields from the table: "enquete_answer" */
  enqueteAnswerAggregate: EnqueteAnswerAggregate;
  /** fetch data from the table: "enquete_answer" using primary key columns */
  enqueteAnswerByPk?: Maybe<EnqueteAnswer>;
  freeeEmployees: Array<FreeeEmployeesResponse>;
  getIpAddress: GetIpAddressResponse;
  githubIssues: Array<GithubIssue>;
  githubRepositories: Array<GithubRepository>;
  googleCalendarEvent: GoogleCalendarEventResponse;
  nextInvoice: NextInvoice;
  nextInvoicePreview: NextInvoicePreview;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plansAggregate: PlansAggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plansByPk?: Maybe<Plans>;
  /** An array relationship */
  projectAlerts: Array<ProjectAlerts>;
  /** An aggregate relationship */
  projectAlertsAggregate: ProjectAlertsAggregate;
  /** fetch data from the table: "project_alerts" using primary key columns */
  projectAlertsByPk?: Maybe<ProjectAlerts>;
  /** fetch data from the table: "project_creatable_count_view" */
  projectCreatableCountView: Array<ProjectCreatableCountView>;
  /** fetch aggregated fields from the table: "project_creatable_count_view" */
  projectCreatableCountViewAggregate: ProjectCreatableCountViewAggregate;
  /** An array relationship */
  projectMembers: Array<ProjectMembers>;
  /** An aggregate relationship */
  projectMembersAggregate: ProjectMembersAggregate;
  /** fetch data from the table: "project_members" using primary key columns */
  projectMembersByPk?: Maybe<ProjectMembers>;
  /** An array relationship */
  projectTaskTags: Array<ProjectTaskTags>;
  /** An aggregate relationship */
  projectTaskTagsAggregate: ProjectTaskTagsAggregate;
  /** fetch data from the table: "project_task_tags" using primary key columns */
  projectTaskTagsByPk?: Maybe<ProjectTaskTags>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projectsAggregate: ProjectsAggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projectsByPk?: Maybe<Projects>;
  reportDetails: Array<ReportDetailsResponse>;
  reportSummaries: Array<ReportSummariesResponse>;
  reportSummaryPercentages: Array<ReportSummaryPercentagesResponse>;
  sample: Sample;
  /** fetch data from the table: "settlement_histories" */
  settlementHistories: Array<SettlementHistories>;
  /** fetch aggregated fields from the table: "settlement_histories" */
  settlementHistoriesAggregate: SettlementHistoriesAggregate;
  /** fetch data from the table: "settlement_histories" using primary key columns */
  settlementHistoriesByPk?: Maybe<SettlementHistories>;
  /** fetch data from the table: "stripe_customers" */
  stripeCustomers: Array<StripeCustomers>;
  /** fetch aggregated fields from the table: "stripe_customers" */
  stripeCustomersAggregate: StripeCustomersAggregate;
  /** fetch data from the table: "stripe_customers" using primary key columns */
  stripeCustomersByPk?: Maybe<StripeCustomers>;
  /** fetch data from the table: "stripe_settlement_histories" */
  stripeSettlementHistories: Array<StripeSettlementHistories>;
  /** fetch aggregated fields from the table: "stripe_settlement_histories" */
  stripeSettlementHistoriesAggregate: StripeSettlementHistoriesAggregate;
  /** fetch data from the table: "stripe_settlement_histories" using primary key columns */
  stripeSettlementHistoriesByPk?: Maybe<StripeSettlementHistories>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasksByPk?: Maybe<Tasks>;
  /** fetch data from the table: "team_allowed_ip_addresses" */
  teamAllowedIpAddresses: Array<TeamAllowedIpAddresses>;
  /** fetch aggregated fields from the table: "team_allowed_ip_addresses" */
  teamAllowedIpAddressesAggregate: TeamAllowedIpAddressesAggregate;
  /** fetch data from the table: "team_allowed_ip_addresses" using primary key columns */
  teamAllowedIpAddressesByPk?: Maybe<TeamAllowedIpAddresses>;
  /** An array relationship */
  teamBacklogLinkageProjects: Array<TeamBacklogLinkageProjects>;
  /** An aggregate relationship */
  teamBacklogLinkageProjectsAggregate: TeamBacklogLinkageProjectsAggregate;
  /** fetch data from the table: "team_backlog_linkage_projects" using primary key columns */
  teamBacklogLinkageProjectsByPk?: Maybe<TeamBacklogLinkageProjects>;
  /** fetch data from the table: "team_backlog_linkages" */
  teamBacklogLinkages: Array<TeamBacklogLinkages>;
  /** fetch aggregated fields from the table: "team_backlog_linkages" */
  teamBacklogLinkagesAggregate: TeamBacklogLinkagesAggregate;
  /** fetch data from the table: "team_backlog_linkages" using primary key columns */
  teamBacklogLinkagesByPk?: Maybe<TeamBacklogLinkages>;
  /** fetch data from the table: "team_freee_linkage_members" */
  teamFreeeLinkageMembers: Array<TeamFreeeLinkageMembers>;
  /** fetch aggregated fields from the table: "team_freee_linkage_members" */
  teamFreeeLinkageMembersAggregate: TeamFreeeLinkageMembersAggregate;
  /** fetch data from the table: "team_freee_linkage_members" using primary key columns */
  teamFreeeLinkageMembersByPk?: Maybe<TeamFreeeLinkageMembers>;
  /** An array relationship */
  teamGithubLinkageProjects: Array<TeamGithubLinkageProjects>;
  /** An aggregate relationship */
  teamGithubLinkageProjectsAggregate: TeamGithubLinkageProjectsAggregate;
  /** fetch data from the table: "team_github_linkage_projects" using primary key columns */
  teamGithubLinkageProjectsByPk?: Maybe<TeamGithubLinkageProjects>;
  /** fetch data from the table: "team_github_linkages" */
  teamGithubLinkages: Array<TeamGithubLinkages>;
  /** fetch aggregated fields from the table: "team_github_linkages" */
  teamGithubLinkagesAggregate: TeamGithubLinkagesAggregate;
  /** fetch data from the table: "team_github_linkages" using primary key columns */
  teamGithubLinkagesByPk?: Maybe<TeamGithubLinkages>;
  /** fetch data from the table: "team_google_calendar_linkages" */
  teamGoogleCalendarLinkages: Array<TeamGoogleCalendarLinkages>;
  /** fetch aggregated fields from the table: "team_google_calendar_linkages" */
  teamGoogleCalendarLinkagesAggregate: TeamGoogleCalendarLinkagesAggregate;
  /** fetch data from the table: "team_google_calendar_linkages" using primary key columns */
  teamGoogleCalendarLinkagesByPk?: Maybe<TeamGoogleCalendarLinkages>;
  /** fetch data from the table: "team_invitable_count_view" */
  teamInvitableCountView: Array<TeamInvitableCountView>;
  /** fetch aggregated fields from the table: "team_invitable_count_view" */
  teamInvitableCountViewAggregate: TeamInvitableCountViewAggregate;
  /** An array relationship */
  teamMemberInvitations: Array<TeamMemberInvitations>;
  /** An aggregate relationship */
  teamMemberInvitationsAggregate: TeamMemberInvitationsAggregate;
  /** fetch data from the table: "team_member_invitations" using primary key columns */
  teamMemberInvitationsByPk?: Maybe<TeamMemberInvitations>;
  /** fetch data from the table: "team_member_snapshots" */
  teamMemberSnapshots: Array<TeamMemberSnapshots>;
  /** fetch aggregated fields from the table: "team_member_snapshots" */
  teamMemberSnapshotsAggregate: TeamMemberSnapshotsAggregate;
  /** fetch data from the table: "team_member_snapshots" using primary key columns */
  teamMemberSnapshotsByPk?: Maybe<TeamMemberSnapshots>;
  /** An array relationship */
  teamMembers: Array<TeamMembers>;
  /** An aggregate relationship */
  teamMembersAggregate: TeamMembersAggregate;
  /** fetch data from the table: "team_members" using primary key columns */
  teamMembersByPk?: Maybe<TeamMembers>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teamsAggregate: TeamsAggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teamsByPk?: Maybe<Teams>;
  temporaryFreeeEmployeesForLink: Array<TemporaryFreeeEmployeesForLinkResponse>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** An array relationship */
  workBreaks: Array<WorkBreaks>;
  /** An aggregate relationship */
  workBreaksAggregate: WorkBreaksAggregate;
  /** fetch data from the table: "work_breaks" using primary key columns */
  workBreaksByPk?: Maybe<WorkBreaks>;
  /** An array relationship */
  workTasks: Array<WorkTasks>;
  /** An aggregate relationship */
  workTasksAggregate: WorkTasksAggregate;
  /** fetch data from the table: "work_tasks" using primary key columns */
  workTasksByPk?: Maybe<WorkTasks>;
  /** An array relationship */
  workTrackingBreaks: Array<WorkTrackingBreaks>;
  /** An aggregate relationship */
  workTrackingBreaksAggregate: WorkTrackingBreaksAggregate;
  /** fetch data from the table: "work_tracking_breaks" using primary key columns */
  workTrackingBreaksByPk?: Maybe<WorkTrackingBreaks>;
  /** An array relationship */
  workTrackingTasks: Array<WorkTrackingTasks>;
  /** An aggregate relationship */
  workTrackingTasksAggregate: WorkTrackingTasksAggregate;
  /** fetch data from the table: "work_tracking_tasks" using primary key columns */
  workTrackingTasksByPk?: Maybe<WorkTrackingTasks>;
  /** An array relationship */
  workTrackings: Array<WorkTrackings>;
  /** An aggregate relationship */
  workTrackingsAggregate: WorkTrackingsAggregate;
  /** fetch data from the table: "work_trackings" using primary key columns */
  workTrackingsByPk?: Maybe<WorkTrackings>;
  /** An array relationship */
  works: Array<Works>;
  /** An aggregate relationship */
  worksAggregate: WorksAggregate;
  /** fetch data from the table: "works" using primary key columns */
  worksByPk?: Maybe<Works>;
  worksCalendar: Array<WorksCalendarResponse>;
};

export type Query_RootActivitiesArgs = {
  distinctOn?: InputMaybe<Array<ActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

export type Query_RootActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

export type Query_RootActivitiesByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootBacklogIssuesArgs = {
  input: BacklogIssuesInput;
};

export type Query_RootBacklogProjectsArgs = {
  input: BacklogProjectsInput;
};

export type Query_RootContractsArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

export type Query_RootContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

export type Query_RootContractsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootEnqueteAnswerArgs = {
  distinctOn?: InputMaybe<Array<EnqueteAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EnqueteAnswerOrderBy>>;
  where?: InputMaybe<EnqueteAnswerBoolExp>;
};

export type Query_RootEnqueteAnswerAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnqueteAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EnqueteAnswerOrderBy>>;
  where?: InputMaybe<EnqueteAnswerBoolExp>;
};

export type Query_RootEnqueteAnswerByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootFreeeEmployeesArgs = {
  input: FreeeEmployeesInput;
};

export type Query_RootGithubIssuesArgs = {
  input: GithubIssuesInput;
};

export type Query_RootGithubRepositoriesArgs = {
  input: GithubRepositoriesInput;
};

export type Query_RootGoogleCalendarEventArgs = {
  input: GoogleCalendarEventInput;
};

export type Query_RootNextInvoicePreviewArgs = {
  input: NextInvoicePreviewInput;
};

export type Query_RootPlansArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Query_RootPlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Query_RootPlansByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootProjectAlertsArgs = {
  distinctOn?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectAlertsOrderBy>>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

export type Query_RootProjectAlertsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectAlertsOrderBy>>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

export type Query_RootProjectAlertsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootProjectCreatableCountViewArgs = {
  distinctOn?: InputMaybe<Array<ProjectCreatableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectCreatableCountViewOrderBy>>;
  where?: InputMaybe<ProjectCreatableCountViewBoolExp>;
};

export type Query_RootProjectCreatableCountViewAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectCreatableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectCreatableCountViewOrderBy>>;
  where?: InputMaybe<ProjectCreatableCountViewBoolExp>;
};

export type Query_RootProjectMembersArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

export type Query_RootProjectMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

export type Query_RootProjectMembersByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootProjectTaskTagsArgs = {
  distinctOn?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectTaskTagsOrderBy>>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

export type Query_RootProjectTaskTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectTaskTagsOrderBy>>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

export type Query_RootProjectTaskTagsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootProjectsArgs = {
  distinctOn?: InputMaybe<Array<ProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  where?: InputMaybe<ProjectsBoolExp>;
};

export type Query_RootProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  where?: InputMaybe<ProjectsBoolExp>;
};

export type Query_RootProjectsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootReportDetailsArgs = {
  input: ReportDetailsInput;
};

export type Query_RootReportSummariesArgs = {
  input: ReportSummariesInput;
};

export type Query_RootReportSummaryPercentagesArgs = {
  input: ReportSummaryPercentagesInput;
};

export type Query_RootSettlementHistoriesArgs = {
  distinctOn?: InputMaybe<Array<SettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SettlementHistoriesOrderBy>>;
  where?: InputMaybe<SettlementHistoriesBoolExp>;
};

export type Query_RootSettlementHistoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SettlementHistoriesOrderBy>>;
  where?: InputMaybe<SettlementHistoriesBoolExp>;
};

export type Query_RootSettlementHistoriesByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootStripeCustomersArgs = {
  distinctOn?: InputMaybe<Array<StripeCustomersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeCustomersOrderBy>>;
  where?: InputMaybe<StripeCustomersBoolExp>;
};

export type Query_RootStripeCustomersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StripeCustomersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeCustomersOrderBy>>;
  where?: InputMaybe<StripeCustomersBoolExp>;
};

export type Query_RootStripeCustomersByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootStripeSettlementHistoriesArgs = {
  distinctOn?: InputMaybe<Array<StripeSettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeSettlementHistoriesOrderBy>>;
  where?: InputMaybe<StripeSettlementHistoriesBoolExp>;
};

export type Query_RootStripeSettlementHistoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StripeSettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeSettlementHistoriesOrderBy>>;
  where?: InputMaybe<StripeSettlementHistoriesBoolExp>;
};

export type Query_RootStripeSettlementHistoriesByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Query_RootTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Query_RootTasksByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamAllowedIpAddressesArgs = {
  distinctOn?: InputMaybe<Array<TeamAllowedIpAddressesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamAllowedIpAddressesOrderBy>>;
  where?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
};

export type Query_RootTeamAllowedIpAddressesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamAllowedIpAddressesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamAllowedIpAddressesOrderBy>>;
  where?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
};

export type Query_RootTeamAllowedIpAddressesByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamBacklogLinkageProjectsArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

export type Query_RootTeamBacklogLinkageProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

export type Query_RootTeamBacklogLinkageProjectsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamBacklogLinkagesArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkagesOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkagesBoolExp>;
};

export type Query_RootTeamBacklogLinkagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkagesOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkagesBoolExp>;
};

export type Query_RootTeamBacklogLinkagesByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamFreeeLinkageMembersArgs = {
  distinctOn?: InputMaybe<Array<TeamFreeeLinkageMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamFreeeLinkageMembersOrderBy>>;
  where?: InputMaybe<TeamFreeeLinkageMembersBoolExp>;
};

export type Query_RootTeamFreeeLinkageMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamFreeeLinkageMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamFreeeLinkageMembersOrderBy>>;
  where?: InputMaybe<TeamFreeeLinkageMembersBoolExp>;
};

export type Query_RootTeamFreeeLinkageMembersByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamGithubLinkageProjectsArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

export type Query_RootTeamGithubLinkageProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

export type Query_RootTeamGithubLinkageProjectsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamGithubLinkagesArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkagesOrderBy>>;
  where?: InputMaybe<TeamGithubLinkagesBoolExp>;
};

export type Query_RootTeamGithubLinkagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkagesOrderBy>>;
  where?: InputMaybe<TeamGithubLinkagesBoolExp>;
};

export type Query_RootTeamGithubLinkagesByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamGoogleCalendarLinkagesArgs = {
  distinctOn?: InputMaybe<Array<TeamGoogleCalendarLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGoogleCalendarLinkagesOrderBy>>;
  where?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
};

export type Query_RootTeamGoogleCalendarLinkagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamGoogleCalendarLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGoogleCalendarLinkagesOrderBy>>;
  where?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
};

export type Query_RootTeamGoogleCalendarLinkagesByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamInvitableCountViewArgs = {
  distinctOn?: InputMaybe<Array<TeamInvitableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamInvitableCountViewOrderBy>>;
  where?: InputMaybe<TeamInvitableCountViewBoolExp>;
};

export type Query_RootTeamInvitableCountViewAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamInvitableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamInvitableCountViewOrderBy>>;
  where?: InputMaybe<TeamInvitableCountViewBoolExp>;
};

export type Query_RootTeamMemberInvitationsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberInvitationsOrderBy>>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

export type Query_RootTeamMemberInvitationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberInvitationsOrderBy>>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

export type Query_RootTeamMemberInvitationsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamMemberSnapshotsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberSnapshotsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberSnapshotsOrderBy>>;
  where?: InputMaybe<TeamMemberSnapshotsBoolExp>;
};

export type Query_RootTeamMemberSnapshotsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberSnapshotsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberSnapshotsOrderBy>>;
  where?: InputMaybe<TeamMemberSnapshotsBoolExp>;
};

export type Query_RootTeamMemberSnapshotsByPkArgs = {
  teamId: Scalars["String"];
  teamMemberId: Scalars["String"];
};

export type Query_RootTeamMembersArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

export type Query_RootTeamMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

export type Query_RootTeamMembersByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTeamsArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Query_RootTeamsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Query_RootTeamsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootTemporaryFreeeEmployeesForLinkArgs = {
  input: TemporaryFreeeEmployeesForLinkInput;
};

export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Query_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Query_RootUsersByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootWorkBreaksArgs = {
  distinctOn?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkBreaksOrderBy>>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

export type Query_RootWorkBreaksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkBreaksOrderBy>>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

export type Query_RootWorkBreaksByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootWorkTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

export type Query_RootWorkTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

export type Query_RootWorkTasksByPkArgs = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workId: Scalars["String"];
};

export type Query_RootWorkTrackingBreaksArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingBreaksOrderBy>>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

export type Query_RootWorkTrackingBreaksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingBreaksOrderBy>>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

export type Query_RootWorkTrackingBreaksByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootWorkTrackingTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

export type Query_RootWorkTrackingTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

export type Query_RootWorkTrackingTasksByPkArgs = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workTrackingId: Scalars["String"];
};

export type Query_RootWorkTrackingsArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingsOrderBy>>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

export type Query_RootWorkTrackingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingsOrderBy>>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

export type Query_RootWorkTrackingsByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootWorksArgs = {
  distinctOn?: InputMaybe<Array<WorksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorksOrderBy>>;
  where?: InputMaybe<WorksBoolExp>;
};

export type Query_RootWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorksOrderBy>>;
  where?: InputMaybe<WorksBoolExp>;
};

export type Query_RootWorksByPkArgs = {
  id: Scalars["String"];
};

export type Query_RootWorksCalendarArgs = {
  input: WorksCalendarInput;
};

export type Subscription_Root = {
  /** An array relationship */
  activities: Array<Activities>;
  /** An aggregate relationship */
  activitiesAggregate: ActivitiesAggregate;
  /** fetch data from the table: "activities" using primary key columns */
  activitiesByPk?: Maybe<Activities>;
  /** fetch data from the table in a streaming manner: "activities" */
  activitiesStream: Array<Activities>;
  /** An array relationship */
  contracts: Array<Contracts>;
  /** An aggregate relationship */
  contractsAggregate: ContractsAggregate;
  /** fetch data from the table: "contracts" using primary key columns */
  contractsByPk?: Maybe<Contracts>;
  /** fetch data from the table in a streaming manner: "contracts" */
  contractsStream: Array<Contracts>;
  /** fetch data from the table: "enquete_answer" */
  enqueteAnswer: Array<EnqueteAnswer>;
  /** fetch aggregated fields from the table: "enquete_answer" */
  enqueteAnswerAggregate: EnqueteAnswerAggregate;
  /** fetch data from the table: "enquete_answer" using primary key columns */
  enqueteAnswerByPk?: Maybe<EnqueteAnswer>;
  /** fetch data from the table in a streaming manner: "enquete_answer" */
  enqueteAnswerStream: Array<EnqueteAnswer>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plansAggregate: PlansAggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plansByPk?: Maybe<Plans>;
  /** fetch data from the table in a streaming manner: "plans" */
  plansStream: Array<Plans>;
  /** An array relationship */
  projectAlerts: Array<ProjectAlerts>;
  /** An aggregate relationship */
  projectAlertsAggregate: ProjectAlertsAggregate;
  /** fetch data from the table: "project_alerts" using primary key columns */
  projectAlertsByPk?: Maybe<ProjectAlerts>;
  /** fetch data from the table in a streaming manner: "project_alerts" */
  projectAlertsStream: Array<ProjectAlerts>;
  /** fetch data from the table: "project_creatable_count_view" */
  projectCreatableCountView: Array<ProjectCreatableCountView>;
  /** fetch aggregated fields from the table: "project_creatable_count_view" */
  projectCreatableCountViewAggregate: ProjectCreatableCountViewAggregate;
  /** fetch data from the table in a streaming manner: "project_creatable_count_view" */
  projectCreatableCountViewStream: Array<ProjectCreatableCountView>;
  /** An array relationship */
  projectMembers: Array<ProjectMembers>;
  /** An aggregate relationship */
  projectMembersAggregate: ProjectMembersAggregate;
  /** fetch data from the table: "project_members" using primary key columns */
  projectMembersByPk?: Maybe<ProjectMembers>;
  /** fetch data from the table in a streaming manner: "project_members" */
  projectMembersStream: Array<ProjectMembers>;
  /** An array relationship */
  projectTaskTags: Array<ProjectTaskTags>;
  /** An aggregate relationship */
  projectTaskTagsAggregate: ProjectTaskTagsAggregate;
  /** fetch data from the table: "project_task_tags" using primary key columns */
  projectTaskTagsByPk?: Maybe<ProjectTaskTags>;
  /** fetch data from the table in a streaming manner: "project_task_tags" */
  projectTaskTagsStream: Array<ProjectTaskTags>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projectsAggregate: ProjectsAggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projectsByPk?: Maybe<Projects>;
  /** fetch data from the table in a streaming manner: "projects" */
  projectsStream: Array<Projects>;
  /** fetch data from the table: "settlement_histories" */
  settlementHistories: Array<SettlementHistories>;
  /** fetch aggregated fields from the table: "settlement_histories" */
  settlementHistoriesAggregate: SettlementHistoriesAggregate;
  /** fetch data from the table: "settlement_histories" using primary key columns */
  settlementHistoriesByPk?: Maybe<SettlementHistories>;
  /** fetch data from the table in a streaming manner: "settlement_histories" */
  settlementHistoriesStream: Array<SettlementHistories>;
  /** fetch data from the table: "stripe_customers" */
  stripeCustomers: Array<StripeCustomers>;
  /** fetch aggregated fields from the table: "stripe_customers" */
  stripeCustomersAggregate: StripeCustomersAggregate;
  /** fetch data from the table: "stripe_customers" using primary key columns */
  stripeCustomersByPk?: Maybe<StripeCustomers>;
  /** fetch data from the table in a streaming manner: "stripe_customers" */
  stripeCustomersStream: Array<StripeCustomers>;
  /** fetch data from the table: "stripe_settlement_histories" */
  stripeSettlementHistories: Array<StripeSettlementHistories>;
  /** fetch aggregated fields from the table: "stripe_settlement_histories" */
  stripeSettlementHistoriesAggregate: StripeSettlementHistoriesAggregate;
  /** fetch data from the table: "stripe_settlement_histories" using primary key columns */
  stripeSettlementHistoriesByPk?: Maybe<StripeSettlementHistories>;
  /** fetch data from the table in a streaming manner: "stripe_settlement_histories" */
  stripeSettlementHistoriesStream: Array<StripeSettlementHistories>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasksAggregate: TasksAggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasksByPk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasksStream: Array<Tasks>;
  /** fetch data from the table: "team_allowed_ip_addresses" */
  teamAllowedIpAddresses: Array<TeamAllowedIpAddresses>;
  /** fetch aggregated fields from the table: "team_allowed_ip_addresses" */
  teamAllowedIpAddressesAggregate: TeamAllowedIpAddressesAggregate;
  /** fetch data from the table: "team_allowed_ip_addresses" using primary key columns */
  teamAllowedIpAddressesByPk?: Maybe<TeamAllowedIpAddresses>;
  /** fetch data from the table in a streaming manner: "team_allowed_ip_addresses" */
  teamAllowedIpAddressesStream: Array<TeamAllowedIpAddresses>;
  /** An array relationship */
  teamBacklogLinkageProjects: Array<TeamBacklogLinkageProjects>;
  /** An aggregate relationship */
  teamBacklogLinkageProjectsAggregate: TeamBacklogLinkageProjectsAggregate;
  /** fetch data from the table: "team_backlog_linkage_projects" using primary key columns */
  teamBacklogLinkageProjectsByPk?: Maybe<TeamBacklogLinkageProjects>;
  /** fetch data from the table in a streaming manner: "team_backlog_linkage_projects" */
  teamBacklogLinkageProjectsStream: Array<TeamBacklogLinkageProjects>;
  /** fetch data from the table: "team_backlog_linkages" */
  teamBacklogLinkages: Array<TeamBacklogLinkages>;
  /** fetch aggregated fields from the table: "team_backlog_linkages" */
  teamBacklogLinkagesAggregate: TeamBacklogLinkagesAggregate;
  /** fetch data from the table: "team_backlog_linkages" using primary key columns */
  teamBacklogLinkagesByPk?: Maybe<TeamBacklogLinkages>;
  /** fetch data from the table in a streaming manner: "team_backlog_linkages" */
  teamBacklogLinkagesStream: Array<TeamBacklogLinkages>;
  /** fetch data from the table: "team_freee_linkage_members" */
  teamFreeeLinkageMembers: Array<TeamFreeeLinkageMembers>;
  /** fetch aggregated fields from the table: "team_freee_linkage_members" */
  teamFreeeLinkageMembersAggregate: TeamFreeeLinkageMembersAggregate;
  /** fetch data from the table: "team_freee_linkage_members" using primary key columns */
  teamFreeeLinkageMembersByPk?: Maybe<TeamFreeeLinkageMembers>;
  /** fetch data from the table in a streaming manner: "team_freee_linkage_members" */
  teamFreeeLinkageMembersStream: Array<TeamFreeeLinkageMembers>;
  /** An array relationship */
  teamGithubLinkageProjects: Array<TeamGithubLinkageProjects>;
  /** An aggregate relationship */
  teamGithubLinkageProjectsAggregate: TeamGithubLinkageProjectsAggregate;
  /** fetch data from the table: "team_github_linkage_projects" using primary key columns */
  teamGithubLinkageProjectsByPk?: Maybe<TeamGithubLinkageProjects>;
  /** fetch data from the table in a streaming manner: "team_github_linkage_projects" */
  teamGithubLinkageProjectsStream: Array<TeamGithubLinkageProjects>;
  /** fetch data from the table: "team_github_linkages" */
  teamGithubLinkages: Array<TeamGithubLinkages>;
  /** fetch aggregated fields from the table: "team_github_linkages" */
  teamGithubLinkagesAggregate: TeamGithubLinkagesAggregate;
  /** fetch data from the table: "team_github_linkages" using primary key columns */
  teamGithubLinkagesByPk?: Maybe<TeamGithubLinkages>;
  /** fetch data from the table in a streaming manner: "team_github_linkages" */
  teamGithubLinkagesStream: Array<TeamGithubLinkages>;
  /** fetch data from the table: "team_google_calendar_linkages" */
  teamGoogleCalendarLinkages: Array<TeamGoogleCalendarLinkages>;
  /** fetch aggregated fields from the table: "team_google_calendar_linkages" */
  teamGoogleCalendarLinkagesAggregate: TeamGoogleCalendarLinkagesAggregate;
  /** fetch data from the table: "team_google_calendar_linkages" using primary key columns */
  teamGoogleCalendarLinkagesByPk?: Maybe<TeamGoogleCalendarLinkages>;
  /** fetch data from the table in a streaming manner: "team_google_calendar_linkages" */
  teamGoogleCalendarLinkagesStream: Array<TeamGoogleCalendarLinkages>;
  /** fetch data from the table: "team_invitable_count_view" */
  teamInvitableCountView: Array<TeamInvitableCountView>;
  /** fetch aggregated fields from the table: "team_invitable_count_view" */
  teamInvitableCountViewAggregate: TeamInvitableCountViewAggregate;
  /** fetch data from the table in a streaming manner: "team_invitable_count_view" */
  teamInvitableCountViewStream: Array<TeamInvitableCountView>;
  /** An array relationship */
  teamMemberInvitations: Array<TeamMemberInvitations>;
  /** An aggregate relationship */
  teamMemberInvitationsAggregate: TeamMemberInvitationsAggregate;
  /** fetch data from the table: "team_member_invitations" using primary key columns */
  teamMemberInvitationsByPk?: Maybe<TeamMemberInvitations>;
  /** fetch data from the table in a streaming manner: "team_member_invitations" */
  teamMemberInvitationsStream: Array<TeamMemberInvitations>;
  /** fetch data from the table: "team_member_snapshots" */
  teamMemberSnapshots: Array<TeamMemberSnapshots>;
  /** fetch aggregated fields from the table: "team_member_snapshots" */
  teamMemberSnapshotsAggregate: TeamMemberSnapshotsAggregate;
  /** fetch data from the table: "team_member_snapshots" using primary key columns */
  teamMemberSnapshotsByPk?: Maybe<TeamMemberSnapshots>;
  /** fetch data from the table in a streaming manner: "team_member_snapshots" */
  teamMemberSnapshotsStream: Array<TeamMemberSnapshots>;
  /** An array relationship */
  teamMembers: Array<TeamMembers>;
  /** An aggregate relationship */
  teamMembersAggregate: TeamMembersAggregate;
  /** fetch data from the table: "team_members" using primary key columns */
  teamMembersByPk?: Maybe<TeamMembers>;
  /** fetch data from the table in a streaming manner: "team_members" */
  teamMembersStream: Array<TeamMembers>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teamsAggregate: TeamsAggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teamsByPk?: Maybe<Teams>;
  /** fetch data from the table in a streaming manner: "teams" */
  teamsStream: Array<Teams>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  usersStream: Array<Users>;
  /** An array relationship */
  workBreaks: Array<WorkBreaks>;
  /** An aggregate relationship */
  workBreaksAggregate: WorkBreaksAggregate;
  /** fetch data from the table: "work_breaks" using primary key columns */
  workBreaksByPk?: Maybe<WorkBreaks>;
  /** fetch data from the table in a streaming manner: "work_breaks" */
  workBreaksStream: Array<WorkBreaks>;
  /** An array relationship */
  workTasks: Array<WorkTasks>;
  /** An aggregate relationship */
  workTasksAggregate: WorkTasksAggregate;
  /** fetch data from the table: "work_tasks" using primary key columns */
  workTasksByPk?: Maybe<WorkTasks>;
  /** fetch data from the table in a streaming manner: "work_tasks" */
  workTasksStream: Array<WorkTasks>;
  /** An array relationship */
  workTrackingBreaks: Array<WorkTrackingBreaks>;
  /** An aggregate relationship */
  workTrackingBreaksAggregate: WorkTrackingBreaksAggregate;
  /** fetch data from the table: "work_tracking_breaks" using primary key columns */
  workTrackingBreaksByPk?: Maybe<WorkTrackingBreaks>;
  /** fetch data from the table in a streaming manner: "work_tracking_breaks" */
  workTrackingBreaksStream: Array<WorkTrackingBreaks>;
  /** An array relationship */
  workTrackingTasks: Array<WorkTrackingTasks>;
  /** An aggregate relationship */
  workTrackingTasksAggregate: WorkTrackingTasksAggregate;
  /** fetch data from the table: "work_tracking_tasks" using primary key columns */
  workTrackingTasksByPk?: Maybe<WorkTrackingTasks>;
  /** fetch data from the table in a streaming manner: "work_tracking_tasks" */
  workTrackingTasksStream: Array<WorkTrackingTasks>;
  /** An array relationship */
  workTrackings: Array<WorkTrackings>;
  /** An aggregate relationship */
  workTrackingsAggregate: WorkTrackingsAggregate;
  /** fetch data from the table: "work_trackings" using primary key columns */
  workTrackingsByPk?: Maybe<WorkTrackings>;
  /** fetch data from the table in a streaming manner: "work_trackings" */
  workTrackingsStream: Array<WorkTrackings>;
  /** An array relationship */
  works: Array<Works>;
  /** An aggregate relationship */
  worksAggregate: WorksAggregate;
  /** fetch data from the table: "works" using primary key columns */
  worksByPk?: Maybe<Works>;
  /** fetch data from the table in a streaming manner: "works" */
  worksStream: Array<Works>;
};

export type Subscription_RootActivitiesArgs = {
  distinctOn?: InputMaybe<Array<ActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

export type Subscription_RootActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

export type Subscription_RootActivitiesByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootActivitiesStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<ActivitiesStreamCursorInput>>;
  where?: InputMaybe<ActivitiesBoolExp>;
};

export type Subscription_RootContractsArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

export type Subscription_RootContractsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ContractsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy>>;
  where?: InputMaybe<ContractsBoolExp>;
};

export type Subscription_RootContractsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootContractsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<ContractsStreamCursorInput>>;
  where?: InputMaybe<ContractsBoolExp>;
};

export type Subscription_RootEnqueteAnswerArgs = {
  distinctOn?: InputMaybe<Array<EnqueteAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EnqueteAnswerOrderBy>>;
  where?: InputMaybe<EnqueteAnswerBoolExp>;
};

export type Subscription_RootEnqueteAnswerAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnqueteAnswerSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<EnqueteAnswerOrderBy>>;
  where?: InputMaybe<EnqueteAnswerBoolExp>;
};

export type Subscription_RootEnqueteAnswerByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootEnqueteAnswerStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<EnqueteAnswerStreamCursorInput>>;
  where?: InputMaybe<EnqueteAnswerBoolExp>;
};

export type Subscription_RootPlansArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Subscription_RootPlansAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlansSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PlansOrderBy>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Subscription_RootPlansByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootPlansStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<PlansStreamCursorInput>>;
  where?: InputMaybe<PlansBoolExp>;
};

export type Subscription_RootProjectAlertsArgs = {
  distinctOn?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectAlertsOrderBy>>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

export type Subscription_RootProjectAlertsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectAlertsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectAlertsOrderBy>>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

export type Subscription_RootProjectAlertsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootProjectAlertsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<ProjectAlertsStreamCursorInput>>;
  where?: InputMaybe<ProjectAlertsBoolExp>;
};

export type Subscription_RootProjectCreatableCountViewArgs = {
  distinctOn?: InputMaybe<Array<ProjectCreatableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectCreatableCountViewOrderBy>>;
  where?: InputMaybe<ProjectCreatableCountViewBoolExp>;
};

export type Subscription_RootProjectCreatableCountViewAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectCreatableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectCreatableCountViewOrderBy>>;
  where?: InputMaybe<ProjectCreatableCountViewBoolExp>;
};

export type Subscription_RootProjectCreatableCountViewStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<ProjectCreatableCountViewStreamCursorInput>>;
  where?: InputMaybe<ProjectCreatableCountViewBoolExp>;
};

export type Subscription_RootProjectMembersArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

export type Subscription_RootProjectMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

export type Subscription_RootProjectMembersByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootProjectMembersStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<ProjectMembersStreamCursorInput>>;
  where?: InputMaybe<ProjectMembersBoolExp>;
};

export type Subscription_RootProjectTaskTagsArgs = {
  distinctOn?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectTaskTagsOrderBy>>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

export type Subscription_RootProjectTaskTagsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectTaskTagsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectTaskTagsOrderBy>>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

export type Subscription_RootProjectTaskTagsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootProjectTaskTagsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<ProjectTaskTagsStreamCursorInput>>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
};

export type Subscription_RootProjectsArgs = {
  distinctOn?: InputMaybe<Array<ProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  where?: InputMaybe<ProjectsBoolExp>;
};

export type Subscription_RootProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  where?: InputMaybe<ProjectsBoolExp>;
};

export type Subscription_RootProjectsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootProjectsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<ProjectsStreamCursorInput>>;
  where?: InputMaybe<ProjectsBoolExp>;
};

export type Subscription_RootSettlementHistoriesArgs = {
  distinctOn?: InputMaybe<Array<SettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SettlementHistoriesOrderBy>>;
  where?: InputMaybe<SettlementHistoriesBoolExp>;
};

export type Subscription_RootSettlementHistoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SettlementHistoriesOrderBy>>;
  where?: InputMaybe<SettlementHistoriesBoolExp>;
};

export type Subscription_RootSettlementHistoriesByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootSettlementHistoriesStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<SettlementHistoriesStreamCursorInput>>;
  where?: InputMaybe<SettlementHistoriesBoolExp>;
};

export type Subscription_RootStripeCustomersArgs = {
  distinctOn?: InputMaybe<Array<StripeCustomersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeCustomersOrderBy>>;
  where?: InputMaybe<StripeCustomersBoolExp>;
};

export type Subscription_RootStripeCustomersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StripeCustomersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeCustomersOrderBy>>;
  where?: InputMaybe<StripeCustomersBoolExp>;
};

export type Subscription_RootStripeCustomersByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootStripeCustomersStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<StripeCustomersStreamCursorInput>>;
  where?: InputMaybe<StripeCustomersBoolExp>;
};

export type Subscription_RootStripeSettlementHistoriesArgs = {
  distinctOn?: InputMaybe<Array<StripeSettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeSettlementHistoriesOrderBy>>;
  where?: InputMaybe<StripeSettlementHistoriesBoolExp>;
};

export type Subscription_RootStripeSettlementHistoriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StripeSettlementHistoriesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StripeSettlementHistoriesOrderBy>>;
  where?: InputMaybe<StripeSettlementHistoriesBoolExp>;
};

export type Subscription_RootStripeSettlementHistoriesByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootStripeSettlementHistoriesStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<StripeSettlementHistoriesStreamCursorInput>>;
  where?: InputMaybe<StripeSettlementHistoriesBoolExp>;
};

export type Subscription_RootTasksArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Subscription_RootTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Subscription_RootTasksByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTasksStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TasksStreamCursorInput>>;
  where?: InputMaybe<TasksBoolExp>;
};

export type Subscription_RootTeamAllowedIpAddressesArgs = {
  distinctOn?: InputMaybe<Array<TeamAllowedIpAddressesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamAllowedIpAddressesOrderBy>>;
  where?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
};

export type Subscription_RootTeamAllowedIpAddressesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamAllowedIpAddressesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamAllowedIpAddressesOrderBy>>;
  where?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
};

export type Subscription_RootTeamAllowedIpAddressesByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamAllowedIpAddressesStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamAllowedIpAddressesStreamCursorInput>>;
  where?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
};

export type Subscription_RootTeamBacklogLinkageProjectsArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

export type Subscription_RootTeamBacklogLinkageProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

export type Subscription_RootTeamBacklogLinkageProjectsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamBacklogLinkageProjectsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamBacklogLinkageProjectsStreamCursorInput>>;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
};

export type Subscription_RootTeamBacklogLinkagesArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkagesOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkagesBoolExp>;
};

export type Subscription_RootTeamBacklogLinkagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamBacklogLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamBacklogLinkagesOrderBy>>;
  where?: InputMaybe<TeamBacklogLinkagesBoolExp>;
};

export type Subscription_RootTeamBacklogLinkagesByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamBacklogLinkagesStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamBacklogLinkagesStreamCursorInput>>;
  where?: InputMaybe<TeamBacklogLinkagesBoolExp>;
};

export type Subscription_RootTeamFreeeLinkageMembersArgs = {
  distinctOn?: InputMaybe<Array<TeamFreeeLinkageMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamFreeeLinkageMembersOrderBy>>;
  where?: InputMaybe<TeamFreeeLinkageMembersBoolExp>;
};

export type Subscription_RootTeamFreeeLinkageMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamFreeeLinkageMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamFreeeLinkageMembersOrderBy>>;
  where?: InputMaybe<TeamFreeeLinkageMembersBoolExp>;
};

export type Subscription_RootTeamFreeeLinkageMembersByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamFreeeLinkageMembersStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamFreeeLinkageMembersStreamCursorInput>>;
  where?: InputMaybe<TeamFreeeLinkageMembersBoolExp>;
};

export type Subscription_RootTeamGithubLinkageProjectsArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

export type Subscription_RootTeamGithubLinkageProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkageProjectsOrderBy>>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

export type Subscription_RootTeamGithubLinkageProjectsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamGithubLinkageProjectsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamGithubLinkageProjectsStreamCursorInput>>;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
};

export type Subscription_RootTeamGithubLinkagesArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkagesOrderBy>>;
  where?: InputMaybe<TeamGithubLinkagesBoolExp>;
};

export type Subscription_RootTeamGithubLinkagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamGithubLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGithubLinkagesOrderBy>>;
  where?: InputMaybe<TeamGithubLinkagesBoolExp>;
};

export type Subscription_RootTeamGithubLinkagesByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamGithubLinkagesStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamGithubLinkagesStreamCursorInput>>;
  where?: InputMaybe<TeamGithubLinkagesBoolExp>;
};

export type Subscription_RootTeamGoogleCalendarLinkagesArgs = {
  distinctOn?: InputMaybe<Array<TeamGoogleCalendarLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGoogleCalendarLinkagesOrderBy>>;
  where?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
};

export type Subscription_RootTeamGoogleCalendarLinkagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamGoogleCalendarLinkagesSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamGoogleCalendarLinkagesOrderBy>>;
  where?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
};

export type Subscription_RootTeamGoogleCalendarLinkagesByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamGoogleCalendarLinkagesStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamGoogleCalendarLinkagesStreamCursorInput>>;
  where?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
};

export type Subscription_RootTeamInvitableCountViewArgs = {
  distinctOn?: InputMaybe<Array<TeamInvitableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamInvitableCountViewOrderBy>>;
  where?: InputMaybe<TeamInvitableCountViewBoolExp>;
};

export type Subscription_RootTeamInvitableCountViewAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamInvitableCountViewSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamInvitableCountViewOrderBy>>;
  where?: InputMaybe<TeamInvitableCountViewBoolExp>;
};

export type Subscription_RootTeamInvitableCountViewStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamInvitableCountViewStreamCursorInput>>;
  where?: InputMaybe<TeamInvitableCountViewBoolExp>;
};

export type Subscription_RootTeamMemberInvitationsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberInvitationsOrderBy>>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

export type Subscription_RootTeamMemberInvitationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberInvitationsOrderBy>>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

export type Subscription_RootTeamMemberInvitationsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamMemberInvitationsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamMemberInvitationsStreamCursorInput>>;
  where?: InputMaybe<TeamMemberInvitationsBoolExp>;
};

export type Subscription_RootTeamMemberSnapshotsArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberSnapshotsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberSnapshotsOrderBy>>;
  where?: InputMaybe<TeamMemberSnapshotsBoolExp>;
};

export type Subscription_RootTeamMemberSnapshotsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMemberSnapshotsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMemberSnapshotsOrderBy>>;
  where?: InputMaybe<TeamMemberSnapshotsBoolExp>;
};

export type Subscription_RootTeamMemberSnapshotsByPkArgs = {
  teamId: Scalars["String"];
  teamMemberId: Scalars["String"];
};

export type Subscription_RootTeamMemberSnapshotsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamMemberSnapshotsStreamCursorInput>>;
  where?: InputMaybe<TeamMemberSnapshotsBoolExp>;
};

export type Subscription_RootTeamMembersArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

export type Subscription_RootTeamMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamMembersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

export type Subscription_RootTeamMembersByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamMembersStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamMembersStreamCursorInput>>;
  where?: InputMaybe<TeamMembersBoolExp>;
};

export type Subscription_RootTeamsArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Subscription_RootTeamsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TeamsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Subscription_RootTeamsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootTeamsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<TeamsStreamCursorInput>>;
  where?: InputMaybe<TeamsBoolExp>;
};

export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootUsersByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootUsersStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootWorkBreaksArgs = {
  distinctOn?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkBreaksOrderBy>>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

export type Subscription_RootWorkBreaksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkBreaksOrderBy>>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

export type Subscription_RootWorkBreaksByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootWorkBreaksStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<WorkBreaksStreamCursorInput>>;
  where?: InputMaybe<WorkBreaksBoolExp>;
};

export type Subscription_RootWorkTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

export type Subscription_RootWorkTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTasksOrderBy>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

export type Subscription_RootWorkTasksByPkArgs = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workId: Scalars["String"];
};

export type Subscription_RootWorkTasksStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<WorkTasksStreamCursorInput>>;
  where?: InputMaybe<WorkTasksBoolExp>;
};

export type Subscription_RootWorkTrackingBreaksArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingBreaksOrderBy>>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

export type Subscription_RootWorkTrackingBreaksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingBreaksOrderBy>>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

export type Subscription_RootWorkTrackingBreaksByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootWorkTrackingBreaksStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<WorkTrackingBreaksStreamCursorInput>>;
  where?: InputMaybe<WorkTrackingBreaksBoolExp>;
};

export type Subscription_RootWorkTrackingTasksArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

export type Subscription_RootWorkTrackingTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingTasksOrderBy>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

export type Subscription_RootWorkTrackingTasksByPkArgs = {
  taskId: Scalars["String"];
  teamId: Scalars["String"];
  workTrackingId: Scalars["String"];
};

export type Subscription_RootWorkTrackingTasksStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<WorkTrackingTasksStreamCursorInput>>;
  where?: InputMaybe<WorkTrackingTasksBoolExp>;
};

export type Subscription_RootWorkTrackingsArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingsOrderBy>>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

export type Subscription_RootWorkTrackingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorkTrackingsOrderBy>>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

export type Subscription_RootWorkTrackingsByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootWorkTrackingsStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<WorkTrackingsStreamCursorInput>>;
  where?: InputMaybe<WorkTrackingsBoolExp>;
};

export type Subscription_RootWorksArgs = {
  distinctOn?: InputMaybe<Array<WorksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorksOrderBy>>;
  where?: InputMaybe<WorksBoolExp>;
};

export type Subscription_RootWorksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WorksSelectColumn>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<WorksOrderBy>>;
  where?: InputMaybe<WorksBoolExp>;
};

export type Subscription_RootWorksByPkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootWorksStreamArgs = {
  batchSize: Scalars["Int"];
  cursor: Array<InputMaybe<WorksStreamCursorInput>>;
  where?: InputMaybe<WorksBoolExp>;
};

export type TasksAggregateBoolExpBool_And = {
  arguments: TasksSelectColumnTasksAggregateBoolExpBool_AndArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TasksBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TasksAggregateBoolExpBool_Or = {
  arguments: TasksSelectColumnTasksAggregateBoolExpBool_OrArgumentsColumns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TasksBoolExp>;
  predicate: BooleanComparisonExp;
};

export type TasksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TasksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TasksBoolExp>;
  predicate: IntComparisonExp;
};

export type TeamBacklogLinkageProjectsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TeamBacklogLinkageProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
  predicate: IntComparisonExp;
};

export type TeamGithubLinkageProjectsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TeamGithubLinkageProjectsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
  predicate: IntComparisonExp;
};

export type TeamMemberInvitationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TeamMemberInvitationsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TeamMemberInvitationsBoolExp>;
  predicate: IntComparisonExp;
};

export type TeamMembersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TeamMembersSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<TeamMembersBoolExp>;
  predicate: IntComparisonExp;
};

export type WorkBreaksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WorkBreaksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<WorkBreaksBoolExp>;
  predicate: IntComparisonExp;
};

export type WorkTasksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WorkTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<WorkTasksBoolExp>;
  predicate: IntComparisonExp;
};

export type WorkTrackingBreaksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WorkTrackingBreaksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<WorkTrackingBreaksBoolExp>;
  predicate: IntComparisonExp;
};

export type WorkTrackingTasksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WorkTrackingTasksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<WorkTrackingTasksBoolExp>;
  predicate: IntComparisonExp;
};

export type WorkTrackingsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WorkTrackingsSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<WorkTrackingsBoolExp>;
  predicate: IntComparisonExp;
};

export type WorksAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WorksSelectColumn>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<WorksBoolExp>;
  predicate: IntComparisonExp;
};

export type ActivityAttributesFragment = {
  createdAt: any;
  id: string;
  taskId?: string | null;
  teamId: string;
  teamMemberId: string;
  type: string;
  teamMember?: {
    createdAt: any;
    deletedAt?: any | null;
    doingTaskId?: string | null;
    id: string;
    role: string;
    teamId: string;
    updatedAt: any;
    userId: string;
    workStatus: string;
    lastWorkedAt?: any | null;
    user: {
      createdAt: any;
      deletedAt?: any | null;
      email: string;
      firstName: string;
      id: string;
      imageFileName?: string | null;
      lastName: string;
      tutorialViewedAt?: any | null;
      twofaEnabled: boolean;
      twofaSecret?: string | null;
      updatedAt: any;
    };
    doingTask?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
  } | null;
  task?: {
    id: string;
    name: string;
    order: number;
    projectId: string;
    projectTaskTagId: string;
    status: string;
    teamMemberId: string;
    lock: boolean;
    serviceType?: string | null;
    project?: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    } | null;
    projectTaskTag?: {
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      teamId: string;
      projectId: string;
      updatedAt: any;
    } | null;
  } | null;
  workTracking?: {
    finishedAt?: any | null;
    startedAt: any;
    breakMinutes: number;
    work?: {
      breakMinutes: number;
      createdAt: any;
      finishedAt: any;
      id: string;
      startedAt: any;
      teamId: string;
      teamMemberId: string;
      updatedAt: any;
      workTrackingId: string;
    } | null;
  } | null;
};

export type ContractsAttributeFragment = {
  accountQuantity: number;
  contractedAt: any;
  createdAt: any;
  finishedAt?: any | null;
  id: string;
  planId: string;
  settlementFailedAt?: any | null;
  settlementService: string;
  teamId: string;
  updatedAt: any;
};

export type CredentialsAttributeFragment = {
  accessKeyId: string;
  expiration: string;
  secretAccessKey: string;
  sessionToken: string;
};

export type LoginResultAttributeFragment = {
  accessToken: string;
  refreshToken: string;
  belongingTeams: Array<{
    createdAt: string;
    deletedAt?: string | null;
    enabledBlurBackground: boolean;
    enabledSnapshot: boolean;
    imageFilePath?: string | null;
    name: string;
    teamId: string;
    updatedAt: string;
  }>;
  credentials: {
    accessKeyId: string;
    expiration: string;
    secretAccessKey: string;
    sessionToken: string;
  };
};

export type PlansAttributeFragment = {
  code: string;
  createdAt: any;
  id: string;
  name: string;
  paymentCycle?: string | null;
  price: number;
  stripePriceId?: string | null;
  updatedAt: any;
};

export type ProjectsAttributeFragment = {
  archived: boolean;
  createdAt: any;
  deletedAt?: any | null;
  id: string;
  name: string;
  teamId: string;
  updatedAt: any;
};

export type ProjectAlertAttributesFragment = {
  createdAt: any;
  deletedAt?: any | null;
  id: string;
  projectId: string;
  teamId: string;
  threshold: number;
  updatedAt: any;
  name: string;
  project?: {
    name: string;
    archived: boolean;
    tasks: Array<{
      projectId: string;
      workTasks: Array<{
        totalMinutes: number;
        work?: { startedAt: any } | null;
      }>;
    }>;
  } | null;
};

export type ProjectMembersAttributeFragment = {
  createdAt: any;
  deletedAt?: any | null;
  id: string;
  projectId: string;
  role: string;
  teamId: string;
  teamMemberId: string;
  updatedAt: any;
  teamMember?: {
    createdAt: any;
    deletedAt?: any | null;
    doingTaskId?: string | null;
    id: string;
    role: string;
    teamId: string;
    updatedAt: any;
    userId: string;
    workStatus: string;
    lastWorkedAt?: any | null;
    user: {
      createdAt: any;
      deletedAt?: any | null;
      email: string;
      firstName: string;
      id: string;
      imageFileName?: string | null;
      lastName: string;
      tutorialViewedAt?: any | null;
      twofaEnabled: boolean;
      twofaSecret?: string | null;
      updatedAt: any;
    };
    doingTask?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
  } | null;
};

export type ProjectTaskTagAttributesFragment = {
  createdAt: any;
  deletedAt?: any | null;
  id: string;
  name: string;
  order: string;
  teamId: string;
  projectId: string;
  updatedAt: any;
};

export type SettlementHistoriesAttributeFragment = {
  amount: number;
  contractId: string;
  createdAt: any;
  id: string;
  settledAt?: any | null;
  settlementService: string;
  status: string;
  teamId: string;
  updatedAt: any;
  stripeSettlementHistory?: { invoicePdfUrl?: string | null } | null;
};

export type StripeCustomersAttributeFragment = {
  country: string;
  createdAt: any;
  email: string;
  id: string;
  line1: string;
  line2?: string | null;
  postalCode: string;
  state: string;
  teamId: string;
  updatedAt: any;
};

export type TaskAttributesFragment = {
  id: string;
  name: string;
  order: number;
  projectId: string;
  projectTaskTagId: string;
  status: string;
  teamMemberId: string;
  lock: boolean;
  serviceType?: string | null;
  project?: {
    archived: boolean;
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    teamId: string;
    updatedAt: any;
  } | null;
  projectTaskTag?: {
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    order: string;
    teamId: string;
    projectId: string;
    updatedAt: any;
  } | null;
};

export type TeamsAttributeFragment = {
  createdAt: any;
  deletedAt?: any | null;
  enabledBlurBackground: boolean;
  enabledSnapshot: boolean;
  forceSso: string;
  forceTwofa: boolean;
  id: string;
  imageFilePath?: string | null;
  name: string;
  updatedAt: any;
};

export type TeamAllowedIpAddressesAttributeFragment = {
  createdAt: any;
  id: string;
  ipAddress: string;
  teamId: string;
  updatedAt: any;
};

export type TeamGithubLinkagesAttributeFragment = {
  createdAt: any;
  id: string;
  organizationName?: string | null;
  teamId: string;
  updatedAt: any;
  isRefreshTokenExpired: boolean;
};

export type TeamMembersAttributeFragment = {
  createdAt: any;
  deletedAt?: any | null;
  doingTaskId?: string | null;
  id: string;
  role: string;
  teamId: string;
  updatedAt: any;
  userId: string;
  workStatus: string;
  lastWorkedAt?: any | null;
  user: {
    createdAt: any;
    deletedAt?: any | null;
    email: string;
    firstName: string;
    id: string;
    imageFileName?: string | null;
    lastName: string;
    tutorialViewedAt?: any | null;
    twofaEnabled: boolean;
    twofaSecret?: string | null;
    updatedAt: any;
  };
  doingTask?: {
    id: string;
    name: string;
    order: number;
    projectId: string;
    projectTaskTagId: string;
    status: string;
    teamMemberId: string;
    lock: boolean;
    serviceType?: string | null;
    project?: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    } | null;
    projectTaskTag?: {
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      teamId: string;
      projectId: string;
      updatedAt: any;
    } | null;
  } | null;
};

export type TeamMemberInvitationsAttributeFragment = {
  id: string;
  email: string;
  lastSendAt: any;
};

export type TeamMembersSubscriptionAttributeFragment = {
  doingTaskId?: string | null;
  id: string;
  userId: string;
  workStatus: string;
  lastWorkedAt?: any | null;
  role: string;
  doingTask?: {
    id: string;
    name: string;
    order: number;
    projectId: string;
    projectTaskTagId: string;
    status: string;
    teamMemberId: string;
    lock: boolean;
    serviceType?: string | null;
    project?: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    } | null;
    projectTaskTag?: {
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      teamId: string;
      projectId: string;
      updatedAt: any;
    } | null;
  } | null;
  user: {
    createdAt: any;
    deletedAt?: any | null;
    email: string;
    firstName: string;
    id: string;
    imageFileName?: string | null;
    lastName: string;
    tutorialViewedAt?: any | null;
    twofaEnabled: boolean;
    twofaSecret?: string | null;
    updatedAt: any;
  };
  teamMemberSnapshot?: {
    snapshotFilePath?: string | null;
    updatedAt: any;
  } | null;
  projectMembers: Array<{ projectId: string }>;
};

export type UsersAttributeFragment = {
  createdAt: any;
  deletedAt?: any | null;
  email: string;
  firstName: string;
  id: string;
  imageFileName?: string | null;
  lastName: string;
  tutorialViewedAt?: any | null;
  twofaEnabled: boolean;
  twofaSecret?: string | null;
  updatedAt: any;
};

export type WorkAttributesFragment = {
  breakMinutes: number;
  createdAt: any;
  finishedAt: any;
  id: string;
  startedAt: any;
  teamId: string;
  teamMemberId: string;
  updatedAt: any;
  workTrackingId: string;
};

export type WorkTaskAttributesFragment = {
  createdAt: any;
  taskId: string;
  teamId: string;
  totalMinutes: number;
  updatedAt: any;
  workId: string;
  task?: {
    id: string;
    name: string;
    order: number;
    projectId: string;
    projectTaskTagId: string;
    status: string;
    teamMemberId: string;
    lock: boolean;
    serviceType?: string | null;
    project?: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    } | null;
    projectTaskTag?: {
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      teamId: string;
      projectId: string;
      updatedAt: any;
    } | null;
  } | null;
  work?: {
    breakMinutes: number;
    createdAt: any;
    finishedAt: any;
    id: string;
    startedAt: any;
    teamId: string;
    teamMemberId: string;
    updatedAt: any;
    workTrackingId: string;
  } | null;
};

export type WorkTrackingsAttributeFragment = {
  breakMinutes: number;
  createdAt: any;
  finishedAt?: any | null;
  id: string;
  startedAt: any;
  teamId: string;
  teamMemberId: string;
  updatedAt: any;
  workTrackingBreaks: Array<{
    createdAt: any;
    finishedAt?: any | null;
    id: string;
    startedAt: any;
    teamId: string;
    updatedAt: any;
    workTrackingId: string;
  }>;
  workTrackingTasks: Array<{
    createdAt: any;
    startedAt?: any | null;
    taskId: string;
    teamId: string;
    totalMinutes?: number | null;
    trackingMinutes?: number | null;
    updatedAt: any;
    workTrackingId: string;
    task?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
  }>;
};

export type WorkTrackingBreaksAttributeFragment = {
  createdAt: any;
  finishedAt?: any | null;
  id: string;
  startedAt: any;
  teamId: string;
  updatedAt: any;
  workTrackingId: string;
};

export type WorkTrackingTasksAttributeFragment = {
  createdAt: any;
  startedAt?: any | null;
  taskId: string;
  teamId: string;
  totalMinutes?: number | null;
  trackingMinutes?: number | null;
  updatedAt: any;
  workTrackingId: string;
  task?: {
    id: string;
    name: string;
    order: number;
    projectId: string;
    projectTaskTagId: string;
    status: string;
    teamMemberId: string;
    lock: boolean;
    serviceType?: string | null;
    project?: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    } | null;
    projectTaskTag?: {
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      teamId: string;
      projectId: string;
      updatedAt: any;
    } | null;
  } | null;
};

export type AcceptTeamMemberInvitationMutationVariables = Exact<{
  input: AcceptTeamMemberInvitationInput;
}>;

export type AcceptTeamMemberInvitationMutation = {
  acceptTeamMemberInvitation: { teamId: string; teamMemberId: string };
};

export type ApproveGitHubInstallationMutationVariables = Exact<{
  input: ApproveGitHubInstallationInput;
}>;

export type ApproveGitHubInstallationMutation = {
  approveGitHubInstallation: {
    isSettingCompleted: boolean;
    organizationName?: string | null;
  };
};

export type ArchiveProjectMutationVariables = Exact<{
  input: ArchiveProjectInput;
}>;

export type ArchiveProjectMutation = { archiveProject: { result: boolean } };

export type AuthenticateTwoFaMutationVariables = Exact<{
  input: AuthenticateTwoFaInput;
}>;

export type AuthenticateTwoFaMutation = {
  authenticateTwoFA: {
    accessToken: string;
    refreshToken: string;
    belongingTeams: Array<{
      createdAt: string;
      deletedAt?: string | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      imageFilePath?: string | null;
      name: string;
      teamId: string;
      updatedAt: string;
    }>;
    credentials: {
      accessKeyId: string;
      expiration: string;
      secretAccessKey: string;
      sessionToken: string;
    };
  };
};

export type CreateBacklogAuthzUrlMutationVariables = Exact<{
  input: CreateBacklogAuthzUrlInput;
}>;

export type CreateBacklogAuthzUrlMutation = {
  createBacklogAuthzUrl: { backlogAuthzUrl: string };
};

export type CreateGitHubAuthzUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateGitHubAuthzUrlMutation = {
  createGitHubAuthzUrl: { state: string; url: string };
};

export type CreateGitHubReAuthzUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateGitHubReAuthzUrlMutation = {
  createGitHubReAuthzUrl: { state: string; url: string };
};

export type CreateGoogleCalendarAuthzUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateGoogleCalendarAuthzUrlMutation = {
  createGoogleCalendarAuthzUrl: { url: string };
};

export type CreateGoogleLoginAuthzUrlMutationVariables = Exact<{
  input: CreateGoogleLoginAuthzUrlInput;
}>;

export type CreateGoogleLoginAuthzUrlMutation = {
  createGoogleLoginAuthzUrl: { url: string };
};

export type CreateMicrosoftLoginAuthzUrlMutationVariables = Exact<{
  input: CreateMicrosoftLoginAuthzUrlInput;
}>;

export type CreateMicrosoftLoginAuthzUrlMutation = {
  createMicrosoftLoginAuthzUrl: { url: string };
};

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;

export type CreateProjectMutation = {
  createProject: {
    archived: boolean;
    createdAt: string;
    deletedAt?: string | null;
    name: string;
    projectId: string;
    teamId: string;
    updatedAt: string;
  };
};

export type CreateSetupSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateSetupSessionMutation = {
  createSetupSession: { sessionUrl: string };
};

export type CreateSubscriptionSessionMutationVariables = Exact<{
  input: CreateSubscriptionSessionInput;
}>;

export type CreateSubscriptionSessionMutation = {
  createSubscriptionSession: { sessionUrl: string };
};

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;

export type CreateTeamMutation = { createTeam: { teamId: string } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  createUser: {
    accessToken: string;
    refreshToken: string;
    belongingTeams: Array<{
      createdAt: string;
      deletedAt?: string | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      imageFilePath?: string | null;
      name: string;
      teamId: string;
      updatedAt: string;
    }>;
    credentials: {
      accessKeyId: string;
      secretAccessKey: string;
      sessionToken: string;
      expiration: string;
    };
  };
};

export type DeleteTeamMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteTeamMutation = { deleteTeam: { result: boolean } };

export type DeleteTeamBacklogLinkageProjectsMutationVariables = Exact<{
  where: TeamBacklogLinkageProjectsBoolExp;
}>;

export type DeleteTeamBacklogLinkageProjectsMutation = {
  deleteTeamBacklogLinkageProjects?: {
    affectedRows: number;
    returning: Array<{
      backlogProjectId: number;
      createdAt: any;
      id: string;
      projectId: string;
      teamId: string;
      updatedAt: any;
    }>;
  } | null;
};

export type DeleteTeamBacklogLinkagesMutationVariables = Exact<{
  where: TeamBacklogLinkagesBoolExp;
}>;

export type DeleteTeamBacklogLinkagesMutation = {
  deleteTeamBacklogLinkages?: { affectedRows: number } | null;
};

export type DeleteTeamGithubLinkageProjectsMutationVariables = Exact<{
  where: TeamGithubLinkageProjectsBoolExp;
}>;

export type DeleteTeamGithubLinkageProjectsMutation = {
  deleteTeamGithubLinkageProjects?: {
    affectedRows: number;
    returning: Array<{
      createdAt: any;
      githubRepositoryId: number;
      id: string;
      projectId: string;
      teamId: string;
      updatedAt: any;
    }>;
  } | null;
};

export type DeleteTeamGoogleCalendarLinkagesMutationVariables = Exact<{
  where: TeamGoogleCalendarLinkagesBoolExp;
}>;

export type DeleteTeamGoogleCalendarLinkagesMutation = {
  deleteTeamGoogleCalendarLinkages?: { affectedRows: number } | null;
};

export type DeleteTeamMemberInvitationsByPkMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteTeamMemberInvitationsByPkMutation = {
  deleteTeamMemberInvitationsByPk?: { id: string } | null;
};

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteUserMutation = { deleteUser: { result: boolean } };

export type ExportTeamReportMutationVariables = Exact<{
  input: ExportTeamReportInput;
}>;

export type ExportTeamReportMutation = { exportTeamReport: { path: string } };

export type ExportWorksMutationVariables = Exact<{
  input: ExportWorksInput;
}>;

export type ExportWorksMutation = { exportWorks: { path: string } };

export type FinishWorkMutationVariables = Exact<{
  input: FinishWorkInput;
}>;

export type FinishWorkMutation = { finishWork: { result: boolean } };

export type InsertEnqueteAnswerMutationVariables = Exact<{
  objects: Array<EnqueteAnswerInsertInput> | EnqueteAnswerInsertInput;
}>;

export type InsertEnqueteAnswerMutation = {
  insertEnqueteAnswer?: { affectedRows: number } | null;
};

export type InsertProjectAlertsOneMutationVariables = Exact<{
  object: ProjectAlertsInsertInput;
}>;

export type InsertProjectAlertsOneMutation = {
  insertProjectAlertsOne?: {
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    projectId: string;
    teamId: string;
    threshold: number;
    updatedAt: any;
  } | null;
};

export type InsertProjectMembersMutationVariables = Exact<{
  objects: Array<ProjectMembersInsertInput> | ProjectMembersInsertInput;
}>;

export type InsertProjectMembersMutation = {
  insertProjectMembers?: {
    affectedRows: number;
    returning: Array<{
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      projectId: string;
      role: string;
      teamId: string;
      teamMemberId: string;
      updatedAt: any;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      teamMember?: {
        createdAt: any;
        deletedAt?: any | null;
        doingTaskId?: string | null;
        id: string;
        role: string;
        teamId: string;
        updatedAt: any;
        userId: string;
        workStatus: string;
        user: {
          firstName: string;
          lastName: string;
          imageFileName?: string | null;
        };
      } | null;
    }>;
  } | null;
};

export type InsertProjectMembersOneMutationVariables = Exact<{
  object: ProjectMembersInsertInput;
}>;

export type InsertProjectMembersOneMutation = {
  insertProjectMembersOne?: {
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    projectId: string;
    role: string;
    teamId: string;
    teamMemberId: string;
    updatedAt: any;
    teamMember?: {
      user: {
        firstName: string;
        imageFileName?: string | null;
        lastName: string;
      };
    } | null;
  } | null;
};

export type InsertProjectTaskTagsMutationVariables = Exact<{
  objects: Array<ProjectTaskTagsInsertInput> | ProjectTaskTagsInsertInput;
}>;

export type InsertProjectTaskTagsMutation = {
  insertProjectTaskTags?: {
    returning: Array<{
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      projectId: string;
      teamId: string;
      updatedAt: any;
    }>;
  } | null;
};

export type InsertProjectTaskTagsOneMutationVariables = Exact<{
  object: ProjectTaskTagsInsertInput;
}>;

export type InsertProjectTaskTagsOneMutation = {
  insertProjectTaskTagsOne?: {
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    order: string;
    projectId: string;
    teamId: string;
    updatedAt: any;
  } | null;
};

export type InsertProjectsOneMutationVariables = Exact<{
  object: ProjectsInsertInput;
}>;

export type InsertProjectsOneMutation = {
  insertProjectsOne?: { id: string } | null;
};

export type InsertTasksMutationVariables = Exact<{
  objects: Array<TasksInsertInput> | TasksInsertInput;
  onConflict?: InputMaybe<TasksOnConflict>;
}>;

export type InsertTasksMutation = {
  insertTasks?: { affectedRows: number } | null;
};

export type InsertTeamAllowedIpAddressesMutationVariables = Exact<{
  objects:
    | Array<TeamAllowedIpAddressesInsertInput>
    | TeamAllowedIpAddressesInsertInput;
  onConflict?: InputMaybe<TeamAllowedIpAddressesOnConflict>;
}>;

export type InsertTeamAllowedIpAddressesMutation = {
  insertTeamAllowedIpAddresses?: {
    affectedRows: number;
    returning: Array<{
      createdAt: any;
      id: string;
      ipAddress: string;
      teamId: string;
      updatedAt: any;
    }>;
  } | null;
};

export type InsertTeamBacklogLinkageProjectsMutationVariables = Exact<{
  objects:
    | Array<TeamBacklogLinkageProjectsInsertInput>
    | TeamBacklogLinkageProjectsInsertInput;
  onConflict?: InputMaybe<TeamBacklogLinkageProjectsOnConflict>;
}>;

export type InsertTeamBacklogLinkageProjectsMutation = {
  insertTeamBacklogLinkageProjects?: {
    affectedRows: number;
    returning: Array<{
      backlogProjectId: number;
      createdAt: any;
      id: string;
      projectId: string;
      teamId: string;
      updatedAt: any;
    }>;
  } | null;
};

export type InsertTeamGithubLinkageProjectsMutationVariables = Exact<{
  objects:
    | Array<TeamGithubLinkageProjectsInsertInput>
    | TeamGithubLinkageProjectsInsertInput;
  onConflict?: InputMaybe<TeamGithubLinkageProjectsOnConflict>;
}>;

export type InsertTeamGithubLinkageProjectsMutation = {
  insertTeamGithubLinkageProjects?: {
    affectedRows: number;
    returning: Array<{
      createdAt: any;
      githubRepositoryId: number;
      id: string;
      projectId: string;
      teamId: string;
      updatedAt: any;
    }>;
  } | null;
};

export type InsertTeamMemberInvitationsMutationVariables = Exact<{
  objects:
    | Array<TeamMemberInvitationsInsertInput>
    | TeamMemberInvitationsInsertInput;
}>;

export type InsertTeamMemberInvitationsMutation = {
  insertTeamMemberInvitations?: { affectedRows: number } | null;
};

export type InsertTeamMemberInvitationsOneMutationVariables = Exact<{
  object: TeamMemberInvitationsInsertInput;
}>;

export type InsertTeamMemberInvitationsOneMutation = {
  insertTeamMemberInvitationsOne?: { id: string } | null;
};

export type LinkBacklogMutationVariables = Exact<{
  input: LinkBacklogInput;
}>;

export type LinkBacklogMutation = { linkBacklog: { result: boolean } };

export type LinkGitHubMutationVariables = Exact<{
  input: LinkGithubInput;
}>;

export type LinkGitHubMutation = {
  linkGitHub: { isSettingCompleted: boolean; organizationName?: string | null };
};

export type LinkGoogleCalendarMutationVariables = Exact<{
  input: LinkGoogleCalendarInput;
}>;

export type LinkGoogleCalendarMutation = {
  linkGoogleCalendar: { result: boolean };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  login: {
    accessToken: string;
    refreshToken: string;
    belongingTeams: Array<{
      createdAt: string;
      deletedAt?: string | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      imageFilePath?: string | null;
      name: string;
      teamId: string;
      updatedAt: string;
    }>;
    credentials: {
      accessKeyId: string;
      secretAccessKey: string;
      sessionToken: string;
      expiration: string;
    };
  };
};

export type LoginByGoogleMutationVariables = Exact<{
  input: LoginByGoogleInput;
}>;

export type LoginByGoogleMutation = {
  loginByGoogle: {
    accessToken: string;
    refreshToken: string;
    belongingTeams: Array<{
      createdAt: string;
      deletedAt?: string | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      imageFilePath?: string | null;
      name: string;
      teamId: string;
      updatedAt: string;
    }>;
    credentials: {
      accessKeyId: string;
      expiration: string;
      secretAccessKey: string;
      sessionToken: string;
    };
  };
};

export type LoginByMicrosoftMutationVariables = Exact<{
  input: LoginByMicrosoftInput;
}>;

export type LoginByMicrosoftMutation = {
  loginByMicrosoft: {
    accessToken: string;
    refreshToken: string;
    belongingTeams: Array<{
      createdAt: string;
      deletedAt?: string | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      imageFilePath?: string | null;
      name: string;
      teamId: string;
      updatedAt: string;
    }>;
    credentials: {
      accessKeyId: string;
      expiration: string;
      secretAccessKey: string;
      sessionToken: string;
    };
  };
};

export type LoginByRefreshTokenMutationVariables = Exact<{
  input: LoginByRefreshTokenInput;
}>;

export type LoginByRefreshTokenMutation = {
  loginByRefreshToken: {
    accessToken: string;
    refreshToken: string;
    belongingTeams: Array<{
      createdAt: string;
      deletedAt?: string | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      imageFilePath?: string | null;
      name: string;
      teamId: string;
      updatedAt: string;
    }>;
    credentials: {
      accessKeyId: string;
      secretAccessKey: string;
      sessionToken: string;
      expiration: string;
    };
  };
};

export type ReLinkGitHubMutationVariables = Exact<{
  input: ReLinkGithubInput;
}>;

export type ReLinkGitHubMutation = { reLinkGitHub: { result: boolean } };

export type RemoveProjectMemberMutationVariables = Exact<{
  input: RemoveProjectMemberInput;
}>;

export type RemoveProjectMemberMutation = {
  removeProjectMember: { result: boolean };
};

export type RemoveTeamAllowedIpAddressMutationVariables = Exact<{
  input: RemoveTeamAllowedIpAddressInput;
}>;

export type RemoveTeamAllowedIpAddressMutation = {
  removeTeamAllowedIpAddress: { result: boolean };
};

export type RemoveTeamMemberMutationVariables = Exact<{
  input: RemoveTeamMemberInput;
}>;

export type RemoveTeamMemberMutation = {
  removeTeamMember: { result: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { resetPassword: { result: boolean } };

export type ResumeWorkMutationVariables = Exact<{ [key: string]: never }>;

export type ResumeWorkMutation = { resumeWork: { result: boolean } };

export type SendContactMailMutationVariables = Exact<{
  input: SendContactMailInput;
}>;

export type SendContactMailMutation = { sendContactMail: { result: boolean } };

export type StartWorkMutationVariables = Exact<{ [key: string]: never }>;

export type StartWorkMutation = { startWork: { result: boolean } };

export type SuspendWorkMutationVariables = Exact<{ [key: string]: never }>;

export type SuspendWorkMutation = { suspendWork: { result: boolean } };

export type UnLinkGitHubMutationVariables = Exact<{
  input: UnLinkGithubInput;
}>;

export type UnLinkGitHubMutation = { unLinkGitHub: { result: boolean } };

export type UpdateContractMutationVariables = Exact<{
  input: UpdateContractInput;
}>;

export type UpdateContractMutation = { updateContract: { result: boolean } };

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = { updateCustomer: { result: boolean } };

export type UpdateProjectAlertsByPkMutationVariables = Exact<{
  _set?: InputMaybe<ProjectAlertsSetInput>;
  pkColumns: ProjectAlertsPkColumnsInput;
}>;

export type UpdateProjectAlertsByPkMutation = {
  updateProjectAlertsByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    projectId: string;
    teamId: string;
    threshold: number;
    updatedAt: any;
  } | null;
};

export type UpdateProjectMembersByPkMutationVariables = Exact<{
  _set?: InputMaybe<ProjectMembersSetInput>;
  pkColumns: ProjectMembersPkColumnsInput;
}>;

export type UpdateProjectMembersByPkMutation = {
  updateProjectMembersByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    id: string;
  } | null;
};

export type UpdateProjectTaskTagsByPkMutationVariables = Exact<{
  _set?: InputMaybe<ProjectTaskTagsSetInput>;
  pkColumns: ProjectTaskTagsPkColumnsInput;
}>;

export type UpdateProjectTaskTagsByPkMutation = {
  updateProjectTaskTagsByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    order: string;
    projectId: string;
    teamId: string;
    updatedAt: any;
  } | null;
};

export type UpdateProjectTaskTagsManyMutationVariables = Exact<{
  updates: Array<ProjectTaskTagsUpdates> | ProjectTaskTagsUpdates;
}>;

export type UpdateProjectTaskTagsManyMutation = {
  updateProjectTaskTagsMany?: Array<{
    affectedRows: number;
    returning: Array<{
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      projectId: string;
      teamId: string;
      updatedAt: any;
    }>;
  } | null> | null;
};

export type UpdateProjectsByPkMutationVariables = Exact<{
  _set: ProjectsSetInput;
  pkColumns: ProjectsPkColumnsInput;
}>;

export type UpdateProjectsByPkMutation = {
  updateProjectsByPk?: {
    archived: boolean;
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    teamId: string;
    updatedAt: any;
  } | null;
};

export type UpdateTasksMutationVariables = Exact<{
  _set?: InputMaybe<TasksSetInput>;
  where: TasksBoolExp;
}>;

export type UpdateTasksMutation = {
  updateTasks?: { affectedRows: number } | null;
};

export type UpdateTasksByPkMutationVariables = Exact<{
  _set?: InputMaybe<TasksSetInput>;
  pkColumns: TasksPkColumnsInput;
}>;

export type UpdateTasksByPkMutation = {
  updateTasksByPk?: { id: string } | null;
};

export type UpdateTasksManyMutationVariables = Exact<{
  updates: Array<TasksUpdates> | TasksUpdates;
}>;

export type UpdateTasksManyMutation = {
  updateTasksMany?: Array<{ affectedRows: number } | null> | null;
};

export type UpdateTeamAllowedIpAddressMutationVariables = Exact<{
  input: UpdateTeamAllowedIpAddressInput;
}>;

export type UpdateTeamAllowedIpAddressMutation = {
  updateTeamAllowedIpAddress: { result: boolean };
};

export type UpdateTeamMemberInvitationsMutationVariables = Exact<{
  _set?: InputMaybe<TeamMemberInvitationsSetInput>;
  where: TeamMemberInvitationsBoolExp;
}>;

export type UpdateTeamMemberInvitationsMutation = {
  updateTeamMemberInvitations?: { affectedRows: number } | null;
};

export type UpdateTeamMemberSnapshotsByPkMutationVariables = Exact<{
  _set?: InputMaybe<TeamMemberSnapshotsSetInput>;
  pkColumns: TeamMemberSnapshotsPkColumnsInput;
}>;

export type UpdateTeamMemberSnapshotsByPkMutation = {
  updateTeamMemberSnapshotsByPk?: { snapshotFilePath?: string | null } | null;
};

export type UpdateTeamMembersMutationVariables = Exact<{
  _set?: InputMaybe<TeamMembersSetInput>;
  where: TeamMembersBoolExp;
}>;

export type UpdateTeamMembersMutation = {
  updateTeamMembers?: { affectedRows: number } | null;
};

export type UpdateTeamsByPkMutationVariables = Exact<{
  _set?: InputMaybe<TeamsSetInput>;
  pkColumns: TeamsPkColumnsInput;
}>;

export type UpdateTeamsByPkMutation = {
  updateTeamsByPk?: { id: string; imageFilePath?: string | null } | null;
};

export type UpdateTwoFaSettingMutationVariables = Exact<{
  input: UpdateTwoFaSettingInput;
}>;

export type UpdateTwoFaSettingMutation = {
  updateTwoFASetting: { accessToken: string; refreshToken: string };
};

export type UpdateUserEmailMutationVariables = Exact<{
  input: UpdateUserEmailInput;
}>;

export type UpdateUserEmailMutation = { updateUserEmail: { result: boolean } };

export type UpdateUserPasswordMutationVariables = Exact<{
  input: UpdateUserPasswordInput;
}>;

export type UpdateUserPasswordMutation = {
  updateUserPassword: { result: boolean };
};

export type UpdateUserPasswordForceMutationVariables = Exact<{
  input: UpdateUserPasswordForceInput;
}>;

export type UpdateUserPasswordForceMutation = {
  updateUserPasswordForce: { result: boolean };
};

export type UpdateUserProfileByPkMutationVariables = Exact<{
  _set?: InputMaybe<UsersSetInput>;
  pkColumns: UsersPkColumnsInput;
}>;

export type UpdateUserProfileByPkMutation = {
  updateUsersByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    email: string;
    firstName: string;
    id: string;
    imageFileName?: string | null;
    lastName: string;
    tutorialViewedAt?: any | null;
    twofaEnabled: boolean;
    twofaSecret?: string | null;
    updatedAt: any;
  } | null;
};

export type UpdateUsersByPkMutationVariables = Exact<{
  columns?: InputMaybe<
    Array<TeamMembersSelectColumn> | TeamMembersSelectColumn
  >;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  distinctOn?: InputMaybe<
    Array<TeamMembersSelectColumn> | TeamMembersSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy> | TeamMembersOrderBy>;
  where?: InputMaybe<TeamMembersBoolExp>;
  distinctOn1?: InputMaybe<
    Array<ActivitiesSelectColumn> | ActivitiesSelectColumn
  >;
  limit1?: InputMaybe<Scalars["Int"]>;
  offset1?: InputMaybe<Scalars["Int"]>;
  orderBy1?: InputMaybe<Array<ActivitiesOrderBy> | ActivitiesOrderBy>;
  where1?: InputMaybe<ActivitiesBoolExp>;
  distinctOn2?: InputMaybe<
    Array<ProjectMembersSelectColumn> | ProjectMembersSelectColumn
  >;
  limit2?: InputMaybe<Scalars["Int"]>;
  offset2?: InputMaybe<Scalars["Int"]>;
  orderBy2?: InputMaybe<Array<ProjectMembersOrderBy> | ProjectMembersOrderBy>;
  where2?: InputMaybe<ProjectMembersBoolExp>;
  distinctOn3?: InputMaybe<Array<TasksSelectColumn> | TasksSelectColumn>;
  limit3?: InputMaybe<Scalars["Int"]>;
  offset3?: InputMaybe<Scalars["Int"]>;
  orderBy3?: InputMaybe<Array<TasksOrderBy> | TasksOrderBy>;
  where3?: InputMaybe<TasksBoolExp>;
  distinctOn4?: InputMaybe<
    Array<WorkTrackingsSelectColumn> | WorkTrackingsSelectColumn
  >;
  limit4?: InputMaybe<Scalars["Int"]>;
  offset4?: InputMaybe<Scalars["Int"]>;
  orderBy4?: InputMaybe<Array<WorkTrackingsOrderBy> | WorkTrackingsOrderBy>;
  where4?: InputMaybe<WorkTrackingsBoolExp>;
  distinctOn5?: InputMaybe<Array<WorksSelectColumn> | WorksSelectColumn>;
  limit5?: InputMaybe<Scalars["Int"]>;
  offset5?: InputMaybe<Scalars["Int"]>;
  orderBy5?: InputMaybe<Array<WorksOrderBy> | WorksOrderBy>;
  where5?: InputMaybe<WorksBoolExp>;
  distinctOn6?: InputMaybe<
    Array<TeamMembersSelectColumn> | TeamMembersSelectColumn
  >;
  limit6?: InputMaybe<Scalars["Int"]>;
  offset6?: InputMaybe<Scalars["Int"]>;
  orderBy6?: InputMaybe<Array<TeamMembersOrderBy> | TeamMembersOrderBy>;
  where6?: InputMaybe<TeamMembersBoolExp>;
  _set?: InputMaybe<UsersSetInput>;
  pkColumns: UsersPkColumnsInput;
}>;

export type UpdateUsersByPkMutation = {
  updateUsersByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    email: string;
    firstName: string;
    id: string;
    imageFileName?: string | null;
    lastName: string;
    password: string;
    refreshToken?: string | null;
    tutorialViewedAt?: any | null;
    twofaEnabled: boolean;
    twofaSecret?: string | null;
    updatedAt: any;
    teamMembersAggregate: {
      aggregate?: { count: number } | null;
      nodes: Array<{
        createdAt: any;
        deletedAt?: any | null;
        doingTaskId?: string | null;
        id: string;
        lastWorkedAt?: any | null;
        role: string;
        teamId: string;
        updatedAt: any;
        userId: string;
        workStatus: string;
      }>;
    };
    team_members: Array<{
      createdAt: any;
      deletedAt?: any | null;
      doingTaskId?: string | null;
      id: string;
      lastWorkedAt?: any | null;
      role: string;
      teamId: string;
      updatedAt: any;
      userId: string;
      workStatus: string;
      activities: Array<{
        createdAt: any;
        id: string;
        taskId?: string | null;
        teamId: string;
        teamMemberId: string;
        type: string;
        updatedAt: any;
        workTrackingId?: string | null;
      }>;
      doingTask?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        lock: boolean;
        name: string;
        order: number;
        projectId: string;
        projectMemberId: string;
        projectTaskTagId: string;
        status: string;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
      } | null;
      projectMembers: Array<{
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        projectId: string;
        role: string;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
      }>;
      tasks: Array<{
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        lock: boolean;
        name: string;
        order: number;
        projectId: string;
        projectMemberId: string;
        projectTaskTagId: string;
        status: string;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
      }>;
      team: {
        createdAt: any;
        deletedAt?: any | null;
        enabledBlurBackground: boolean;
        enabledSnapshot: boolean;
        forceSso: string;
        forceTwofa: boolean;
        id: string;
        imageFilePath?: string | null;
        name: string;
        updatedAt: any;
      };
      teamMemberSnapshot?: {
        createdAt: any;
        snapshotFilePath?: string | null;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
      } | null;
      user: {
        createdAt: any;
        deletedAt?: any | null;
        email: string;
        firstName: string;
        id: string;
        imageFileName?: string | null;
        lastName: string;
        password: string;
        refreshToken?: string | null;
        tutorialViewedAt?: any | null;
        twofaEnabled: boolean;
        twofaSecret?: string | null;
        updatedAt: any;
      };
      workTrackings: Array<{
        breakMinutes: number;
        createdAt: any;
        finishedAt?: any | null;
        id: string;
        startedAt: any;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
      }>;
      works: Array<{
        breakMinutes: number;
        createdAt: any;
        finishedAt: any;
        id: string;
        startedAt: any;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
        workTrackingId: string;
      }>;
    }>;
  } | null;
};

export type UpdateWorkTaskMutationVariables = Exact<{
  input: UpdateWorkTaskInput;
}>;

export type UpdateWorkTaskMutation = { updateWorkTask: { result: boolean } };

export type VerificateEmailForCreateUserMutationVariables = Exact<{
  input: VerificateEmailForCreateUserInput;
}>;

export type VerificateEmailForCreateUserMutation = {
  verificateEmailForCreateUser: { result: boolean };
};

export type VerificateEmailForUpdateUserEmailMutationVariables = Exact<{
  input: VerificateEmailForUpdateUserEmailInput;
}>;

export type VerificateEmailForUpdateUserEmailMutation = {
  verificateEmailForUpdateUserEmail: { result: boolean };
};

export type ActivitiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy> | ActivitiesOrderBy>;
  where?: InputMaybe<ActivitiesBoolExp>;
}>;

export type ActivitiesQuery = {
  activities: Array<{
    createdAt: any;
    id: string;
    taskId?: string | null;
    teamId: string;
    teamMemberId: string;
    type: string;
    teamMember?: {
      createdAt: any;
      deletedAt?: any | null;
      doingTaskId?: string | null;
      id: string;
      role: string;
      teamId: string;
      updatedAt: any;
      userId: string;
      workStatus: string;
      lastWorkedAt?: any | null;
      user: {
        createdAt: any;
        deletedAt?: any | null;
        email: string;
        firstName: string;
        id: string;
        imageFileName?: string | null;
        lastName: string;
        tutorialViewedAt?: any | null;
        twofaEnabled: boolean;
        twofaSecret?: string | null;
        updatedAt: any;
      };
      doingTask?: {
        id: string;
        name: string;
        order: number;
        projectId: string;
        projectTaskTagId: string;
        status: string;
        teamMemberId: string;
        lock: boolean;
        serviceType?: string | null;
        project?: {
          archived: boolean;
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          teamId: string;
          updatedAt: any;
        } | null;
        projectTaskTag?: {
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          order: string;
          teamId: string;
          projectId: string;
          updatedAt: any;
        } | null;
      } | null;
    } | null;
    task?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
    workTracking?: {
      finishedAt?: any | null;
      startedAt: any;
      breakMinutes: number;
      work?: {
        breakMinutes: number;
        createdAt: any;
        finishedAt: any;
        id: string;
        startedAt: any;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
        workTrackingId: string;
      } | null;
    } | null;
  }>;
};

export type BacklogIssuesQueryVariables = Exact<{
  input: BacklogIssuesInput;
}>;

export type BacklogIssuesQuery = {
  backlogIssues: Array<{ id: number; name: string }>;
};

export type BacklogProjectsQueryVariables = Exact<{
  input: BacklogProjectsInput;
}>;

export type BacklogProjectsQuery = {
  backlogProjects: Array<{ id: number; name: string }>;
};

export type ContractsQueryVariables = Exact<{
  distinctOn?: InputMaybe<Array<ContractsSelectColumn> | ContractsSelectColumn>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ContractsOrderBy> | ContractsOrderBy>;
  where?: InputMaybe<ContractsBoolExp>;
}>;

export type ContractsQuery = {
  contracts: Array<{
    accountQuantity: number;
    contractedAt: any;
    createdAt: any;
    finishedAt?: any | null;
    id: string;
    planId: string;
    settlementFailedAt?: any | null;
    settlementService: string;
    teamId: string;
    updatedAt: any;
    plan: {
      code: string;
      createdAt: any;
      id: string;
      name: string;
      paymentCycle?: string | null;
      price: number;
      stripePriceId?: string | null;
      updatedAt: any;
    };
    team: {
      createdAt: any;
      deletedAt?: any | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      forceSso: string;
      forceTwofa: boolean;
      id: string;
      imageFilePath?: string | null;
      name: string;
      updatedAt: any;
      teamMembersAggregate: { aggregate?: { count: number } | null };
      projectsAggregate: { aggregate?: { count: number } | null };
      stripeCustomer?: {
        country: string;
        createdAt: any;
        email: string;
        id: string;
        line1: string;
        line2?: string | null;
        postalCode: string;
        state: string;
        teamId: string;
        updatedAt: any;
      } | null;
    };
  }>;
};

export type ContractsAggregateQueryVariables = Exact<{
  where?: InputMaybe<ContractsBoolExp>;
}>;

export type ContractsAggregateQuery = {
  contractsAggregate: { aggregate?: { count: number } | null };
};

export type FindTeamMembersByUserIdQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type FindTeamMembersByUserIdQuery = {
  teamMembers: Array<{
    createdAt: any;
    deletedAt?: any | null;
    doingTaskId?: string | null;
    id: string;
    role: string;
    teamId: string;
    updatedAt: any;
    userId: string;
    workStatus: string;
    lastWorkedAt?: any | null;
    team: {
      createdAt: any;
      deletedAt?: any | null;
      enabledBlurBackground: boolean;
      enabledSnapshot: boolean;
      forceSso: string;
      forceTwofa: boolean;
      id: string;
      imageFilePath?: string | null;
      name: string;
      updatedAt: any;
    };
    user: {
      createdAt: any;
      deletedAt?: any | null;
      email: string;
      firstName: string;
      id: string;
      imageFileName?: string | null;
      lastName: string;
      tutorialViewedAt?: any | null;
      twofaEnabled: boolean;
      twofaSecret?: string | null;
      updatedAt: any;
    };
    doingTask?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
  }>;
};

export type GithubIssuesQueryVariables = Exact<{
  input: GithubIssuesInput;
}>;

export type GithubIssuesQuery = {
  githubIssues: Array<{ id: number; name: string }>;
};

export type GithubRepositoriesQueryVariables = Exact<{
  input: GithubRepositoriesInput;
}>;

export type GithubRepositoriesQuery = {
  githubRepositories: Array<{ id: number; name: string }>;
};

export type GoogleCalendarEventQueryVariables = Exact<{
  input: GoogleCalendarEventInput;
}>;

export type GoogleCalendarEventQuery = {
  googleCalendarEvent: {
    nextPageToken?: string | null;
    events: Array<{ summary: string }>;
  };
};

export type NextInvoiceQueryVariables = Exact<{ [key: string]: never }>;

export type NextInvoiceQuery = {
  nextInvoice: {
    amount: number;
    isTrial?: boolean | null;
    settleDate?: string | null;
  };
};

export type NextInvoicePreviewQueryVariables = Exact<{
  input: NextInvoicePreviewInput;
}>;

export type NextInvoicePreviewQuery = {
  nextInvoicePreview: {
    amount: number;
    proratedNumberOfDays?: number | null;
    prorationAmount?: number | null;
    settleDate?: string | null;
    isTrial: boolean;
  };
};

export type PlansQueryVariables = Exact<{
  where?: InputMaybe<PlansBoolExp>;
}>;

export type PlansQuery = {
  plans: Array<{
    code: string;
    createdAt: any;
    id: string;
    name: string;
    paymentCycle?: string | null;
    price: number;
    stripePriceId?: string | null;
    updatedAt: any;
  }>;
};

export type ProjectAlertsQueryVariables = Exact<{
  where?: InputMaybe<ProjectAlertsBoolExp>;
}>;

export type ProjectAlertsQuery = {
  projectAlerts: Array<{
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    projectId: string;
    teamId: string;
    threshold: number;
    updatedAt: any;
    name: string;
    project?: {
      name: string;
      archived: boolean;
      tasks: Array<{
        projectId: string;
        workTasks: Array<{
          totalMinutes: number;
          work?: { startedAt: any } | null;
        }>;
      }>;
    } | null;
  }>;
};

export type ProjectMembersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ProjectMembersOrderBy> | ProjectMembersOrderBy>;
  where?: InputMaybe<ProjectMembersBoolExp>;
}>;

export type ProjectMembersQuery = {
  projectMembers: Array<{
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    projectId: string;
    role: string;
    teamId: string;
    teamMemberId: string;
    updatedAt: any;
    teamMember?: {
      createdAt: any;
      deletedAt?: any | null;
      doingTaskId?: string | null;
      id: string;
      role: string;
      teamId: string;
      updatedAt: any;
      userId: string;
      workStatus: string;
      lastWorkedAt?: any | null;
      user: {
        createdAt: any;
        deletedAt?: any | null;
        email: string;
        firstName: string;
        id: string;
        imageFileName?: string | null;
        lastName: string;
        tutorialViewedAt?: any | null;
        twofaEnabled: boolean;
        twofaSecret?: string | null;
        updatedAt: any;
      };
      doingTask?: {
        id: string;
        name: string;
        order: number;
        projectId: string;
        projectTaskTagId: string;
        status: string;
        teamMemberId: string;
        lock: boolean;
        serviceType?: string | null;
        project?: {
          archived: boolean;
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          teamId: string;
          updatedAt: any;
        } | null;
        projectTaskTag?: {
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          order: string;
          teamId: string;
          projectId: string;
          updatedAt: any;
        } | null;
      } | null;
    } | null;
  }>;
};

export type ProjectTaskTagsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ProjectTaskTagsOrderBy> | ProjectTaskTagsOrderBy>;
  where?: InputMaybe<ProjectTaskTagsBoolExp>;
}>;

export type ProjectTaskTagsQuery = {
  projectTaskTags: Array<{
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    order: string;
    teamId: string;
    projectId: string;
    updatedAt: any;
  }>;
};

export type ProjectTaskTagsByIdQueryVariables = Exact<{
  projectId: Scalars["String"];
}>;

export type ProjectTaskTagsByIdQuery = {
  projectTaskTags: Array<{
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    order: string;
    teamId: string;
    projectId: string;
    updatedAt: any;
  }>;
};

export type ProjectsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ProjectsOrderBy> | ProjectsOrderBy>;
  where?: InputMaybe<ProjectsBoolExp>;
}>;

export type ProjectsQuery = {
  projects: Array<{
    archived: boolean;
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    teamId: string;
    updatedAt: any;
  }>;
};

export type ProjectsByTeamIdQueryVariables = Exact<{
  teamId: Scalars["String"];
}>;

export type ProjectsByTeamIdQuery = {
  projects: Array<{
    archived: boolean;
    createdAt: any;
    deletedAt?: any | null;
    id: string;
    name: string;
    teamId: string;
    updatedAt: any;
  }>;
};

export type ReportDetailsQueryVariables = Exact<{
  input: ReportDetailsInput;
}>;

export type ReportDetailsQuery = {
  reportDetails: Array<{
    minutes: number;
    workId: string;
    projectId: string;
    projectName: string;
    projectTaskTagId: string;
    projectTaskTagName: string;
    taskId: string;
    taskName: string;
    teamMemberId: string;
    userId: string;
    userName: string;
    viewDate: string;
  }>;
};

export type ReportSummariesQueryVariables = Exact<{
  input: ReportSummariesInput;
}>;

export type ReportSummariesQuery = {
  reportSummaries: Array<{ minutes: number; viewDate: string }>;
};

export type ReportSummaryPercentagesQueryVariables = Exact<{
  input: ReportSummaryPercentagesInput;
}>;

export type ReportSummaryPercentagesQuery = {
  reportSummaryPercentages: Array<{
    minutes: number;
    percentage: number;
    title: string;
  }>;
};

export type SettlementHistoriesQueryVariables = Exact<{
  distinctOn?: InputMaybe<
    Array<SettlementHistoriesSelectColumn> | SettlementHistoriesSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<SettlementHistoriesOrderBy> | SettlementHistoriesOrderBy
  >;
  where?: InputMaybe<SettlementHistoriesBoolExp>;
}>;

export type SettlementHistoriesQuery = {
  settlementHistories: Array<{
    amount: number;
    contractId: string;
    createdAt: any;
    id: string;
    settledAt?: any | null;
    settlementService: string;
    status: string;
    teamId: string;
    updatedAt: any;
    stripeSettlementHistory?: { invoicePdfUrl?: string | null } | null;
  }>;
};

export type TasksQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<TasksOrderBy> | TasksOrderBy>;
  where?: InputMaybe<TasksBoolExp>;
}>;

export type TasksQuery = {
  tasks: Array<{
    id: string;
    name: string;
    order: number;
    projectId: string;
    projectTaskTagId: string;
    status: string;
    teamMemberId: string;
    lock: boolean;
    serviceType?: string | null;
    project?: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    } | null;
    projectTaskTag?: {
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      order: string;
      teamId: string;
      projectId: string;
      updatedAt: any;
    } | null;
  }>;
};

export type TeamAllowedIpAddressesQueryVariables = Exact<{
  distinctOn?: InputMaybe<
    | Array<TeamAllowedIpAddressesSelectColumn>
    | TeamAllowedIpAddressesSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<TeamAllowedIpAddressesOrderBy> | TeamAllowedIpAddressesOrderBy
  >;
  where?: InputMaybe<TeamAllowedIpAddressesBoolExp>;
}>;

export type TeamAllowedIpAddressesQuery = {
  teamAllowedIpAddresses: Array<{
    createdAt: any;
    id: string;
    ipAddress: string;
    teamId: string;
    updatedAt: any;
  }>;
};

export type TeamBacklogLinkageProjectsQueryVariables = Exact<{
  distinctOn?: InputMaybe<
    | Array<TeamBacklogLinkageProjectsSelectColumn>
    | TeamBacklogLinkageProjectsSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<TeamBacklogLinkageProjectsOrderBy> | TeamBacklogLinkageProjectsOrderBy
  >;
  where?: InputMaybe<TeamBacklogLinkageProjectsBoolExp>;
}>;

export type TeamBacklogLinkageProjectsQuery = {
  teamBacklogLinkageProjects: Array<{
    backlogProjectId: number;
    createdAt: any;
    id: string;
    projectId: string;
    teamId: string;
    updatedAt: any;
    linkId: string;
    project: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    };
  }>;
};

export type TeamBacklogLinkagesQueryVariables = Exact<{
  distinctOn?: InputMaybe<
    Array<TeamBacklogLinkagesSelectColumn> | TeamBacklogLinkagesSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<TeamBacklogLinkagesOrderBy> | TeamBacklogLinkagesOrderBy
  >;
  where?: InputMaybe<TeamBacklogLinkagesBoolExp>;
}>;

export type TeamBacklogLinkagesQuery = {
  teamBacklogLinkages: Array<{
    backlogUrl: string;
    createdAt: any;
    id: string;
    teamId: string;
    updatedAt: any;
  }>;
};

export type TeamGithubLinkageProjectsQueryVariables = Exact<{
  distinctOn?: InputMaybe<
    | Array<TeamGithubLinkageProjectsSelectColumn>
    | TeamGithubLinkageProjectsSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<TeamGithubLinkageProjectsOrderBy> | TeamGithubLinkageProjectsOrderBy
  >;
  where?: InputMaybe<TeamGithubLinkageProjectsBoolExp>;
}>;

export type TeamGithubLinkageProjectsQuery = {
  teamGithubLinkageProjects: Array<{
    createdAt: any;
    githubRepositoryId: number;
    id: string;
    projectId: string;
    teamId: string;
    updatedAt: any;
    linkId: string;
    project: {
      archived: boolean;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      name: string;
      teamId: string;
      updatedAt: any;
    };
  }>;
};

export type TeamGithubLinkagesQueryVariables = Exact<{
  distinctOn?: InputMaybe<
    Array<TeamGithubLinkagesSelectColumn> | TeamGithubLinkagesSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<TeamGithubLinkagesOrderBy> | TeamGithubLinkagesOrderBy
  >;
  where?: InputMaybe<TeamGithubLinkagesBoolExp>;
}>;

export type TeamGithubLinkagesQuery = {
  teamGithubLinkages: Array<{
    createdAt: any;
    id: string;
    organizationName?: string | null;
    teamId: string;
    updatedAt: any;
    isRefreshTokenExpired: boolean;
  }>;
};

export type TeamGoogleCalendarLinkagesQueryVariables = Exact<{
  distinctOn?: InputMaybe<
    | Array<TeamGoogleCalendarLinkagesSelectColumn>
    | TeamGoogleCalendarLinkagesSelectColumn
  >;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<
    Array<TeamGoogleCalendarLinkagesOrderBy> | TeamGoogleCalendarLinkagesOrderBy
  >;
  where?: InputMaybe<TeamGoogleCalendarLinkagesBoolExp>;
}>;

export type TeamGoogleCalendarLinkagesQuery = {
  teamGoogleCalendarLinkages: Array<{
    createdAt: any;
    id: string;
    teamId: string;
    updatedAt: any;
  }>;
};

export type TeamMemberInvitationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TeamMemberInvitationsQuery = {
  teamMemberInvitations: Array<{ id: string; email: string; lastSendAt: any }>;
};

export type TeamMembersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<TeamMembersOrderBy> | TeamMembersOrderBy>;
  where?: InputMaybe<TeamMembersBoolExp>;
}>;

export type TeamMembersQuery = {
  teamMembers: Array<{
    createdAt: any;
    deletedAt?: any | null;
    doingTaskId?: string | null;
    id: string;
    role: string;
    teamId: string;
    updatedAt: any;
    userId: string;
    workStatus: string;
    lastWorkedAt?: any | null;
    user: {
      createdAt: any;
      deletedAt?: any | null;
      email: string;
      firstName: string;
      id: string;
      imageFileName?: string | null;
      lastName: string;
      tutorialViewedAt?: any | null;
      twofaEnabled: boolean;
      twofaSecret?: string | null;
      updatedAt: any;
    };
    doingTask?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
  }>;
};

export type TeamMembersByPkQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type TeamMembersByPkQuery = {
  teamMembersByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    doingTaskId?: string | null;
    id: string;
    role: string;
    teamId: string;
    updatedAt: any;
    userId: string;
    workStatus: string;
    lastWorkedAt?: any | null;
    user: {
      createdAt: any;
      deletedAt?: any | null;
      email: string;
      firstName: string;
      id: string;
      imageFileName?: string | null;
      lastName: string;
      tutorialViewedAt?: any | null;
      twofaEnabled: boolean;
      twofaSecret?: string | null;
      updatedAt: any;
    };
    doingTask?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
  } | null;
};

export type TeamsQueryVariables = Exact<{
  where?: InputMaybe<TeamsBoolExp>;
}>;

export type TeamsQuery = {
  teams: Array<{
    createdAt: any;
    deletedAt?: any | null;
    enabledBlurBackground: boolean;
    enabledSnapshot: boolean;
    forceSso: string;
    forceTwofa: boolean;
    id: string;
    imageFilePath?: string | null;
    name: string;
    updatedAt: any;
  }>;
};

export type TeamsByPkQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type TeamsByPkQuery = {
  teamsByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    enabledBlurBackground: boolean;
    enabledSnapshot: boolean;
    forceSso: string;
    forceTwofa: boolean;
    id: string;
    imageFilePath?: string | null;
    name: string;
    updatedAt: any;
  } | null;
};

export type UsersByPkQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type UsersByPkQuery = {
  usersByPk?: {
    createdAt: any;
    deletedAt?: any | null;
    email: string;
    firstName: string;
    id: string;
    imageFileName?: string | null;
    lastName: string;
    tutorialViewedAt?: any | null;
    twofaEnabled: boolean;
    twofaSecret?: string | null;
    updatedAt: any;
  } | null;
};

export type WorkTasksQueryVariables = Exact<{
  where: WorkTasksBoolExp;
}>;

export type WorkTasksQuery = {
  workTasks: Array<{
    createdAt: any;
    taskId: string;
    teamId: string;
    totalMinutes: number;
    updatedAt: any;
    workId: string;
    task?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
    work?: {
      breakMinutes: number;
      createdAt: any;
      finishedAt: any;
      id: string;
      startedAt: any;
      teamId: string;
      teamMemberId: string;
      updatedAt: any;
      workTrackingId: string;
    } | null;
  }>;
};

export type WorkTrackingsQueryVariables = Exact<{
  where?: InputMaybe<WorkTrackingsBoolExp>;
}>;

export type WorkTrackingsQuery = {
  workTrackings: Array<{
    breakMinutes: number;
    createdAt: any;
    finishedAt?: any | null;
    id: string;
    startedAt: any;
    teamId: string;
    teamMemberId: string;
    updatedAt: any;
    workTrackingBreaks: Array<{
      createdAt: any;
      finishedAt?: any | null;
      id: string;
      startedAt: any;
      teamId: string;
      updatedAt: any;
      workTrackingId: string;
    }>;
    workTrackingTasks: Array<{
      createdAt: any;
      startedAt?: any | null;
      taskId: string;
      teamId: string;
      totalMinutes?: number | null;
      trackingMinutes?: number | null;
      updatedAt: any;
      workTrackingId: string;
      task?: {
        id: string;
        name: string;
        order: number;
        projectId: string;
        projectTaskTagId: string;
        status: string;
        teamMemberId: string;
        lock: boolean;
        serviceType?: string | null;
        project?: {
          archived: boolean;
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          teamId: string;
          updatedAt: any;
        } | null;
        projectTaskTag?: {
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          order: string;
          teamId: string;
          projectId: string;
          updatedAt: any;
        } | null;
      } | null;
    }>;
  }>;
};

export type WorksQueryVariables = Exact<{
  where: WorksBoolExp;
}>;

export type WorksQuery = {
  works: Array<{
    breakMinutes: number;
    createdAt: any;
    finishedAt: any;
    id: string;
    startedAt: any;
    teamId: string;
    teamMemberId: string;
    updatedAt: any;
    workTrackingId: string;
  }>;
};

export type WorksCalendarQueryVariables = Exact<{
  input: WorksCalendarInput;
}>;

export type WorksCalendarQuery = {
  worksCalendar: Array<{
    breakTime: string;
    finishedTime: string;
    startedTime: string;
    workDate: string;
    workTime: string;
    workTimeMinutes: number;
  }>;
};

export type SubscribeActivitiesStreamSubscriptionVariables = Exact<{
  batchSize: Scalars["Int"];
  cursor:
    | Array<InputMaybe<ActivitiesStreamCursorInput>>
    | InputMaybe<ActivitiesStreamCursorInput>;
  where?: InputMaybe<ActivitiesBoolExp>;
}>;

export type SubscribeActivitiesStreamSubscription = {
  activitiesStream: Array<{
    createdAt: any;
    id: string;
    taskId?: string | null;
    teamId: string;
    teamMemberId: string;
    type: string;
    teamMember?: {
      createdAt: any;
      deletedAt?: any | null;
      doingTaskId?: string | null;
      id: string;
      role: string;
      teamId: string;
      updatedAt: any;
      userId: string;
      workStatus: string;
      lastWorkedAt?: any | null;
      user: {
        createdAt: any;
        deletedAt?: any | null;
        email: string;
        firstName: string;
        id: string;
        imageFileName?: string | null;
        lastName: string;
        tutorialViewedAt?: any | null;
        twofaEnabled: boolean;
        twofaSecret?: string | null;
        updatedAt: any;
      };
      doingTask?: {
        id: string;
        name: string;
        order: number;
        projectId: string;
        projectTaskTagId: string;
        status: string;
        teamMemberId: string;
        lock: boolean;
        serviceType?: string | null;
        project?: {
          archived: boolean;
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          teamId: string;
          updatedAt: any;
        } | null;
        projectTaskTag?: {
          createdAt: any;
          deletedAt?: any | null;
          id: string;
          name: string;
          order: string;
          teamId: string;
          projectId: string;
          updatedAt: any;
        } | null;
      } | null;
    } | null;
    task?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
    workTracking?: {
      finishedAt?: any | null;
      startedAt: any;
      breakMinutes: number;
      work?: {
        breakMinutes: number;
        createdAt: any;
        finishedAt: any;
        id: string;
        startedAt: any;
        teamId: string;
        teamMemberId: string;
        updatedAt: any;
        workTrackingId: string;
      } | null;
    } | null;
  }>;
};

export type SubscribeTeamMembersSubscriptionVariables = Exact<{
  where?: InputMaybe<TeamMembersBoolExp>;
}>;

export type SubscribeTeamMembersSubscription = {
  teamMembers: Array<{
    doingTaskId?: string | null;
    id: string;
    userId: string;
    workStatus: string;
    lastWorkedAt?: any | null;
    role: string;
    doingTask?: {
      id: string;
      name: string;
      order: number;
      projectId: string;
      projectTaskTagId: string;
      status: string;
      teamMemberId: string;
      lock: boolean;
      serviceType?: string | null;
      project?: {
        archived: boolean;
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        teamId: string;
        updatedAt: any;
      } | null;
      projectTaskTag?: {
        createdAt: any;
        deletedAt?: any | null;
        id: string;
        name: string;
        order: string;
        teamId: string;
        projectId: string;
        updatedAt: any;
      } | null;
    } | null;
    user: {
      createdAt: any;
      deletedAt?: any | null;
      email: string;
      firstName: string;
      id: string;
      imageFileName?: string | null;
      lastName: string;
      tutorialViewedAt?: any | null;
      twofaEnabled: boolean;
      twofaSecret?: string | null;
      updatedAt: any;
    };
    teamMemberSnapshot?: {
      snapshotFilePath?: string | null;
      updatedAt: any;
    } | null;
    projectMembers: Array<{ projectId: string }>;
  }>;
};

export const UsersAttributeFragmentDoc = gql`
  fragment UsersAttribute on Users {
    createdAt
    deletedAt
    email
    firstName
    id
    imageFileName
    lastName
    tutorialViewedAt
    twofaEnabled
    twofaSecret
    updatedAt
  }
`;
export const ProjectsAttributeFragmentDoc = gql`
  fragment ProjectsAttribute on Projects {
    archived
    createdAt
    deletedAt
    id
    name
    teamId
    updatedAt
  }
`;
export const ProjectTaskTagAttributesFragmentDoc = gql`
  fragment ProjectTaskTagAttributes on ProjectTaskTags {
    createdAt
    deletedAt
    id
    name
    order
    teamId
    projectId
    updatedAt
  }
`;
export const TaskAttributesFragmentDoc = gql`
  fragment TaskAttributes on Tasks {
    id
    name
    order
    project {
      ...ProjectsAttribute
    }
    projectId
    projectTaskTag {
      ...ProjectTaskTagAttributes
    }
    projectTaskTagId
    status
    teamMemberId
    lock
    serviceType
  }
  ${ProjectsAttributeFragmentDoc}
  ${ProjectTaskTagAttributesFragmentDoc}
`;
export const TeamMembersAttributeFragmentDoc = gql`
  fragment TeamMembersAttribute on TeamMembers {
    createdAt
    deletedAt
    doingTaskId
    id
    role
    teamId
    updatedAt
    user {
      ...UsersAttribute
    }
    userId
    workStatus
    lastWorkedAt
    doingTask {
      ...TaskAttributes
    }
  }
  ${UsersAttributeFragmentDoc}
  ${TaskAttributesFragmentDoc}
`;
export const WorkAttributesFragmentDoc = gql`
  fragment WorkAttributes on Works {
    breakMinutes
    createdAt
    finishedAt
    id
    startedAt
    teamId
    teamMemberId
    updatedAt
    workTrackingId
  }
`;
export const ActivityAttributesFragmentDoc = gql`
  fragment ActivityAttributes on Activities {
    createdAt
    id
    taskId
    teamId
    teamMemberId
    type
    teamMember {
      ...TeamMembersAttribute
    }
    task {
      ...TaskAttributes
    }
    workTracking {
      finishedAt
      startedAt
      breakMinutes
      work {
        ...WorkAttributes
      }
    }
  }
  ${TeamMembersAttributeFragmentDoc}
  ${TaskAttributesFragmentDoc}
  ${WorkAttributesFragmentDoc}
`;
export const ContractsAttributeFragmentDoc = gql`
  fragment ContractsAttribute on Contracts {
    accountQuantity
    contractedAt
    createdAt
    finishedAt
    id
    planId
    settlementFailedAt
    settlementService
    teamId
    updatedAt
  }
`;
export const CredentialsAttributeFragmentDoc = gql`
  fragment CredentialsAttribute on Credentials {
    accessKeyId
    expiration
    secretAccessKey
    sessionToken
  }
`;
export const LoginResultAttributeFragmentDoc = gql`
  fragment LoginResultAttribute on LoginResult {
    accessToken
    belongingTeams {
      createdAt
      deletedAt
      enabledBlurBackground
      enabledSnapshot
      imageFilePath
      name
      teamId
      updatedAt
    }
    credentials {
      ...CredentialsAttribute
    }
    refreshToken
  }
  ${CredentialsAttributeFragmentDoc}
`;
export const PlansAttributeFragmentDoc = gql`
  fragment PlansAttribute on Plans {
    code
    createdAt
    id
    name
    paymentCycle
    price
    stripePriceId
    updatedAt
  }
`;
export const ProjectAlertAttributesFragmentDoc = gql`
  fragment ProjectAlertAttributes on ProjectAlerts {
    createdAt
    deletedAt
    id
    projectId
    teamId
    threshold
    updatedAt
    name
    project {
      name
      archived
      tasks {
        projectId
        workTasks {
          totalMinutes
          work {
            startedAt
          }
        }
      }
    }
  }
`;
export const ProjectMembersAttributeFragmentDoc = gql`
  fragment ProjectMembersAttribute on ProjectMembers {
    createdAt
    deletedAt
    id
    projectId
    role
    teamId
    teamMember {
      ...TeamMembersAttribute
    }
    teamMemberId
    updatedAt
  }
  ${TeamMembersAttributeFragmentDoc}
`;
export const SettlementHistoriesAttributeFragmentDoc = gql`
  fragment SettlementHistoriesAttribute on SettlementHistories {
    amount
    contractId
    createdAt
    id
    settledAt
    settlementService
    status
    teamId
    updatedAt
    stripeSettlementHistory {
      invoicePdfUrl
    }
  }
`;
export const StripeCustomersAttributeFragmentDoc = gql`
  fragment StripeCustomersAttribute on StripeCustomers {
    country
    createdAt
    email
    id
    line1
    line2
    postalCode
    state
    teamId
    updatedAt
  }
`;
export const TeamsAttributeFragmentDoc = gql`
  fragment TeamsAttribute on Teams {
    createdAt
    deletedAt
    enabledBlurBackground
    enabledSnapshot
    forceSso
    forceTwofa
    id
    imageFilePath
    name
    updatedAt
  }
`;
export const TeamAllowedIpAddressesAttributeFragmentDoc = gql`
  fragment TeamAllowedIpAddressesAttribute on TeamAllowedIpAddresses {
    createdAt
    id
    ipAddress
    teamId
    updatedAt
  }
`;
export const TeamGithubLinkagesAttributeFragmentDoc = gql`
  fragment TeamGithubLinkagesAttribute on TeamGithubLinkages {
    createdAt
    id
    organizationName
    teamId
    updatedAt
    isRefreshTokenExpired
  }
`;
export const TeamMemberInvitationsAttributeFragmentDoc = gql`
  fragment TeamMemberInvitationsAttribute on TeamMemberInvitations {
    id
    email
    lastSendAt
  }
`;
export const TeamMembersSubscriptionAttributeFragmentDoc = gql`
  fragment TeamMembersSubscriptionAttribute on TeamMembers {
    doingTask {
      ...TaskAttributes
    }
    doingTaskId
    id
    user {
      ...UsersAttribute
    }
    userId
    workStatus
    lastWorkedAt
    teamMemberSnapshot {
      snapshotFilePath
      updatedAt
    }
    role
    projectMembers {
      projectId
    }
  }
  ${TaskAttributesFragmentDoc}
  ${UsersAttributeFragmentDoc}
`;
export const WorkTaskAttributesFragmentDoc = gql`
  fragment WorkTaskAttributes on WorkTasks {
    createdAt
    taskId
    teamId
    totalMinutes
    updatedAt
    workId
    task {
      ...TaskAttributes
    }
    work {
      ...WorkAttributes
    }
  }
  ${TaskAttributesFragmentDoc}
  ${WorkAttributesFragmentDoc}
`;
export const WorkTrackingBreaksAttributeFragmentDoc = gql`
  fragment WorkTrackingBreaksAttribute on WorkTrackingBreaks {
    createdAt
    finishedAt
    id
    startedAt
    teamId
    updatedAt
    workTrackingId
  }
`;
export const WorkTrackingTasksAttributeFragmentDoc = gql`
  fragment WorkTrackingTasksAttribute on WorkTrackingTasks {
    createdAt
    startedAt
    task {
      ...TaskAttributes
    }
    taskId
    teamId
    totalMinutes
    trackingMinutes
    updatedAt
    workTrackingId
  }
  ${TaskAttributesFragmentDoc}
`;
export const WorkTrackingsAttributeFragmentDoc = gql`
  fragment WorkTrackingsAttribute on WorkTrackings {
    breakMinutes
    createdAt
    finishedAt
    id
    startedAt
    teamId
    teamMemberId
    updatedAt
    workTrackingBreaks {
      ...WorkTrackingBreaksAttribute
    }
    workTrackingTasks {
      ...WorkTrackingTasksAttribute
    }
  }
  ${WorkTrackingBreaksAttributeFragmentDoc}
  ${WorkTrackingTasksAttributeFragmentDoc}
`;
export const AcceptTeamMemberInvitationDocument = gql`
  mutation acceptTeamMemberInvitation(
    $input: AcceptTeamMemberInvitationInput!
  ) {
    acceptTeamMemberInvitation(input: $input) {
      teamId
      teamMemberId
    }
  }
`;
export const ApproveGitHubInstallationDocument = gql`
  mutation approveGitHubInstallation($input: ApproveGitHubInstallationInput!) {
    approveGitHubInstallation(input: $input) {
      isSettingCompleted
      organizationName
    }
  }
`;
export const ArchiveProjectDocument = gql`
  mutation archiveProject($input: ArchiveProjectInput!) {
    archiveProject(input: $input) {
      result
    }
  }
`;
export const AuthenticateTwoFaDocument = gql`
  mutation authenticateTwoFA($input: AuthenticateTwoFAInput!) {
    authenticateTwoFA(input: $input) {
      accessToken
      belongingTeams {
        createdAt
        deletedAt
        enabledBlurBackground
        enabledSnapshot
        imageFilePath
        name
        teamId
        updatedAt
      }
      credentials {
        accessKeyId
        expiration
        secretAccessKey
        sessionToken
      }
      refreshToken
    }
  }
`;
export const CreateBacklogAuthzUrlDocument = gql`
  mutation createBacklogAuthzUrl($input: CreateBacklogAuthzUrlInput!) {
    createBacklogAuthzUrl(input: $input) {
      backlogAuthzUrl
    }
  }
`;
export const CreateGitHubAuthzUrlDocument = gql`
  mutation createGitHubAuthzUrl {
    createGitHubAuthzUrl {
      state
      url
    }
  }
`;
export const CreateGitHubReAuthzUrlDocument = gql`
  mutation createGitHubReAuthzUrl {
    createGitHubReAuthzUrl {
      state
      url
    }
  }
`;
export const CreateGoogleCalendarAuthzUrlDocument = gql`
  mutation createGoogleCalendarAuthzUrl {
    createGoogleCalendarAuthzUrl {
      url
    }
  }
`;
export const CreateGoogleLoginAuthzUrlDocument = gql`
  mutation createGoogleLoginAuthzUrl($input: CreateGoogleLoginAuthzUrlInput!) {
    createGoogleLoginAuthzUrl(input: $input) {
      url
    }
  }
`;
export const CreateMicrosoftLoginAuthzUrlDocument = gql`
  mutation createMicrosoftLoginAuthzUrl(
    $input: CreateMicrosoftLoginAuthzUrlInput!
  ) {
    createMicrosoftLoginAuthzUrl(input: $input) {
      url
    }
  }
`;
export const CreateProjectDocument = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      archived
      createdAt
      deletedAt
      name
      projectId
      teamId
      updatedAt
    }
  }
`;
export const CreateSetupSessionDocument = gql`
  mutation createSetupSession {
    createSetupSession {
      sessionUrl
    }
  }
`;
export const CreateSubscriptionSessionDocument = gql`
  mutation createSubscriptionSession($input: CreateSubscriptionSessionInput!) {
    createSubscriptionSession(input: $input) {
      sessionUrl
    }
  }
`;
export const CreateTeamDocument = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      teamId
    }
  }
`;
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      accessToken
      belongingTeams {
        createdAt
        deletedAt
        enabledBlurBackground
        enabledSnapshot
        imageFilePath
        name
        teamId
        updatedAt
      }
      credentials {
        accessKeyId
        secretAccessKey
        sessionToken
        expiration
      }
      refreshToken
    }
  }
`;
export const DeleteTeamDocument = gql`
  mutation deleteTeam {
    deleteTeam {
      result
    }
  }
`;
export const DeleteTeamBacklogLinkageProjectsDocument = gql`
  mutation deleteTeamBacklogLinkageProjects(
    $where: TeamBacklogLinkageProjectsBoolExp!
  ) {
    deleteTeamBacklogLinkageProjects(where: $where) {
      affectedRows
      returning {
        backlogProjectId
        createdAt
        id
        projectId
        teamId
        updatedAt
      }
    }
  }
`;
export const DeleteTeamBacklogLinkagesDocument = gql`
  mutation deleteTeamBacklogLinkages($where: TeamBacklogLinkagesBoolExp!) {
    deleteTeamBacklogLinkages(where: $where) {
      affectedRows
    }
  }
`;
export const DeleteTeamGithubLinkageProjectsDocument = gql`
  mutation deleteTeamGithubLinkageProjects(
    $where: TeamGithubLinkageProjectsBoolExp!
  ) {
    deleteTeamGithubLinkageProjects(where: $where) {
      affectedRows
      returning {
        createdAt
        githubRepositoryId
        id
        projectId
        teamId
        updatedAt
      }
    }
  }
`;
export const DeleteTeamGoogleCalendarLinkagesDocument = gql`
  mutation deleteTeamGoogleCalendarLinkages(
    $where: TeamGoogleCalendarLinkagesBoolExp!
  ) {
    deleteTeamGoogleCalendarLinkages(where: $where) {
      affectedRows
    }
  }
`;
export const DeleteTeamMemberInvitationsByPkDocument = gql`
  mutation deleteTeamMemberInvitationsByPk($id: String!) {
    deleteTeamMemberInvitationsByPk(id: $id) {
      id
    }
  }
`;
export const DeleteUserDocument = gql`
  mutation deleteUser {
    deleteUser {
      result
    }
  }
`;
export const ExportTeamReportDocument = gql`
  mutation exportTeamReport($input: ExportTeamReportInput!) {
    exportTeamReport(input: $input) {
      path
    }
  }
`;
export const ExportWorksDocument = gql`
  mutation exportWorks($input: ExportWorksInput!) {
    exportWorks(input: $input) {
      path
    }
  }
`;
export const FinishWorkDocument = gql`
  mutation finishWork($input: FinishWorkInput!) {
    finishWork(input: $input) {
      result
    }
  }
`;
export const InsertEnqueteAnswerDocument = gql`
  mutation insertEnqueteAnswer($objects: [EnqueteAnswerInsertInput!]!) {
    insertEnqueteAnswer(objects: $objects) {
      affectedRows
    }
  }
`;
export const InsertProjectAlertsOneDocument = gql`
  mutation insertProjectAlertsOne($object: ProjectAlertsInsertInput!) {
    insertProjectAlertsOne(object: $object) {
      createdAt
      deletedAt
      id
      name
      projectId
      teamId
      threshold
      updatedAt
    }
  }
`;
export const InsertProjectMembersDocument = gql`
  mutation insertProjectMembers($objects: [ProjectMembersInsertInput!]!) {
    insertProjectMembers(objects: $objects) {
      affectedRows
      returning {
        createdAt
        deletedAt
        id
        project {
          archived
          createdAt
          deletedAt
          id
          name
          teamId
          updatedAt
        }
        projectId
        role
        teamId
        teamMember {
          createdAt
          deletedAt
          doingTaskId
          id
          role
          teamId
          updatedAt
          userId
          workStatus
          user {
            firstName
            lastName
            imageFileName
          }
        }
        teamMemberId
        updatedAt
      }
    }
  }
`;
export const InsertProjectMembersOneDocument = gql`
  mutation insertProjectMembersOne($object: ProjectMembersInsertInput!) {
    insertProjectMembersOne(object: $object) {
      createdAt
      deletedAt
      id
      projectId
      role
      teamId
      teamMember {
        user {
          firstName
          imageFileName
          lastName
        }
      }
      teamMemberId
      updatedAt
    }
  }
`;
export const InsertProjectTaskTagsDocument = gql`
  mutation insertProjectTaskTags($objects: [ProjectTaskTagsInsertInput!]!) {
    insertProjectTaskTags(objects: $objects) {
      returning {
        createdAt
        deletedAt
        id
        name
        order
        projectId
        teamId
        updatedAt
      }
    }
  }
`;
export const InsertProjectTaskTagsOneDocument = gql`
  mutation insertProjectTaskTagsOne($object: ProjectTaskTagsInsertInput!) {
    insertProjectTaskTagsOne(object: $object) {
      createdAt
      deletedAt
      id
      name
      order
      projectId
      teamId
      updatedAt
    }
  }
`;
export const InsertProjectsOneDocument = gql`
  mutation insertProjectsOne($object: ProjectsInsertInput!) {
    insertProjectsOne(object: $object) {
      id
    }
  }
`;
export const InsertTasksDocument = gql`
  mutation insertTasks(
    $objects: [TasksInsertInput!]!
    $onConflict: TasksOnConflict
  ) {
    insertTasks(objects: $objects, onConflict: $onConflict) {
      affectedRows
    }
  }
`;
export const InsertTeamAllowedIpAddressesDocument = gql`
  mutation insertTeamAllowedIpAddresses(
    $objects: [TeamAllowedIpAddressesInsertInput!]!
    $onConflict: TeamAllowedIpAddressesOnConflict
  ) {
    insertTeamAllowedIpAddresses(objects: $objects, onConflict: $onConflict) {
      affectedRows
      returning {
        createdAt
        id
        ipAddress
        teamId
        updatedAt
      }
    }
  }
`;
export const InsertTeamBacklogLinkageProjectsDocument = gql`
  mutation insertTeamBacklogLinkageProjects(
    $objects: [TeamBacklogLinkageProjectsInsertInput!]!
    $onConflict: TeamBacklogLinkageProjectsOnConflict
  ) {
    insertTeamBacklogLinkageProjects(
      objects: $objects
      onConflict: $onConflict
    ) {
      affectedRows
      returning {
        backlogProjectId
        createdAt
        id
        projectId
        teamId
        updatedAt
      }
    }
  }
`;
export const InsertTeamGithubLinkageProjectsDocument = gql`
  mutation insertTeamGithubLinkageProjects(
    $objects: [TeamGithubLinkageProjectsInsertInput!]!
    $onConflict: TeamGithubLinkageProjectsOnConflict
  ) {
    insertTeamGithubLinkageProjects(
      objects: $objects
      onConflict: $onConflict
    ) {
      affectedRows
      returning {
        createdAt
        githubRepositoryId
        id
        projectId
        teamId
        updatedAt
      }
    }
  }
`;
export const InsertTeamMemberInvitationsDocument = gql`
  mutation insertTeamMemberInvitations(
    $objects: [TeamMemberInvitationsInsertInput!]!
  ) {
    insertTeamMemberInvitations(objects: $objects) {
      affectedRows
    }
  }
`;
export const InsertTeamMemberInvitationsOneDocument = gql`
  mutation insertTeamMemberInvitationsOne(
    $object: TeamMemberInvitationsInsertInput!
  ) {
    insertTeamMemberInvitationsOne(object: $object) {
      id
    }
  }
`;
export const LinkBacklogDocument = gql`
  mutation linkBacklog($input: LinkBacklogInput!) {
    linkBacklog(input: $input) {
      result
    }
  }
`;
export const LinkGitHubDocument = gql`
  mutation linkGitHub($input: LinkGithubInput!) {
    linkGitHub(input: $input) {
      isSettingCompleted
      organizationName
    }
  }
`;
export const LinkGoogleCalendarDocument = gql`
  mutation linkGoogleCalendar($input: LinkGoogleCalendarInput!) {
    linkGoogleCalendar(input: $input) {
      result
    }
  }
`;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      belongingTeams {
        createdAt
        deletedAt
        enabledBlurBackground
        enabledSnapshot
        imageFilePath
        name
        teamId
        updatedAt
      }
      credentials {
        accessKeyId
        secretAccessKey
        sessionToken
        expiration
      }
      refreshToken
    }
  }
`;
export const LoginByGoogleDocument = gql`
  mutation loginByGoogle($input: LoginByGoogleInput!) {
    loginByGoogle(input: $input) {
      ...LoginResultAttribute
    }
  }
  ${LoginResultAttributeFragmentDoc}
`;
export const LoginByMicrosoftDocument = gql`
  mutation loginByMicrosoft($input: LoginByMicrosoftInput!) {
    loginByMicrosoft(input: $input) {
      ...LoginResultAttribute
    }
  }
  ${LoginResultAttributeFragmentDoc}
`;
export const LoginByRefreshTokenDocument = gql`
  mutation loginByRefreshToken($input: LoginByRefreshTokenInput!) {
    loginByRefreshToken(input: $input) {
      accessToken
      belongingTeams {
        createdAt
        deletedAt
        enabledBlurBackground
        enabledSnapshot
        imageFilePath
        name
        teamId
        updatedAt
      }
      credentials {
        accessKeyId
        secretAccessKey
        sessionToken
        expiration
      }
      refreshToken
    }
  }
`;
export const ReLinkGitHubDocument = gql`
  mutation reLinkGitHub($input: ReLinkGithubInput!) {
    reLinkGitHub(input: $input) {
      result
    }
  }
`;
export const RemoveProjectMemberDocument = gql`
  mutation removeProjectMember($input: RemoveProjectMemberInput!) {
    removeProjectMember(input: $input) {
      result
    }
  }
`;
export const RemoveTeamAllowedIpAddressDocument = gql`
  mutation removeTeamAllowedIpAddress(
    $input: RemoveTeamAllowedIpAddressInput!
  ) {
    removeTeamAllowedIpAddress(input: $input) {
      result
    }
  }
`;
export const RemoveTeamMemberDocument = gql`
  mutation removeTeamMember($input: RemoveTeamMemberInput!) {
    removeTeamMember(input: $input) {
      result
    }
  }
`;
export const ResetPasswordDocument = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      result
    }
  }
`;
export const ResumeWorkDocument = gql`
  mutation resumeWork {
    resumeWork {
      result
    }
  }
`;
export const SendContactMailDocument = gql`
  mutation sendContactMail($input: SendContactMailInput!) {
    sendContactMail(input: $input) {
      result
    }
  }
`;
export const StartWorkDocument = gql`
  mutation startWork {
    startWork {
      result
    }
  }
`;
export const SuspendWorkDocument = gql`
  mutation suspendWork {
    suspendWork {
      result
    }
  }
`;
export const UnLinkGitHubDocument = gql`
  mutation unLinkGitHub($input: UnLinkGithubInput!) {
    unLinkGitHub(input: $input) {
      result
    }
  }
`;
export const UpdateContractDocument = gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      result
    }
  }
`;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      result
    }
  }
`;
export const UpdateProjectAlertsByPkDocument = gql`
  mutation updateProjectAlertsByPk(
    $_set: ProjectAlertsSetInput
    $pkColumns: ProjectAlertsPkColumnsInput!
  ) {
    updateProjectAlertsByPk(_set: $_set, pkColumns: $pkColumns) {
      createdAt
      deletedAt
      id
      name
      projectId
      teamId
      threshold
      updatedAt
    }
  }
`;
export const UpdateProjectMembersByPkDocument = gql`
  mutation updateProjectMembersByPk(
    $_set: ProjectMembersSetInput
    $pkColumns: ProjectMembersPkColumnsInput!
  ) {
    updateProjectMembersByPk(_set: $_set, pkColumns: $pkColumns) {
      createdAt
      deletedAt
      id
    }
  }
`;
export const UpdateProjectTaskTagsByPkDocument = gql`
  mutation updateProjectTaskTagsByPk(
    $_set: ProjectTaskTagsSetInput
    $pkColumns: ProjectTaskTagsPkColumnsInput!
  ) {
    updateProjectTaskTagsByPk(_set: $_set, pkColumns: $pkColumns) {
      createdAt
      deletedAt
      id
      name
      order
      projectId
      teamId
      updatedAt
    }
  }
`;
export const UpdateProjectTaskTagsManyDocument = gql`
  mutation updateProjectTaskTagsMany($updates: [ProjectTaskTagsUpdates!]!) {
    updateProjectTaskTagsMany(updates: $updates) {
      affectedRows
      returning {
        createdAt
        deletedAt
        id
        name
        order
        projectId
        teamId
        updatedAt
      }
    }
  }
`;
export const UpdateProjectsByPkDocument = gql`
  mutation updateProjectsByPk(
    $_set: ProjectsSetInput!
    $pkColumns: ProjectsPkColumnsInput!
  ) {
    updateProjectsByPk(_set: $_set, pkColumns: $pkColumns) {
      archived
      createdAt
      deletedAt
      id
      name
      teamId
      updatedAt
    }
  }
`;
export const UpdateTasksDocument = gql`
  mutation updateTasks($_set: TasksSetInput, $where: TasksBoolExp!) {
    updateTasks(_set: $_set, where: $where) {
      affectedRows
    }
  }
`;
export const UpdateTasksByPkDocument = gql`
  mutation updateTasksByPk(
    $_set: TasksSetInput
    $pkColumns: TasksPkColumnsInput!
  ) {
    updateTasksByPk(_set: $_set, pkColumns: $pkColumns) {
      id
    }
  }
`;
export const UpdateTasksManyDocument = gql`
  mutation updateTasksMany($updates: [TasksUpdates!]!) {
    updateTasksMany(updates: $updates) {
      affectedRows
    }
  }
`;
export const UpdateTeamAllowedIpAddressDocument = gql`
  mutation updateTeamAllowedIpAddress(
    $input: UpdateTeamAllowedIpAddressInput!
  ) {
    updateTeamAllowedIpAddress(input: $input) {
      result
    }
  }
`;
export const UpdateTeamMemberInvitationsDocument = gql`
  mutation updateTeamMemberInvitations(
    $_set: TeamMemberInvitationsSetInput
    $where: TeamMemberInvitationsBoolExp!
  ) {
    updateTeamMemberInvitations(_set: $_set, where: $where) {
      affectedRows
    }
  }
`;
export const UpdateTeamMemberSnapshotsByPkDocument = gql`
  mutation updateTeamMemberSnapshotsByPk(
    $_set: TeamMemberSnapshotsSetInput
    $pkColumns: TeamMemberSnapshotsPkColumnsInput!
  ) {
    updateTeamMemberSnapshotsByPk(_set: $_set, pkColumns: $pkColumns) {
      snapshotFilePath
    }
  }
`;
export const UpdateTeamMembersDocument = gql`
  mutation updateTeamMembers(
    $_set: TeamMembersSetInput
    $where: TeamMembersBoolExp!
  ) {
    updateTeamMembers(_set: $_set, where: $where) {
      affectedRows
    }
  }
`;
export const UpdateTeamsByPkDocument = gql`
  mutation updateTeamsByPk(
    $_set: TeamsSetInput
    $pkColumns: TeamsPkColumnsInput!
  ) {
    updateTeamsByPk(_set: $_set, pkColumns: $pkColumns) {
      id
      imageFilePath
    }
  }
`;
export const UpdateTwoFaSettingDocument = gql`
  mutation updateTwoFASetting($input: UpdateTwoFASettingInput!) {
    updateTwoFASetting(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export const UpdateUserEmailDocument = gql`
  mutation updateUserEmail($input: UpdateUserEmailInput!) {
    updateUserEmail(input: $input) {
      result
    }
  }
`;
export const UpdateUserPasswordDocument = gql`
  mutation updateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      result
    }
  }
`;
export const UpdateUserPasswordForceDocument = gql`
  mutation updateUserPasswordForce($input: UpdateUserPasswordForceInput!) {
    updateUserPasswordForce(input: $input) {
      result
    }
  }
`;
export const UpdateUserProfileByPkDocument = gql`
  mutation updateUserProfileByPk(
    $_set: UsersSetInput
    $pkColumns: UsersPkColumnsInput!
  ) {
    updateUsersByPk(_set: $_set, pkColumns: $pkColumns) {
      ...UsersAttribute
    }
  }
  ${UsersAttributeFragmentDoc}
`;
export const UpdateUsersByPkDocument = gql`
  mutation updateUsersByPk(
    $columns: [TeamMembersSelectColumn!]
    $distinct: Boolean
    $distinctOn: [TeamMembersSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [TeamMembersOrderBy!]
    $where: TeamMembersBoolExp
    $distinctOn1: [ActivitiesSelectColumn!]
    $limit1: Int
    $offset1: Int
    $orderBy1: [ActivitiesOrderBy!]
    $where1: ActivitiesBoolExp
    $distinctOn2: [ProjectMembersSelectColumn!]
    $limit2: Int
    $offset2: Int
    $orderBy2: [ProjectMembersOrderBy!]
    $where2: ProjectMembersBoolExp
    $distinctOn3: [TasksSelectColumn!]
    $limit3: Int
    $offset3: Int
    $orderBy3: [TasksOrderBy!]
    $where3: TasksBoolExp
    $distinctOn4: [WorkTrackingsSelectColumn!]
    $limit4: Int
    $offset4: Int
    $orderBy4: [WorkTrackingsOrderBy!]
    $where4: WorkTrackingsBoolExp
    $distinctOn5: [WorksSelectColumn!]
    $limit5: Int
    $offset5: Int
    $orderBy5: [WorksOrderBy!]
    $where5: WorksBoolExp
    $distinctOn6: [TeamMembersSelectColumn!]
    $limit6: Int
    $offset6: Int
    $orderBy6: [TeamMembersOrderBy!]
    $where6: TeamMembersBoolExp
    $_set: UsersSetInput
    $pkColumns: UsersPkColumnsInput!
  ) {
    updateUsersByPk(_set: $_set, pkColumns: $pkColumns) {
      createdAt
      deletedAt
      email
      firstName
      id
      imageFileName
      lastName
      password
      refreshToken
      teamMembersAggregate(
        distinctOn: $distinctOn
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        where: $where
      ) {
        aggregate {
          count(columns: $columns, distinct: $distinct)
        }
        nodes {
          createdAt
          deletedAt
          doingTaskId
          id
          lastWorkedAt
          role
          teamId
          updatedAt
          userId
          workStatus
        }
      }
      team_members(
        distinctOn: $distinctOn6
        limit: $limit6
        offset: $offset6
        orderBy: $orderBy6
        where: $where6
      ) {
        activities(
          distinctOn: $distinctOn1
          limit: $limit1
          offset: $offset1
          orderBy: $orderBy1
          where: $where1
        ) {
          createdAt
          id
          taskId
          teamId
          teamMemberId
          type
          updatedAt
          workTrackingId
        }
        createdAt
        deletedAt
        doingTask {
          createdAt
          deletedAt
          id
          lock
          name
          order
          projectId
          projectMemberId
          projectTaskTagId
          status
          teamId
          teamMemberId
          updatedAt
        }
        doingTaskId
        id
        lastWorkedAt
        projectMembers(
          distinctOn: $distinctOn2
          limit: $limit2
          offset: $offset2
          orderBy: $orderBy2
          where: $where2
        ) {
          createdAt
          deletedAt
          id
          projectId
          role
          teamId
          teamMemberId
          updatedAt
        }
        role
        tasks(
          distinctOn: $distinctOn3
          limit: $limit3
          offset: $offset3
          orderBy: $orderBy3
          where: $where3
        ) {
          createdAt
          deletedAt
          id
          lock
          name
          order
          projectId
          projectMemberId
          projectTaskTagId
          status
          teamId
          teamMemberId
          updatedAt
        }
        team {
          createdAt
          deletedAt
          enabledBlurBackground
          enabledSnapshot
          forceSso
          forceTwofa
          id
          imageFilePath
          name
          updatedAt
        }
        teamId
        teamMemberSnapshot {
          createdAt
          snapshotFilePath
          teamId
          teamMemberId
          updatedAt
        }
        updatedAt
        user {
          createdAt
          deletedAt
          email
          firstName
          id
          imageFileName
          lastName
          password
          refreshToken
          tutorialViewedAt
          twofaEnabled
          twofaSecret
          updatedAt
        }
        userId
        workStatus
        workTrackings(
          distinctOn: $distinctOn4
          limit: $limit4
          offset: $offset4
          orderBy: $orderBy4
          where: $where4
        ) {
          breakMinutes
          createdAt
          finishedAt
          id
          startedAt
          teamId
          teamMemberId
          updatedAt
        }
        works(
          distinctOn: $distinctOn5
          limit: $limit5
          offset: $offset5
          orderBy: $orderBy5
          where: $where5
        ) {
          breakMinutes
          createdAt
          finishedAt
          id
          startedAt
          teamId
          teamMemberId
          updatedAt
          workTrackingId
        }
      }
      tutorialViewedAt
      twofaEnabled
      twofaSecret
      updatedAt
    }
  }
`;
export const UpdateWorkTaskDocument = gql`
  mutation updateWorkTask($input: UpdateWorkTaskInput!) {
    updateWorkTask(input: $input) {
      result
    }
  }
`;
export const VerificateEmailForCreateUserDocument = gql`
  mutation verificateEmailForCreateUser(
    $input: VerificateEmailForCreateUserInput!
  ) {
    verificateEmailForCreateUser(input: $input) {
      result
    }
  }
`;
export const VerificateEmailForUpdateUserEmailDocument = gql`
  mutation verificateEmailForUpdateUserEmail(
    $input: VerificateEmailForUpdateUserEmailInput!
  ) {
    verificateEmailForUpdateUserEmail(input: $input) {
      result
    }
  }
`;
export const ActivitiesDocument = gql`
  query activities(
    $limit: Int
    $offset: Int
    $orderBy: [ActivitiesOrderBy!]
    $where: ActivitiesBoolExp
  ) {
    activities(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      ...ActivityAttributes
    }
  }
  ${ActivityAttributesFragmentDoc}
`;
export const BacklogIssuesDocument = gql`
  query backlogIssues($input: BacklogIssuesInput!) {
    backlogIssues(input: $input) {
      id
      name
    }
  }
`;
export const BacklogProjectsDocument = gql`
  query backlogProjects($input: BacklogProjectsInput!) {
    backlogProjects(input: $input) {
      id
      name
    }
  }
`;
export const ContractsDocument = gql`
  query contracts(
    $distinctOn: [ContractsSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [ContractsOrderBy!]
    $where: ContractsBoolExp
  ) {
    contracts(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      ...ContractsAttribute
      plan {
        ...PlansAttribute
      }
      team {
        ...TeamsAttribute
        teamMembersAggregate(where: { deletedAt: { _isNull: true } }) {
          aggregate {
            count
          }
        }
        projectsAggregate(
          where: { archived: { _eq: false }, deletedAt: { _isNull: true } }
        ) {
          aggregate {
            count
          }
        }
        stripeCustomer {
          ...StripeCustomersAttribute
        }
      }
    }
  }
  ${ContractsAttributeFragmentDoc}
  ${PlansAttributeFragmentDoc}
  ${TeamsAttributeFragmentDoc}
  ${StripeCustomersAttributeFragmentDoc}
`;
export const ContractsAggregateDocument = gql`
  query contractsAggregate($where: ContractsBoolExp) {
    contractsAggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const FindTeamMembersByUserIdDocument = gql`
  query findTeamMembersByUserId($userId: String!) {
    teamMembers(where: { userId: { _eq: $userId } }) {
      ...TeamMembersAttribute
      team {
        ...TeamsAttribute
      }
    }
  }
  ${TeamMembersAttributeFragmentDoc}
  ${TeamsAttributeFragmentDoc}
`;
export const GithubIssuesDocument = gql`
  query githubIssues($input: GithubIssuesInput!) {
    githubIssues(input: $input) {
      id
      name
    }
  }
`;
export const GithubRepositoriesDocument = gql`
  query githubRepositories($input: GithubRepositoriesInput!) {
    githubRepositories(input: $input) {
      id
      name
    }
  }
`;
export const GoogleCalendarEventDocument = gql`
  query googleCalendarEvent($input: GoogleCalendarEventInput!) {
    googleCalendarEvent(input: $input) {
      events {
        summary
      }
      nextPageToken
    }
  }
`;
export const NextInvoiceDocument = gql`
  query nextInvoice {
    nextInvoice {
      amount
      isTrial
      settleDate
    }
  }
`;
export const NextInvoicePreviewDocument = gql`
  query nextInvoicePreview($input: NextInvoicePreviewInput!) {
    nextInvoicePreview(input: $input) {
      amount
      proratedNumberOfDays
      prorationAmount
      settleDate
      isTrial
    }
  }
`;
export const PlansDocument = gql`
  query plans($where: PlansBoolExp) {
    plans(where: $where) {
      code
      createdAt
      id
      name
      paymentCycle
      price
      stripePriceId
      updatedAt
    }
  }
`;
export const ProjectAlertsDocument = gql`
  query projectAlerts($where: ProjectAlertsBoolExp) {
    projectAlerts(where: $where) {
      ...ProjectAlertAttributes
    }
  }
  ${ProjectAlertAttributesFragmentDoc}
`;
export const ProjectMembersDocument = gql`
  query projectMembers(
    $orderBy: [ProjectMembersOrderBy!]
    $where: ProjectMembersBoolExp
  ) {
    projectMembers(orderBy: $orderBy, where: $where) {
      ...ProjectMembersAttribute
    }
  }
  ${ProjectMembersAttributeFragmentDoc}
`;
export const ProjectTaskTagsDocument = gql`
  query projectTaskTags(
    $orderBy: [ProjectTaskTagsOrderBy!]
    $where: ProjectTaskTagsBoolExp
  ) {
    projectTaskTags(orderBy: $orderBy, where: $where) {
      ...ProjectTaskTagAttributes
    }
  }
  ${ProjectTaskTagAttributesFragmentDoc}
`;
export const ProjectTaskTagsByIdDocument = gql`
  query projectTaskTagsById($projectId: String!) {
    projectTaskTags(where: { projectId: { _eq: $projectId } }) {
      ...ProjectTaskTagAttributes
    }
  }
  ${ProjectTaskTagAttributesFragmentDoc}
`;
export const ProjectsDocument = gql`
  query projects($orderBy: [ProjectsOrderBy!], $where: ProjectsBoolExp) {
    projects(orderBy: $orderBy, where: $where) {
      ...ProjectsAttribute
    }
  }
  ${ProjectsAttributeFragmentDoc}
`;
export const ProjectsByTeamIdDocument = gql`
  query projectsByTeamId($teamId: String!) {
    projects(where: { teamId: { _eq: $teamId } }) {
      ...ProjectsAttribute
    }
  }
  ${ProjectsAttributeFragmentDoc}
`;
export const ReportDetailsDocument = gql`
  query reportDetails($input: ReportDetailsInput!) {
    reportDetails(input: $input) {
      minutes
      workId
      projectId
      projectName
      projectTaskTagId
      projectTaskTagName
      taskId
      taskName
      teamMemberId
      userId
      userName
      viewDate
    }
  }
`;
export const ReportSummariesDocument = gql`
  query reportSummaries($input: ReportSummariesInput!) {
    reportSummaries(input: $input) {
      minutes
      viewDate
    }
  }
`;
export const ReportSummaryPercentagesDocument = gql`
  query reportSummaryPercentages($input: ReportSummaryPercentagesInput!) {
    reportSummaryPercentages(input: $input) {
      minutes
      percentage
      title
    }
  }
`;
export const SettlementHistoriesDocument = gql`
  query settlementHistories(
    $distinctOn: [SettlementHistoriesSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [SettlementHistoriesOrderBy!]
    $where: SettlementHistoriesBoolExp
  ) {
    settlementHistories(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      ...SettlementHistoriesAttribute
    }
  }
  ${SettlementHistoriesAttributeFragmentDoc}
`;
export const TasksDocument = gql`
  query tasks($orderBy: [TasksOrderBy!], $where: TasksBoolExp) {
    tasks(orderBy: $orderBy, where: $where) {
      ...TaskAttributes
    }
  }
  ${TaskAttributesFragmentDoc}
`;
export const TeamAllowedIpAddressesDocument = gql`
  query teamAllowedIpAddresses(
    $distinctOn: [TeamAllowedIpAddressesSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [TeamAllowedIpAddressesOrderBy!]
    $where: TeamAllowedIpAddressesBoolExp
  ) {
    teamAllowedIpAddresses(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      ...TeamAllowedIpAddressesAttribute
    }
  }
  ${TeamAllowedIpAddressesAttributeFragmentDoc}
`;
export const TeamBacklogLinkageProjectsDocument = gql`
  query teamBacklogLinkageProjects(
    $distinctOn: [TeamBacklogLinkageProjectsSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [TeamBacklogLinkageProjectsOrderBy!]
    $where: TeamBacklogLinkageProjectsBoolExp
  ) {
    teamBacklogLinkageProjects(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      backlogProjectId
      createdAt
      id
      projectId
      teamId
      updatedAt
      linkId
      project {
        ...ProjectsAttribute
      }
    }
  }
  ${ProjectsAttributeFragmentDoc}
`;
export const TeamBacklogLinkagesDocument = gql`
  query teamBacklogLinkages(
    $distinctOn: [TeamBacklogLinkagesSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [TeamBacklogLinkagesOrderBy!]
    $where: TeamBacklogLinkagesBoolExp
  ) {
    teamBacklogLinkages(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      backlogUrl
      createdAt
      id
      teamId
      updatedAt
    }
  }
`;
export const TeamGithubLinkageProjectsDocument = gql`
  query teamGithubLinkageProjects(
    $distinctOn: [TeamGithubLinkageProjectsSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [TeamGithubLinkageProjectsOrderBy!]
    $where: TeamGithubLinkageProjectsBoolExp
  ) {
    teamGithubLinkageProjects(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      createdAt
      githubRepositoryId
      id
      projectId
      teamId
      updatedAt
      linkId
      project {
        ...ProjectsAttribute
      }
    }
  }
  ${ProjectsAttributeFragmentDoc}
`;
export const TeamGithubLinkagesDocument = gql`
  query teamGithubLinkages(
    $distinctOn: [TeamGithubLinkagesSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [TeamGithubLinkagesOrderBy!]
    $where: TeamGithubLinkagesBoolExp
  ) {
    teamGithubLinkages(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      ...TeamGithubLinkagesAttribute
    }
  }
  ${TeamGithubLinkagesAttributeFragmentDoc}
`;
export const TeamGoogleCalendarLinkagesDocument = gql`
  query teamGoogleCalendarLinkages(
    $distinctOn: [TeamGoogleCalendarLinkagesSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [TeamGoogleCalendarLinkagesOrderBy!]
    $where: TeamGoogleCalendarLinkagesBoolExp
  ) {
    teamGoogleCalendarLinkages(
      distinctOn: $distinctOn
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      createdAt
      id
      teamId
      updatedAt
    }
  }
`;
export const TeamMemberInvitationsDocument = gql`
  query teamMemberInvitations {
    teamMemberInvitations {
      ...TeamMemberInvitationsAttribute
    }
  }
  ${TeamMemberInvitationsAttributeFragmentDoc}
`;
export const TeamMembersDocument = gql`
  query teamMembers(
    $limit: Int
    $offset: Int
    $orderBy: [TeamMembersOrderBy!]
    $where: TeamMembersBoolExp
  ) {
    teamMembers(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      where: $where
    ) {
      ...TeamMembersAttribute
    }
  }
  ${TeamMembersAttributeFragmentDoc}
`;
export const TeamMembersByPkDocument = gql`
  query teamMembersByPk($id: String!) {
    teamMembersByPk(id: $id) {
      ...TeamMembersAttribute
    }
  }
  ${TeamMembersAttributeFragmentDoc}
`;
export const TeamsDocument = gql`
  query teams($where: TeamsBoolExp) {
    teams(where: $where) {
      ...TeamsAttribute
    }
  }
  ${TeamsAttributeFragmentDoc}
`;
export const TeamsByPkDocument = gql`
  query teamsByPk($id: String!) {
    teamsByPk(id: $id) {
      ...TeamsAttribute
    }
  }
  ${TeamsAttributeFragmentDoc}
`;
export const UsersByPkDocument = gql`
  query usersByPk($id: String!) {
    usersByPk(id: $id) {
      ...UsersAttribute
    }
  }
  ${UsersAttributeFragmentDoc}
`;
export const WorkTasksDocument = gql`
  query workTasks($where: WorkTasksBoolExp!) {
    workTasks(where: $where) {
      ...WorkTaskAttributes
    }
  }
  ${WorkTaskAttributesFragmentDoc}
`;
export const WorkTrackingsDocument = gql`
  query workTrackings($where: WorkTrackingsBoolExp) {
    workTrackings(where: $where) {
      ...WorkTrackingsAttribute
    }
  }
  ${WorkTrackingsAttributeFragmentDoc}
`;
export const WorksDocument = gql`
  query works($where: WorksBoolExp!) {
    works(where: $where) {
      ...WorkAttributes
    }
  }
  ${WorkAttributesFragmentDoc}
`;
export const WorksCalendarDocument = gql`
  query worksCalendar($input: WorksCalendarInput!) {
    worksCalendar(input: $input) {
      breakTime
      finishedTime
      startedTime
      workDate
      workTime
      workTimeMinutes
    }
  }
`;
export const SubscribeActivitiesStreamDocument = gql`
  subscription subscribeActivitiesStream(
    $batchSize: Int!
    $cursor: [ActivitiesStreamCursorInput]!
    $where: ActivitiesBoolExp
  ) {
    activitiesStream(batchSize: $batchSize, cursor: $cursor, where: $where) {
      ...ActivityAttributes
    }
  }
  ${ActivityAttributesFragmentDoc}
`;
export const SubscribeTeamMembersDocument = gql`
  subscription subscribeTeamMembers($where: TeamMembersBoolExp) {
    teamMembers(where: $where) {
      ...TeamMembersSubscriptionAttribute
    }
  }
  ${TeamMembersSubscriptionAttributeFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    acceptTeamMemberInvitation(
      variables: AcceptTeamMemberInvitationMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AcceptTeamMemberInvitationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AcceptTeamMemberInvitationMutation>(
            AcceptTeamMemberInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "acceptTeamMemberInvitation",
        "mutation"
      );
    },
    approveGitHubInstallation(
      variables: ApproveGitHubInstallationMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ApproveGitHubInstallationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ApproveGitHubInstallationMutation>(
            ApproveGitHubInstallationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "approveGitHubInstallation",
        "mutation"
      );
    },
    archiveProject(
      variables: ArchiveProjectMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ArchiveProjectMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ArchiveProjectMutation>(
            ArchiveProjectDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "archiveProject",
        "mutation"
      );
    },
    authenticateTwoFA(
      variables: AuthenticateTwoFaMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AuthenticateTwoFaMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuthenticateTwoFaMutation>(
            AuthenticateTwoFaDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "authenticateTwoFA",
        "mutation"
      );
    },
    createBacklogAuthzUrl(
      variables: CreateBacklogAuthzUrlMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateBacklogAuthzUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateBacklogAuthzUrlMutation>(
            CreateBacklogAuthzUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createBacklogAuthzUrl",
        "mutation"
      );
    },
    createGitHubAuthzUrl(
      variables?: CreateGitHubAuthzUrlMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateGitHubAuthzUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateGitHubAuthzUrlMutation>(
            CreateGitHubAuthzUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createGitHubAuthzUrl",
        "mutation"
      );
    },
    createGitHubReAuthzUrl(
      variables?: CreateGitHubReAuthzUrlMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateGitHubReAuthzUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateGitHubReAuthzUrlMutation>(
            CreateGitHubReAuthzUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createGitHubReAuthzUrl",
        "mutation"
      );
    },
    createGoogleCalendarAuthzUrl(
      variables?: CreateGoogleCalendarAuthzUrlMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateGoogleCalendarAuthzUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateGoogleCalendarAuthzUrlMutation>(
            CreateGoogleCalendarAuthzUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createGoogleCalendarAuthzUrl",
        "mutation"
      );
    },
    createGoogleLoginAuthzUrl(
      variables: CreateGoogleLoginAuthzUrlMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateGoogleLoginAuthzUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateGoogleLoginAuthzUrlMutation>(
            CreateGoogleLoginAuthzUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createGoogleLoginAuthzUrl",
        "mutation"
      );
    },
    createMicrosoftLoginAuthzUrl(
      variables: CreateMicrosoftLoginAuthzUrlMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateMicrosoftLoginAuthzUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateMicrosoftLoginAuthzUrlMutation>(
            CreateMicrosoftLoginAuthzUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createMicrosoftLoginAuthzUrl",
        "mutation"
      );
    },
    createProject(
      variables: CreateProjectMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateProjectMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateProjectMutation>(
            CreateProjectDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createProject",
        "mutation"
      );
    },
    createSetupSession(
      variables?: CreateSetupSessionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateSetupSessionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateSetupSessionMutation>(
            CreateSetupSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createSetupSession",
        "mutation"
      );
    },
    createSubscriptionSession(
      variables: CreateSubscriptionSessionMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateSubscriptionSessionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateSubscriptionSessionMutation>(
            CreateSubscriptionSessionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "createSubscriptionSession",
        "mutation"
      );
    },
    createTeam(
      variables: CreateTeamMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateTeamMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateTeamMutation>(CreateTeamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createTeam",
        "mutation"
      );
    },
    createUser(
      variables: CreateUserMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateUserMutation>(CreateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createUser",
        "mutation"
      );
    },
    deleteTeam(
      variables?: DeleteTeamMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteTeamMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTeamMutation>(DeleteTeamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "deleteTeam",
        "mutation"
      );
    },
    deleteTeamBacklogLinkageProjects(
      variables: DeleteTeamBacklogLinkageProjectsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteTeamBacklogLinkageProjectsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTeamBacklogLinkageProjectsMutation>(
            DeleteTeamBacklogLinkageProjectsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "deleteTeamBacklogLinkageProjects",
        "mutation"
      );
    },
    deleteTeamBacklogLinkages(
      variables: DeleteTeamBacklogLinkagesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteTeamBacklogLinkagesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTeamBacklogLinkagesMutation>(
            DeleteTeamBacklogLinkagesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "deleteTeamBacklogLinkages",
        "mutation"
      );
    },
    deleteTeamGithubLinkageProjects(
      variables: DeleteTeamGithubLinkageProjectsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteTeamGithubLinkageProjectsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTeamGithubLinkageProjectsMutation>(
            DeleteTeamGithubLinkageProjectsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "deleteTeamGithubLinkageProjects",
        "mutation"
      );
    },
    deleteTeamGoogleCalendarLinkages(
      variables: DeleteTeamGoogleCalendarLinkagesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteTeamGoogleCalendarLinkagesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTeamGoogleCalendarLinkagesMutation>(
            DeleteTeamGoogleCalendarLinkagesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "deleteTeamGoogleCalendarLinkages",
        "mutation"
      );
    },
    deleteTeamMemberInvitationsByPk(
      variables: DeleteTeamMemberInvitationsByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteTeamMemberInvitationsByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteTeamMemberInvitationsByPkMutation>(
            DeleteTeamMemberInvitationsByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "deleteTeamMemberInvitationsByPk",
        "mutation"
      );
    },
    deleteUser(
      variables?: DeleteUserMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DeleteUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteUserMutation>(DeleteUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "deleteUser",
        "mutation"
      );
    },
    exportTeamReport(
      variables: ExportTeamReportMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ExportTeamReportMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExportTeamReportMutation>(
            ExportTeamReportDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "exportTeamReport",
        "mutation"
      );
    },
    exportWorks(
      variables: ExportWorksMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ExportWorksMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExportWorksMutation>(ExportWorksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "exportWorks",
        "mutation"
      );
    },
    finishWork(
      variables: FinishWorkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<FinishWorkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FinishWorkMutation>(FinishWorkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "finishWork",
        "mutation"
      );
    },
    insertEnqueteAnswer(
      variables: InsertEnqueteAnswerMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertEnqueteAnswerMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertEnqueteAnswerMutation>(
            InsertEnqueteAnswerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertEnqueteAnswer",
        "mutation"
      );
    },
    insertProjectAlertsOne(
      variables: InsertProjectAlertsOneMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertProjectAlertsOneMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertProjectAlertsOneMutation>(
            InsertProjectAlertsOneDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertProjectAlertsOne",
        "mutation"
      );
    },
    insertProjectMembers(
      variables: InsertProjectMembersMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertProjectMembersMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertProjectMembersMutation>(
            InsertProjectMembersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertProjectMembers",
        "mutation"
      );
    },
    insertProjectMembersOne(
      variables: InsertProjectMembersOneMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertProjectMembersOneMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertProjectMembersOneMutation>(
            InsertProjectMembersOneDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertProjectMembersOne",
        "mutation"
      );
    },
    insertProjectTaskTags(
      variables: InsertProjectTaskTagsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertProjectTaskTagsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertProjectTaskTagsMutation>(
            InsertProjectTaskTagsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertProjectTaskTags",
        "mutation"
      );
    },
    insertProjectTaskTagsOne(
      variables: InsertProjectTaskTagsOneMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertProjectTaskTagsOneMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertProjectTaskTagsOneMutation>(
            InsertProjectTaskTagsOneDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertProjectTaskTagsOne",
        "mutation"
      );
    },
    insertProjectsOne(
      variables: InsertProjectsOneMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertProjectsOneMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertProjectsOneMutation>(
            InsertProjectsOneDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertProjectsOne",
        "mutation"
      );
    },
    insertTasks(
      variables: InsertTasksMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertTasksMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertTasksMutation>(InsertTasksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "insertTasks",
        "mutation"
      );
    },
    insertTeamAllowedIpAddresses(
      variables: InsertTeamAllowedIpAddressesMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertTeamAllowedIpAddressesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertTeamAllowedIpAddressesMutation>(
            InsertTeamAllowedIpAddressesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertTeamAllowedIpAddresses",
        "mutation"
      );
    },
    insertTeamBacklogLinkageProjects(
      variables: InsertTeamBacklogLinkageProjectsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertTeamBacklogLinkageProjectsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertTeamBacklogLinkageProjectsMutation>(
            InsertTeamBacklogLinkageProjectsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertTeamBacklogLinkageProjects",
        "mutation"
      );
    },
    insertTeamGithubLinkageProjects(
      variables: InsertTeamGithubLinkageProjectsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertTeamGithubLinkageProjectsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertTeamGithubLinkageProjectsMutation>(
            InsertTeamGithubLinkageProjectsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertTeamGithubLinkageProjects",
        "mutation"
      );
    },
    insertTeamMemberInvitations(
      variables: InsertTeamMemberInvitationsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertTeamMemberInvitationsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertTeamMemberInvitationsMutation>(
            InsertTeamMemberInvitationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertTeamMemberInvitations",
        "mutation"
      );
    },
    insertTeamMemberInvitationsOne(
      variables: InsertTeamMemberInvitationsOneMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<InsertTeamMemberInvitationsOneMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<InsertTeamMemberInvitationsOneMutation>(
            InsertTeamMemberInvitationsOneDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "insertTeamMemberInvitationsOne",
        "mutation"
      );
    },
    linkBacklog(
      variables: LinkBacklogMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LinkBacklogMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LinkBacklogMutation>(LinkBacklogDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "linkBacklog",
        "mutation"
      );
    },
    linkGitHub(
      variables: LinkGitHubMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LinkGitHubMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LinkGitHubMutation>(LinkGitHubDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "linkGitHub",
        "mutation"
      );
    },
    linkGoogleCalendar(
      variables: LinkGoogleCalendarMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LinkGoogleCalendarMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LinkGoogleCalendarMutation>(
            LinkGoogleCalendarDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "linkGoogleCalendar",
        "mutation"
      );
    },
    login(
      variables: LoginMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginMutation>(LoginDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "login",
        "mutation"
      );
    },
    loginByGoogle(
      variables: LoginByGoogleMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoginByGoogleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginByGoogleMutation>(
            LoginByGoogleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "loginByGoogle",
        "mutation"
      );
    },
    loginByMicrosoft(
      variables: LoginByMicrosoftMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoginByMicrosoftMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginByMicrosoftMutation>(
            LoginByMicrosoftDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "loginByMicrosoft",
        "mutation"
      );
    },
    loginByRefreshToken(
      variables: LoginByRefreshTokenMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<LoginByRefreshTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginByRefreshTokenMutation>(
            LoginByRefreshTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "loginByRefreshToken",
        "mutation"
      );
    },
    reLinkGitHub(
      variables: ReLinkGitHubMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ReLinkGitHubMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReLinkGitHubMutation>(
            ReLinkGitHubDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "reLinkGitHub",
        "mutation"
      );
    },
    removeProjectMember(
      variables: RemoveProjectMemberMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RemoveProjectMemberMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveProjectMemberMutation>(
            RemoveProjectMemberDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "removeProjectMember",
        "mutation"
      );
    },
    removeTeamAllowedIpAddress(
      variables: RemoveTeamAllowedIpAddressMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RemoveTeamAllowedIpAddressMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveTeamAllowedIpAddressMutation>(
            RemoveTeamAllowedIpAddressDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "removeTeamAllowedIpAddress",
        "mutation"
      );
    },
    removeTeamMember(
      variables: RemoveTeamMemberMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RemoveTeamMemberMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RemoveTeamMemberMutation>(
            RemoveTeamMemberDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "removeTeamMember",
        "mutation"
      );
    },
    resetPassword(
      variables: ResetPasswordMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ResetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResetPasswordMutation>(
            ResetPasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "resetPassword",
        "mutation"
      );
    },
    resumeWork(
      variables?: ResumeWorkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ResumeWorkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResumeWorkMutation>(ResumeWorkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "resumeWork",
        "mutation"
      );
    },
    sendContactMail(
      variables: SendContactMailMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SendContactMailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SendContactMailMutation>(
            SendContactMailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "sendContactMail",
        "mutation"
      );
    },
    startWork(
      variables?: StartWorkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<StartWorkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StartWorkMutation>(StartWorkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "startWork",
        "mutation"
      );
    },
    suspendWork(
      variables?: SuspendWorkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SuspendWorkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SuspendWorkMutation>(SuspendWorkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "suspendWork",
        "mutation"
      );
    },
    unLinkGitHub(
      variables: UnLinkGitHubMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UnLinkGitHubMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UnLinkGitHubMutation>(
            UnLinkGitHubDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "unLinkGitHub",
        "mutation"
      );
    },
    updateContract(
      variables: UpdateContractMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateContractMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateContractMutation>(
            UpdateContractDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateContract",
        "mutation"
      );
    },
    updateCustomer(
      variables: UpdateCustomerMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateCustomerMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCustomerMutation>(
            UpdateCustomerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateCustomer",
        "mutation"
      );
    },
    updateProjectAlertsByPk(
      variables: UpdateProjectAlertsByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateProjectAlertsByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProjectAlertsByPkMutation>(
            UpdateProjectAlertsByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateProjectAlertsByPk",
        "mutation"
      );
    },
    updateProjectMembersByPk(
      variables: UpdateProjectMembersByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateProjectMembersByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProjectMembersByPkMutation>(
            UpdateProjectMembersByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateProjectMembersByPk",
        "mutation"
      );
    },
    updateProjectTaskTagsByPk(
      variables: UpdateProjectTaskTagsByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateProjectTaskTagsByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProjectTaskTagsByPkMutation>(
            UpdateProjectTaskTagsByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateProjectTaskTagsByPk",
        "mutation"
      );
    },
    updateProjectTaskTagsMany(
      variables: UpdateProjectTaskTagsManyMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateProjectTaskTagsManyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProjectTaskTagsManyMutation>(
            UpdateProjectTaskTagsManyDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateProjectTaskTagsMany",
        "mutation"
      );
    },
    updateProjectsByPk(
      variables: UpdateProjectsByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateProjectsByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProjectsByPkMutation>(
            UpdateProjectsByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateProjectsByPk",
        "mutation"
      );
    },
    updateTasks(
      variables: UpdateTasksMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTasksMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTasksMutation>(UpdateTasksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateTasks",
        "mutation"
      );
    },
    updateTasksByPk(
      variables: UpdateTasksByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTasksByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTasksByPkMutation>(
            UpdateTasksByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTasksByPk",
        "mutation"
      );
    },
    updateTasksMany(
      variables: UpdateTasksManyMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTasksManyMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTasksManyMutation>(
            UpdateTasksManyDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTasksMany",
        "mutation"
      );
    },
    updateTeamAllowedIpAddress(
      variables: UpdateTeamAllowedIpAddressMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTeamAllowedIpAddressMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTeamAllowedIpAddressMutation>(
            UpdateTeamAllowedIpAddressDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTeamAllowedIpAddress",
        "mutation"
      );
    },
    updateTeamMemberInvitations(
      variables: UpdateTeamMemberInvitationsMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTeamMemberInvitationsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTeamMemberInvitationsMutation>(
            UpdateTeamMemberInvitationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTeamMemberInvitations",
        "mutation"
      );
    },
    updateTeamMemberSnapshotsByPk(
      variables: UpdateTeamMemberSnapshotsByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTeamMemberSnapshotsByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTeamMemberSnapshotsByPkMutation>(
            UpdateTeamMemberSnapshotsByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTeamMemberSnapshotsByPk",
        "mutation"
      );
    },
    updateTeamMembers(
      variables: UpdateTeamMembersMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTeamMembersMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTeamMembersMutation>(
            UpdateTeamMembersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTeamMembers",
        "mutation"
      );
    },
    updateTeamsByPk(
      variables: UpdateTeamsByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTeamsByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTeamsByPkMutation>(
            UpdateTeamsByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTeamsByPk",
        "mutation"
      );
    },
    updateTwoFASetting(
      variables: UpdateTwoFaSettingMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateTwoFaSettingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTwoFaSettingMutation>(
            UpdateTwoFaSettingDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateTwoFASetting",
        "mutation"
      );
    },
    updateUserEmail(
      variables: UpdateUserEmailMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateUserEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserEmailMutation>(
            UpdateUserEmailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateUserEmail",
        "mutation"
      );
    },
    updateUserPassword(
      variables: UpdateUserPasswordMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateUserPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserPasswordMutation>(
            UpdateUserPasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateUserPassword",
        "mutation"
      );
    },
    updateUserPasswordForce(
      variables: UpdateUserPasswordForceMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateUserPasswordForceMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserPasswordForceMutation>(
            UpdateUserPasswordForceDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateUserPasswordForce",
        "mutation"
      );
    },
    updateUserProfileByPk(
      variables: UpdateUserProfileByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateUserProfileByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserProfileByPkMutation>(
            UpdateUserProfileByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateUserProfileByPk",
        "mutation"
      );
    },
    updateUsersByPk(
      variables: UpdateUsersByPkMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateUsersByPkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUsersByPkMutation>(
            UpdateUsersByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateUsersByPk",
        "mutation"
      );
    },
    updateWorkTask(
      variables: UpdateWorkTaskMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UpdateWorkTaskMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateWorkTaskMutation>(
            UpdateWorkTaskDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateWorkTask",
        "mutation"
      );
    },
    verificateEmailForCreateUser(
      variables: VerificateEmailForCreateUserMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<VerificateEmailForCreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VerificateEmailForCreateUserMutation>(
            VerificateEmailForCreateUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "verificateEmailForCreateUser",
        "mutation"
      );
    },
    verificateEmailForUpdateUserEmail(
      variables: VerificateEmailForUpdateUserEmailMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<VerificateEmailForUpdateUserEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VerificateEmailForUpdateUserEmailMutation>(
            VerificateEmailForUpdateUserEmailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "verificateEmailForUpdateUserEmail",
        "mutation"
      );
    },
    activities(
      variables?: ActivitiesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ActivitiesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ActivitiesQuery>(ActivitiesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "activities",
        "query"
      );
    },
    backlogIssues(
      variables: BacklogIssuesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<BacklogIssuesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BacklogIssuesQuery>(BacklogIssuesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "backlogIssues",
        "query"
      );
    },
    backlogProjects(
      variables: BacklogProjectsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<BacklogProjectsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BacklogProjectsQuery>(
            BacklogProjectsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "backlogProjects",
        "query"
      );
    },
    contracts(
      variables?: ContractsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ContractsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ContractsQuery>(ContractsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "contracts",
        "query"
      );
    },
    contractsAggregate(
      variables?: ContractsAggregateQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ContractsAggregateQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ContractsAggregateQuery>(
            ContractsAggregateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "contractsAggregate",
        "query"
      );
    },
    findTeamMembersByUserId(
      variables: FindTeamMembersByUserIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<FindTeamMembersByUserIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FindTeamMembersByUserIdQuery>(
            FindTeamMembersByUserIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "findTeamMembersByUserId",
        "query"
      );
    },
    githubIssues(
      variables: GithubIssuesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GithubIssuesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GithubIssuesQuery>(GithubIssuesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "githubIssues",
        "query"
      );
    },
    githubRepositories(
      variables: GithubRepositoriesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GithubRepositoriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GithubRepositoriesQuery>(
            GithubRepositoriesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "githubRepositories",
        "query"
      );
    },
    googleCalendarEvent(
      variables: GoogleCalendarEventQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GoogleCalendarEventQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GoogleCalendarEventQuery>(
            GoogleCalendarEventDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "googleCalendarEvent",
        "query"
      );
    },
    nextInvoice(
      variables?: NextInvoiceQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<NextInvoiceQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NextInvoiceQuery>(NextInvoiceDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "nextInvoice",
        "query"
      );
    },
    nextInvoicePreview(
      variables: NextInvoicePreviewQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<NextInvoicePreviewQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NextInvoicePreviewQuery>(
            NextInvoicePreviewDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "nextInvoicePreview",
        "query"
      );
    },
    plans(
      variables?: PlansQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PlansQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PlansQuery>(PlansDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "plans",
        "query"
      );
    },
    projectAlerts(
      variables?: ProjectAlertsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProjectAlertsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectAlertsQuery>(ProjectAlertsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "projectAlerts",
        "query"
      );
    },
    projectMembers(
      variables?: ProjectMembersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProjectMembersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectMembersQuery>(
            ProjectMembersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "projectMembers",
        "query"
      );
    },
    projectTaskTags(
      variables?: ProjectTaskTagsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProjectTaskTagsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectTaskTagsQuery>(
            ProjectTaskTagsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "projectTaskTags",
        "query"
      );
    },
    projectTaskTagsById(
      variables: ProjectTaskTagsByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProjectTaskTagsByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectTaskTagsByIdQuery>(
            ProjectTaskTagsByIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "projectTaskTagsById",
        "query"
      );
    },
    projects(
      variables?: ProjectsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProjectsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectsQuery>(ProjectsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "projects",
        "query"
      );
    },
    projectsByTeamId(
      variables: ProjectsByTeamIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProjectsByTeamIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectsByTeamIdQuery>(
            ProjectsByTeamIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "projectsByTeamId",
        "query"
      );
    },
    reportDetails(
      variables: ReportDetailsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ReportDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReportDetailsQuery>(ReportDetailsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "reportDetails",
        "query"
      );
    },
    reportSummaries(
      variables: ReportSummariesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ReportSummariesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReportSummariesQuery>(
            ReportSummariesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "reportSummaries",
        "query"
      );
    },
    reportSummaryPercentages(
      variables: ReportSummaryPercentagesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ReportSummaryPercentagesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReportSummaryPercentagesQuery>(
            ReportSummaryPercentagesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "reportSummaryPercentages",
        "query"
      );
    },
    settlementHistories(
      variables?: SettlementHistoriesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SettlementHistoriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SettlementHistoriesQuery>(
            SettlementHistoriesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "settlementHistories",
        "query"
      );
    },
    tasks(
      variables?: TasksQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TasksQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TasksQuery>(TasksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "tasks",
        "query"
      );
    },
    teamAllowedIpAddresses(
      variables?: TeamAllowedIpAddressesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamAllowedIpAddressesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamAllowedIpAddressesQuery>(
            TeamAllowedIpAddressesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamAllowedIpAddresses",
        "query"
      );
    },
    teamBacklogLinkageProjects(
      variables?: TeamBacklogLinkageProjectsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamBacklogLinkageProjectsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamBacklogLinkageProjectsQuery>(
            TeamBacklogLinkageProjectsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamBacklogLinkageProjects",
        "query"
      );
    },
    teamBacklogLinkages(
      variables?: TeamBacklogLinkagesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamBacklogLinkagesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamBacklogLinkagesQuery>(
            TeamBacklogLinkagesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamBacklogLinkages",
        "query"
      );
    },
    teamGithubLinkageProjects(
      variables?: TeamGithubLinkageProjectsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamGithubLinkageProjectsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamGithubLinkageProjectsQuery>(
            TeamGithubLinkageProjectsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamGithubLinkageProjects",
        "query"
      );
    },
    teamGithubLinkages(
      variables?: TeamGithubLinkagesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamGithubLinkagesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamGithubLinkagesQuery>(
            TeamGithubLinkagesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamGithubLinkages",
        "query"
      );
    },
    teamGoogleCalendarLinkages(
      variables?: TeamGoogleCalendarLinkagesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamGoogleCalendarLinkagesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamGoogleCalendarLinkagesQuery>(
            TeamGoogleCalendarLinkagesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamGoogleCalendarLinkages",
        "query"
      );
    },
    teamMemberInvitations(
      variables?: TeamMemberInvitationsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamMemberInvitationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamMemberInvitationsQuery>(
            TeamMemberInvitationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamMemberInvitations",
        "query"
      );
    },
    teamMembers(
      variables?: TeamMembersQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamMembersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamMembersQuery>(TeamMembersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "teamMembers",
        "query"
      );
    },
    teamMembersByPk(
      variables: TeamMembersByPkQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamMembersByPkQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamMembersByPkQuery>(
            TeamMembersByPkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "teamMembersByPk",
        "query"
      );
    },
    teams(
      variables?: TeamsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamsQuery>(TeamsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "teams",
        "query"
      );
    },
    teamsByPk(
      variables: TeamsByPkQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TeamsByPkQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamsByPkQuery>(TeamsByPkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "teamsByPk",
        "query"
      );
    },
    usersByPk(
      variables: UsersByPkQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UsersByPkQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UsersByPkQuery>(UsersByPkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "usersByPk",
        "query"
      );
    },
    workTasks(
      variables: WorkTasksQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<WorkTasksQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WorkTasksQuery>(WorkTasksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "workTasks",
        "query"
      );
    },
    workTrackings(
      variables?: WorkTrackingsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<WorkTrackingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WorkTrackingsQuery>(WorkTrackingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "workTrackings",
        "query"
      );
    },
    works(
      variables: WorksQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<WorksQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WorksQuery>(WorksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "works",
        "query"
      );
    },
    worksCalendar(
      variables: WorksCalendarQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<WorksCalendarQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WorksCalendarQuery>(WorksCalendarDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "worksCalendar",
        "query"
      );
    },
    subscribeActivitiesStream(
      variables: SubscribeActivitiesStreamSubscriptionVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SubscribeActivitiesStreamSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeActivitiesStreamSubscription>(
            SubscribeActivitiesStreamDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "subscribeActivitiesStream",
        "subscription"
      );
    },
    subscribeTeamMembers(
      variables?: SubscribeTeamMembersSubscriptionVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SubscribeTeamMembersSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeTeamMembersSubscription>(
            SubscribeTeamMembersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "subscribeTeamMembers",
        "subscription"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
