import { useWorkTrackingQuery } from "@/hooks/queries/useWorkTrackingQuery";
import { WorkTrackingView } from "@/hooks/queries/views/WorkTrackingView";
import { FinishWorkInput } from "@/libs/api/generated/types";
import { useForm } from "@mantine/form";
import { useCallback, useEffect, useMemo, useState } from "react";

import { TaskStatus } from "@/libs/api/generated/enum";
import { secondsToHHMM, timeStringToMinutes } from "@/utils/date";
import { closeAllModals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { useTasksQuery } from "../queries/useTasksQuery";
import { useWorks } from "./useWorks";

const initBreakStartedTime = "12:00";
const initBreakFinishedTime = "13:00";

type EndOfWorkForm = Pick<
  WorkTrackingView,
  "startedTime" | "finishedTime" | "breakTimes" | "tasks"
>;

export const useEndOfWorks = (teamMemberId: string) => {
  const { workTracking, workTrackingLoading, mutateWorkTracking } =
    useWorkTrackingQuery(teamMemberId);
  const { finishWork } = useWorks();
  const {
    doneTaskContainer,
    suspendTaskContainer,
    todoTaskContainer,
    mutateTasks,
  } = useTasksQuery(teamMemberId);

  const [submitting, setSubmitting] = useState(false);
  const [isFormValueSetted, setIsFormValueSetted] = useState(false);

  // startedDateView: YYYY/MM/DD(ddd) の形式
  const startedDateView = useMemo(() => {
    if (!workTracking) return "";
    return workTracking.startedDate;
  }, [workTracking]);

  // startedDate: YYYY-MM-DD の形式
  const startedDate = useMemo(() => {
    if (!workTracking) return "";
    return dayjs(workTracking.raw.startedAt).format("YYYY-MM-DD");
  }, [workTracking]);

  const form = useForm<EndOfWorkForm>({
    initialValues: {
      startedTime: "",
      finishedTime: "",
      breakTimes: [],
      tasks: [],
    },
  });

  useEffect(() => {
    if (!workTracking || isFormValueSetted) return;
    const tasks = [
      ...doneTaskContainer.tasks,
      ...suspendTaskContainer.tasks,
      ...todoTaskContainer.tasks,
    ];

    form.setValues({
      startedTime: workTracking.startedTime,
      finishedTime: workTracking.finishedTime,
      breakTimes: workTracking.breakTimes,
      tasks: tasks.map((task) => {
        const workingTask = workTracking.tasks.find(
          (_task) => _task.id === task.id
        );
        return {
          id: task.id,
          name: task.name,
          projectName: task.projectName,
          projectTagName: task.tag,
          status: task.status as TaskStatus,
          workTime: workingTask ? workingTask.workTime : "00:00",
          workTimeSecond: workingTask ? workingTask.workTimeSecond : 0,
        };
      }),
    });

    // フォームにworkTrackingの値が度セットされた以降は値を更新しない
    // 別タブを開く等でフォーカスが切り替わり、入力値がリセットされるのを防ぐため
    setIsFormValueSetted(true);

    // formは初期化時のみに実行するため依存関係から外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doneTaskContainer,
    suspendTaskContainer,
    todoTaskContainer,
    workTracking,
  ]);

  // fromとtoの日時間の差分を分単位で返す
  const diffTime = useCallback(
    ({ fromTime, toTime }: { fromTime: string; toTime: string }) => {
      const dayjsFromTime = dayjs(`${startedDate} ${fromTime}`);
      let dayjsToTime = dayjs(`${startedDate} ${toTime}`);

      // ライブラリの制約で24時間以上を扱えないため、開始時刻よりも前の終了時刻の場合は1日を足す
      // ２日間働きっぱなしのようなケースは想定しない。
      // 例: 1時00分 → 25時00分
      if (dayjsToTime.isBefore(dayjsFromTime)) {
        dayjsToTime = dayjsToTime.add(1, "day");
      }

      return dayjsToTime.diff(dayjsFromTime, "minutes", true);
    },
    [startedDate]
  );

  // 作業時間（作業終了時間 - 作業開始時間）
  const attendanceDiffTime = useMemo(() => {
    return diffTime({
      fromTime: form.values.startedTime,
      toTime: form.values.finishedTime,
    });
  }, [diffTime, form.values.startedTime, form.values.finishedTime]);

  // 休憩時間（休憩終了時間 - 休憩開始時間）
  const breakDiffTimes = useMemo(() => {
    const result = form.values.breakTimes.map((breakTime) =>
      diffTime({
        fromTime: breakTime.startedTime,
        toTime: breakTime.finishedTime,
      })
    );
    return result;
  }, [diffTime, form.values.breakTimes]);

  // 作業時間の計算結果
  const attendanceTimeResult = useMemo(() => {
    // 休憩時間の合計
    const totalBreakTime: number = form.values.breakTimes.reduce(
      (total, breakTime) => {
        const breakTimeDiff = diffTime({
          fromTime: breakTime.startedTime,
          toTime: breakTime.finishedTime,
        });
        return total + breakTimeDiff;
      },
      0
    );

    // 作業時間の合計
    const totalWorkTime = attendanceDiffTime - totalBreakTime;

    // タスク作業時間の合計
    const totalTaskTime = form.values.tasks.reduce((total, task) => {
      return total + timeStringToMinutes(task.workTime);
    }, 0);

    return {
      totalWorkTime: totalWorkTime,
      totalTaskTime: totalTaskTime,
      timeDifference: totalWorkTime - totalTaskTime,
    };
  }, [attendanceDiffTime, diffTime, form.values.breakTimes, form.values.tasks]);

  const diffTimeAdjustment = useCallback(
    (index: number) => () => {
      form.setValues({
        ...form.values,
        tasks: form.values.tasks.map((task, i) => {
          if (i === index) {
            const diffSecond = attendanceTimeResult.timeDifference * 60;
            const taskWorkTimeSecond = timeStringToMinutes(task.workTime) * 60;
            const workTimeSecond = taskWorkTimeSecond + diffSecond;
            const workTime = secondsToHHMM(workTimeSecond);
            const _task = {
              ...task,
              workTime,
              workTimeSecond,
            };
            return _task;
          } else {
            return task;
          }
        }),
      });
    },
    [attendanceTimeResult, form]
  );

  // 休憩フォームの追加
  const addBreakTime = useCallback(() => {
    form.setValues({
      ...form.values,
      breakTimes: [
        ...form.values.breakTimes,
        {
          startedTime: initBreakStartedTime,
          finishedTime: initBreakFinishedTime,
        },
      ],
    });
  }, [form]);

  // 休憩フォームの削除
  const deleteBreakTime = useCallback(
    (index: number) => {
      form.setValues({
        ...form.values,
        breakTimes: form.values.breakTimes.filter((_, i) => index !== i),
      });
    },
    [form]
  );

  // 作業時間 - 休憩時間 の合計が タスク作業時間 と一致するか
  const isEndOfWorkValid = useMemo(() => {
    return (
      attendanceTimeResult.totalWorkTime >= 0 &&
      attendanceTimeResult.totalTaskTime >= 0 &&
      attendanceTimeResult.timeDifference === 0
    );
  }, [attendanceTimeResult]);

  // 作業終了ボタンのサブミット
  const onSubmit = useCallback(async () => {
    const formattedDateTime = (startedTime: string, finishedTime: string) => {
      const dayjsStartedTime = dayjs(`${startedDate} ${startedTime}`);
      let dayjsFinishedTime = dayjs(`${startedDate} ${finishedTime}`);
      if (dayjsFinishedTime.isBefore(dayjsStartedTime)) {
        dayjsFinishedTime = dayjsFinishedTime.add(1, "day");
      }
      const dayjsFormat = "YYYY-MM-DD HH:mm:ss";
      return {
        startedAt: dayjsStartedTime.format(dayjsFormat),
        finishedAt: dayjsFinishedTime.format(dayjsFormat),
      };
    };

    const input: FinishWorkInput = {
      breaks: form.values.breakTimes.map((breakTime) =>
        formattedDateTime(breakTime.startedTime, breakTime.finishedTime)
      ),
      ...formattedDateTime(form.values.startedTime, form.values.finishedTime),
      tasks: form.values.tasks.map((task) => ({
        taskId: task.id,
        totalMinutes: timeStringToMinutes(task.workTime),
      })),
    };

    try {
      setSubmitting(true);
      await finishWork({ input });
      notifications.show({
        title: "🚪 作業終了しました",
        message: "",
      });
      await mutateWorkTracking();
      await mutateTasks();
      closeAllModals();
    } finally {
      setSubmitting(false);
    }
  }, [finishWork, form, mutateTasks, mutateWorkTracking, startedDate]);

  return {
    form,
    workTrackingsLoading: workTrackingLoading,
    startedDateView,
    attendanceTimeResult,
    isEndOfWorkValid,
    submitting,
    attendanceDiffTime,
    breakDiffTimes,
    onSubmit,
    addBreakTime,
    deleteBreakTime,
    diffTime,
    diffTimeAdjustment,
  };
};
