import { useDevice } from "@/hooks/features/useDevice";
import { Button, useMantineTheme } from "@mantine/core";

export const EndOfWorkButton = ({
  loading,
  disabled,
  onClick,
}: {
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
}) => {
  const theme = useMantineTheme();
  const { isTabletOrSmartphone } = useDevice();
  return (
    <Button
      size={isTabletOrSmartphone ? "sm" : "md"}
      variant="gradient"
      gradient={{
        from: theme.colors.factog[0],
        to: theme.colors.factog[1],
        deg: 20,
      }}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    >
      作業終了
    </Button>
  );
};
