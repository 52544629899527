import { logger } from "@/libs/utils/logger";
import { ValidationError } from "yup";
import {
  BadRequestError,
  ConflictError,
  ForbiddenError,
  NotFoundError,
  SystemError,
  TooManyRequestError,
  UnauthorizedError,
} from "./errors";
import { ErrorCode } from "./generated/codes";

/*
Hasuraのエラー例:
{
  "errors": [
    {
      "extensions": {
        "code": "invalid-jwt",
        "path": "$"
      },
      "message": "Could not verify JWT: JWTExpired"
    }
  ]
}

NestJSのエラー例:
{
  "data": null,
  "errors": [
    {
      "extensions": {
        "code": "INTERNAL_SERVER_ERROR",
        "exception": {
          "code": "400-001",
          "debugMessage": "デバッグメッセージ",
          "stacktrace": [
            "CustomBadRequestError: ユーザーメッセージ",
            "    at AdminUsersService.login (/workspace/backend/dist/apps/admin-backend/libs/domain/src/admin-users/admin-users.service.js:36:19)",
            "    at async AuthResolver.login (/workspace/backend/dist/apps/admin-backend/apps/admin-backend/src/auth/auth.resolver.js:44:31)"
          ],
          "type": "CustomBadRequestError",
          "userMessage": "ユーザーメッセージ"
        }
      },
      "locations": [
        {
          "column": 39,
          "line": 1
        }
      ],
      "message": "ユーザーメッセージ",
      "path": [
        "login"
      ]
    }
  ]
}
*/
export const apiErrorHandler = (error: any) => {
  const extensions = error.response?.errors[0]?.extensions;
  const exception = extensions?.exception;
  logger.info("GraphQL実行でエラー発生", extensions, exception);
  if (exception) {
    // NestJS側のエラー
    if (exception.type === "BadRequestError") {
      logger.info(error);
      throw new BadRequestError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
    if (exception.type === "ValidationError") {
      logger.info(error);
      throw new ValidationError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
    if (exception.type === "UnauthorizedError") {
      logger.info(error);
      throw new UnauthorizedError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
    if (exception.type === "ForbiddenError") {
      logger.info(error);
      throw new ForbiddenError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
    if (exception.type === "NotFoundError") {
      logger.info(error);
      throw new NotFoundError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
    if (exception.type === "ConflictError") {
      logger.info(error);
      throw new ConflictError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
    if (exception.type === "TooManyRequestError") {
      logger.info(error);
      throw new TooManyRequestError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
    if (exception.type === "SystemError") {
      logger.info(error);
      throw new SystemError(
        exception.code,
        exception.userMessage,
        exception.debugMessage
      );
    }
  } else {
    // Hasura側のエラー

    // 無効（不正・有効期限切れ）なJWTトークン
    if (extensions?.code === "invalid-jwt") {
      throw new UnauthorizedError(
        ErrorCode.Forbidden,
        "",
        "無効（不正・有効期限切れ）なJWTトークン(invalid jwt)"
      );
    }

    // セッション不正
    if (extensions?.code === "not-found") {
      if (error.message.includes("missing session variable"))
        throw new UnauthorizedError(
          ErrorCode.Forbidden,
          "",
          "無効（不正・有効期限切れ）なJWTトークン(missing session)"
        );
    }

    // DB制約違反
    if (extensions?.code === "constraint-violation") {
      if (
        error.message.includes("duplicate key value violates unique constraint")
      )
        throw new ConflictError(
          ErrorCode.UniquenessError,
          "",
          "一意性制約違反"
        );
    }

    // パーミッションエラー
    if (extensions?.code === "permission-error") {
      throw new ForbiddenError(ErrorCode.Forbidden, "", "実行権限なし");
    }

    // IP制限などのアクセス制限
    if (extensions?.code === "access-denied") {
      throw new ForbiddenError(
        ErrorCode.NotAllowedIpAddress,
        "",
        "IP制限などのアクセス制限"
      );
    }
  }
  const businessException = extensions?.response;

  // // システムエラー
  // if (exception) {
  //   if (exception.type === "BadRequestError") {
  //     logger.info(error);
  //     throw new BadRequestError(exception.title);
  //   }

  //   if (exception.status === 400) {
  //     logger.info(error);
  //     throw new BadRequestError("ValidationError");
  //   }

  //   if (exception.type === "UnauthorizedError") {
  //     logger.info(error);
  //     throw new UnauthorizedError(exception.title);
  //   }

  //   if (exception.type === "ForbiddenError") {
  //     logger.info(error);
  //     throw new ForbiddenError(exception.title);
  //   }

  //   if (exception.type === "NotFoundError") {
  //     logger.info(error);
  //     throw new NotFoundError(exception.title);
  //   }

  //   if (exception.type === "ConflictError") {
  //     logger.info(error);
  //     throw new ConflictError(exception.title);
  //   }

  //   if (exception.type === "TooManyRequestError") {
  //     logger.info(error);
  //     throw new TooManyRequestError(exception.title);
  //   }

  //   if (exception.type === "SystemError") {
  //     logger.error("GraphQLリクエストでシステムエラー発生", error);
  //     throw new SystemError(exception.title);
  //   }
  // }

  // // 業務上発生しうるエラー
  // if (businessException) {
  //   if (businessException.statusCode === 400) {
  //     logger.info(error);
  //     throw new BadRequestError(businessException.message);
  //   }
  //   if (businessException.statusCode === 401) {
  //     logger.info(error);
  //     throw new UnauthorizedError(businessException.message);
  //   }
  //   if (businessException.statusCode === 403) {
  //     logger.info(error);
  //     throw new ForbiddenError(businessException.message);
  //   }
  //   if (businessException.statusCode === 404) {
  //     logger.info(error);
  //     throw new NotFoundError(businessException.message);
  //   }
  //   if (businessException.statusCode === 409) {
  //     logger.info(error);
  //     throw new ConflictError(businessException.message);
  //   }
  // }

  logger.error("GraphQLリクエストで予期しないエラー発生", error);
  throw error;
};
