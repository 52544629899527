import { usePage } from "@/hooks/usePage";
import { useRouter } from "next/router";
import { ReactElement } from "react";
import { LoggedInDesktop } from "./layouts/LoggedInDesktop";
import { LoggedInMobile } from "./layouts/LoggedInMobile";
import { LoggedOutDesktop } from "./layouts/LoggedOutDesktop";
import { LoggedOutMobile } from "./layouts/LoggedOutMobile";
import { SettlementFailureMiddleware } from "./middlewares/SettlementFailureMiddleware";
import { ForceSsoMiddleware } from "./middlewares/ForceSsoMiddleware";
import { ForceTwoFaMiddleware } from "./middlewares/ForceTwoFaMiddleware";

type Props = {
  children: ReactElement;
};
export const Body = (props: Props): JSX.Element => {
  const {
    pageMeta: { isMobile },
  } = usePage();

  const router = useRouter();
  const paths = router.route.split("/");
  const isLoggedIn = paths[1] === "t";

  if (isLoggedIn) {
    return isMobile ? (
      <SettlementFailureMiddleware>
        <ForceTwoFaMiddleware>
          <ForceSsoMiddleware>
            <LoggedInMobile>{props.children}</LoggedInMobile>
          </ForceSsoMiddleware>
        </ForceTwoFaMiddleware>
      </SettlementFailureMiddleware>
    ) : (
      <SettlementFailureMiddleware>
        <ForceTwoFaMiddleware>
          <ForceSsoMiddleware>
            <LoggedInDesktop>{props.children}</LoggedInDesktop>
          </ForceSsoMiddleware>
        </ForceTwoFaMiddleware>
      </SettlementFailureMiddleware>
    );
  }

  return isMobile ? (
    <LoggedOutMobile>{props.children}</LoggedOutMobile>
  ) : (
    <LoggedOutDesktop>{props.children}</LoggedOutDesktop>
  );
};
