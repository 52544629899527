import { UnauthorizedError } from "@/libs/api/errors";
import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";
import { OrderBy } from "@/libs/api/generated/types";
import { useTeamBacklogLinkageQuery } from "./useTeamBacklogLinkageQuery";
import _ from "lodash";

export const useTeamBacklogLinkageProjectsQuery = (backlogUrl?: string) => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();
  const { teamBacklogLinkages } = useTeamBacklogLinkageQuery(backlogUrl);

  const {
    data: teamBacklogLinkageProjects,
    isLoading: teamBacklogLinkageProjectsLoading,
  } = useSWR(
    [
      SWR_KEYS.QUERY_TEAM_BACKLOG_LINKAGE_PROJECTS,
      session?.teamId,
      teamBacklogLinkages,
      backlogUrl,
    ],
    async () => {
      if (_.isEmpty(teamBacklogLinkages)) return [];
      const client = await getClientWithSession();

      if (!session?.teamId) {
        throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
      }
      const { teamBacklogLinkageProjects } =
        await client.teamBacklogLinkageProjects({
          where: {
            teamId: {
              _eq: session.teamId,
            },
            linkId: {
              _in: teamBacklogLinkages.map((linkage) => linkage.id),
            },
            project: {
              archived: {
                _eq: false,
              },
              deletedAt: {
                _isNull: true,
              },
            },
          },
          orderBy: {
            createdAt: OrderBy.Asc,
          },
        });

      return teamBacklogLinkageProjects;
    }
  );

  return {
    teamBacklogLinkageProjects: teamBacklogLinkageProjects || [],
    teamBacklogLinkageProjectsLoading,
  };
};
