import { ProjectMembersAttributeFragment } from "@/libs/api/generated/types";

export type ProjectMemberView = {
  raw: ProjectMembersAttributeFragment;
  id: string;
  teamMemberId: string;
  imagePath: string | null;
  name: string;
  authority: string;
};

export const toProjectMemberView = (
  projectMember: ProjectMembersAttributeFragment
): ProjectMemberView => {
  return {
    raw: projectMember,
    id: projectMember.id,
    teamMemberId: projectMember.teamMemberId,
    imagePath: projectMember.teamMember?.user.imageFileName
      ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${projectMember.teamMember?.user.imageFileName}`
      : null,
    name: `${projectMember.teamMember?.user.lastName} ${projectMember.teamMember?.user.firstName}`,
    authority: projectMember.role === "PROJECT_ADMIN" ? "管理者" : "メンバー",
  };
};
