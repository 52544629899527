import { WorkStatus } from "@/libs/api/generated/enum";
import { TeamMembersSubscriptionAttributeFragment } from "@/libs/api/generated/types";
import _ from "lodash";
import { TaskView, toTaskView } from "./TasksView";
import dayjs from "dayjs";

export type TeamMemberSubscriptionView = {
  raw: TeamMembersSubscriptionAttributeFragment;
  fullName: string;
  avatarImagePath?: string | null;
  fallbackImagePath: string;
  statusColor: "green" | "yellow" | "gray";
  doingTask: TaskView | null;
};

export const sortTeamMemberSubscription = (
  teamMembers: TeamMemberSubscriptionView[]
) =>
  // チームボード・チームメンバーアクティビティ表示を以下の条件でソート
  // 1. チーム管理者かつworkStatusがWORKING
  // 2. workStatusがWORKING
  // 3. チーム管理者かつworkStatusがSUSPENDED
  // 4. workStatusがSUSPENDED
  // 5. チーム管理者
  _.orderBy(
    teamMembers,
    [(item) => item.raw.workStatus, (item) => item.raw.role, "fullName"],
    ["desc", "asc", "asc"]
  );

export const toTeamMemberSubscriptionView = async (
  entity: TeamMembersSubscriptionAttributeFragment,
  getSignedUrl: any
): Promise<TeamMemberSubscriptionView> => {
  const snapshotPath = await (() => {
    if (
      entity.workStatus !== WorkStatus.WORKING ||
      !entity.teamMemberSnapshot ||
      dayjs().diff(dayjs(entity.teamMemberSnapshot.updatedAt), "millisecond") >
        Number(process.env.NEXT_PUBLIC_S3_SNAPSHOT_ENABLED)
    ) {
      return null;
    }

    return getSignedUrl(entity.teamMemberSnapshot.snapshotFilePath, {
      cache: true,
    });
  })();

  const userImagePath = (() => {
    if (entity.user.imageFileName) {
      return `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${entity.user.imageFileName}`;
    }

    return "";
  })();

  const statusColor =
    entity.workStatus === "WORKING"
      ? "green"
      : entity.workStatus === "SUSPENDED"
      ? "yellow"
      : "gray";

  return {
    raw: entity,
    fullName: `${entity.user.lastName} ${entity.user.firstName}`,
    // アバター画像
    avatarImagePath:
      // 作業中はスナップショットを表示
      snapshotPath ||
      // 作業中以外、または作業開始から環境変数で設定した時間を経過した場合はユーザーのアバターを表示
      userImagePath ||
      // どちらもない場合はnull（Avatarコンポーネントでデフォルトアバターを表示する）
      null,
    // スナップショットが取得できなかった場合のイメージ
    fallbackImagePath: userImagePath,
    statusColor,
    doingTask: entity.doingTask ? toTaskView(entity.doingTask) : null,
  };
};
