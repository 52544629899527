export const RoleType = {
  TEAM_MANAGER: "TEAM_MANAGER",
  PROJECT_MANAGER: "PROJECT_MANAGER",
  MEMBER: "MEMBER",
} as const;

export type RoleType = (typeof RoleType)[keyof typeof RoleType];

export enum Plan {
  FREE = "FREE",
  STANDARD = "STANDARD",
  ENTERPRISE = "ENTERPRISE",
}
export const PLAN_NAME = {
  [Plan.FREE]: "フリープラン",
  [Plan.STANDARD]: "スタンダードプラン",
  [Plan.ENTERPRISE]: "エンタープライズプラン",
};

export const WITHDRAWAL_ENQUETE_VERSION = "1";

export const EnqueteHeading = {
  TAIKAI_RIYU: "taikai_riyu",
} as const;

export const TaikaiRiyu = {
  ACCOUNT_FUKUSU_SAKUSEI: "account_fukusu_sakusei",
  RIYO_KIYAKU: "riyo_kiyaku",
  SECURITY_FUMAN: "security_fuman",
  TSUKAIZURAI: "tsukaizurai",
  KITAI_SURU_KOUKA_GA_NAI: "kitai_suru_kouka_ga_nai",
  PROJECT_SHURYO: "project_shuryo",
  RYOKIN_TAKAI: "ryokin_takai",
  SUPPORT_FUJUBUN: "support_fujubun",
  SONOTA: "sonota",
} as const;

export type TaikaiRiyuType = (typeof TaikaiRiyu)[keyof typeof TaikaiRiyu];

export const TaikaiRiyuLabels = {
  [TaikaiRiyu.ACCOUNT_FUKUSU_SAKUSEI]:
    "アカウントを複数作ってしまったので削除したい",
  [TaikaiRiyu.RIYO_KIYAKU]: "会社のシステム利用規則などに抵触する恐れがあった",
  [TaikaiRiyu.SECURITY_FUMAN]: "セキュリティ面に不安があった",
  [TaikaiRiyu.TSUKAIZURAI]: "使いづらかった、不明な点があった",
  [TaikaiRiyu.KITAI_SURU_KOUKA_GA_NAI]: "期待する効果が得られなかった",
  [TaikaiRiyu.PROJECT_SHURYO]: "Factogを使用するプロジェクトが終了した",
  [TaikaiRiyu.RYOKIN_TAKAI]: "料金が高かった",
  [TaikaiRiyu.SUPPORT_FUJUBUN]: "サポートが不十分だった",
  [TaikaiRiyu.SONOTA]: "その他",
} as const;

export type TaikaiRiyuLabelsType =
  (typeof TaikaiRiyuLabels)[keyof typeof TaikaiRiyuLabels];
