import { Box, Flex, Stack, Text } from "@mantine/core";
import { IconUser } from "@tabler/icons";
import { TeamMemberItemType } from "./constant";
import ProjectTagTaskLabel from "../../common/ProjectTagTaskLabel";

const TeamMemberTooltipLabel: React.FC<TeamMemberItemType> = ({
  teamMemberName,
  taskName,
  projectName,
  tagName,
}) => {
  return (
    <Stack spacing={4} w={240}>
      <Flex gap={4} align={"center"}>
        <IconUser size="16px" stroke={1.0} />
        <Text
          fz={13}
          lh={1}
          sx={{
            flex: 1,
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
          }}
        >
          {teamMemberName}
        </Text>
      </Flex>
      <ProjectTagTaskLabel
        project={projectName}
        tag={tagName}
        task={taskName}
        w={"100%"}
      />
    </Stack>
  );
};

export default TeamMemberTooltipLabel;
