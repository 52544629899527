import { TaskView } from "@/hooks/queries/views/TasksView";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const doingTaskAtom = atom<TaskView | null>({
  key: "tasks.doingTaskAtom",
  default: null,
});

export const doingTaskCounterAtom = atom<number>({
  key: "tasks.doingTaskCounterAtom",
  default: 0,
});

export const previousWorkTasksDateAtom = atom<Date | null>({
  key: "tasks.previousWorkTasksDateAtom",
  default: null,
});

export const projectDisplayMenuAtom = atom<{
  displayEmptyProject: boolean;
  displaySuspendTask: boolean;
}>({
  key: "tasks.projectDisplayMenu",
  default: {
    displayEmptyProject: false,
    displaySuspendTask: false,
  },
  effects_UNSTABLE: [persistAtom],
});
