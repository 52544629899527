import { useCallback } from "react";

export const routeIds = {
  ACCEPT_INVITATION: "ACCEPT_INVITATION",
  CONTACT: "CONTACT",
  COOKIE_POLICY: "COOKIE_POLICY",
  FAQ: "FAQ",
  HOME: "HOME",
  PASSWORD_RESET: "PASSWORD_RESET",
  PASSWORD_SETTING: "PASSWORD_SETTING",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  SIGN_IN: "SIGN_IN",
  SIGN_IN_TEAM_SELECT: "SIGN_IN_TEAM_SELECT",
  SIGN_IN_TWO_FACTOR: "SIGN_IN_TWO_FACTOR",
  AFTER_SIGN_IN_PROCESS: "AFTER_SIGN_IN_PROCESS",
  SIGN_UP_CONFIRM: "SIGN_UP_CONFIRM",
  SIGN_UP: "SIGN_UP",
  ATTENDANCE_MOBILE: "ATTENDANCE_MOBILE",
  RELATION_PROJECT: "RELATION_PROJECT",
  BACKLOG_SIGN_IN: "BACKLOG_SIGN_IN",
  FREEE_BLANK: "FREEE_BLANK",
  FREEE_COMPANY: "FREEE_COMPANY",
  FREEE_MEMBER: "FREEE_MEMBER",
  GITHUB_RELATION_PROJECT: "GITHUB_RELATION_PROJECT",
  GOOGLE_CALENDAR_BLANK: "GOOGLE_CALENDAR_BLANK",
  JIRA_RELATION_PROJECT: "JIRA_RELATION_PROJECT",
  JIRA_SIGN_IN: "JIRA_SIGN_IN",
  TEAM_MEMBER_PROFILE: "TEAM_MEMBER_PROFILE",
  TEAM_MEMBER_PROJECTS: "TEAM_MEMBER_PROJECTS",
  TEAM_MEMBER_SETTINGS: "TEAM_MEMBER_SETTINGS",
  TEAM_MEMBER_TASKS: "TEAM_MEMBER_TASKS",
  TEAM_MEMBER_WORKS: "TEAM_MEMBER_WORKS",
  TEAM_KANBAN: "TEAM_KANBAN",
  TEAM_PLAN: "TEAM_PLAN",
  TEAM_PLAN_SETTLEMENT: "TEAM_PLAN_SETTLEMENT",
  PROJECT_MEMBERS: "PROJECT_MEMBERS",
  PROJECT_SETTINGS: "PROJECT_SETTINGS",
  PROJECT_TAGS: "PROJECT_TAGS",
  PROJECTS: "PROJECTS",
  REPORT_DETAILS: "REPORT_DETAILS",
  REPORT_SUMMARY: "REPORT_SUMMARY",
  DELETED_TEAMS: "DELETED_TEAMS",
  TEAM_IP_ADDRESS_RESTRICTION: "TEAM_IP_ADDRESS_RESTRICTION",
  TEAM_MEMBERS: "TEAM_MEMBERS",
  TEAM_SETTINGS: "TEAM_SETTINGS",
  TEAM_PLAN_SETTINGS_ADD_LICENSES: "TEAM_PLAN_SETTINGS_ADD_LICENSES",
  TEAM_PLAN_SETTINGS_COMPARE_PLANS: "TEAM_PLAN_SETTINGS_COMPARE_PLANS",
  TEAM_PLAN_SETTINGS_COMPLETE_EDIT_LICENSE:
    "TEAM_PLAN_SETTINGS_COMPLETE_EDIT_LICENSE",
  TEAM_PLAN_SETTINGS_DOWNGRADE: "TEAM_PLAN_SETTINGS_DOWNGRADE",
  TEAM_PLAN_SETTINGS_PAYMENT_HISTORY: "TEAM_PLAN_SETTINGS_PAYMENT_HISTORY",
  TEAM_PLAN_SETTINGS_REMOVE_LICENSES: "TEAM_PLAN_SETTINGS_REMOVE_LICENSES",
  TEAM_PLAN_SETTINGS_UPGRADE: "TEAM_PLAN_SETTINGS_UPGRADE",
  TEAM_CREATION_FLOW_PLAN_AGREEMENT: "TEAM_CREATION_FLOW_PLAN_AGREEMENT",
  TEAM_CREATION_FLOW_PROJECT_CREATION: "TEAM_CREATION_FLOW_PROJECT_CREATION",
  TEAM_CREATION_FLOW_SELECT_PLAN: "TEAM_CREATION_FLOW_SELECT_PLAN",
  TEAM_CREATION_FLOW_TEAM_CREATION: "TEAM_CREATION_FLOW_TEAM_CREATION",
  TERMS_OF_USE: "TERMS_OF_USE",
  EMAIL_CONFIRM: "EMAIL_CONFIRM",
  WITHDRAWAL_COMPLETE: "WITHDRAWAL_COMPLETE",
  WITHDRAWAL_QUESTIONNAIRE: "WITHDRAWAL_QUESTIONNAIRE",
  REMOVE_TEAM_ALLOWED_IP_ADDRESS_COMPLETE:
    "REMOVE_TEAM_ALLOWED_IP_ADDRESS_COMPLETE",
  ADD_TO_HOME: "ADD_TO_HOME",
} as const;

type RouteId = (typeof routeIds)[keyof typeof routeIds];

interface Route {
  id: string;
  name: string;
  path: string;
}

type Routes = {
  [key in RouteId]: Route;
};

export const Routes: Routes = {
  ACCEPT_INVITATION: {
    id: "acceptInvitation",
    name: "チーム招待",
    path: "/accept-invitation/",
  },
  CONTACT: { id: "contact", name: "お問い合わせ", path: "/contact/" },
  COOKIE_POLICY: {
    id: "cookiePolicy",
    name: "クッキーポリシー",
    path: "/cookie-policy/",
  },
  FAQ: { id: "faq", name: "よくある質問", path: "/faq/" },
  HOME: { id: "home", name: "ホーム", path: "/" },
  PASSWORD_RESET: {
    id: "passwordReset",
    name: "パスワードリセット",
    path: "/password-reset/",
  },
  PASSWORD_SETTING: {
    id: "passwordSetting",
    name: "パスワード設定",
    path: "/password-setting/",
  },
  PRIVACY_POLICY: {
    id: "privacyPolicy",
    name: "プライバシーポリシー",
    path: "/privacy-policy/",
  },
  SIGN_IN: { id: "signIn", name: "ログイン", path: "/sign-in/" },
  SIGN_IN_TEAM_SELECT: {
    id: "signInTeamSelect",
    name: "チーム選択",
    path: "/sign-in/team-select/",
  },
  SIGN_IN_TWO_FACTOR: {
    id: "signInTwoFactor",
    name: "二要素認証",
    path: "/sign-in/two-factor/",
  },
  AFTER_SIGN_IN_PROCESS: {
    id: "afterSignInProcess",
    name: "ログイン後処理",
    path: "/sign-in/after-sign-in-process/",
  },
  SIGN_UP_CONFIRM: {
    id: "signUpConfirm",
    name: "アカウント登録確認",
    path: "/sign-up/confirm/",
  },
  SIGN_UP: { id: "signUp", name: "アカウント登録", path: "/sign-up/" },
  ATTENDANCE_MOBILE: {
    id: "attendanceMobile",
    name: "タスク",
    path: "/t/sp/[teamId]/members/[teamMemberId]/tasks/",
  },
  RELATION_PROJECT: {
    id: "relationProject",
    name: "関連プロジェクト",
    path: "/t/[teamId]/linkage/backlog/relation-project/",
  },
  BACKLOG_SIGN_IN: {
    id: "backlogSignIn",
    name: "Backlogサインイン",
    path: "/t/[teamId]/linkage/backlog/sign-in/",
  },
  FREEE_BLANK: {
    id: "freeeBlank",
    name: "freee空白",
    path: "/t/[teamId]/linkage/freee/blank/",
  },
  FREEE_COMPANY: {
    id: "freeeCompany",
    name: "freee会社",
    path: "/t/[teamId]/linkage/freee/company/",
  },
  FREEE_MEMBER: {
    id: "freeeMember",
    name: "freeeメンバー",
    path: "/t/[teamId]/linkage/freee/member/",
  },
  GITHUB_RELATION_PROJECT: {
    id: "githubRelationProject",
    name: "GitHub関連プロジェクト",
    path: "/t/[teamId]/linkage/github/relation-project/",
  },
  GOOGLE_CALENDAR_BLANK: {
    id: "googleCalendarBlank",
    name: "Googleカレンダー空白",
    path: "/t/[teamId]/linkage/google-calendar/blank/",
  },
  JIRA_RELATION_PROJECT: {
    id: "jiraRelationProject",
    name: "Jira関連プロジェクト",
    path: "/t/[teamId]/linkage/jira/relation-project/",
  },
  JIRA_SIGN_IN: {
    id: "jiraSignIn",
    name: "Jiraサインイン",
    path: "/t/[teamId]/linkage/jira/sign-in/",
  },
  TEAM_MEMBER_PROFILE: {
    id: "teamMemberProfile",
    name: "プロフィール",
    path: "/t/[teamId]/members/[teamMemberId]/profile/",
  },
  TEAM_MEMBER_PROJECTS: {
    id: "teamMemberProjects",
    name: "プロジェクト",
    path: "/t/[teamId]/members/[teamMemberId]/projects/",
  },
  TEAM_MEMBER_SETTINGS: {
    id: "teamMemberSettings",
    name: "設定",
    path: "/t/[teamId]/members/[teamMemberId]/settings/",
  },
  TEAM_MEMBER_TASKS: {
    id: "teamMemberTasks",
    name: "タスク",
    path: "/t/[teamId]/members/[teamMemberId]/tasks/",
  },
  TEAM_MEMBER_WORKS: {
    id: "teamMemberWorks",
    name: "勤怠",
    path: "/t/[teamId]/members/[teamMemberId]/works/",
  },
  TEAM_KANBAN: {
    id: "teamKanban",
    name: "チームボード",
    path: "/t/[teamId]/members/",
  },
  TEAM_PLAN: { id: "teamPlan", name: "プラン", path: "/t/[teamId]/plan/" },
  TEAM_PLAN_SETTLEMENT: {
    id: "teamPlanSettlement",
    name: "決済",
    path: "/t/[teamId]/plan/settlement/",
  },
  PROJECT_MEMBERS: {
    id: "projectMembers",
    name: "プロジェクトメンバー",
    path: "/t/[teamId]/projects/[projectId]/members/",
  },
  PROJECT_SETTINGS: {
    id: "projectSettings",
    name: "プロジェクト設定",
    path: "/t/[teamId]/projects/[projectId]/settings/",
  },
  PROJECT_TAGS: {
    id: "projectTags",
    name: "プロジェクトタグ",
    path: "/t/[teamId]/projects/[projectId]/tags/",
  },
  PROJECTS: {
    id: "projects",
    name: "チームプロジェクト",
    path: "/t/[teamId]/projects/",
  },
  REPORT_DETAILS: {
    id: "reportDetails",
    name: "チーム分析詳細",
    path: "/t/[teamId]/reports/details/",
  },
  REPORT_SUMMARY: {
    id: "reportSummary",
    name: "チーム分析",
    path: "/t/[teamId]/reports/summary/",
  },
  DELETED_TEAMS: {
    id: "deletedTeams",
    name: "削除されたチーム",
    path: "/team-delete-success/",
  },
  TEAM_IP_ADDRESS_RESTRICTION: {
    id: "teamIpAddressRestriction",
    name: "IPアドレス制限",
    path: "/t/[teamId]/teams/ip-address-restriction/",
  },
  TEAM_MEMBERS: {
    id: "teamMembers",
    name: "チームメンバー",
    path: "/t/[teamId]/teams/members/",
  },
  TEAM_SETTINGS: {
    id: "teamSettings",
    name: "チーム設定",
    path: "/t/[teamId]/teams/settings/[activeTab]/",
  },
  TEAM_PLAN_SETTINGS_ADD_LICENSES: {
    id: "teamPlanSettingsAddLicenses",
    name: "ライセンス追加",
    path: "/t/[teamId]/teams/settings/plans/add-licenses/",
  },
  TEAM_PLAN_SETTINGS_COMPARE_PLANS: {
    id: "teamPlanSettingsComparePlans",
    name: "プラン比較",
    path: "/t/[teamId]/teams/settings/plans/compare-plans/",
  },
  TEAM_PLAN_SETTINGS_COMPLETE_EDIT_LICENSE: {
    id: "teamPlanSettingsCompleteEditLicense",
    name: "ライセンス編集完了",
    path: "/t/[teamId]/teams/settings/plans/complete-edit-license/",
  },
  TEAM_PLAN_SETTINGS_DOWNGRADE: {
    id: "teamPlanSettingsDowngrade",
    name: "ダウングレード",
    path: "/t/[teamId]/teams/settings/plans/downgrade/",
  },
  TEAM_PLAN_SETTINGS_PAYMENT_HISTORY: {
    id: "teamPlanSettingsPaymentHistory",
    name: "支払い履歴",
    path: "/t/[teamId]/teams/settings/plans/payment-history/",
  },
  TEAM_PLAN_SETTINGS_REMOVE_LICENSES: {
    id: "teamPlanSettingsRemoveLicenses",
    name: "ライセンス削除",
    path: "/t/[teamId]/teams/settings/plans/remove-licenses/",
  },
  TEAM_PLAN_SETTINGS_UPGRADE: {
    id: "teamPlanSettingsUpgrade",
    name: "アップグレード",
    path: "/t/[teamId]/teams/settings/plans/upgrade/",
  },
  TEAM_CREATION_FLOW_PLAN_AGREEMENT: {
    id: "teamCreationFlowPlanAgreement",
    name: "プラン同意",
    path: "/team-creation-flow/plan-agreement/",
  },
  TEAM_CREATION_FLOW_PROJECT_CREATION: {
    id: "teamCreationFlowProjectCreation",
    name: "プロジェクト作成",
    path: "/team-creation-flow/project-creation/",
  },
  TEAM_CREATION_FLOW_SELECT_PLAN: {
    id: "teamCreationFlowSelectPlan",
    name: "プラン選択",
    path: "/team-creation-flow/select-plan/",
  },
  TEAM_CREATION_FLOW_TEAM_CREATION: {
    id: "teamCreationFlowTeamCreation",
    name: "チーム作成",
    path: "/team-creation-flow/team-creation/",
  },
  TERMS_OF_USE: { id: "termsOfUse", name: "利用規約", path: "/terms-of-use/" },
  EMAIL_CONFIRM: {
    id: "emailConfirm",
    name: "メール確認",
    path: "/users/[userId]/email/confirm/",
  },
  WITHDRAWAL_COMPLETE: {
    id: "withdrawalComplete",
    name: "退会完了",
    path: "/withdrawal/complete/",
  },
  WITHDRAWAL_QUESTIONNAIRE: {
    id: "withdrawalQuestionnaire",
    name: "退会アンケート",
    path: "/withdrawal/questionnaire/",
  },
  REMOVE_TEAM_ALLOWED_IP_ADDRESS_COMPLETE: {
    id: "removeTeamAllowedIpAddressComplete",
    name: "IPアドレス制限解除",
    path: "/remove-team-allowed-ip-address/complete/",
  },
  ADD_TO_HOME: {
    id: "addToHome",
    name: "ホーム画面に追加",
    path: "/t/sp/[teamId]/add-to-home/",
  },
};

interface DynamicParams {
  [key: string]: string;
}

interface QueryParams {
  [key: string]: string;
}

export const usePath = () => {
  const generatePath = useCallback(
    ({
      routePath,
      dynamicParams = {},
      queryParams = {},
    }: {
      routePath?: string;
      dynamicParams?: DynamicParams;
      queryParams?: QueryParams;
    }): string => {
      if (!routePath) return "";
      if (dynamicParams) {
        if (Object.values(dynamicParams).some((value) => !value)) {
          return "";
        }
      }

      let fullPath = routePath;

      // パスの動的パラメータを置き換える
      Object.keys(dynamicParams).forEach((param) => {
        const paramPattern = new RegExp(`\\[${param}\\]`, "g");
        fullPath = fullPath.replace(paramPattern, dynamicParams[param]);
      });

      // クエリーパラメーターをパスに追加する
      const queryParts = Object.entries(queryParams).map(
        ([key, value]) => `${key}=${value}`
      );
      if (queryParts.length > 0) {
        fullPath += `?${queryParts.join("&")}`;
      }
      return fullPath;
    },
    []
  );

  return {
    generatePath,
  };
};
