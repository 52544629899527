import { TaskView } from "@/hooks/queries/views/TasksView";

export const ActivityStatus = {
  START: "START",
  BREAK: "BREAK",
  RESTART: "RESTART",
  FINISH: "FINISH",
  TASK: "TASK",
} as const;

export type ActivityStatusType =
  (typeof ActivityStatus)[keyof typeof ActivityStatus];

export type ActivityContentType = {
  type: ActivityStatusType;
  workTime?: string;
  projectName?: string;
  tagName?: string;
  taskName?: string;
};

export interface ActivityUserType {
  name: string;
  avatar: string;
  teamMemberId: string;
}

export interface ActivityType {
  time: string;
  user: ActivityUserType;
  content: ActivityContentType;
  task: TaskView | null;
}

export interface ActivityDateType {
  date: string;
  activities: ActivityType[];
}
