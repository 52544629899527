import { getSdk, SdkFunctionWrapper } from "@/libs/api/generated/types";
import { logger } from "@/libs/utils/logger";
import { GraphQLClient } from "graphql-request";
import * as jwt from "jsonwebtoken";
import { apiErrorHandler } from "./apiErrorHandler";
import { ApplicationError } from "./errors";

export type GetClientOptionsRole = "anonymous" | "notset" | null;
export interface GetClientOptions {
  accessToken: string | null;
  role?: GetClientOptionsRole;
}

const client = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_API_BASE_URL!}/v1/graphql`,
  {
    credentials: "include",
  }
);

export const getClient = (options: GetClientOptions) => {
  const headers: any = {};
  if (options.accessToken) {
    const accessToken = options.accessToken;
    const decodedToken = jwt.decode(accessToken) as {
      roles: string[];
    };

    headers.Authorization = `Bearer ${accessToken}`;
    headers["x-hasura-role"] = decodedToken.roles[0];
  }

  if (options.role) {
    headers["x-hasura-role"] = options.role;
  }

  const requestWrapper: SdkFunctionWrapper = async <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>,
    operationName: string,
    operationType?: string
  ): Promise<T> => {
    try {
      logger.info(`GraphQL Request: ${operationType} - ${operationName}`);
      const startTime = new Date();
      const response = await action(headers);
      logger.debug(
        `GraphQL Response: ${operationType} - ${operationName}`,
        response,
        `${new Date().getTime() - startTime.getTime()}ms`
      );
      return response;
    } catch (error: any) {
      apiErrorHandler(error);
      throw error;
    }
  };

  return getSdk(client, requestWrapper);
};

export const extractExpectedError = (
  error: Error,
  expectedCodes: string[]
): ApplicationError | null => {
  if (!(error instanceof ApplicationError)) {
    return null;
  }

  if (!expectedCodes.includes(error.code)) {
    return null;
  }

  return error;
};
