import { projectDisplayMenuAtom } from "@/components/pages/tasks/tasks.store";
import { TaskStatus } from "@/libs/api/generated/enum";
import { OrderBy, TaskAttributesFragment } from "@/libs/api/generated/types";
import { logger } from "@/libs/utils/logger";
import { SWR_KEYS } from "@/stores/swr";
import { uniqArray } from "@/utils/array";
import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";
import { useProjectsQuery } from "./useProjectsQuery";
import { TaskView, toTaskView } from "./views/TasksView";

export type TaskContainer = {
  containerId: string;
  containerName?: string;
  tasks: TaskView[];
};

export const TodoContainerId = "todo";
export const DoneContainerId = "done";
export const SuspendContainerId = "suspend";

const DefaultTodoTaskContainer: TaskContainer = {
  containerId: TodoContainerId,
  tasks: [],
};

const DefaultDoneTaskContainer: TaskContainer = {
  containerId: DoneContainerId,
  tasks: [],
};

const DefaultSuspendTaskContainer: TaskContainer = {
  containerId: SuspendContainerId,
  tasks: [],
};

export const useTasksQuery = (teamMemberId: string) => {
  const { getClientWithSession } = useApi();
  const { activeProjects } = useProjectsQuery();
  const projectIds = useMemo(
    () => activeProjects.map((project) => project.id),
    [activeProjects]
  );
  const [projectDisplayMenu, setprojectDisplayMenu] = useRecoilState(
    projectDisplayMenuAtom
  );

  // タスク情報を取得
  const {
    data: tasks,
    isLoading: tasksLoading,
    mutate: mutateTasks,
  } = useSWR([SWR_KEYS.QUERY_TASKS, teamMemberId, projectIds], async () => {
    if (!teamMemberId) {
      logger.debug("currentTeamMember is null");
      return [];
    }
    if (!projectIds.length) {
      logger.debug("プロジェクトが未取得");
      return [];
    }

    const client = await getClientWithSession();
    const { tasks } = await client.tasks({
      where: {
        teamMemberId: {
          _eq: teamMemberId,
        },
        deletedAt: {
          _isNull: true,
        },
        project: {
          archived: {
            _eq: false,
          },
          id: {
            _in: projectIds,
          },
          deletedAt: {
            _isNull: true,
          },
        },
      },
      orderBy: {
        order: OrderBy.Asc,
      },
    });
    return tasks;
  });

  const filterTasks = useCallback(
    (
      filterConditioner: (task: TaskAttributesFragment) => boolean,
      containerId?: string
    ) => {
      if (!tasks) return [];

      return tasks.filter(filterConditioner).reduce((acc, task) => {
        const targetContainerId = containerId || task.projectId;
        const targetContainerName = task.project?.name;
        let taskContainer = acc.find(
          (container) => container.containerId === targetContainerId
        );
        if (!taskContainer) {
          taskContainer = {
            containerId: targetContainerId,
            containerName: targetContainerName,
            tasks: [],
          };
          acc.push(taskContainer);
        }

        taskContainer.tasks.push(toTaskView(task));

        return acc;
      }, [] as TaskContainer[]);
    },
    [tasks]
  );

  // 本日作業予定タスク
  const todoTaskContainers = useMemo(
    () =>
      filterTasks((task) => {
        return (
          task.status === TaskStatus.TODAY || task.status === TaskStatus.DOING
        );
      }, TodoContainerId),
    [filterTasks]
  );
  const todoTaskContainer = useMemo(
    () => todoTaskContainers[0] || DefaultTodoTaskContainer,
    [todoTaskContainers]
  );

  // 本日作業中断タスク
  const suspendTaskContainers = useMemo(
    () =>
      filterTasks((task) => {
        return task.status === TaskStatus.SUSPEND;
      }, SuspendContainerId),
    [filterTasks]
  );
  const suspendTaskContainer = useMemo(
    () => suspendTaskContainers[0] || DefaultSuspendTaskContainer,
    [suspendTaskContainers]
  );

  // 本日作業完了タスク
  const doneTaskContainers = useMemo(
    () =>
      filterTasks((task) => {
        return task.status === TaskStatus.DONE;
      }, DoneContainerId),
    [filterTasks]
  );
  const doneTaskContainer = useMemo(
    () => doneTaskContainers[0] || DefaultDoneTaskContainer,
    [doneTaskContainers]
  );

  // TODOタスク
  const projectTaskContainers = useMemo(() => {
    const _projectTaskContainers = filterTasks((task) => {
      return task.status === TaskStatus.TODO;
    });

    // タスクが存在しない空のプロジェクト表示を制御
    const _projects = projectDisplayMenu.displayEmptyProject
      ? activeProjects.map((project) => {
          return {
            id: project.id,
            name: project.name,
          };
        })
      : // TODO・DONE・SUSPENDに存在して、プロジェクト側に存在しないタスクを空コンテナとしてプロジェクトに追加
        uniqArray(
          [
            ...doneTaskContainer.tasks.map((task) => ({
              id: task.projectId,
              name: task.projectName,
            })),
            ...todoTaskContainer.tasks.map((task) => ({
              id: task.projectId,
              name: task.projectName,
            })),
            ...suspendTaskContainer.tasks.map((task) => ({
              id: task.projectId,
              name: task.projectName,
            })),
          ],
          (result, item) => {
            return !result.find((r) => r.id === item.id);
          }
        );
    const projectTaskContainerProjectIds = _projectTaskContainers.map(
      (container) => container.containerId
    );
    _projects.forEach((project) => {
      if (!projectTaskContainerProjectIds.includes(project.id)) {
        _projectTaskContainers.push({
          containerId: project.id,
          containerName: project.name,
          tasks: [],
        });
      }
    });

    return _projectTaskContainers;
  }, [
    doneTaskContainer.tasks,
    filterTasks,
    activeProjects,
    projectDisplayMenu.displayEmptyProject,
    suspendTaskContainer.tasks,
    todoTaskContainer.tasks,
  ]);

  return {
    tasks: tasks || [],
    tasksView: tasks ? tasks.map(toTaskView) : [],
    mutateTasks,
    taskContainers: [
      ...projectTaskContainers.sort((a, b) =>
        (a.containerName as string).localeCompare(b.containerName as string)
      ),
      todoTaskContainer,
      doneTaskContainer,
      suspendTaskContainer,
    ],
    projectTaskContainers,
    todoTaskContainer,
    suspendTaskContainer,
    doneTaskContainer,
  };
};
