import { doingTaskCounterAtom } from "@/components/pages/tasks/tasks.store";
import { totalWorkCounterAtom } from "@/components/pages/works/works.store";
import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { WorkStatus } from "@/libs/api/generated/enum";
import { newLogger } from "@/libs/utils/logger";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useWorkTrackingQuery } from "../queries/useWorkTrackingQuery";

const formatWorkingTaskTime = ({
  sec,
  type,
}: {
  sec: number;
  type: "sec" | "min" | "hour";
}): string => {
  // ex(type === 'sec'):  0sec
  // ex(type === 'min'):  mm:ss
  // ex(type === 'hour'): HH:mm:ss

  if (type === "sec") {
    return `${sec}sec`;
  }

  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const remainingSeconds = sec % 60;

  const hoursStr =
    type === "hour" ? `${hours.toString().padStart(2, "0")}:` : "";
  const minutesStr = `${minutes.toString().padStart(2, "0")}:`;
  const secondsStr = `${remainingSeconds.toString().padStart(2, "0")}`;

  return `${hoursStr}${minutesStr}${secondsStr}`;
};

const logger = newLogger({ prefix: "useTimeTracker" });
export const useTimeTracker = () => {
  const { currentTeamMember } = useTeamMembersQuery();
  const { workTracking } = useWorkTrackingQuery(currentTeamMember?.id || "");

  // 勤怠別の作業時間のカウンター
  const [totalWorkCounter, setTotalWorkCounter] =
    useRecoilState(totalWorkCounterAtom);

  useEffect(() => {
    if (currentTeamMember?.workStatus === WorkStatus.NOT_WORKING) {
      logger.debug("勤怠経過時間をリセット");
      setTotalWorkCounter(0);
    }
    logger.debug("勤怠経過時間を現在時刻に合わせる");
    setTotalWorkCounter(workTracking?.totalWorkTimeSecond || 0);
  }, [currentTeamMember?.workStatus, setTotalWorkCounter, workTracking]);

  useEffect(() => {
    //休憩中の場合は作業時間カウントをストップする
    if (currentTeamMember?.workStatus !== WorkStatus.WORKING) return;

    const timeoutId = setTimeout(() => {
      setTotalWorkCounter((prevCounter) => {
        return prevCounter + 1;
      });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [currentTeamMember?.workStatus, setTotalWorkCounter, totalWorkCounter]);

  // タスク別の作業時間のカウンター
  const [doingTaskCounter, setDoingTaskCounter] =
    useRecoilState(doingTaskCounterAtom);

  useEffect(() => {
    logger.debug("タスク経過時間を現在時刻に合わせる");
    const doingTask = workTracking?.tasks.filter(
      (task) => task.id === currentTeamMember?.doingTaskId
    )[0];
    setDoingTaskCounter(doingTask?.workTimeSecond || 0);
  }, [
    currentTeamMember?.doingTaskId,
    setDoingTaskCounter,
    workTracking?.tasks,
  ]);

  useEffect(() => {
    //休憩中の場合は作業時間カウントをストップする
    if (currentTeamMember?.workStatus !== WorkStatus.WORKING) return;

    const timeoutId = setTimeout(() => {
      setDoingTaskCounter((prevCounter) => {
        return prevCounter + 1;
      });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [currentTeamMember?.workStatus, setDoingTaskCounter, doingTaskCounter]);

  return {
    currentTeamMember,
    workTracking,
    doingTaskTrackingTime: formatWorkingTaskTime({
      sec: doingTaskCounter,
      type: "hour",
    }),
    workTrackingTime: formatWorkingTaskTime({
      sec: totalWorkCounter,
      type: "hour",
    }),
  };
};
