import {
  UnstyledButton,
  UnstyledButtonProps,
  useMantineTheme,
} from "@mantine/core";
import { ReactNode } from "react";

interface UnstyledButtonWrapperProps extends UnstyledButtonProps {
  children: ReactNode;
  p?: number | string;
}

const UnstyledButtonWrapper: React.FC<UnstyledButtonWrapperProps> = ({
  children,
  p,
  sx,
  ...props
}) => {
  const theme = useMantineTheme();
  const defaultP = theme.spacing.xs;
  return (
    <UnstyledButton
      p={p ?? defaultP}
      w={"100%"}
      sx={{
        ...sx,
        "&:hover": {
          backgroundColor: theme.colors.dark[6],
        },
      }}
      {...props}
    >
      {children}
    </UnstyledButton>
  );
};

export default UnstyledButtonWrapper;
