export enum SWR_KEYS {
  QUERY_TEAM = "query/team",
  QUERY_TEAM_MEMBER = "query/teamMember",
  QUERY_TEAM_MEMBERS = "query/teamMembers",
  QUERY_TEAM_MEMBER_INVITATIONS = "query/teamMemberInvitations",
  QUERY_BELONG_TEAMS = "query/belongTeams",
  QUERY_USER = "query/user",
  QUERY_ACTIVITIES = "query/activities",
  QUERY_PROJECTS = "query/projects",
  QUERY_PROJECT_TAGS = "query/projectTags",
  QUERY_PROJECT_MEMBERS = "query/projectMembers",
  QUERY_PROJECT_ALERTS = "query/projectAlerts",
  QUERY_USER_PROJECTS = "query/userProjects",
  SUBSCRIPTION_TEAM_MEMBERS = "subscription/teamMembers",
  SUBSCRIPTION_ACTIVITIES = "subscription/activities",
  SCHEDULER_SNAPSHOT = "scheduler/snapshot",
  QUERY_TASKS = "query/tasks",
  QUERY_WORKS = "query/works",
  QUERY_WORKS_CALENDAR = "query/worksCalendar",
  QUERY_WORK_TRACKING = "query/workTracking",
  QUERY_WORK_TASKS = "query/workTasks",
  QUERY_TEAM_MEMBER_WORK_TRACKING = "query/teamMemberWorkTracking",
  QUERY_TEAM_MEMBER_TASKS = "query/teamMemberTasks",
  QUERY_PLANS = "query/plans",
  QUERY_REPORTS_DETAIL = "query/reportsDetail",
  QUERY_REPORTS_SUMMARY = "query/reportsSummary",
  QUERY_REPORTS_SUMMARY_PERCENTAGE = "query/reportSummaryPercentages",
  QUERY_LATEST_CONTRACT = "query/latestContract",
  QUERY_PAID_CONTRACTS_COUNT = "query/paidContractsCount",
  QUERY_NEXT_INVOICE = "query/nextInvoice",
  QUERY_NEXT_INVOICE_PREVIEW = "query/nextInvoicePreview",
  QUERY_STRIPE_CUSTOMER = "query/stripeCustomer",
  QUERY_SETTLEMENT_HISTORIES = "query/settlementHistories",
  QUERY_TEAM_ALLOWED_IP_ADDRESSES = "query/teamAllowedIpAddresses",
  QUERY_TEAM_GITHUB_LINKAGE = "query/teamGithubLinkage",
  QUERY_TEAM_GITHUB_LINKAGE_PROJECTS = "query/teamGithubLinkageProjects",
  QUERY_GITHUB_REPOSITORIES = "query/githubRepositories",
  QUERY_GITHUB_ISSUES = "query/githubIssues",
  QUERY_TEAM_BACKLOG_LINKAGE = "query/teamBacklogLinkage",
  QUERY_TEAM_BACKLOG_LINKAGE_PROJECTS = "query/teamBacklogLinkageProjects",
  QUERY_BACKLOG_PROJECTS = "query/backlogProjects",
  QUERY_TEAM_GOOGLE_CALENDAR_LINKAGE = "query/teamGoogleCalendarLinkage",
  QUERY_GOOGLE_CALENDAR_EVENT = "query/googleCalendarEvent",
}

export const getSWRKey = (key: SWR_KEYS, ...args: string[]) => {
  return key.replace(/%s/g, () => args.shift() || "");
};
