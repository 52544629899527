import { getExtension } from "@/utils/file";
import { GetObjectCommand, PutObjectCommand } from "@aws-sdk/client-s3";
import { useCallback } from "react";
import { ulid } from "ulid";
import { useS3 } from "./useS3";

const signedUrlKeyCache = new Map<string, string>();
export const useContents = () => {
  const s3 = useS3();

  const getSignedUrl = useCallback(
    async (key: string, options: { cache?: boolean } = { cache: false }) => {
      if (!key) return "";

      if (options.cache && signedUrlKeyCache.has(key)) {
        return signedUrlKeyCache.get(key)!;
      }

      const signedUrl = await s3.getSignedUrl(
        new GetObjectCommand({
          Bucket: process.env.NEXT_PUBLIC_S3_CONTENTS_BUCKET_NAME!,
          Key: key,
        }),
        { expiresIn: 86400 } // 1時間
      );

      if (options.cache) {
        signedUrlKeyCache.set(key, signedUrl);
      }

      return signedUrl;
    },
    [s3]
  );

  const uploadFile = useCallback(
    async ({
      key,
      file,
      tagging,
    }: {
      key: string;
      file: File;
      tagging?: string;
    }) => {
      const response = await s3.putObject(
        new PutObjectCommand({
          Bucket: process.env.NEXT_PUBLIC_S3_CONTENTS_BUCKET_NAME!,
          Key: key,
          Body: file,
          ContentType: file.type,
          Tagging: tagging,
        })
      );

      return {
        key,
        signedUrl: await getSignedUrl(key),
      };
    },
    [getSignedUrl, s3]
  );

  // チーム画像をアップロード
  const uploadTeamIcon = useCallback(
    async ({ teamId, file }: { teamId: string; file: File }) =>
      uploadFile({
        key: `public/teams/${teamId}/images/team-icon/team-icon-${ulid()}.${getExtension(
          file.name
        )}`,
        file,
      }),
    [uploadFile]
  );

  // スナップショットをアップロード
  const uploadTeamMemberSnapshot = useCallback(
    async ({
      teamId,
      teamMemberId,
      file,
    }: {
      teamId: string;
      teamMemberId: string;
      file: File;
    }) =>
      uploadFile({
        key: `teams/${teamId}/team-members/${teamMemberId}/images/snapshots/snapshot-${ulid()}.${getExtension(
          file.name
        )}`,
        file,
        tagging: "IsSnapshot=true",
      }),
    [uploadFile]
  );

  // プロフィール画像をアップロード
  const uploadUserProfileImage = useCallback(
    async ({ userId, file }: { userId: string; file: File }) =>
      uploadFile({
        key: `public/users/${userId}/images/profile/profile-${ulid()}.${getExtension(
          file.name
        )}`,
        file,
      }),
    [uploadFile]
  );

  return {
    getSignedUrl,
    uploadTeamIcon,
    uploadUserProfileImage,
    uploadTeamMemberSnapshot,
  };
};
