import { NotificationAlert } from "@/components/NotificationAlert";
import { NotificationSuccess } from "@/components/NotificationSuccess";
import { useTeamMemberInvitations } from "@/hooks/features/useTeamMemberInvitations";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { TeamRoleOptions } from "@/libs/api/constants";
import { ConflictError, ForbiddenError } from "@/libs/api/errors";
import { TeamRole } from "@/libs/api/generated/enum";
import { localizedYup } from "@/utils/localized-yup";
import { messages } from "@/utils/messages";
import { validationRules } from "@/utils/yup-rules";
import {
  Button,
  Flex,
  Modal,
  MultiSelect,
  Select,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";

interface FormProps {
  email: string;
  role: TeamRole;
  projectIds: string[];
}

const schema = localizedYup.object({
  email: validationRules.teamMember.email.required(),
  role: validationRules.teamMember.role.required(),
  projectIds: localizedYup.array().min(1),
});

type Props = {
  opened: boolean;
  onCloseDialog: () => void;
};

function ModelInviteMembers(props: Props): JSX.Element {
  const { onCloseDialog, opened } = props;
  const { inviteTeamMember } = useTeamMemberInvitations();
  const { teamMembers } = useTeamMembersQuery();
  const { activeProjects } = useProjectsQuery();
  const [loading, setLoading] = useState(false);
  const [isSelectOpened, setIsSelectOpened] = useState(false);

  const onDropdown = useCallback(
    (opened: boolean) => () => setIsSelectOpened(opened),
    []
  );

  const inviteProjectList = useMemo(
    () =>
      activeProjects.map((project) => ({
        value: project.id,
        label: project.name,
      })),
    [activeProjects]
  );

  const form = useForm<FormProps>({
    initialValues: { email: "", role: TeamRole.MEMBER, projectIds: [] },
    validate: yupResolver(schema),
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (
      _.find(teamMembers, (teamMember) => {
        return teamMember.user.email === values.email;
      })
    ) {
      // チームメンバーに同じメールアドレスが存在
      NotificationAlert({ message: messages.inviteTeamMember.alreadyExists });
      return;
    }
    try {
      setLoading(true);
      await inviteTeamMember([
        {
          email: values.email,
          role: values.role,
          // text[]は{"xxx", "xxx",...}の形にして渡す
          projectIds: JSON.stringify(values.projectIds)
            .replace("[", "{")
            .replace("]", "}"),
        },
      ]);

      form.reset();
      NotificationSuccess({ message: messages.inviteTeamMember.success });
    } catch (error: any) {
      if (error instanceof ForbiddenError) {
        // Hasuraパーミッションエラー
        NotificationAlert({ message: messages.inviteTeamMember.quantityOver });
      } else if (error instanceof ConflictError) {
        // 既に招待済み
        NotificationAlert({
          message: messages.inviteTeamMember.alreadyInviteExists,
        });
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
      onCloseDialog();
    }
  });

  return (
    <>
      <Modal
        centered
        opened={opened}
        onClose={onCloseDialog}
        title="メンバー招待"
        size={480}
        closeOnClickOutside={true}
      >
        <form onSubmit={onSubmit}>
          <Text ta={"center"} sx={{ whiteSpace: "pre-wrap" }}>
            {`入力されたメールアドレス宛に招待メールを送信します。\n\n招待されたユーザーは招待メールのリンクからアカウントを作成しチームに参加することができます。`}
          </Text>
          <Space h={"xl"} />
          <TextInput
            withAsterisk
            label="メールアドレス"
            placeholder="example@example.com"
            {...form.getInputProps("email")}
          />
          <Space h={16} />
          <Select
            label="権限"
            withAsterisk
            data={TeamRoleOptions}
            {...form.getInputProps("role")}
          />
          <Space h={16} />
          <MultiSelect
            label="プロジェクト"
            placeholder="選択してください"
            withAsterisk
            dropdownPosition="bottom"
            searchable
            data={inviteProjectList}
            maxDropdownHeight={144}
            onDropdownOpen={onDropdown(true)}
            onDropdownClose={onDropdown(false)}
            {...form.getInputProps("projectIds")}
          />
          {isSelectOpened && <Space h={128} />}
          <Space h={32} />
          <Flex justify={"center"}>
            <Button type="submit" loading={loading} disabled={loading}>
              招待メール送信
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
}

export default ModelInviteMembers;
