import { useProjectTagQuery } from "@/hooks/queries/useProjectTagQuery";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { localizedYup } from "@/utils/localized-yup";
import { validationRules } from "@/utils/yup-rules";
import { Flex, Select, SelectItem, Text, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import React, { forwardRef, useCallback, useState } from "react";
import { FormEditWorkTaskProps } from "./ModalAnalysisDetailsTaskEdit";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
}

// バリデーションルール
export const FormRowAddTaskSchema = localizedYup.object().shape({
  tasks: localizedYup.array().of(
    localizedYup.object({
      projectId: validationRules.task.projectId.required(),
      projectTagId: validationRules.task.projectTagId.required(),
      name: validationRules.task.name.required(),
      lock: validationRules.task.lock.required(),
    })
  ),
});

type Props = {
  form: UseFormReturnType<
    FormEditWorkTaskProps,
    (values: FormEditWorkTaskProps) => FormEditWorkTaskProps
  >;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>
    </div>
  )
);
SelectItem.displayName = "SelectItem";

const CreateAnalysisDetailsTaskRow: React.FC<Props> = (props) => {
  const { form } = props;
  const { projects } = useProjectsQuery();
  const [projectId, setProjectId] = useState(form.values.projectId);
  const { projectTags, mutateProjectTags } = useProjectTagQuery({
    projectId,
  });

  const onChangeProject = useCallback(
    async (e: string) => {
      setProjectId(e);
      form.setValues({
        ...form.values,
        projectId: e,
        projectTaskTagId: undefined,
      });

      await mutateProjectTags();
    },
    [form, mutateProjectTags]
  );

  const filter = useCallback(
    (value: string, item: SelectItem) =>
      item.label!.toLowerCase().includes(value.toLowerCase().trim()),
    []
  );

  if (!projects || !projectTags) {
    return <></>;
  }

  return (
    <Flex gap={16} align={"flex-start"}>
      <Select
        id="project"
        w={160}
        placeholder="プロジェクト名"
        itemComponent={SelectItem}
        data={projects.map((project) => ({
          label: project.name,
          value: project.id,
        }))}
        maxDropdownHeight={160}
        nothingFound="プロジェクトが見つかりません"
        searchable
        dropdownPosition="bottom"
        filter={filter}
        data-autofocus
        withinPortal
        onChange={onChangeProject}
        value={form.getInputProps(`projectId`).value}
      />
      <Select
        id="tag"
        w={160}
        placeholder="タグ名"
        itemComponent={SelectItem}
        data={projectTags.map((tag) => ({
          label: tag.name,
          value: tag.id,
        }))}
        maxDropdownHeight={460}
        nothingFound="タグが見つかりません"
        searchable
        dropdownPosition="bottom"
        filter={filter}
        withinPortal
        {...form.getInputProps(`projectTaskTagId`)}
      />
      <TextInput
        id="taskName"
        placeholder="タスク名"
        autoComplete="off"
        sx={{ flex: 1 }}
        {...form.getInputProps(`taskName`)}
      />
    </Flex>
  );
};
export default CreateAnalysisDetailsTaskRow;
