import { useDevice } from "@/hooks/features/useDevice";
import { useEndOfWorks } from "@/hooks/features/useEndOfWorks";
import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { TaskStatus } from "@/libs/api/generated/enum";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  Paper,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import { IconAdjustments, IconPlus } from "@tabler/icons";
import { useCallback } from "react";
import { FormBreakTime } from "./FormBreakTime";
import { FormWorkingTime } from "./FormWorkingTime";
import TooltipWrapper from "./new/common/TooltipWrapper";
import TaskCardSmall from "./new/teamBoard/TaskCardSmall";
import { TaskStatusToTaskConstant } from "./new/userTasks/constant";
import { AttendanceTimeResult } from "./pages/works/AttendanceTimeResult";
import { CustomTimeInput } from "./pages/works/CustomTimeInput";
import { EndOfWorkButton } from "./pages/works/EndOfWorkButton";

export const ModalEndOfWork = ({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}): JSX.Element => {
  const { currentTeamMember } = useTeamMembersQuery();
  const teamMemberId = currentTeamMember?.id as string;
  const { isTabletOrSmartphone } = useDevice();

  const {
    form,
    startedDateView,
    attendanceTimeResult,
    isEndOfWorkValid,
    submitting,
    attendanceDiffTime,
    breakDiffTimes,
    onSubmit,
    addBreakTime,
    deleteBreakTime,
    diffTimeAdjustment,
  } = useEndOfWorks(teamMemberId);

  const onClickIcon = useCallback(
    (index: number) => () => deleteBreakTime(index),
    [deleteBreakTime]
  );

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={`${startedDateView}勤怠入力`}
      size={640}
      closeOnEscape={false}
      closeOnClickOutside={false}
      zIndex={9999}
    >
      {isTabletOrSmartphone && (
        <Modal.Header w={"100%"} p={8}>
          <Flex gap={16} justify={"space-between"} w={"100%"}>
            <AttendanceTimeResult {...attendanceTimeResult} />
            <EndOfWorkButton
              loading={submitting}
              disabled={!isEndOfWorkValid || submitting}
              onClick={onSubmit}
            />
          </Flex>
        </Modal.Header>
      )}
      <Modal.Body
        p={0}
        mah={isTabletOrSmartphone ? "auto" : 800}
        sx={{
          overflowY: "auto",
          position: "relative",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {/* SP表示 */}
        {isTabletOrSmartphone && (
          <Box>
            <Box mt={16}>
              <Stack spacing={16}>
                {/* 作業時間フォーム */}
                <Stack spacing={4}>
                  <Text size="xs">作業</Text>
                  <FormWorkingTime {...{ attendanceDiffTime, form }} />
                </Stack>
                {/* 休憩時間フォーム */}
                <Stack spacing={4}>
                  <Text size="xs">休憩</Text>
                  {form.values.breakTimes.map((_: any, i: number) => (
                    <FormBreakTime
                      key={i}
                      form={form}
                      breakDiffTimes={breakDiffTimes}
                      index={i}
                      onClickIcon={onClickIcon}
                    />
                  ))}
                  <Box mt={4}>
                    <Button
                      color="gray"
                      leftIcon={<IconPlus size={18} />}
                      size="xs"
                      onClick={addBreakTime}
                    >
                      休憩時間を追加
                    </Button>
                  </Box>
                </Stack>
                <Divider my="sm" />
                {/* タスクカード */}
                <Stack spacing={"xs"}>
                  {form.values.tasks.length ? (
                    form.values.tasks.map((task, i) => (
                      <Flex key={i} justify={"space-between"} align={"center"}>
                        <TaskCardSmall
                          {...task}
                          workTime={null}
                          status={TaskStatusToTaskConstant(task.status)}
                          active={task.status === TaskStatus.DOING}
                        />
                        <Space w={12} />
                        <CustomTimeInput
                          {...form.getInputProps(`tasks.${i}.workTime`)}
                        />
                        <Space w={12} />
                        <TooltipWrapper
                          label={"差分時間調整"}
                          position="bottom-start"
                          zIndex={9999}
                        >
                          <ActionIcon
                            size={"sm"}
                            variant="default"
                            onClick={diffTimeAdjustment(i)}
                          >
                            <IconAdjustments size={18} />
                          </ActionIcon>
                        </TooltipWrapper>
                      </Flex>
                    ))
                  ) : (
                    <Paper withBorder w={"100%"} p={16}>
                      タスクがありません
                    </Paper>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Box>
        )}
        {/* PC表示 */}
        {!isTabletOrSmartphone && (
          <div>
            <AttendanceTimeResult {...attendanceTimeResult} />
            <div>
              <Stack spacing={16}>
                {/* 作業時間フォーム */}
                <Stack spacing={"xs"}>
                  <Text size="sm">作業</Text>
                  <FormWorkingTime {...{ attendanceDiffTime, form }} />
                </Stack>
                {/* 休憩時間フォーム */}
                <Stack spacing={"xs"}>
                  <Text size="sm">休憩</Text>
                  {form.values.breakTimes.map((_: any, i: number) => (
                    <FormBreakTime
                      key={i}
                      form={form}
                      breakDiffTimes={breakDiffTimes}
                      index={i}
                      onClickIcon={onClickIcon}
                    />
                  ))}
                  <div>
                    <Button
                      color="gray"
                      leftIcon={<IconPlus size={18} />}
                      size="sm"
                      onClick={addBreakTime}
                    >
                      休憩時間を追加
                    </Button>
                  </div>
                </Stack>
                <Divider my="sm" />
                {/* タスクカード */}
                <Stack spacing={"xs"}>
                  {form.values.tasks.length ? (
                    form.values.tasks.map((task, i) => (
                      <Flex key={i} justify={"space-between"} align={"center"}>
                        <TaskCardSmall
                          {...task}
                          workTime={null}
                          status={TaskStatusToTaskConstant(task.status)}
                          active={task.status === TaskStatus.DOING}
                          fullWidth
                        />
                        <Space w={12} />
                        <CustomTimeInput
                          {...form.getInputProps(`tasks.${i}.workTime`)}
                        />
                        <Space w={12} />
                        <TooltipWrapper
                          label={"差分時間調整"}
                          position="bottom-start"
                          zIndex={9999}
                        >
                          <ActionIcon
                            size={"sm"}
                            variant="default"
                            onClick={diffTimeAdjustment(i)}
                          >
                            <IconAdjustments size={18} />
                          </ActionIcon>
                        </TooltipWrapper>
                      </Flex>
                    ))
                  ) : (
                    <Paper withBorder w={"100%"} p={16}>
                      タスクがありません
                    </Paper>
                  )}
                </Stack>
                <Divider my={32}></Divider>
                <Flex justify={"center"}>
                  <EndOfWorkButton
                    loading={submitting}
                    disabled={!isEndOfWorkValid || submitting}
                    onClick={onSubmit}
                  />
                </Flex>
                <Space h={16}></Space>
              </Stack>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
