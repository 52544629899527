import { ModalInnerActivity } from "@/components/pages/activities/ModalInnerActivity";
import { useActivitiesQuery } from "@/hooks/queries/useActivitiesQuery";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { useDialog } from "@/hooks/useDialog";
import { ActivityType } from "@/libs/api/generated/enum";
import { Box, Divider, Flex, Loader, Modal } from "@mantine/core";
import { IconActivity } from "@tabler/icons";
import { SetStateAction, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ActionIconWrapper from "../../common/ActionIconWrapper";
import TooltipWrapper from "../../common/TooltipWrapper";
import AsideSectionBodyWrapper from "../AsideSectionBodyWrapper";
import AsideSectionHeader from "../AsideSectionHeader";
import ActivityDates from "./ActivityDates";
import { ActivityDateType, ActivityStatus } from "./constant";

type ActivitySectionType = {
  show: boolean;
  toggle: (value?: SetStateAction<boolean> | undefined) => void;
  style: any;
};

const ActivitySection: React.FC<ActivitySectionType> = ({
  show,
  toggle,
  style,
}) => {
  const { openDialog, closeDialog, isDialogMode } =
    useDialog<"activityDialog">("");
  const onClickActionIconWrapper = useCallback(
    () => openDialog("activityDialog"),
    [openDialog]
  );
  const { activeProjects } = useProjectsQuery();
  const { liveActivitiesGroupByDate, liveActivities, nextActivityPage } =
    useActivitiesQuery({
      teamMemberIds: [],
      projectIds: activeProjects.map((project) => project.id),
      enableSubscription: true,
    });

  const loader = liveActivities.length ? (
    <Flex justify={"center"}>
      <Loader key={0} />
    </Flex>
  ) : (
    <></>
  );

  return (
    <>
      <Flex direction={"column"} sx={{ overflow: "hidden" }} style={style}>
        <AsideSectionHeader
          label="アクティビティ"
          show={show}
          toggle={toggle}
          buttonEl={
            <TooltipWrapper position="bottom" label="アクティビティ">
              <ActionIconWrapper m={6} onClick={onClickActionIconWrapper}>
                <IconActivity size="19px" stroke={1.0} />
              </ActionIconWrapper>
            </TooltipWrapper>
          }
        />
        <Divider />
        <AsideSectionBodyWrapper show={show}>
          <InfiniteScroll
            dataLength={liveActivities.length} //現在のデータの長さ
            next={nextActivityPage} // スクロール位置を監視してコールバック（次のデータを読み込ませる）
            hasMore={true} // さらにスクロールするかどうか（ある一定数のデータ数に達したらfalseを返すことで無限スクロールを回避）
            loader={loader} // ローディング中のコンポーネント
            height={"790px"} // 高さ
          >
            {liveActivitiesGroupByDate.map((item, i) => {
              // TODO:ActivityViewの改修
              const activityDate: ActivityDateType = {
                date: item.date,
                activities: item.activities.map((activity) => ({
                  time: activity.activityTime,
                  user: {
                    name: `${activity.lastName} ${activity.firstName}`,
                    avatar: activity.raw.teamMember?.user.imageFileName
                      ? `https://factog-${process.env.NEXT_PUBLIC_STAGE}-contents.s3.ap-northeast-1.amazonaws.com/${activity.raw.teamMember?.user.imageFileName}`
                      : "",
                    teamMemberId: activity.teamMemberId,
                  },
                  content: {
                    type:
                      activity.activityType === ActivityType.STARTED
                        ? ActivityStatus.START
                        : activity.activityType === ActivityType.SUSPENDED
                        ? ActivityStatus.BREAK
                        : activity.activityType === ActivityType.RESUMED
                        ? ActivityStatus.RESTART
                        : activity.activityType === ActivityType.FINISHED
                        ? ActivityStatus.FINISH
                        : ActivityStatus.TASK,
                    workTime: activity.workTime,
                    projectName: activity.projectName,
                    tagName: activity.projectTagName,
                    taskName: activity.task?.name,
                  },
                  task: activity.task,
                })),
              };
              // MEMO: typeがTASKかつprojectNameとprojectTagNameとtaskNameが存在しないものは除外
              // (自分が属していないプロジェクトのメンバータスク)
              const filteredActivityDate = {
                ...activityDate,
                activities: activityDate.activities.filter(
                  (activity) =>
                    activity.content.type !== ActivityStatus.TASK ||
                    (activity.content.type === ActivityStatus.TASK &&
                      activity.content.projectName &&
                      activity.content.tagName &&
                      activity.content.taskName)
                ),
              };
              return (
                <Box key={i}>
                  <ActivityDates {...filteredActivityDate}></ActivityDates>
                </Box>
              );
            })}
          </InfiniteScroll>
        </AsideSectionBodyWrapper>
      </Flex>
      {isDialogMode("activityDialog") && (
        <Modal
          centered
          opened={isDialogMode("activityDialog")}
          onClose={closeDialog}
          title="アクティビティ"
          size={640}
        >
          <ModalInnerActivity />
        </Modal>
      )}
    </>
  );
};

export default ActivitySection;
