import { TaskStatus } from "@/libs/api/generated/enum";

export const TaskConstant = {
  TASK_BOX: "TASK_BOX",
  TODAYS_TASK: "TODAYS_TASK",
  SUSPEND_TASK: "SUSPEND_TASK",
  DONE_TASK: "DONE_TASK",
} as const;

export type TaskConstantObjectType = typeof TaskConstant;
export type TaskConstantType = (typeof TaskConstant)[keyof typeof TaskConstant];

export const TaskConstantToTaskStatus = (status?: string): TaskStatus =>
  status === TaskConstant.TASK_BOX
    ? TaskStatus.TODO
    : status === TaskConstant.DONE_TASK
    ? TaskStatus.DONE
    : status === TaskConstant.SUSPEND_TASK
    ? TaskStatus.SUSPEND
    : TaskStatus.TODAY;

export const TaskStatusToTaskConstant = (status?: string): TaskConstantType =>
  status === TaskStatus.DONE
    ? TaskConstant.DONE_TASK
    : status === TaskStatus.SUSPEND
    ? TaskConstant.SUSPEND_TASK
    : status === TaskStatus.TODAY || status === TaskStatus.DOING
    ? TaskConstant.TODAYS_TASK
    : TaskConstant.TASK_BOX;
