import { ActivityType } from "@/libs/api/generated/enum";
import { ActivityAttributesFragment } from "@/libs/api/generated/types";
import { formatDateWithWeek, formatTime, minuteToHour } from "@/utils/date";
import dayjs from "dayjs";
import { TaskView, toTaskView } from "./TasksView";

export type ActivityView = {
  raw: ActivityAttributesFragment;
  activityType: ActivityType;
  activityId: string;
  activityDate: string;
  activityTime: string;
  workTime?: string;
  lastName: string;
  firstName: string;
  teamMemberId: string;
  projectName?: string;
  projectTagName?: string;
  task: TaskView | null;
};

// 表示用のアクティビティ情報に変換
export const toActivityView = (
  activity: ActivityAttributesFragment
): ActivityView => {
  let workTime;
  if (activity.workTracking) {
    const { workTracking } = activity;
    const diffInMinutes =
      dayjs(workTracking.work?.finishedAt).diff(
        dayjs(workTracking.work?.startedAt).startOf("minutes"),
        "minute"
      ) - (workTracking.work?.breakMinutes || 0);

    // 作業時間を算出
    workTime = minuteToHour(diffInMinutes, {
      isJapanese: true,
    });
  }

  return {
    raw: activity,
    activityType: activity.type as ActivityType,
    activityId: activity.id,
    activityDate: formatDateWithWeek(activity.createdAt),
    activityTime: formatTime(activity.createdAt),
    workTime,
    lastName: activity.teamMember?.user.lastName as string,
    firstName: activity.teamMember?.user.firstName as string,
    teamMemberId: activity.teamMemberId,
    projectName: activity.task?.project?.name || "",
    projectTagName: activity.task?.projectTaskTag?.name || "",
    task: activity.task ? toTaskView(activity.task) : null,
  };
};
