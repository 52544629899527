import { AddTaskPage } from "@/libs/api/constants";
import { addTaskAtom, selectedTasksAtom } from "@/stores";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Loader,
  MantineTheme,
  NavLink,
  Paper,
  ScrollArea,
  Space,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconArrowsUpRight, IconX } from "@tabler/icons";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilState } from "recoil";
import { ulid } from "ulid";
import { sleep } from "../../../utils/test";
import { useTeamGithubLinkageProjectsQuery } from "@/hooks/queries/useTeamGithubLinkageProjectsQuery";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { useGithubRepositoriesQuery } from "@/hooks/queries/useGithubRepositoriesQuery";
import { useTeamBacklogLinkageProjectsQuery } from "@/hooks/queries/useTeamBacklogLinkageProjectsQuery";
import { useTeamGoogleCalendarLinkageQuery } from "@/hooks/queries/useTeamGoogleCalendarLinkageQuery";
import { useBacklogProjectsQuery } from "@/hooks/queries/useBacklogProjectsQuery";
import { useGoogleCalendarEventQuery } from "@/hooks/queries/useGoogleCalendarEventQuery";
import { useGithubIssuesQuery } from "@/hooks/queries/useGithubIssuesQuery";
import { useBacklogIssuesQuery } from "@/hooks/queries/useBacklogIssuesQuery";
import _ from "lodash";
import { useTeamGithubLinkageQuery } from "@/hooks/queries/useTeamGithubLinkageQuery";
import { useTeamBacklogLinkageQuery } from "@/hooks/queries/useTeamBacklogLinkageQuery";

export enum ProviderName {
  GOOGLE_CALENDAR = "GoogleCalendar",
  GITHUB = "Github",
  BACKLOG = "Backlog",
}

type GoogleCalendarEvent = {
  providerName: ProviderName.GOOGLE_CALENDAR;
};

type GithubProject = {
  providerName: ProviderName.GITHUB;
  // projectLinkId
  id: string;
  // 外部サービス
  organizationName: string;
  repositoryId: number;
  from: string;
  // factogプロジェクト
  to: string;
  projectId: string;
};

type BacklogProject = {
  providerName: ProviderName.BACKLOG;
  // projectLinkId
  backlogUrl: string;
  id: string;
  // 外部サービス
  backlogProjectId: number;
  from: string;
  // factogプロジェクト
  to: string;
  projectId: string;
};

type Project = GoogleCalendarEvent | GithubProject | BacklogProject;

export type Task = {
  id: string;
  text: string;
  providerName: ProviderName;
  projectFrom: string;
  // factogプロジェクトと紐づいてる場合設定
  projectTo?: string;
  projectId?: string;
  // 外部サービスID
  serviceId?: string;
};

type Provider = {
  name: string;
  src: string;
  projects: Array<Project>;
};

export const FormSelectLinkedTasks = (): JSX.Element => {
  const { teamGithubLinkages } = useTeamGithubLinkageQuery();
  const { teamGithubLinkageProjects } = useTeamGithubLinkageProjectsQuery();
  const { teamBacklogLinkages } = useTeamBacklogLinkageQuery();
  const { teamBacklogLinkageProjects } = useTeamBacklogLinkageProjectsQuery();
  const { teamGoogleCalendarLinkage } = useTeamGoogleCalendarLinkageQuery();

  const { getGithubRepositories } = useGithubRepositoriesQuery();
  const { getGithubIssues } = useGithubIssuesQuery();
  const { getBacklogProjects } = useBacklogProjectsQuery();
  const { getBacklogIssues } = useBacklogIssuesQuery();
  const { getGoogleCalendarEvent } = useGoogleCalendarEventQuery();

  // State==================================================
  // モーダル内ページ数
  const [addTaskState, setAddTaskState] = useRecoilState(addTaskAtom);
  // 外部サービス連携プロジェクト
  const [providers, setProviders] = useState<Provider[]>([]);
  const [linkBacklogProjects, setLinkBacklogProjects] = useState<
    BacklogProject[]
  >([]);
  const [linkGithubProjects, setLinkGithubProjects] = useState<GithubProject[]>(
    []
  );
  // 選択されたプロジェクト
  const [selectedProject, setSelectedProject] = useState<Project>();
  // 検索
  const [searchValue, setSearchValue] = useState("");
  // タスク一覧
  const [tasks, setTasks] = useState<Task[]>([]);
  // 選択済みタスク一覧
  const [selectedTasks, setSelectedTasks] = useRecoilState(selectedTasksAtom);
  // githubとbacklogのissue一覧のページ
  const [page, setPage] = useState<number>(1);
  // Googleカレンダーの次のページ
  const [nextPageToken, setNextPageToken] = useState<string | null | undefined>(
    undefined
  );
  // 全てのタスクを取得でtrue
  const [isTaskLimit, setIsTaskLimit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Effects================================================
  useEffect(() => {
    void (async () => {
      const linkGithubProjects: GithubProject[] = [];

      for (const teamGithubLinkageProject of teamGithubLinkageProjects) {
        const linkage = teamGithubLinkages.find(
          (teamGithubLinkage) =>
            teamGithubLinkage.teamId === teamGithubLinkageProject.teamId &&
            teamGithubLinkage.id === teamGithubLinkageProject.linkId
        );

        if (!linkage || linkage.isRefreshTokenExpired) continue;

        const repositories = await getGithubRepositories({
          organizationName: linkage.organizationName as string,
        });

        const linkRepository = repositories.find(
          (repository) =>
            repository.id === teamGithubLinkageProject.githubRepositoryId
        );

        linkGithubProjects.push({
          id: teamGithubLinkageProject.id,
          providerName: ProviderName.GITHUB,
          organizationName: linkage.organizationName as string, // 連携済みの場合組織名は必須
          repositoryId: teamGithubLinkageProject.githubRepositoryId,
          from: linkRepository?.name || "",
          to: teamGithubLinkageProject?.project.name || "",
          projectId: teamGithubLinkageProject?.project.id || "",
        });
      }
      setLinkGithubProjects(linkGithubProjects);
    })();
  }, [getGithubRepositories, teamGithubLinkageProjects, teamGithubLinkages]);

  useEffect(() => {
    void (async () => {
      const linkBacklogProjects: BacklogProject[] = [];
      for (const teamBacklogLinkageProject of teamBacklogLinkageProjects) {
        const linkage = teamBacklogLinkages.find(
          (teamBacklogLinkage) =>
            teamBacklogLinkage.teamId === teamBacklogLinkageProject.teamId &&
            teamBacklogLinkage.id === teamBacklogLinkageProject.linkId
        );

        if (!linkage) continue;

        const projects = await getBacklogProjects({
          backlogUrl: linkage.backlogUrl,
        });

        const linkProject = projects.find(
          (project) => project.id === teamBacklogLinkageProject.backlogProjectId
        );

        linkBacklogProjects.push({
          id: teamBacklogLinkageProject.id,
          providerName: ProviderName.BACKLOG,
          backlogUrl: linkage.backlogUrl,
          backlogProjectId: linkProject?.id as number,
          from: linkProject?.name || "",
          to: teamBacklogLinkageProject?.project.name || "",
          projectId: teamBacklogLinkageProject?.project.id || "",
        });
      }
      setLinkBacklogProjects(linkBacklogProjects);
    })();
  }, [getBacklogProjects, teamBacklogLinkageProjects, teamBacklogLinkages]);

  useEffect(() => {
    void (async () => {
      setProviders([
        {
          name: ProviderName.GOOGLE_CALENDAR,
          src: "/icon-google-calendar.png",
          projects: teamGoogleCalendarLinkage
            ? [
                {
                  providerName: ProviderName.GOOGLE_CALENDAR,
                },
              ]
            : [],
        },
        // TODO: Jiraは設計してない
        // {
        //   name: "Jira (Grandream)",
        //   src: "/icon-jira.png",
        //   projects: projectList({ providerName: "Jira" }),
        // },
        // {
        //   name: "Jira (OKAN)",
        //   src: "/icon-jira.png",
        //   projects: projectList({ providerName: "Jira" }),
        // },
        {
          name: ProviderName.BACKLOG,
          src: "/icon-backlog.png",
          projects: linkBacklogProjects,
        },
        {
          name: ProviderName.GITHUB,
          src: "/icon-github.png",
          projects: linkGithubProjects,
        },
      ]);
    })();
  }, [teamGoogleCalendarLinkage, linkGithubProjects, linkBacklogProjects]);

  const handleSetTasks = useCallback(
    async (params: {
      page?: number;
      nextPageToken?: string;
      searchValue?: string;
      isAdd?: boolean;
    }) => {
      setIsTaskLimit(false);
      const { page, nextPageToken, searchValue, isAdd } = params;

      let tasks: Task[] = [];
      if (selectedProject?.providerName === ProviderName.GITHUB) {
        const issues = await getGithubIssues({
          organizationName: selectedProject.organizationName,
          repositoryName: selectedProject.from,
          page: page || 1,
          target: searchValue,
        });
        if (issues.length < 50) setIsTaskLimit(true);
        tasks = issues.map((s) => {
          return {
            id: s.id.toString(),
            text: s.name,
            providerName: ProviderName.GITHUB,
            projectFrom: selectedProject.from,
            projectTo: selectedProject.to,
            projectId: selectedProject.projectId,
            serviceId: selectedProject.repositoryId.toString(),
          };
        });
        setPage((prev) => prev + 1);
      } else if (selectedProject?.providerName === ProviderName.BACKLOG) {
        const issues = await getBacklogIssues({
          backlogUrl: selectedProject.backlogUrl,
          projectId: selectedProject.backlogProjectId,
          page: page || 1,
          target: searchValue,
        });
        tasks = issues.map((s) => {
          return {
            id: s.id.toString(),
            text: s.name,
            providerName: ProviderName.BACKLOG,
            projectFrom: selectedProject.from,
            projectTo: selectedProject.to,
            projectId: selectedProject.projectId,
            serviceId: selectedProject.backlogProjectId.toString(),
          };
        });
        setPage((prev) => prev + 1);
        if (issues.length < 50) setIsTaskLimit(true);
      } else if (
        selectedProject?.providerName === ProviderName.GOOGLE_CALENDAR
      ) {
        const result = await getGoogleCalendarEvent(nextPageToken);
        tasks = result.events.map((e) => {
          return {
            id: ulid(),
            text: e.summary,
            providerName: ProviderName.GOOGLE_CALENDAR,
            projectFrom: ProviderName.GOOGLE_CALENDAR,
            projectTo: "",
          };
        });
        setNextPageToken(result.nextPageToken);
        if (!result.nextPageToken) setIsTaskLimit(true);
      }

      isAdd ? setTasks((prev) => [...prev, ...tasks]) : setTasks(tasks);
    },
    [selectedProject, getGithubIssues, getBacklogIssues, getGoogleCalendarEvent]
  );

  // プロジェクト選択タスク初期化
  useEffect(() => {
    void (async () => {
      setLoading(true);

      // 検索stateを初期化
      setIsTaskLimit(false);
      setSearchValue("");
      setPage(1);
      setNextPageToken("");

      // タスクセット
      await handleSetTasks({});

      setLoading(false);
    })();
  }, [selectedProject, handleSetTasks]);

  // スクロールで次のタスクを追加
  const fetchMoreData = useCallback(async () => {
    if (isTaskLimit) return;

    await handleSetTasks({
      page,
      nextPageToken: nextPageToken || undefined,
      searchValue,
      isAdd: true,
    });
  }, [isTaskLimit, handleSetTasks, searchValue, page, nextPageToken]);

  // 検索ボタン押下でタスク追加
  const onClickSearchButton = useCallback(async () => {
    setLoading(true);

    // 検索stateを初期化
    setIsTaskLimit(false);
    setPage(1);
    setNextPageToken("");

    // タスクセット
    await handleSetTasks({ searchValue });

    setLoading(false);
  }, [handleSetTasks, searchValue]);

  // Functions================================================
  const handleSetSelectedTasks = useCallback(
    (arg: Task) => {
      const existTask = selectedTasks.some((task) => {
        return task.id === arg.id;
      });
      if (existTask) {
        const newArray = selectedTasks.filter((task) => task.id !== arg.id);
        setSelectedTasks(newArray);
      } else {
        setSelectedTasks([...selectedTasks, arg]);
      }
    },
    [selectedTasks, setSelectedTasks]
  );

  const handleSelectProject = useCallback(async (project: Project) => {
    setSelectedProject(project);
  }, []);

  // Components================================================
  const TaskCard = ({
    text,
    selected,
    onClick,
  }: {
    text: string;
    selected?: boolean;
    onClick: () => void;
  }): JSX.Element => {
    const sx = useCallback(
      (theme: MantineTheme) => ({
        background: theme.colors.dark[5],
        border: selected ? `1px solid ${theme.colors.blue[5]} !important` : "",
        cursor: "pointer",
      }),
      [selected]
    );

    const selectedProjectLabel =
      selectedProject?.providerName === ProviderName.GOOGLE_CALENDAR
        ? `${selectedProject.providerName}`
        : `${selectedProject?.providerName} | ${selectedProject?.from} - ${selectedProject?.to}`;

    return (
      <Paper withBorder p={8} sx={sx} onClick={onClick}>
        <Text size={10}>{selectedProjectLabel}</Text>
        <Text
          size={12}
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            wordBreak: "break-all",
          }}
        >
          {text}
        </Text>
      </Paper>
    );
  };

  const SelectedTaskCard = (taskArg: Task): JSX.Element => {
    const deleteSelectedTask = useCallback(() => {
      const filteredSelectedTasks = selectedTasks.filter(
        (task) => task.id !== taskArg.id
      );
      setSelectedTasks(filteredSelectedTasks);
    }, [taskArg.id]);

    const sx = useCallback(
      (theme: MantineTheme) => ({
        background: theme.colors.dark[5],
      }),
      []
    );

    const taskLabel =
      taskArg.providerName === ProviderName.GOOGLE_CALENDAR
        ? `${taskArg.providerName}`
        : `${taskArg?.providerName} | ${taskArg.projectFrom} - ${taskArg.projectTo}`;
    return (
      <Flex justify={"space-around"}>
        <Paper withBorder p={8} w={"100%"} sx={sx}>
          <Text size={10}>{taskLabel}</Text>
          <Text
            size={"sm"}
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              wordBreak: "break-all",
            }}
          >
            {taskArg.text}
          </Text>
        </Paper>
        <Space w={8}></Space>
        <ActionIcon size={"sm"} onClick={deleteSelectedTask}>
          <IconX />
        </ActionIcon>
      </Flex>
    );
  };

  const onClickNavLink = useCallback(
    (project: Project) => () => handleSelectProject(project),
    [handleSelectProject]
  );

  const onClickTaskCard = useCallback(
    (task: Task) => () => handleSetSelectedTasks(task),
    [handleSetSelectedTasks]
  );

  const onClickDeleteButton = useCallback(() => setSelectedTasks([]), []);

  const onClickSelectButton = useCallback(
    () => setAddTaskState({ page: AddTaskPage.ADD_GOOGLE_TASK }),
    [setAddTaskState]
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSearchValue(event.currentTarget.value),
    []
  );

  return (
    <>
      <Grid>
        {/* LEFT */}
        <Grid.Col
          span={3}
          sx={{ borderRight: "1px solid rgba(255,255,255,0.3)" }}
        >
          <ScrollArea h={480} scrollbarSize="sm">
            {providers.map((provider, i) => {
              return (
                <NavLink
                  key={i}
                  label={<Text truncate>{provider.name}</Text>}
                  icon={<Image src={provider.src} width={24}></Image>}
                  defaultOpened
                >
                  {provider.projects.map((project, j) => {
                    const isGoogleCalendar =
                      project.providerName === ProviderName.GOOGLE_CALENDAR;
                    return (
                      <NavLink
                        key={j}
                        label={
                          <Text truncate>
                            {isGoogleCalendar
                              ? project.providerName
                              : project.from}
                          </Text>
                        }
                        description={
                          isGoogleCalendar ? (
                            <></>
                          ) : (
                            <Flex>
                              <IconArrowsUpRight size={12} />
                              <Text truncate>{project.to}</Text>
                            </Flex>
                          )
                        }
                        active={selectedProject === project}
                        onClick={onClickNavLink(project)}
                      />
                    );
                  })}
                </NavLink>
              );
            })}
          </ScrollArea>
        </Grid.Col>
        {/* CENTER */}
        <Grid.Col span={6}>
          <Box>
            <TextInput
              placeholder="検索..."
              value={searchValue}
              onChange={onChange}
              disabled={
                selectedProject?.providerName === ProviderName.GOOGLE_CALENDAR
              }
            />
            <Space h={8} />

            <Button
              onClick={onClickSearchButton}
              disabled={
                selectedProject?.providerName === ProviderName.GOOGLE_CALENDAR
              }
            >
              絞り込み
            </Button>
            <Space h={24} />
            <Paper withBorder>
              <Box
                p={16}
                sx={{
                  overflowY: "scroll",
                }}
              >
                <InfiniteScroll
                  dataLength={tasks.length} //現在のデータの長さ
                  next={fetchMoreData} // スクロール位置を監視してコールバック（次のデータを読み込ませる）
                  hasMore={!!selectedProject && !isTaskLimit} // さらにスクロールするかどうか（ある一定数のデータ数に達したらfalseを返すことで無限スクロールを回避）
                  loader={
                    <Flex justify={"center"}>
                      <Loader key={0} />
                    </Flex>
                  }
                  height={"420px"} // 高さ
                >
                  {!loading && (
                    <Stack spacing={16}>
                      {tasks.length ? (
                        tasks.map((v, i) => {
                          return (
                            <TaskCard
                              key={i}
                              text={v.text}
                              selected={selectedTasks.some(
                                (task) => task.id === v.id
                              )}
                              onClick={onClickTaskCard(v)}
                            ></TaskCard>
                          );
                        })
                      ) : (
                        <Flex justify={"center"}>
                          <Text size="sm">タスクがありません</Text>
                        </Flex>
                      )}
                    </Stack>
                  )}
                </InfiniteScroll>
              </Box>
            </Paper>
          </Box>
        </Grid.Col>
        {/* RIGHT */}
        <Grid.Col
          span={3}
          sx={{ borderLeft: "1px solid rgba(255,255,255,0.3)" }}
        >
          <Box>
            <Button
              size={"sm"}
              w={"100%"}
              leftIcon={<IconX size={16} />}
              disabled={!selectedTasks.length}
              onClick={onClickDeleteButton}
            >
              すべて削除
            </Button>
            <Space h={16}></Space>
            <Paper withBorder p={8}>
              <ScrollArea h={330} scrollbarSize="xs">
                <Stack spacing={8}>
                  {selectedTasks.map((task, i) => (
                    <SelectedTaskCard key={i} {...task} />
                  ))}
                </Stack>
              </ScrollArea>
            </Paper>
            <Divider my={16}></Divider>
            <Flex justify={"center"} mt={"auto"}>
              <Button
                disabled={_.isEmpty(selectedTasks)}
                onClick={onClickSelectButton}
              >
                選択
              </Button>
            </Flex>
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
};
