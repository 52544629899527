import { isNavExpandedAtom } from "@/stores";
import { Navbar } from "@mantine/core";
import { useRecoilState } from "recoil";
import NavbarContentItems from "./NavbarContentItems";

const NavbarContent: React.FC = () => {
  const [isNavExpanded] = useRecoilState(isNavExpandedAtom);

  return (
    <Navbar
      hidden={!isNavExpanded}
      width={isNavExpanded ? { sm: 188, lg: 188 } : { sm: 40, lg: 40 }}
      sx={{ overflow: "hidden" }}
    >
      <NavbarContentItems />
    </Navbar>
  );
};
export default NavbarContent;
