import { Text } from "@mantine/core";
import ProjectTagTaskLabel from "../../common/ProjectTagTaskLabel";
import { ActivityStatus, ActivityStatusType } from "./constant";

interface ActivityContentProps {
  type: ActivityStatusType;
  workTime?: string;
  projectName?: string;
  tagName?: string;
  taskName?: string;
  truncate?: true | "end" | "start";
}

const ActivityContent: React.FC<ActivityContentProps> = ({
  type,
  workTime,
  projectName,
  tagName,
  taskName,
  truncate,
}) => {
  const fz = 13;
  if (type === ActivityStatus.START) {
    return <Text fz={fz}>🔆 作業開始</Text>;
  } else if (type === ActivityStatus.BREAK) {
    return <Text fz={fz}>☕ 作業中断</Text>;
  } else if (type === ActivityStatus.RESTART) {
    return <Text fz={fz}>🔥 作業再開</Text>;
  } else if (type === ActivityStatus.FINISH && workTime !== undefined) {
    return <Text fz={fz}>{`🚪 作業終了(${workTime})`}</Text>;
  } else if (
    type === ActivityStatus.TASK &&
    projectName &&
    tagName &&
    taskName
  ) {
    return (
      <ProjectTagTaskLabel
        project={projectName}
        tag={tagName}
        task={taskName}
        w={truncate ? "calc(100% - 63px)" : "100%"}
        truncate={truncate}
      />
    );
  }
  return (
    <Text fz={fz} c={"red"}>
      不正な値です
    </Text>
  );
};

export default ActivityContent;
