import { UnauthorizedError } from "@/libs/api/errors";
import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";
import { OrderBy } from "@/libs/api/generated/types";

export const useTeamGithubLinkageQuery = (organizationName?: string) => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();

  const {
    data: teamGithubLinkages,
    isLoading: teamGithubLinkageLoading,
    mutate: mutateTeamGithubLinkages,
  } = useSWR(
    [SWR_KEYS.QUERY_TEAM_GITHUB_LINKAGE, session?.teamId, organizationName],
    async () => {
      const client = await getClientWithSession();

      if (!session?.teamId) {
        throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
      }
      const { teamGithubLinkages } = await client.teamGithubLinkages({
        where: {
          teamId: {
            _eq: session.teamId,
          },
          organizationName: organizationName ? { _eq: organizationName } : {},
        },
        orderBy: {
          createdAt: OrderBy.Asc,
        },
      });

      return teamGithubLinkages;
    }
  );

  return {
    teamGithubLinkages: teamGithubLinkages || [],
    teamGithubLinkageLoading,
    mutateTeamGithubLinkages,
  };
};
