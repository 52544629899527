import { OrderBy } from "@/libs/api/generated/types";
import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";

export const useContractsQuery = () => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();

  // 最新の契約情報を取得
  const {
    data: latestContract,
    isLoading: latestContractLoading,
    mutate: mutateLatestContract,
  } = useSWR([SWR_KEYS.QUERY_LATEST_CONTRACT], async () => {
    const client = await getClientWithSession();

    if (!session?.teamId) {
      return undefined;
    }
    const { contracts } = await client.contracts({
      where: {
        teamId: {
          _eq: session.teamId,
        },
      },
      orderBy: {
        contractedAt: OrderBy.Desc,
      },
      limit: 1,
    });

    return contracts[0];
  });

  return {
    latestContract,
    latestContractLoading,
    mutateLatestContract,
  };
};
