import { Box, Flex, Text } from "@mantine/core";
import { IconCalendarEvent } from "@tabler/icons";

type ActivityDateType = {
  date: string;
};

const ActivityDate: React.FC<ActivityDateType> = ({ date }) => {
  return (
    <Flex align="center" gap={4} px={10} py={6}>
      <IconCalendarEvent size="19px" stroke={1.0} />
      <Text fz={"sm"} color="dimmed">
        {date}
      </Text>
      <Box w={"100%"} h={1} bg={"dark.4"} sx={{ flex: 1 }}></Box>
    </Flex>
  );
};

export default ActivityDate;
