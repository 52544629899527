import { NotificationAlert } from "@/components/NotificationAlert";
import TooltipWrapper from "@/components/new/common/TooltipWrapper";
import { useProjectsQuery } from "@/hooks/queries/useProjectsQuery";
import { useDialog } from "@/hooks/useDialog";
import { taskBoxSearchTextAtom } from "@/stores";
import {
  ActionIcon,
  Box,
  Flex,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconArrowLeft, IconPlus, IconSearch, IconX } from "@tabler/icons";
import { IconHelpSquareRounded } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { ModalTaskAdd, ModalTaskAddProps } from "../createTask/ModalTaskAdd";
import TaskStatusIcon from "../navbar/TaskStatusIcon";
import TaskBoxDescription from "./TaskBoxDescription";
import { TaskConstant, TaskConstantType } from "./constant";
import { onClickAddTaskButton } from "./AddTaskButton";

export const TaskStatusTitleMap: Record<TaskConstantType, string> = {
  TASK_BOX: "Task Box",
  TODAYS_TASK: "Today's Task",
  SUSPEND_TASK: "Suspended Task",
  DONE_TASK: "Done Task",
};

const TaskBoxHeader: React.FC<{
  type: TaskConstantType;
  isCurrentUser: boolean;
  isNotWorking: boolean;
  disabledAddTask?: boolean;
}> = ({ type, isCurrentUser, isNotWorking, disabledAddTask }) => {
  const theme = useMantineTheme();
  const { activeProjects } = useProjectsQuery();
  const { openDialog, closeDialog, isDialogMode } = useDialog<"addTask">();

  // タスク追加ダイアログのProps
  const [modalTaskAddProps, setModalTaskAddProps] = useState<ModalTaskAddProps>(
    {
      opened: false,
      focusOnProjectSelect: false,
      onClose: closeDialog,
      type,
    }
  );

  // タスク追加
  const onOpenAddTaskDialog = useCallback(() => {
    if (activeProjects.length === 0) {
      notifications.hide("NotExistTodaysTask");
      NotificationAlert({
        message: "プロジェクトに所属していません。管理者に連絡してください",
      });
      notifications.show({
        id: "NotExistTodaysTask",
        withCloseButton: false,
        autoClose: false,
        title: "「Today's Task」にタスクを移動してください",
        message: "",
        color: "red",
      });
      return;
    }
    openDialog("addTask");
    setModalTaskAddProps({
      ...modalTaskAddProps,
      opened: true,
      focusOnProjectSelect: true,
      project: {
        id: activeProjects[0]?.id || "",
        name: activeProjects[0]?.name || "",
      },
    });
  }, [modalTaskAddProps, activeProjects, openDialog]);

  // 検索フォーム
  const [searchText, setSearchText] = useRecoilState(taskBoxSearchTextAtom);
  const onChangeSearchText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    },
    [setSearchText]
  );
  const clearSearchText = useCallback(() => setSearchText(""), []);

  const [isSearchMode, setIsSearchMode] = useState(false);
  const onClickToggleSearch = useCallback(() => {
    setIsSearchMode((prev) => !prev);
  }, [setIsSearchMode]);

  const onClickBack = useCallback(() => {
    setIsSearchMode(false);
    clearSearchText();
  }, [clearSearchText]);

  return (
    <>
      {type === TaskConstant.TASK_BOX ? (
        isSearchMode ? (
          <Flex align={"center"} h={28} gap={8}>
            <ActionIcon size={"sm"} onClick={onClickBack}>
              <IconArrowLeft size="16px" stroke={1.0} />
            </ActionIcon>
            <TextInput
              h={28}
              size="xs"
              icon={<IconSearch size="13px" />}
              radius={999}
              placeholder="タスクを絞り込む"
              autoFocus
              rightSection={
                <ActionIcon
                  size={"xs"}
                  display={searchText.length > 0 ? "flex" : "none"}
                  sx={{ justifyContent: "center", alignItems: "center" }}
                  onClick={clearSearchText}
                >
                  <IconX size="13px" />
                </ActionIcon>
              }
              value={searchText}
              onChange={onChangeSearchText}
              sx={{ flex: 1 }}
            />
          </Flex>
        ) : (
          <Flex justify={"space-between"} align={"center"} h={28}>
            <Flex gap={8} justify={"center"} align={"center"}>
              <TaskStatusIcon type={type} />
              <Text fw={500} lh={1}>
                {TaskStatusTitleMap[type]}
              </Text>
              <TooltipWrapper
                label={<TaskBoxDescription type={type} />}
                position="bottom-start"
              >
                <Flex
                  justify={"center"}
                  align={"center"}
                  sx={{
                    color: theme.colors.dark[2],
                    transition: "color 0.3s",
                    "&:hover": {
                      color: theme.colors.dark[0],
                    },
                  }}
                >
                  <IconHelpSquareRounded size="18px" stroke={2.0} />
                </Flex>
              </TooltipWrapper>
              <TooltipWrapper position="bottom" label={"検索"}>
                <ActionIcon size={"sm"} onClick={onClickToggleSearch}>
                  <IconSearch size={13.5} stroke={1.0} />
                </ActionIcon>
              </TooltipWrapper>
            </Flex>
            {!disabledAddTask && (
              <TooltipWrapper label="タスク追加">
                <Box onClick={onClickAddTaskButton(isNotWorking)}>
                  <ActionIcon
                    onClick={onOpenAddTaskDialog}
                    disabled={!isCurrentUser || isNotWorking}
                  >
                    <IconPlus size="18px" stroke={2.0} />
                  </ActionIcon>
                </Box>
              </TooltipWrapper>
            )}
          </Flex>
        )
      ) : (
        <Flex justify={"space-between"} align={"center"} h={28}>
          <Flex gap={8} justify={"center"} align={"center"}>
            <TaskStatusIcon type={type} />
            <Text fw={500} lh={1}>
              {TaskStatusTitleMap[type]}
            </Text>
            <TooltipWrapper
              label={<TaskBoxDescription type={type} />}
              position="bottom-start"
            >
              <Flex
                justify={"center"}
                align={"center"}
                sx={{
                  color: theme.colors.dark[2],
                  transition: "color 0.3s",
                  "&:hover": {
                    color: theme.colors.dark[0],
                  },
                }}
              >
                <IconHelpSquareRounded size="18px" stroke={2.0} />
              </Flex>
            </TooltipWrapper>
          </Flex>
          {!disabledAddTask && (
            <TooltipWrapper label="タスク追加">
              <Box onClick={onClickAddTaskButton(isNotWorking)}>
                <ActionIcon
                  onClick={onOpenAddTaskDialog}
                  disabled={!isCurrentUser || isNotWorking}
                >
                  <IconPlus size="18px" stroke={2.0} />
                </ActionIcon>
              </Box>
            </TooltipWrapper>
          )}
        </Flex>
      )}
      {/* Modal: addTask */}
      {isDialogMode("addTask") && <ModalTaskAdd {...modalTaskAddProps} />}
    </>
  );
};

export default TaskBoxHeader;
