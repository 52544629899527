import { useTeamMembersQuery } from "@/hooks/queries/useTeamMembersQuery";
import { useTeamsQuery } from "@/hooks/queries/useTeamsQuery";
import { Routes, usePath } from "@/hooks/usePath";
import { isNavExpandedAtom } from "@/stores";
import { Divider, Navbar } from "@mantine/core";
import {
  IconChartHistogram,
  IconCheckbox,
  IconCircleLetterP,
  IconClipboardData,
  IconClockEdit,
  IconLayoutKanban,
  IconLayoutSidebar,
  IconSquareLetterP,
  IconUser,
  IconUsers,
} from "@tabler/icons";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import ActionIconWrapper from "../common/ActionIconWrapper";
import TooltipWrapper from "../common/TooltipWrapper";
import NavLinkItem from "./NavLinkItem";
import TeamMenu from "./TeamMenu";
import UserMenu from "./UserMenu";
import { IconShare2 } from "@tabler/icons-react";

type NavLinkType = {
  id: string;
  label: string;
  icon: React.ReactNode;
  path: string;
  routePathName: string;
};

const NavbarContentItems: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const router = useRouter();
  const { team } = useTeamsQuery();
  const { currentTeamMember } = useTeamMembersQuery();
  const { generatePath } = usePath();
  const [isNavExpanded, setIsNavExpanded] = useRecoilState(isNavExpandedAtom);
  const onClickExpandButton = useCallback(
    () => setIsNavExpanded((current) => !current),
    [setIsNavExpanded]
  );

  const TeamNavLinks: NavLinkType[] = [
    {
      id: Routes.TEAM_KANBAN.id,
      label: Routes.TEAM_KANBAN.name,
      icon: <IconLayoutKanban size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.TEAM_KANBAN.path,
        dynamicParams: { teamId: team?.id as string },
      }),
      routePathName: Routes.TEAM_KANBAN.path,
    },
    {
      id: Routes.REPORT_SUMMARY.id,
      label: Routes.REPORT_SUMMARY.name,
      icon: <IconChartHistogram size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.REPORT_SUMMARY.path,
        dynamicParams: { teamId: team?.id as string },
      }),
      routePathName: Routes.REPORT_SUMMARY.path,
    },
    {
      id: Routes.REPORT_DETAILS.id,
      label: Routes.REPORT_DETAILS.name,
      icon: <IconClipboardData size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.REPORT_DETAILS.path,
        dynamicParams: { teamId: team?.id as string },
      }),
      routePathName: Routes.REPORT_DETAILS.path,
    },
    {
      id: Routes.TEAM_MEMBERS.id,
      label: Routes.TEAM_MEMBERS.name,
      icon: <IconUsers size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.TEAM_MEMBERS.path,
        dynamicParams: { teamId: team?.id as string },
      }),
      routePathName: Routes.TEAM_MEMBERS.path,
    },
    {
      id: Routes.PROJECTS.id,
      label: Routes.PROJECTS.name,
      icon: <IconSquareLetterP size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.PROJECTS.path,
        dynamicParams: { teamId: team?.id as string },
      }),
      routePathName: Routes.PROJECTS.path,
    },
  ];

  const UserNavLinksSp: NavLinkType[] = [
    {
      id: Routes.ATTENDANCE_MOBILE.id,
      label: Routes.ATTENDANCE_MOBILE.name,
      icon: <IconCheckbox size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.ATTENDANCE_MOBILE.path,
        dynamicParams: {
          teamId: team?.id as string,
          teamMemberId: currentTeamMember?.id || "",
        },
      }),
      routePathName: Routes.ATTENDANCE_MOBILE.path,
    },
    {
      id: Routes.ADD_TO_HOME.id,
      label: Routes.ADD_TO_HOME.name,
      icon: <IconShare2 size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.ADD_TO_HOME.path,
        dynamicParams: {
          teamId: team?.id as string,
        },
      }),
      routePathName: Routes.ADD_TO_HOME.path,
    },
  ];

  const UserNavLinks: NavLinkType[] = [
    {
      id: Routes.TEAM_MEMBER_TASKS.id,
      label: Routes.TEAM_MEMBER_TASKS.name,
      icon: <IconCheckbox size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.TEAM_MEMBER_TASKS.path,
        dynamicParams: {
          teamId: team?.id as string,
          teamMemberId: currentTeamMember?.id || "",
        },
      }),
      routePathName: Routes.TEAM_MEMBER_TASKS.path,
    },
    {
      id: Routes.TEAM_MEMBER_WORKS.id,
      label: Routes.TEAM_MEMBER_WORKS.name,
      icon: <IconClockEdit size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.TEAM_MEMBER_WORKS.path,
        dynamicParams: {
          teamId: team?.id as string,
          teamMemberId: currentTeamMember?.id || "",
        },
      }),
      routePathName: Routes.TEAM_MEMBER_WORKS.path,
    },
    {
      id: Routes.TEAM_MEMBER_PROJECTS.id,
      label: Routes.TEAM_MEMBER_PROJECTS.name,
      icon: <IconCircleLetterP size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.TEAM_MEMBER_PROJECTS.path,
        dynamicParams: {
          teamId: team?.id as string,
          teamMemberId: currentTeamMember?.id || "",
        },
      }),
      routePathName: Routes.TEAM_MEMBER_PROJECTS.path,
    },
    {
      id: Routes.TEAM_MEMBER_PROFILE.id,
      label: Routes.TEAM_MEMBER_PROFILE.name,
      icon: <IconUser size="19px" stroke={1.0} />,
      path: generatePath({
        routePath: Routes.TEAM_MEMBER_PROFILE.path,
        dynamicParams: {
          teamId: team?.id as string,
          teamMemberId: currentTeamMember?.id || "",
        },
      }),
      routePathName: Routes.TEAM_MEMBER_PROFILE.path,
    },
  ];

  return (
    <>
      {!isMobile && (
        <Navbar.Section>
          <TooltipWrapper label="Navbar open / close" inline>
            <ActionIconWrapper m={6} onClick={onClickExpandButton}>
              <IconLayoutSidebar size="19px" stroke={1.0} />
            </ActionIconWrapper>
          </TooltipWrapper>
        </Navbar.Section>
      )}
      <Navbar.Section>
        <UserMenu isMobile={isMobile} />
        {isMobile
          ? UserNavLinksSp.map((link) => (
              <NavLinkItem
                key={link.id}
                {...link}
                tooltipDisabled={isNavExpanded}
                active={`${router.pathname}/` === link.routePathName}
              />
            ))
          : UserNavLinks.map((link) => (
              <NavLinkItem
                key={link.id}
                {...link}
                tooltipDisabled={isNavExpanded}
                active={`${router.pathname}/` === link.routePathName}
              />
            ))}
      </Navbar.Section>
      <Divider />
      <Navbar.Section>
        <TeamMenu isMobile={isMobile} />
        {!isMobile &&
          TeamNavLinks.map((link) => (
            <NavLinkItem
              key={link.id}
              {...link}
              tooltipDisabled={isNavExpanded}
              active={`${router.pathname}/` === link.routePathName}
            />
          ))}
      </Navbar.Section>
    </>
  );
};
export default NavbarContentItems;
