import { ReactElement } from "react";
import { AuthMiddleware } from "./AuthMiddleware";
import { MetaStateMiddleware } from "./MetaStateMiddleware";
import { ComponentErrorHandlingMiddleware } from "./ComponentErrorHandlingMiddleware";
import AsyncErrorHandlingMiddleware from "./AsyncErrorHandlingMiddleware";
type Props = {
  children: ReactElement;
};

export const Middleware = (props: Props): JSX.Element => {
  return (
    <ComponentErrorHandlingMiddleware>
      <AsyncErrorHandlingMiddleware>
        <MetaStateMiddleware>
          <AuthMiddleware>{props.children}</AuthMiddleware>
        </MetaStateMiddleware>
      </AsyncErrorHandlingMiddleware>
    </ComponentErrorHandlingMiddleware>
  );
};
