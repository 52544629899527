import { JWTType, TeamRole, UserLoginType } from "@/libs/api/generated/enum";
import {
  CreateGoogleLoginAuthzUrlInput,
  CreateTeamInput,
  CreateUserInput,
  LoginByGoogleInput,
  LoginByMicrosoftInput,
  LoginInput,
  LoginResult,
} from "@/libs/api/generated/types";
import { newLogger } from "@/libs/utils/logger";
import jwt, { JwtPayload } from "jsonwebtoken";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useApi } from "../useApi";
import { Routes } from "../usePath";
import { useSession } from "./useSession";

type Payload = JwtPayload & {
  typ?: JWTType;
  teamId?: string;
  teamMemberId?: string;
  roles?: Array<TeamRole | "notset">;
  loginType?: UserLoginType;
  twoFACertified?: boolean;
  twoFAEnabled?: boolean;
};

const logger = newLogger({ prefix: "useAuth" });
export const useAuth = () => {
  const { getClientAsGuest, getClientWithSession } = useApi();
  const { session, setCredentials, resetSession, reloadCredentials } =
    useSession();

  const router = useRouter();

  // アカウント登録でユーザーのメールアドレスを検証
  const verifyEmail = useCallback(
    async (input: { email: string; inflowRouteCode: string }) => {
      const client = getClientAsGuest();
      // 既にユーザーが登録されている場合はログインURLへのリンクを送信するため
      // ここではエラーハンドリングは不要
      await client.verificateEmailForCreateUser({
        input,
      });
    },
    [getClientAsGuest]
  );

  // アカウント本登録
  const signUp = useCallback(
    async (input: CreateUserInput) => {
      // アカウント作成
      const { createUser: createUserResponse } =
        await getClientAsGuest().createUser({
          input,
        });

      const newSession = setCredentials({
        teamId: undefined,
        credentials: createUserResponse,
      });
    },
    [getClientAsGuest, setCredentials]
  );

  // ログイン
  const signIn = useCallback(
    async (input: LoginInput) => {
      const { login: loginResponse } = await getClientAsGuest().login({
        input,
      });

      await setCredentialForSignIn({
        credentials: loginResponse,
      });
      void router.push(Routes.AFTER_SIGN_IN_PROCESS.path);
    },
    [getClientAsGuest, session?.accessToken, setCredentials]
  );

  const setCredentialForSignIn = useCallback(
    async ({ credentials }: { credentials: LoginResult }) => {
      logger.debug({ credentials });

      const accessToken = credentials.accessToken;
      const decodedAccessToken = await jwt.decode(accessToken, {
        complete: true,
      });
      const payload = decodedAccessToken?.payload as Payload;

      const authAtom = setCredentials({
        teamId: payload.teamId || undefined,
        credentials,
      });

      return authAtom;
    },
    [setCredentials]
  );

  // Googleログイン認証URLへ遷移
  const toGoogleLoginAuthUrl = useCallback(
    async (inflowRouteCode?: string) => {
      const client = getClientAsGuest();
      const input: CreateGoogleLoginAuthzUrlInput = {
        inflowRouteCode,
      };
      const result = await client.createGoogleLoginAuthzUrl({ input });
      void router.push(result.createGoogleLoginAuthzUrl.url);
    },
    [getClientAsGuest]
  );

  // Googleログイン
  const loginByGoogle = useCallback(
    async (input: LoginByGoogleInput) => {
      const client = getClientAsGuest();
      const { loginByGoogle } = await client.loginByGoogle({ input });

      await setCredentialForSignIn({
        credentials: loginByGoogle,
      });
      void router.push(Routes.AFTER_SIGN_IN_PROCESS.path);
    },
    [getClientAsGuest]
  );

  // Microsoftログイン認証URLへ遷移
  const toMicrosoftLoginAuthUrl = useCallback(
    async (inflowRouteCode?: string) => {
      const client = getClientAsGuest();
      const input: CreateGoogleLoginAuthzUrlInput = {
        inflowRouteCode,
      };
      const result = await client.createMicrosoftLoginAuthzUrl({ input });
      void router.push(result.createMicrosoftLoginAuthzUrl.url);
    },
    [getClientAsGuest]
  );

  // Microsoftログイン
  const loginByMicrosoft = useCallback(
    async (input: LoginByMicrosoftInput) => {
      const client = getClientAsGuest();
      const { loginByMicrosoft } = await client.loginByMicrosoft({ input });

      await setCredentialForSignIn({
        credentials: loginByMicrosoft,
      });
      void router.push(Routes.AFTER_SIGN_IN_PROCESS.path);
    },
    [getClientAsGuest]
  );

  // ログアウト
  const signOut = useCallback(() => {
    resetSession();
    void router.push("/sign-in");
  }, [router, resetSession]);

  // ログアウトのみ(遷移なし)
  const signOutOnly = useCallback(async () => {
    resetSession();
  }, [resetSession]);

  // 新規登録時のチーム作成
  const createNewTeam = useCallback(
    async (input: CreateTeamInput) => {
      const client = await getClientWithSession();
      const {
        createTeam: { teamId },
      } = await client.createTeam({
        input,
      });

      // トークンリフレッシュ
      await reloadCredentials({
        teamId,
      });

      return teamId;
    },
    [getClientWithSession, reloadCredentials]
  );

  // チーム招待のメールアドレス検証
  const verifyTeamInvitationEmail = useCallback(
    async (code: string) => {
      const client = await getClientWithSession();
      const { acceptTeamMemberInvitation } =
        await client.acceptTeamMemberInvitation({
          input: {
            code,
          },
        });
      await reloadCredentials(acceptTeamMemberInvitation);

      return acceptTeamMemberInvitation;
    },
    [getClientWithSession, reloadCredentials]
  );

  return {
    session,
    setCredentials,
    setCredentialForSignIn,
    reloadCredentials,
    verifyEmail,
    signUp,
    signIn,
    signOut,
    signOutOnly,
    createNewTeam,
    verifyTeamInvitationEmail,
    toGoogleLoginAuthUrl,
    loginByGoogle,
    toMicrosoftLoginAuthUrl,
    loginByMicrosoft,
  };
};
