import { UnauthorizedError } from "@/libs/api/errors";
import { authAtom } from "@/stores";
import { SWR_KEYS } from "@/stores/swr";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { useApi } from "../useApi";
import { OrderBy } from "@/libs/api/generated/types";
import { useTeamGithubLinkageQuery } from "./useTeamGithubLinkageQuery";

export const useTeamGithubLinkageProjectsQuery = (
  organizationName?: string
) => {
  const session = useRecoilValue(authAtom);
  const { getClientWithSession } = useApi();
  const { teamGithubLinkages } = useTeamGithubLinkageQuery(organizationName);

  const {
    data: teamGithubLinkageProjects,
    isLoading: teamGithubLinkageProjectsLoading,
  } = useSWR(
    [
      SWR_KEYS.QUERY_TEAM_GITHUB_LINKAGE_PROJECTS,
      session?.teamId,
      teamGithubLinkages,
      organizationName,
    ],
    async () => {
      if (
        organizationName &&
        (!teamGithubLinkages[0] || teamGithubLinkages[0].isRefreshTokenExpired)
      )
        return [];

      const client = await getClientWithSession();

      if (!session?.teamId) {
        throw new UnauthorizedError("401", "", "アクセストークンが存在しない");
      }
      const { teamGithubLinkageProjects } =
        await client.teamGithubLinkageProjects({
          where: {
            teamId: {
              _eq: session.teamId,
            },
            linkId: organizationName
              ? {
                  _eq: teamGithubLinkages[0].id,
                }
              : {},
            project: {
              archived: {
                _eq: false,
              },
              deletedAt: {
                _isNull: true,
              },
            },
          },
          orderBy: {
            createdAt: OrderBy.Asc,
          },
        });

      return teamGithubLinkageProjects;
    }
  );

  return {
    teamGithubLinkageProjects: teamGithubLinkageProjects || [],
    teamGithubLinkageProjectsLoading,
  };
};
