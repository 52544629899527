import { useAuth } from "@/hooks/features/useAuth";
import { useTeamsQuery } from "@/hooks/queries/useTeamsQuery";
import { logger } from "@/libs/utils/logger";
import { useRouter } from "next/router";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { HeadTitle } from "../HeadTitle";
import { PageError } from "../PageError";
import { usePath } from "@/hooks/usePath";
import { Routes } from "@/hooks/usePath";
import { useContractsQuery } from "@/hooks/queries/useContractsQuery";
import { Plan } from "@/types/enums";

type Props = {
  children: ReactElement;
};

export const ForceTwoFaMiddleware = (props: Props): JSX.Element => {
  const router = useRouter();
  const { generatePath } = usePath();
  const { session } = useAuth();
  const { team } = useTeamsQuery();
  const { latestContract } = useContractsQuery();

  const [isForceTwoFa, setIsForceTwoFa] = useState<boolean>(false);

  const onClick = useCallback(() => {
    if (!session) return;
    void router.push(
      generatePath({
        routePath: Routes.TEAM_MEMBER_SETTINGS.path,
        dynamicParams: {
          teamId: session.teamId,
          teamMemberId: session.teamMemberId,
        },
      })
    );
  }, [generatePath, router, session]);

  // 二段階認証強制が有効かつユーザーが二段階認証未設定の場合に設定画面へ強制遷移させる
  useEffect(() => {
    // エンタープライズプランでない場合は無効
    if (latestContract?.plan.code !== Plan.ENTERPRISE) {
      return;
    }

    setIsForceTwoFa(false);
    const teamForceTwofa = team?.forceTwofa;
    if (
      `${router.pathname}/` === Routes.TEAM_MEMBER_SETTINGS.path ||
      `${router.pathname}/` === Routes.TEAM_SETTINGS.path
    ) {
      return;
    }
    if (!teamForceTwofa) {
      return;
    }
    if (session?.twoFAEnabled) {
      return;
    }
    logger.info("二段階認証設定 | ", `ユーザーの二段階認証が未設定`);
    setIsForceTwoFa(true);
  }, [latestContract?.plan.code, router, session, team?.forceTwofa]);

  if (isForceTwoFa) {
    return (
      <>
        <HeadTitle title="SSOログイン制限"></HeadTitle>
        <PageError
          title="二段階認証設定の強制が有効です"
          description={`現在、チーム設定により二段階認証が強制されています。\nアカウント設定画面に移動し、二段階認証を\n有効化してください。`}
          buttonText={"アカウント設定画面へ"}
          onClick={onClick}
        ></PageError>
      </>
    );
  }

  return <>{props.children}</>;
};
