import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";

export const NotificationSuccess = ({ message }: { message: string }) => {
  notifications.show({
    message,
    autoClose: 6000,
    color: "teal",
    icon: <IconCheck size={18} />,
  });
};
