import { logger } from "@/libs/utils/logger";
import { HeadTitle } from "./HeadTitle";
import { PageError } from "./PageError";
import { useAuth } from "@/hooks/features/useAuth";
import { useRouter } from "next/router";
import { ErrorCode } from "@/libs/api/generated/codes";

type ErrorDetails = {
  headTitle: string;
  titleText: string;
  descriptionText: string;
  buttonText?: string;
  onClick: () => void;
};

const Error: React.FC<{ errorCode: ErrorCode }> = ({ errorCode }) => {
  logger.info("Errorコンポーネント", { errorCode });

  const { signOut } = useAuth();
  const router = useRouter();

  let errorDetails: ErrorDetails;

  switch (errorCode) {
    case ErrorCode.ResourceNotFound:
      errorDetails = {
        headTitle: "お探しのページは見つかりませんでした",
        titleText: "お探しのページは\n見つかりませんでした",
        descriptionText: `あなたがお探しのページは消去されたか\nURLが変更されているため、\n見つけることができませんでした。\n（404）`,
        onClick: router.back,
      };
      break;
    case ErrorCode.NotAllowedIpAddress:
      errorDetails = {
        headTitle: "アクセス制限",
        titleText: "アクセス制限",
        descriptionText: `チームにIPアドレス制限が設定されているか、\nアクセストークンの有効期限が切れています。\n再ログインするか、ご利用のIPアドレスをご確認ください。\n（403）`,
        buttonText: "TOPへ",
        onClick: () => {
          location.href = "/sign-out";
        },
      };
      break;
    default:
      errorDetails = {
        headTitle: "サーバーエラーが発生しました",
        titleText: "お探しのページは\n表示できませんでした",
        descriptionText: `サーバーエラーが発生しました。\n再度時間をおいてアクセスしてください。\n（503）`,
        onClick: router.back,
      };
  }

  // ステータスコードに対応するエラーメッセージを取得
  return (
    <>
      <HeadTitle title={errorDetails.headTitle}></HeadTitle>
      <PageError
        title={errorDetails.titleText}
        description={errorDetails.descriptionText}
        onClick={errorDetails.onClick}
        buttonText={errorDetails.buttonText}
      ></PageError>
    </>
  );
};

export default Error;
