import { useDevice } from "@/hooks/features/useDevice";
import { minuteToHour } from "@/utils/date";
import { Flex, Space, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { CustomTimeInput } from "./pages/works/CustomTimeInput";

interface FormWorkingTimeProps<T> {
  form: UseFormReturnType<T, (values: T) => T>;
  attendanceDiffTime: number;
}

export const FormWorkingTime = <EndOfWorkForm,>({
  form,
  attendanceDiffTime,
}: FormWorkingTimeProps<EndOfWorkForm>): JSX.Element => {
  const { isTabletOrSmartphone } = useDevice();
  return (
    <Flex align={"center"}>
      <Flex gap={8} align="center">
        <CustomTimeInput {...form.getInputProps(`startedTime`)} />
        <div>〜</div>
        <CustomTimeInput {...form.getInputProps(`finishedTime`)} />
      </Flex>
      <Space w="md" />
      <Text size={isTabletOrSmartphone ? "xs" : "sm"}>
        {minuteToHour(attendanceDiffTime, { isJapanese: true })}
      </Text>
    </Flex>
  );
};
