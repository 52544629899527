import { useDevice } from "@/hooks/features/useDevice";
import { useSession } from "@/hooks/features/useSession";
import { switchingTeamAtom } from "@/stores";
import {
  Avatar,
  Flex,
  Group,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import { useCallback } from "react";
import { useRecoilState } from "recoil";

type SwitchTeamButtonType = {
  id: string;
  name: string;
  imgPath: string;
  active?: boolean;
};

const SwitchTeamButton: React.FC<SwitchTeamButtonType> = ({
  id,
  name,
  imgPath,
  active,
}) => {
  const { reloadCredentials } = useSession();
  const theme = useMantineTheme();
  const [switchingTeam, setSwitchingTeam] = useRecoilState(switchingTeamAtom);
  const { isTabletOrSmartphone } = useDevice();

  const onClickButton = useCallback(async () => {
    setSwitchingTeam(true);
    const newSession = await reloadCredentials({
      teamId: id,
    });

    location.href = isTabletOrSmartphone
      ? `/t/sp/${id}/members/${newSession.teamMemberId}/tasks`
      : `/t/${id}/members/${newSession.teamMemberId}/tasks`;
  }, [id, reloadCredentials, setSwitchingTeam, isTabletOrSmartphone]);

  return (
    <UnstyledButton
      sx={{
        width: "100%",
        border: `1px solid ${
          active ? theme.colors.violet[5] : theme.colors.dark[3]
        }`,
        padding: "8px",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: active ? "" : theme.colors.dark[4],
          cursor: active ? "auto" : "pointer",
        },
      }}
      disabled={active}
      onClick={onClickButton}
    >
      <Flex align={"center"} justify={"space-between"}>
        <Group spacing={8}>
          <Avatar src={imgPath} radius="sm" size={18} />
          <Text fz={"sm"}>{name}</Text>
        </Group>
        {active && <IconCheck size={20} color={theme.colors.violet[5]} />}
      </Flex>
    </UnstyledButton>
  );
};

export default SwitchTeamButton;
