import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export const formatDate = (date?: string) => {
  if (!date) return "";
  return dayjs(date).format("YYYY/MM/DD");
};

export const formatDateWithWeek = (date?: string) => {
  if (!date) return "";
  return dayjs(date).format("YYYY/MM/DD(ddd)");
};

export const formatMonthDateWithWeek = (date?: string) => {
  if (!date) return "";
  return dayjs(date).format("MM/DD(ddd)");
};

export const formatTime = (date?: string) => {
  if (!date) return "";
  return dayjs(date).format("HH:mm");
};

export const formatDateTimeWithMill = (date?: string) => {
  if (!date) return "";
  return dayjs(date).format("YYYY/MM/DD HH:mm:ss.SSS");
};

export const formatDateTimeForWork = (date?: string) => {
  if (!date) return "--/--/-- --:--";
  return dayjs(date).format("YYYY/MM/DD HH:mm");
};

export const formatDateTimeForReportFileName = (date?: string) => {
  if (!date) return "";
  return dayjs(date).format("YYYYMMDDHHmmss");
};

export const formatDateTimeForReportDetail = (date?: string) => {
  if (!date) return "--/--/--";
  return dayjs(date).format("YYYY/M/D");
};

export const minuteToHour = (
  minutes?: number,
  option?: {
    isAbs?: boolean;
    isJapanese?: boolean;
    over24?: boolean;
  }
) => {
  if (!minutes) return option?.isJapanese ? "0分" : "00:00";

  if (option?.over24) {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    const hoursString = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesString =
      minutesLeft < 10 ? `0${minutesLeft}` : `${minutesLeft}`;

    return `${hoursString}:${minutesString}`;
  }

  const milliseconds = minutes * 60 * 1000;

  const time = option?.isAbs
    ? dayjs.duration(Math.abs(milliseconds))
    : dayjs.duration(milliseconds);

  const hours = time.hours(); // 時間単位を取得
  const minutesLeft = time.minutes(); // 分単位を取得

  if (option?.isJapanese) {
    const formattedTime =
      hours === 0 ? `${minutesLeft}分` : `${hours}時間${minutesLeft}分`;
    return formattedTime;
  } else {
    return time.format("HH:mm");
  }
};

// 時刻の文字列を分に変換する
// 例: "10:30" => 630
export const timeStringToMinutes = (time: string) => {
  const [hour, minute] = time.split(":");
  return parseInt(hour) * 60 + parseInt(minute);
};

// 秒数をHH:mm形式にフォーマットする
export const secondsToHHMM = (seconds: number) => {
  const formattedTime = dayjs()
    .startOf("day")
    .add(seconds, "seconds")
    .format("HH:mm");
  return formattedTime;
};
