import { WorkStatus } from "@/libs/api/generated/enum";
import { Group } from "@mantine/core";
import { FinishButton } from "../pages/works/FinishButton";
import { ResumeButton } from "../pages/works/ResumeButton";
import { StartButton } from "../pages/works/StartButton";
import { SuspendButton } from "../pages/works/SuspendButton";

export const LoggedInHeaderActionsButtons = ({
  workStatus,
}: {
  workStatus?: string;
  isMobile?: boolean;
}): JSX.Element => {
  if (workStatus === WorkStatus.NOT_WORKING) {
    return (
      <Group spacing="md">
        <StartButton></StartButton>
      </Group>
    );
  }
  if (workStatus === WorkStatus.WORKING) {
    return (
      <Group spacing="md">
        <SuspendButton />
        <FinishButton />
      </Group>
    );
  }
  if (workStatus === WorkStatus.SUSPENDED) {
    return (
      <Group spacing="md">
        <ResumeButton />
      </Group>
    );
  }
  return <></>;
};
