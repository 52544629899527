import { Box, useMantineTheme } from "@mantine/core";
import { IconLock } from "@tabler/icons";
import { TaskConstant, TaskConstantType } from "./constant";

const TaskBoxDescription: React.FC<{
  type: TaskConstantType;
  color?: string;
}> = ({ type, color }) => {
  const theme = useMantineTheme();

  let description: React.ReactNode = <div></div>;

  const taskBoxDescription = (
    <div>
      <p>Task Boxには、作業予定のタスクを入れます。</p>
      <p>新しいタスクが発生したときにタスクを追加してください。</p>
      <br />
      <p>
        Factogでは、直近作業予定の<strong>{` 全てのタスク `}</strong>
        をTaskBoxで管理することを推奨しています。
      </p>
      <p>
        チーム設定から外部サービス連携を設定すると、外部サービスからタスクを取り込むことも可能です。
      </p>
      <br />
      <p>例:</p>
      <ul style={{ paddingLeft: "12px" }}>
        <li>Google Calendar: 朝会</li>
        <li>Google Calendar: 顧客定例</li>
        <li>Backlog: 問い合わせ対応</li>
        <li>GitHub: 開発機能のレビュー</li>
        <li>GitHub: 不具合調査</li>
        <li>Jira: 新機能の設計</li>
        <li>見積もり</li>
        <li>請求</li>
        <li>提案資料の作成</li>
        <li>メンバーサポート</li>
      </ul>
    </div>
  );

  const todaysTaskDescription = (
    <div>
      <p>Today&apos;s Taskには、本日作業するタスクを入れます。</p>
      <p>
        最上部に配置されたタスクは現在作業中のタスクとなり、作業時間がリアルタイムで計測されます。
      </p>
      <br />
      <p>
        Factogでは、TaskBoxからToday&apos;s
        Taskに移動したタスクの優先度を整理してから仕事を進める方法を推奨しています。
      </p>
    </div>
  );

  const suspendTaskDescription = (
    <div>
      <p>Suspended Taskには、本日作業したが完了しなかったタスクを入れます。</p>
      <p>Suspended Taskにあるタスクは、作業終了後にTask Boxに戻ります。</p>
      <br />
      <p>以下のケースなどに追加してください。</p>
      <ul style={{ paddingLeft: "12px" }}>
        <li>割り込み作業が発生して当日工数的に間に合わなくなった。</li>
        <li>作業中に顧客への問い合わせが発生して、作業を中断した。</li>
        <li>
          複数日に渡る作業で、当日分の作業は完了したが、翌日再開する予定がある。
        </li>
      </ul>
    </div>
  );

  const doneTaskDescription = (
    <div>
      <p>Done Taskには、完了したタスクを入れます。</p>
      <p>Done Taskにあるタスクは、作業終了後に削除されます。</p>
      <br />
      <p>
        ただし、ロック<IconLock size="13px"></IconLock>
        されているタスクは、作業終了後にTask Boxに戻ります。
      </p>
    </div>
  );

  switch (type) {
    case TaskConstant.TASK_BOX:
      description = taskBoxDescription;
      break;
    case TaskConstant.TODAYS_TASK:
      description = todaysTaskDescription;
      break;
    case TaskConstant.SUSPEND_TASK:
      description = suspendTaskDescription;
      break;
    case TaskConstant.DONE_TASK:
      description = doneTaskDescription;
      break;
    default:
      description = null;
  }

  return (
    <Box
      w={"100%"}
      maw={320}
      sx={{
        whiteSpace: "pre-wrap",
        fontSize: theme.fontSizes.sm,
        color: color ?? theme.colors.dark[0],
        lineHeight: 1.7,
      }}
    >
      {description}
    </Box>
  );
};

export default TaskBoxDescription;
